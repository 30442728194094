import { useState } from "react";
import { Flex, useColorModeValue } from "@chakra-ui/react";

interface VideoPanelProps {
  url: string;
}

function VideoPanel({ url }: VideoPanelProps) {
  // Theme
  const bg = useColorModeValue("gray.300", "dark.600");

  // State
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Flex
      as="video"
      muted
      controls={isHovered}
      src={url}
      w={"100%"}
      minH={"358px"}
      bg={bg}
      objectFit="cover"
      borderRadius={[null, null, null, "8px", "10px", "12px"]}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    />
  );
}

export default VideoPanel;
