import { useState, ChangeEvent } from "react";
import {
    FormControl, FormLabel, Input, Button, Box, Text, InputRightElement, InputGroup, useToast, Icon, useColorMode,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

import { isPasswordValid } from "views/auth/helpers";
import { axiosClient } from "api/axios";
import { useDispatch } from "react-redux";
import { setCredentials } from "redux/features/auth/authSlice";


export default function ChangePassword({
    username,
    resetPasswordSession,
    setResetPasswordSession
}: {
    username: string;
    resetPasswordSession: string | null,
    setResetPasswordSession: (val: string | null) => void,
}) {
    // Hooks
    const toast = useToast();
    const dispatch = useDispatch();

    const [submitting, setSubmitting] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [showPassword, setShowPassword] = useState({
        password: false,
        confirmPassword: false,
    });
    const [passwordForm, setPasswordForm] = useState({
        newPassword: "",
        confirmNewPassword: "",
    });

    // Theme
    const { setColorMode } = useColorMode();

    setColorMode("light");

    // Handlers
    const handleInputPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setErrorMsg("");
        setPasswordForm({
            ...passwordForm,
            [name]: value,
        });
    };

    const handlePasswordToggle = (pass: "PASSWORD" | "CONFIRM_PASSWORD") => {
        if (pass === "PASSWORD") {
            setShowPassword({
                ...showPassword,
                password: !showPassword.password,
            });
        } else {
            setShowPassword({
                ...showPassword,
                confirmPassword: !showPassword.confirmPassword,
            });
        }
    };

    // form submit
    const handleSubmit = async () => {
        try {
            const { newPassword, confirmNewPassword } = passwordForm;


            if (!username || !newPassword) return;

            const passwordError = isPasswordValid(newPassword, confirmNewPassword);

            if (passwordError.length > 0) {
                setErrorMsg(passwordError);
                setSubmitting(false);
                return;
            }

            if (!resetPasswordSession) {
                setErrorMsg('No session, try login again!');
                setSubmitting(false);
                return;
            }
            setSubmitting(true);
            const response = await axiosClient.post(
                "/api/new_password_required",
                JSON.stringify({ username, newPassword, resetPasswordSession }),
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            );
            setResetPasswordSession(null);
            const { user, roles } = response?.data;

            dispatch(setCredentials({ user, roles }));
            toast({
                position: "bottom-right",
                duration: 1500,
                render: () => (
                    <Box
                        color="white"
                        p={3}
                        bg={"highlight.primary"}
                        borderRadius={"6px"}
                    >
                        Password was reset successfully
                    </Box>
                ),
            });
        } catch (error: any) {
            // No response from server
            if (!error.response) {
                toast({
                    description: "No server response!",
                    status: "error",
                });
                return;
            }
            toast({
                description: error.response.data.message,
                status: "error",
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <>
            <Text fontSize="sm" color="brand.primary700">
                Please change your password
            </Text>
            <FormControl id="newPassword" w="100%">
                <FormLabel>New Password</FormLabel>
                <InputGroup>
                    <Input
                        type={showPassword.password ? "text" : "password"}
                        name="newPassword"
                        placeholder="Enter your new password"
                        value={passwordForm.newPassword}
                        onChange={handleInputPasswordChange}
                    />
                    <InputRightElement>
                        <Icon
                            // aria-label={showPassword.password ? "Hide Password" : "Show Password"}
                            as={showPassword.password ? ViewOffIcon : ViewIcon}
                            color={"secondary.300"}
                            cursor={"pointer"}
                            onClick={() => handlePasswordToggle("PASSWORD")}
                        />
                    </InputRightElement>
                </InputGroup>
            </FormControl>
            <FormControl id="confirmNewPassword" w="100%">
                <FormLabel>Confirm New Password</FormLabel>
                <InputGroup>
                    <Input
                        type={showPassword.confirmPassword ? "text" : "password"}
                        name="confirmNewPassword"
                        placeholder="Confirm your new password"
                        value={passwordForm.confirmNewPassword}
                        onChange={handleInputPasswordChange}
                    />
                    <InputRightElement>
                        <Icon
                            aria-label={
                                showPassword.confirmPassword
                                    ? "Hide Password"
                                    : "Show Password"
                            }
                            as={showPassword.confirmPassword ? ViewOffIcon : ViewIcon}
                            color={"secondary.300"}
                            cursor={"pointer"}
                            onClick={() => handlePasswordToggle("CONFIRM_PASSWORD")}
                        />
                    </InputRightElement>
                </InputGroup>
            </FormControl>
            <Text fontSize="sm" color="jasper">
                {errorMsg}
            </Text>
            <Button
                colorScheme="secondary"
                size="md"
                onClick={handleSubmit}
                w="100%"
                isLoading={submitting}
                loadingText="Processing"
            >
                Reset Password
            </Button>
        </>
    );
}
