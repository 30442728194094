import { Fragment } from "react";
import { BsFillCircleFill } from "react-icons/bs";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Tooltip,
  Icon,
  Divider,
  useColorModeValue,
  Flex,
} from "@chakra-ui/react";

import { AssayProps } from "models/assays/AssayProps";
import { useNavigate } from "react-router-dom";

function stringToColor(str: string) {
  const colors = [
    "#555E50",
    "#008A0D",
    "#161FA8",
    "#281F5D",
    "#FD2400",
    "#E49FF6",
    "#EB7013",
    "#930081",
    "#F7BBC7",
    "#00F60B",
  ];
  const hash = [...str].reduce((h, c) => c.charCodeAt(0) + ((h << 5) - h), 0);
  const colorIndex = Math.abs(hash) % colors.length;
  return colors[colorIndex];
}
const assayTypeToColorMap: { [key: string]: string } = {};

interface AssaySummaryCardProps {
  index: number;
  assay: AssayProps;
  onNameClick: (s: string) => void;
  onAssayTypeClick: (s: string) => void;
}
export default function AssaySummaryCard({
  index,
  assay,
  onNameClick,
  onAssayTypeClick,
}: AssaySummaryCardProps) {
  const indicationColor = stringToColor(assay?.overview?.indication);

  const assayTypes = assay?.overview.assay_type
    ?.split(",")
    .map((type: string) => type.trim());

  const navigate = useNavigate();

  // Theme
  const summaryColor = useColorModeValue("gray.600", "gray.300");
  const summaryHeaderColor = useColorModeValue("primary.400", "primary.300");
  const bg = useColorModeValue("neutral.100", "dark.700");
  const cardHoverBgColor = useColorModeValue("secondary.100", "dark.600");

  return (
    <Card key={assay.assay_id} bg={bg} _hover={{ bg: cardHoverBgColor }}>
      <CardHeader
        onClick={() => navigate(`/assays/${assay.assay_id}`)}
        cursor="pointer"
      >
        <Heading
          size={[null, null, null, "xs", "xs", "sm"]}
          color={summaryHeaderColor}
        >
          {assay.name}
        </Heading>
      </CardHeader>

      <CardBody
        borderWidth={1}
        borderTopStyle="dashed"
        borderBottomStyle="dashed"
        borderColor="gray.400"
        borderLeft="none"
        borderRight="none"
        fontSize={[null, null, null, "xs", "xs", "sm"]}
      >
        <Text color={summaryColor} fontSize="14px">
          {assay.summary}
        </Text>
      </CardBody>

      <CardFooter justify="space-between" flexDirection="column" width="100%">
        {/* indication */}
        <Flex
          cursor="pointer"
          onClick={() => onNameClick(assay?.overview?.indication)}
          color="primary.300"
          textDecoration="underline"
        >
          <Tooltip label={assay?.overview?.indication} placement="left" mr={2}>
            <span>
              <Icon
                as={BsFillCircleFill}
                mr={2}
                boxSize={3}
                color={indicationColor}
              />
            </span>
          </Tooltip>
          <Text fontSize="xs" flexWrap="wrap" mt={0.8}>
            {assay?.overview?.indication}
          </Text>
        </Flex>

        <Divider orientation="vertical" />

        {/* assay types */}
        {assayTypes?.map((type: string, i: number) => {
          const typeColor = stringToColor(type); // Color for each assay type
          if (!assayTypeToColorMap[type]) {
            assayTypeToColorMap[type] = stringToColor(type);
          }

          return (
            <Fragment key={i}>
              <Flex
                mt={2}
                cursor="pointer"
                onClick={() => onAssayTypeClick(type)}
                color="primary.300"
                textDecoration="underline"
              >
                <Tooltip label={type} placement="left" mr={2}>
                  <span>
                    <Icon
                      as={BsFillCircleFill}
                      mr={2}
                      boxSize={3}
                      color={typeColor}
                    />
                  </span>
                </Tooltip>
                <Text flexWrap="wrap" fontSize="xs">
                  {type}
                </Text>
              </Flex>
              {index < assayTypes.length - 1 && (
                <Divider orientation="vertical" />
              )}
            </Fragment>
          );
        })}
      </CardFooter>
    </Card>
  );
}
