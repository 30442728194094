import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

export interface SavedCompoundProps {
  compound_id: string;
  name: string;
}

export interface SavedAssayProps {
  assay_id: string;
  name: string;
}

export interface SavedLiteratureProps {
  id: string;
  title: string;
}

export interface SavedChartProps {
  compound_id: string;
  name: string;
  selected_feature: string;
}

export interface SavedFolderProps {
  id: string;
  name: string;
  compounds: SavedCompoundProps[];
  assays: SavedAssayProps[];
  literatures: SavedLiteratureProps[];
  treeOfLifes: SavedChartProps[];
}

export interface SavedStateProps {
  loading: boolean;
  error: string;
  savedFolders: SavedFolderProps[];
}

const initialState = {
  loading: false,
  error: "",
  savedFolders: [],
};

const savedSlice = createSlice({
  name: "saved",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true
      state.error = ''
    },
    setFail: (state, action) => {
      state.error = action.payload;
      state.savedFolders = []
      state.loading = false
    },
    setSuccess: (state, action) => {
      state.savedFolders = action.payload;
      state.error = ''
      state.loading = false
    },
  }
});

export const { setSuccess, setLoading, setFail } = savedSlice.actions;

export default savedSlice.reducer;

const selectSavedState = (state: { saved: SavedStateProps }) => state.saved;

export const selectCurrentSavedData = createSelector(
  [selectSavedState],
  (saved) => ({
    loading: saved.loading,
    error: saved.error,
    savedFolders: saved.savedFolders,
  })
);
