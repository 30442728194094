import { Button, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import { MdOutlinePlaylistRemove } from "react-icons/md";
import { MdOutlinePlaylistAddCheck } from "react-icons/md";

interface ButtonProps {
  allSelected: boolean;
  onSelectAll: () => void;
}

function SelectAllToEmbedButton({ allSelected, onSelectAll }: ButtonProps) {
  const hoverBg = useColorModeValue("blue.50", "blue.600");
  const borderColor = useColorModeValue("blue.400", "blue.600");
  const hoverColor = useColorModeValue("blue.400", "blue.50");

  return (
    <Button
      gap={1}
      borderColor={borderColor}
      color={"secondary.400"}
      h={"fit-content"}
      borderRadius={"20px"}
      px={5}
      py={1.5}
      _hover={{ bg: hoverBg, color: hoverColor }}
      onClick={onSelectAll}
      variant="outline"
    >
      <Icon
        as={allSelected ? MdOutlinePlaylistRemove : MdOutlinePlaylistAddCheck}
        boxSize={"18px"}
      />
      <Text fontWeight={"400"} fontSize={"14px"}>
        {allSelected ? "Unselect all" : "Select all"}
      </Text>
    </Button>
  );
}

export default SelectAllToEmbedButton;
