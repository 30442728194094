const HOST_URL = window.location.protocol + "//" + window.location.hostname;

export const environment = {
  BACKEND_API:
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "http://127.0.0.1:8000"
      : `${HOST_URL}`,
};

export const FeedbackParams = {
  SERVICE_ID: "service_7wmvk8q",
  TEMPLATE_ID: "feedback_template",
  PUBLIC_KEY: "MptCfhc-nceirf57E",
};

export const SubscriptionParams = {
  SERVICE_ID: "service_7wmvk8q",
  TEMPLATE_ID: "template_hby6us9",
  PUBLIC_KEY: "MptCfhc-nceirf57E",
};

export const ContactParams = {
  SERVICE_ID: "service_l267a3e",
  TEMPLATE_ID: "contact_template",
  PUBLIC_KEY: "HgGHRjPm2WVGXxgBq",
};
