import {
  ChartDataProps,
  LeafNode,
  Link,
  Node,
  OptionItem,
  ReducedCompoundProps,
  SimilaritiesProps,
  CharDataExtendedProps,
} from ".";

const MAX_COMPOUNDS: number = 20;

export function filterOptions(
  value: string,
  compounds: OptionItem[]
): OptionItem[] {
  return compounds.filter((i: OptionItem) => {
    return i.label.toLowerCase().includes(value.toLowerCase());
  });
}

// extract level 3 elements by level 2 source node name
function extractCompounds(_data: ChartDataProps, sourceName: string): string[] {
  // find all links with the given level 2 source node name
  const sources = _data.links.filter((link) => link.source === sourceName);

  // extract the level 3 target node names from the links
  const targets = sources.map((link) => link.target);

  return targets;
}

export function selectSomeCompounds(_data: ChartDataProps): string[] {
  // get similar compounds names as array of arrays
  // each array contains similar compounds for a specific feature value
  const featureValues: string[] = _data.nodes
    .filter((node: Node) => node.level === 2)
    .map((node: Node) => node.name);

  const compounds = featureValues.map((feature: string) =>
    extractCompounds(_data, feature)
  );

  // sum the length of arrays within nested array
  const totalLength: number = compounds.reduce((l, arr) => l + arr.length, 0);

  // If the total is within the limit, return all compounds items
  if (totalLength < MAX_COMPOUNDS) {
    return compounds
      .reduce((res, arr) => res.concat(arr), [])
      .slice(0, MAX_COMPOUNDS);
  }

  // ratio for each array
  const ratios: number[] = compounds.map((arr) => arr.length / totalLength);

  // maximum number of compounds to pick from each array
  const maxCmpdsPerArray: number[] = ratios.map((r) =>
    Math.floor(MAX_COMPOUNDS * r) === 0 ? 1 : Math.floor(MAX_COMPOUNDS * r)
  );

  // remaining compounds after picking from each array
  const remCmpds: number =
    MAX_COMPOUNDS - maxCmpdsPerArray.reduce((t, c) => t + c, 0);

  // Distribute the remaining compounds among the compounds proportionally
  const selectedArrays: string[][] = compounds.map((arr, idx) => {
    const itemsToPick: number =
      maxCmpdsPerArray[idx] + (idx === 0 ? remCmpds : 0);
    return arr.slice(0, itemsToPick);
  });

  // Concatenate the selected compounds from all compounds
  return selectedArrays
    .reduce((res, arr) => res.concat(arr), [])
    .slice(0, MAX_COMPOUNDS);
}

export function prepareChartData(
  _data: ChartDataProps,
  _compounds: string[]
): ChartDataProps {
  let filteredNodes = _data.nodes.filter((node: Node | LeafNode) => {
    // take root node +  features nodes + selected cmpds nodes
    if (node.level <= 2 || _compounds.includes(node.name)) {
      return true;
    }

    // Remove links where the target is the current node's name
    return false;
  });

  filteredNodes = filteredNodes.map((node: LeafNode) => {
    if (node.level === 3) return { ...node, checked: true };
    else return node;
  });

  // ✅ Show all compounds array if length below the threshold
  let filteredLinks: Link[] =
    _compounds.length < MAX_COMPOUNDS
      ? _data.links
      : _data.links.filter((link: Link) => {
          return filteredNodes.some((node) => node.name === link.target);
        });

  return { nodes: filteredNodes, links: filteredLinks };
}

export function getChartData(
  similars: {
    compound: ReducedCompoundProps;
    similarities: { [x: string]: ReducedCompoundProps[] | [] };
  },
  allData: ChartDataProps
): CharDataExtendedProps {
  const selectedNames = selectSomeCompounds(allData);
  let selectedCompounds: ChartDataProps = { nodes: [], links: [] };

  const _existingData: boolean =
    similars && allData.nodes.length > 0 && allData.links.length > 0;

  if (_existingData) {
    selectedCompounds = prepareChartData(allData, selectedNames);
  }
  return { selectedCompounds, selectedNames };
}

export function createRootNode(data: ChartDataProps, compound_name: string) {
  const rootNode: Node = {
    name: compound_name,
    level: 1,
  };
  data?.nodes.push(rootNode);
}

export function appendNodesAndLinks(
  data: ChartDataProps,
  similarities: SimilaritiesProps,
  compound_name: string
) {
  for (const [key, value] of Object.entries(similarities)) {
    const featureNode: Node = {
      name: key,
      level: 2,
    };
    const rootFeaturesLink: Link = {
      source: compound_name,
      target: key,
      value: 1,
    };

    data?.nodes.push(featureNode);
    data?.links.push(rootFeaturesLink);

    if ((value as ReducedCompoundProps[])?.length > 0) {
      (value as ReducedCompoundProps[])?.forEach(
        (item: ReducedCompoundProps) => {
          if (
            item?.generic_name === compound_name ||
            item?.name === compound_name
          ) {
            return;
          } else {
            const similarCompoundNode: LeafNode = {
              leaf_id: item?.compound_id,
              name: item?.generic_name || item.name,
              level: 3,
              checked: false,
            };

            // if similar compound already exists
            if (
              !data?.nodes.find(
                (node: Node) =>
                  node.name === similarCompoundNode.name &&
                  node.level === similarCompoundNode.level
              )
            ) {
              data?.nodes.push(similarCompoundNode);
            }
            const FeatureCompoundLink: Link = {
              source: key,
              target: similarCompoundNode.name,
              value: 1,
            };

            data?.links.push(FeatureCompoundLink);
          }
        }
      );
    }
  }
}
