import {
  useColorModeValue,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverBody,
  Icon,
} from "@chakra-ui/react";

import { InfoIcon } from "@chakra-ui/icons";

function MoreInfo() {
  // Theme
  const infoColor = useColorModeValue("primary.200", "highlight.primary");

  return (
    <Popover placement="top-start" closeOnBlur={false} trigger="hover">
      <PopoverTrigger>
        <Icon
          boxSize={"16px"}
          color={infoColor}
          as={InfoIcon}
          cursor={"pointer"}
          _hover={{ bg: "transparent" }}
        />
      </PopoverTrigger>
      <PopoverContent
        bg={"primary.300"}
        color={"primary.100"}
        p={2}
        borderRadius={"10px"}
      >
        <PopoverHeader fontSize={"14px"} fontWeight="bold" border="0">
          View papers connections
        </PopoverHeader>
        <PopoverArrow bg={"primary.300"} />
        <PopoverBody fontSize={"13px"}>
          Enter a keyword and our engine will look for a paper with the keyword
          in the title. The corresponding network will show the referenced
          papers we have collected.
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default MoreInfo;
