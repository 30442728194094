import { Flex, Image, Text, useColorModeValue } from "@chakra-ui/react";
import VerticalCharts from "components/assays/AssayVerticalCharts";
import { AssayChartItemProps } from "models/assays/AssayProps";
import { CompoundProps } from "models/compounds/CompoundProps";
import noDataImg from "assets/no-data.png";

interface ActivityChartProps {
  chartData: AssayChartItemProps[] | undefined;
  compounds: CompoundProps[] | undefined;
}

function ActivityChart({ chartData, compounds }: ActivityChartProps) {
  // Theme
  const themeColor = useColorModeValue("gray.500", "gray.300");

  return (
    <>
      {chartData && chartData?.length > 0 ? (
        <VerticalCharts
          itemsPerPage={10}
          data={chartData ?? []}
          compounds={compounds ?? []}
        />
      ) : (
        <Flex
          direction={"column"}
          gap={4}
          justify={"center"}
          align={"center"}
          minH={"360px"}
        >
          <Image src={noDataImg} w={"200px"} alt="no chart to render" />
          <Text color={themeColor}>No available data...</Text>
        </Flex>
      )}
    </>
  );
}

export default ActivityChart;
