import HelperProps from "./HelperProps";

export const externalsources_helper: HelperProps[] = [
  {
    title: "Sources",
    description:
      "Different sources providing information on the current compound. In the left the name of the source, and at the right the id of the compound in the given sources. Some links are included.",
    image: "",
  },
];

export const papers_helper: HelperProps[] = [
  {
    title: "Research Papers",
    description: "The chart shows the papers that reference the compound. It divides the papers into years, and shows number of papers per year. Note that sometimes the year in unknown and if clicked on it, links to the papers network view displaying all research papers that reference the original one.",
    image: "",
  },
];
