import { useContext, useMemo } from "react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Tooltip,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";

import { AssaysControlsContext } from "..";
import BreadcrumbHome from "components/ui/BreadcrumbHome";

export default function AssaysBreadcrumb() {
  // Navigation
  const navigate = useNavigate();
  const { id } = useParams();

  // Context
  const { searchResults, selectedAssayTypes, selectedIndication, query } =
    useContext(AssaysControlsContext);

  // Theme
  const idColor = useColorModeValue("gray.600", "gray.400");
  const color = useColorModeValue("gray.600", "gray.200");

  // Handlers
  const handleGoToIndex = () => {
    navigate({
      pathname: "/assays",
    });
  };

  const handleGoToIndication = () => {
    navigate({
      pathname: "/assays",
      search: `?${createSearchParams({
        indication: selectedIndication ?? "",
      })}`,
    });
  };

  const handleGoToAssayType = () => {
    navigate({
      pathname: "/assays",
      search: `?${createSearchParams({
        types: selectedAssayTypes ?? "",
      })}`,
    });
  };

  const handleGoToSearchResults = () => {
    if (id) {
      navigate(-1);
    }
  };

  // Responsiveness: ~992px, ~1280px, ~1536px
  const truncLength = useBreakpointValue({
    lg: [12, 19],
    xl: [14, 24],
    "2xl": [20, 28],
  });

  const slicedIndication = useMemo(() => {
    if (!truncLength) return "";
    else {
      if ((query && searchResults.length) || (selectedAssayTypes && !query)) {
        return (
          selectedIndication?.slice(0, truncLength?.at(0)) +
          (selectedIndication?.length > truncLength[0] ? ".." : "")
        );
      } else {
        return (
          selectedIndication?.slice(0, truncLength[1]) +
          (selectedIndication?.length > truncLength[1] ? ".." : "")
        );
      }
    }
  }, [
    query,
    searchResults,
    selectedAssayTypes,
    selectedIndication,
    truncLength,
  ]);

  return (
    <Breadcrumb
      spacing={1}
      separator={<ChevronRightIcon color="gray.500" />}
      fontSize={"14px"}
      color={color}
    >
      {/* Index */}
      <BreadcrumbItem>
        <BreadcrumbLink onClick={handleGoToIndex} lineHeight={1} p={1.5}>
          <BreadcrumbHome />
        </BreadcrumbLink>
      </BreadcrumbItem>

      {/* Search Results */}
      {query && searchResults.length && (
        <BreadcrumbItem
          isCurrentPage={!id}
          onClick={handleGoToSearchResults}
          color={selectedAssayTypes === "" ? "primary.400" : "gray.500"}
        >
          <BreadcrumbLink>Results</BreadcrumbLink>
        </BreadcrumbItem>
      )}

      {/* Indication */}
      {selectedIndication && !query && (
        <BreadcrumbItem
          fontWeight={"500"}
          isCurrentPage={!selectedAssayTypes}
          onClick={handleGoToIndication}
          color={selectedAssayTypes === "" ? "primary.400" : "gray.500"}
        >
          <BreadcrumbLink>
            <Tooltip label={selectedIndication}>{slicedIndication}</Tooltip>
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}

      {/* Assay Type */}
      {selectedAssayTypes && !query && (
        <BreadcrumbItem
          isCurrentPage={!id}
          color="primary.400"
          onClick={handleGoToAssayType}
        >
          <BreadcrumbLink>
            {selectedAssayTypes.length === 1 ? selectedAssayTypes : "Results"}
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}

      {/* Assay Id */}
      {id && (
        <BreadcrumbItem isCurrentPage color={idColor} fontWeight={"500"}>
          <BreadcrumbLink>{id}</BreadcrumbLink>
        </BreadcrumbItem>
      )}
    </Breadcrumb>
  );
}
