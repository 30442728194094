import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { PkProps } from "models/compounds/CompoundProps";

interface PkTableProps {
  rows: PkProps[];
}

export default function PkTable({ rows }: PkTableProps) {
  // Theme
  const color = useColorModeValue("secondary.600", "secondary.300");

  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th textAlign="left">Parameter</Th>
          <Th textAlign="left">Value</Th>
          <Th textAlign="left">Unit</Th>
          <Th textAlign="left">Model</Th>
        </Tr>
      </Thead>
      <Tbody>
        {rows?.map((row: PkProps, index: number) => (
          <Tr key={"pharmacokinetics" + index} fontSize={13}>
            <Td color={color}>{row?.parameter}</Td>
            <Td>{row?.value}</Td>
            <Td>{row?.unit}</Td>
            <Td>{row?.model}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}
