import { useCallback, useContext, useMemo } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { Flex, Text, useTheme, useColorModeValue } from "@chakra-ui/react";

import Select, { SingleValue, StylesConfig } from "react-select";

import { ChartControlsContext, OptionItem } from "views/graph";
import Loading from "components/ui/Loading";

import { CompoundProps } from "models/compounds/CompoundProps";

import { useFetch } from "hooks";
import { environment } from "environments";
import ChartHint from "components/ui/ChartHint";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import { hexToRgba } from "utils/helpers";

let hasNoFeature: boolean = false;

export default function FeatureInput() {
  // Hooks
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  // Theme
  const { colors } = useTheme();
  const titleColor = useColorModeValue("primary.400", "primary.200");
  const fontColor = useColorModeValue(colors.dark[900], colors.neutral[100]);
  const selectedBg = useColorModeValue(
    colors.primary[400],
    colors.highlight.primary
  );

  // Context
  const { selectedId, selectedFeature } = useContext(ChartControlsContext);

  const handleFeatureChange = (choice: SingleValue<OptionItem>): void => {
    const { value } = choice || {};

    // adjust feature value: replace whitespace with _
    const adjustedFeature: string | undefined = value?.replace(/\s/g, "_");

    if (!!selectedId && !!adjustedFeature) {
      navigate({
        pathname: "/graph",
        search: `?${createSearchParams({
          compound: selectedId,
          feature: adjustedFeature ?? "",
        })}`,
      });
    }
  };

  const { data: cmpdData, loading: cmpdLoading } = useFetch(
    useCallback(async () => {
      if (selectedId) {
        const response = await axiosPrivate.get(
          `${environment.BACKEND_API}/api/get_compound_by_id/${selectedId}`
        );
        return response.data as CompoundProps;
      }
      return {};
    }, [selectedId, axiosPrivate])
  );

  const selectInputStyle: StylesConfig<
    SingleValue<OptionItem>,
    false
  > = useMemo(
    () => ({
      control: (base) => ({
        ...base,
        fontSize: "12px",
        backgroundColor: hexToRgba(colors.neutral[600], 0.1),
        border: "transparent",

        "&:hover": { cursor: "pointer" },
      }),

      singleValue: (base) => ({ ...base, color: fontColor }),

      placeholder: (base) => ({
        ...base,
        fontSize: "12px",
        color: fontColor,
      }),

      option: (base, { isSelected }: { isSelected: boolean }) => ({
        ...base,
        paddingLeft: 10,
        fontSize: "12px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        backgroundColor: isSelected ? selectedBg : "transparent",
        color: isSelected ? colors.primary[100] : colors.primary[800],
        "&:hover": {
          backgroundColor: isSelected ? selectedBg : colors.neutral[200],
          color: isSelected ? colors.primary[100] : colors.primary[800],
        },
      }),
    }),
    [colors, fontColor, selectedBg]
  );

  // returns an array containing one or more of the following features: category, assay hits
  const availableFeatures = useMemo(() => {
    const features: string[] = [];

    const hasFeatures: boolean =
      !!(cmpdData as CompoundProps)?.gvk_data ||
      !!(cmpdData as CompoundProps)?.integrity_data ||
      !!(cmpdData as CompoundProps)?.assays_hits?.length;

    if (hasFeatures) {
      // category ("" or string[]) can exist in gvk_data or/and in integrity_data
      const hasCategory: boolean =
        !!(cmpdData as CompoundProps)?.gvk_data?.category ||
        !!(cmpdData as CompoundProps)?.integrity_data?.category;

      if (hasCategory) features.push("category");

      const hasAssayHits: boolean = !!(cmpdData as CompoundProps)?.assays_hits
        ?.length;

      if (hasAssayHits) features.push("assay_hits");

      hasNoFeature = false;
    } else hasNoFeature = true;

    return features;
  }, [cmpdData]);

  // convert features names into data-format objects suitable for the chart
  const featuresOptions: OptionItem[] = availableFeatures?.map(
    (feature: string) => ({
      label: feature,
      value: feature,
    })
  );

  return (
    <Flex direction={"column"} gap={1} justify={"center"} my={2}>
      <Flex
        gap={2}
        align={"center"}
        width={"fit-content"}
        justify={"flex-start"}
      >
        <Text
          fontWeight={"500"}
          fontSize={[null, null, null, "12px", "12px", "12px"]}
          color={titleColor}
        >
          Feature
        </Text>

        {/* Feature loader */}
        {cmpdLoading ? <Loading small /> : <></>}
      </Flex>
      <Select
        placeholder={
          !hasNoFeature ? "Select related feature.." : "No feature found"
        }
        isSearchable={false}
        value={
          hasNoFeature
            ? null
            : {
                value: selectedFeature,
                label:
                  selectedFeature === "indication"
                    ? "assay_hits"
                    : selectedFeature,
              }
        }
        noOptionsMessage={() => <Text fontSize="sm">No feature found</Text>}
        options={featuresOptions}
        isDisabled={hasNoFeature}
        styles={selectInputStyle}
        classNamePrefix="custom-select"
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
        }}
        isClearable={false}
        onChange={handleFeatureChange}
      />

      {selectedId && !cmpdLoading && availableFeatures?.length === 0 && (
        <ChartHint message={"Select another compound"} />
      )}
    </Flex>
  );
}
