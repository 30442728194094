import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  Highlight,
  Image,
  Stack,
  Text,
  useTheme,
} from "@chakra-ui/react";

import { hexToRgba } from "utils/helpers";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import LoginBg from "assets/man-walking.jpg";

export default function AuthLayout() {
  const { colors } = useTheme();
  // Hooks
  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathname || "/";
  const { user } = useSelector(selectCurrentAuthData);
  useEffect(() => {
    if (user) navigate("/chat", { replace: true });
  }, [from, navigate, user]);

  return user ? null : (
    <Grid h="100vh" templateColumns="repeat(7, 1fr)" position={"relative"}>
      <GridItem colSpan={2}></GridItem>
      <GridItem colSpan={5} bg="primary.700">
        <Box
          backgroundImage={LoginBg}
          bgSize="cover"
          bgPosition="top"
          bgRepeat="no-repeat"
          h="100vh"
          position="relative"
        >
          {/* Color overlay */}
          <Box
            bgGradient={`linear(to-br, ${hexToRgba(
              colors.primary[700],
              0.1
            )}, ${hexToRgba(colors.primary[700], 0.65)}, ${hexToRgba(
              colors.primary[700],
              0.9
            )}, ${hexToRgba(colors.primary[700], 1)})`}
            p="4"
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            color="neutral.200"
          >
            {/* Header Content */}
            <Flex
              h={"100%"}
              w={"100%"}
              direction="column"
              justify="center"
              ml="38%"
            >
              {/* Logo */}
              <Flex mb={6}>
                <Center>
                  <Image
                    src="https://reacto.s3.eu-central-1.amazonaws.com/logo/white.svg"
                    h="80px"
                    mr={4}
                    opacity={0.6}
                  />
                  <Heading color={"neutral.100"}>MoleculeLake</Heading>
                </Center>
              </Flex>
              {/* Our mission  */}
              <Stack gap={4} maxW="450px">
                <Text
                  fontSize={[null, null, null, "md", "lg", "xl"]}
                  color={"neutral.100"}
                >
                  <Highlight
                    query="mission"
                    styles={{
                      px: "2",
                      py: "1",
                      bg: "neutral.300",
                      opacity: "0.6",
                      color: "primary.800",
                      rounded: "full",
                      fontWeight: "bold",
                    }}
                  >
                    Our mission is to provide world access and acceleration to
                    drug development.
                  </Highlight>
                </Text>
                <Text fontSize="sm" color={"neutral.100"}>
                  We care about your privacy! We are excited to have you test
                  our platform!
                </Text>
              </Stack>
            </Flex>
          </Box>
        </Box>
      </GridItem>

      {/* Login */}
      <Box position="absolute" left={"15%"} top={"25%"}>
        <Outlet />
      </Box>
    </Grid>
  );
}
