import { useContext, useMemo } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  Flex,
  Box,
  useTheme,
  useColorModeValue,
  Skeleton,
  Icon,
  useBreakpointValue,
} from "@chakra-ui/react";
import { TiDelete } from "react-icons/ti";

import ReactECharts from "echarts-for-react";
import { AssaysControlsContext } from "views/assays";
import Subtitle from "components/ui/Subtitle";
import { AssayProps } from "models/assays/AssayProps";
import {
  hexToRgba,
  stylesToString,
  transformDataForChart,
} from "utils/helpers";
import { GetGradients } from "utils/gradients";
import SidebarTitle from "components/ui/SidebarTitle";

interface Item {
  data: {
    name: string;
  };
  color: string;
}

// Fallback indication color
let selectedColor: string = "gray.300";

export default function AssayTreeMap() {
  // Navigation
  const navigate = useNavigate();

  // Theme
  const { colors } = useTheme();
  const resetIconColor = useColorModeValue("primary.800", "gray.200");
  const { lGradient2 } = GetGradients();
  const bgGradient = useColorModeValue(
    lGradient2,
    `linear(to-b, ${colors.dark[800]}, ${colors.dark[800]})`
  );
  const headerTextColor = useColorModeValue(
    "highlight.primary",
    "secondary.400"
  );

  // Context
  const { assays, selectedAssayTypes, selectedIndication, loadingAssays } =
    useContext(AssaysControlsContext);

  // State
  const chartData = useMemo(() => {
    const indications = !!selectedIndication
      ? [selectedIndication]
      : assays?.map((item: AssayProps) => item?.overview?.indication) || [];
    return transformDataForChart(indications);
  }, [assays, selectedIndication]);

  // Handlers
  const handleResetTreemap = () => {
    navigate({
      pathname: "/assays",
      search: `?${createSearchParams({
        types: selectedAssayTypes,
      })}`,
    });
  };

  const handleIndicationClick = (indication: Item) => {
    const {
      data: { name },
      color: indicationColor,
    } = indication;

    selectedColor = indicationColor;
    // Fix bug: when clicked in the chart but no specific assay is selected, it will return Assays
    if (name.toLowerCase() !== "assays") {
      navigate({
        pathname: "/assays",
        search: `?${createSearchParams({
          types: selectedAssayTypes,
          indication: name,
        })}`,
      });
    }
  };

  // Graph configuration
  const tooltip = {
    formatter: function ({
      color,
      name,
      value,
    }: {
      color: string;
      name: string;
      value: number;
    }) {
      const tooltipStyle = {
        display: "flex",
        "flex-direction": "column",
        "justify-content": "center",
        "align-items": "center",
        gap: "4px",
        padding: "8px",
        margin: "0",
        "border-radius": "13px",
        "font-size": "12px",
      };
      const tooltipTitleStyle = {
        display: "block",
        "font-weight": "bold",
        "max-width": "130px",
        "white-space": "normal",
        "text-align": "center",
        "font-size": "12px",
        color: color,
      };
      const inlineTooltipStyle = stylesToString(tooltipStyle);
      const inlineTooltipTitleStyle = stylesToString(tooltipTitleStyle);
      const tooltipContent = [
        `<span style="${inlineTooltipTitleStyle}">${
          name.length < 36 ? name : name.slice(0, 35) + ".."
        }</span>`,
        `<span> ${value} assay${value > 1 ? "s" : ""}</span>`,
      ].join("");

      return `<Box style="${inlineTooltipStyle}">${tooltipContent}</Box>`;
    },
    padding: 0,
    confine: true, // replaces custom positioning. assigns dynamic position to prevent items overflow
  };

  // NOTE: 3rd condition added to fix this issue:
  // initially, we used to see this title for a fraction of second
  // while chartData getting calculated
  const title = {
    show: !loadingAssays && chartData.length === 0 && assays?.length > 0,
    textStyle: {
      color: colors.gray[500],
      fontSize: 16,
      fontWeight: "normal",
    },
    text: "No indications.",
    left: "center",
    top: "center",
  };

  const seriesConfig = useMemo(() => {
    return {
      width: "100%",
      height: "100%",
      name: "Assays",
      type: "treemap",
      roam: false,
      nodeClick: false,
      label: { show: false },
      emphasis: { focus: "self" },
      breadcrumb: { show: false },
      itemStyle: {
        borderColor: "transparent",
        gapWidth: 1,
      },
      levels: [
        {
          color: chartData.length === 1 ? [selectedColor] : null,
          colorMappingBy: chartData.length > 1 && "value",
        },
      ],
    };
  }, [chartData]);

  const series = [
    {
      ...seriesConfig,
      data: chartData,
      silent: chartData.length === 0,
    },
  ];

  // Responsiveness: ~992px, ~1280px, ~1536px
  const treemapHeight = useBreakpointValue({
    lg: "140px",
    xl: "160px",
    "2xl": "180px",
  });

  const treemapStyle = {
    width: "100%",
    minWidth: "100%",
    height: treemapHeight,
    border:
      chartData.length === 0
        ? `1px solid  ${hexToRgba(colors.primary[400], 0.25)}`
        : "none",
    background: bgGradient,
    borderRadius: "6px",
    overflow: "hidden",
  };

  const loadingStyle = {
    width: "100%",
    minWidth: "100%",
    height: treemapHeight,
    border: `1px solid  ${hexToRgba(colors.primary[400], 0.25)}`,
    backgroundColor: "none",
    borderRadius: "6px",
    overflow: "hidden",
  };

  const onEvents = {
    click: (item: Item) => {
      handleIndicationClick(item);
    },
  };

  return (
    <Box>
      {/* Header */}
      <Flex align={"center"} justify={"space-between"} h={"30px"}>
        <SidebarTitle text="Indication" color={headerTextColor} />

        {chartData.length === 1 && (
          <Icon
            as={TiDelete}
            boxSize={"24px"}
            onClick={handleResetTreemap}
            color={resetIconColor}
            cursor={"pointer"}
          />
        )}
      </Flex>

      {/* Treemap */}
      <Box>
        {/* Graph */}
        {loadingAssays ? (
          <Skeleton borderRadius={5} style={loadingStyle} />
        ) : (
          <ReactECharts
            option={{ series, tooltip, title }}
            onEvents={onEvents}
            style={treemapStyle}
          />
        )}

        {/* Selected indication */}
        <Box h={"20px"}>
          <Subtitle
            w={"80%"}
            whiteSpace={"nowrap"}
            overflow={"hidden"}
            textOverflow={"ellipsis"}
            ml={"auto"}
            fontWeight={"bold"}
            textAlign={"right"}
            color={selectedColor}
            mb={0}
            fontSize={"10px"}
          >
            {chartData.length === 1 && selectedIndication}
          </Subtitle>
        </Box>
      </Box>
    </Box>
  );
}
