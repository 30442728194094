import {
  Link,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

import { AssayHitsProps, ScreeningProps } from "models/assays/ScreeningProps";
import { indicationToColor } from "theme/helper";

interface AssayTableProps {
  assays: AssayHitsProps[] | ScreeningProps[];
  isHit?: boolean;
}

export default function AssaysTable({ assays, isHit }: AssayTableProps) {
  // Theme
  const color = useColorModeValue("secondary.600", "secondary.300");

  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th textAlign="left">Assay Type</Th>
          <Th textAlign="left">Indication</Th>
          {isHit ? (
            <>
              <Th textAlign="left">Activity Type</Th>
              <Th textAlign="left">Measurement</Th>
            </>
          ) : (
            <Th textAlign="left">Screening Int</Th>
          )}
          <Th textAlign="left">Assay Source</Th>
        </Tr>
      </Thead>
      <Tbody>
        {assays?.map((assay, index) => {
          const url = assay?.url?.split("/");
          return (
            <Tr key={"screening-collection" + index} fontSize={13}>
              <Td color={color}>
                <Link as={RouterLink} to={`/assays/${url[url?.length - 1]}`}>
                  {assay.name}
                </Link>
              </Td>
              <Td backgroundColor={indicationToColor(assay.indication)} p={2}>
                {assay.indication}
              </Td>
              {isHit && "activity_type" in assay ? (
                <>
                  <Th textAlign="left">{assay.activity_type}</Th>
                  <Th textAlign="left">{assay.measurement}</Th>
                </>
              ) : (
                "screening_hit" in assay && (
                  <Td pr={1}>
                    <Text
                      {...(assay.screening_hit === "primary screening hit"
                        ? { as: "b" }
                        : {})}
                    >
                      {assay.screening_hit}
                    </Text>
                  </Td>
                )
              )}
              <Td color={color}>{assay.assay_source}</Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
}
