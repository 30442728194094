import { useState, KeyboardEvent, ChangeEvent, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import SearchInput from "components/ui/SearchInput";

export default function SearchCompoundInput() {
  // Navigation
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // State
  const [searchTerm, setSearchTerm] = useState("");
  const query = searchParams.get("query");
  useEffect(() => {
    setSearchTerm(query ?? "");
  }, [query]);

  // Handlers
  const handleChange = (query: string) => {
    setSearchTerm(query);
  };

  const handleSearch = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      const query = event.currentTarget.value;
      navigate(`/compounds?query=${query}`);
    }
  };

  return (
    <SearchInput
      value={searchTerm}
      onChange={(e: ChangeEvent<HTMLInputElement>) =>
        handleChange(e.target.value)
      }
      onKeyDown={handleSearch}
      placeholder="search compound"
    />
  );
}
