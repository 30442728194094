import { Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import { BiMailSend } from "react-icons/bi";

export default function SuccessFeedbackMessage() {
  // Theme
  const color = useColorModeValue("gray.700", "gray.200");
  const color2 = useColorModeValue("gray.500", "gray.300");

  return (
    <Flex
      direction={"column"}
      gap={6}
      justify={"center"}
      align={"center"}
      w={"full"}
      alignSelf={"center"}
      justifySelf={"center"}
      mt={"30px"}
      bg={"transparent"}
    >
      <Flex direction={"column"} gap={2} justify={"center"} align={"center"}>
        <Icon as={BiMailSend} boxSize={10} color={"highlight.primary"} />
        <Text color={color} fontWeight={"500"}>
          Thank you!
        </Text>
      </Flex>

      <Text fontSize={"14px"} color={color2}>
        We received your feedback.
      </Text>
    </Flex>
  );
}
