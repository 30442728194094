import { Box, useColorModeValue, useTheme } from "@chakra-ui/react";
import { RedocStandalone } from "redoc";
import { environment } from "environments";

function Docs() {
  // Theme
  const { colors } = useTheme();
  const bg = useColorModeValue(colors.neutral[100], colors.dark[700]);
  const colorGray100 = useColorModeValue(colors.neutral[200], colors.gray[800]);
  const textColor = useColorModeValue(colors.gray[800], colors.neutral[100]);
  const textColor2 = useColorModeValue("black", colors.neutral[100]);

  return (
    <Box h={"100vh"} overflowY={"auto"} >
      <RedocStandalone
        options={{
          hideDownloadButton: true,
          theme: {
            rightPanel: {
              backgroundColor: bg,
            },
            colors: {
              gray: { 100: colorGray100 },
              text: { primary: textColor },
              primary: { main: colors.blue[500] },
            },
            schema: {
              linesColor: colors.blue[500],
              typeNameColor: colors.blue[500],
              nestedBackground: colorGray100,
            },
            sidebar: {
              backgroundColor: bg,
              textColor: textColor2,
              groupItems: {
                activeBackgroundColor: colors.gray[50],
                activeTextColor: colors.green[500],
              },
            },
          },
          hideFab: true,
        }}
        specUrl={`${environment.BACKEND_API}/documentation2/?format=openapi`}
      />
    </Box>
  );
}

export default Docs;
