import { SuggestionProps } from "./SuggestionsView";


export const addDataIntoCache = (
  cacheName: string,
  searchQuery: string,
  response: SuggestionProps[]
) => {
  if ("caches" in window) {
    // Converting our response into Actual Response form
    caches.open(cacheName).then((cache) => {
      const data = new Response(JSON.stringify(response));
      cache.put(searchQuery.toLocaleLowerCase(), data);
    });
  }
};

// Function to get data from cache
export const getCachedData = async (cacheName: string, searchQuery: string) => {
  if ("caches" in window) {
    const cache = await caches.open(cacheName);
    const cachedResponse = await cache.match(searchQuery);
    if (cachedResponse) {
      const responseData = await cachedResponse.json();
      return responseData;
    }
  }
  return null;
};
