import { ProcessStep } from "components/home/Pipeline/Steps";
import { AiFillDatabase, AiFillRead, AiFillMessage } from "react-icons/ai";

export const processSteps: ProcessStep[] = [
  {
    icon: AiFillDatabase,
    title: "PRIVILIGED DATA ENRICHMENT",
    text: "We empower you to leverage the wealth of insights in ReframeDB, an open and extendable drug repurposing database and screening set by Calibr. These 12000+ curated compounds are further enriched with scientific knowledge, to enhance your analyses and to accelerate your discoveries. The enrichment pipeline enables combination with other curated datasets.",
  },
  {
    icon: AiFillRead,
    title: "LITERATURE PROCESSING AND EMBEDDINGS",
    text: "Imagine millions of research papers, filled with valuable insights, but constrained by the time and the bandwidth to sift through them all. Our AI enables deep reading to extract these insights and make them accessible. Using advanced Large Language Models (LLMs), we read papers in biomedical sciences, life sciences, and chemistry, categorise and extract key concepts from each paper in a computationally efficient way, effortlessly!",
  },
  {
    icon: AiFillMessage,
    title: "NATURAL MODES OF INTERACTION",
    text: "Unlock the power of advanced LLMs with our platform! Our technology dives deep into literature, creating unique 'embeddings' that capture the essence of concepts. Using tailored prompts, we match these embeddings to your research queries, delivering precise answers directly to you. You do not need to be a data scientist to quickly derive insights from your data. Our agent empowers you, putting knowledge at your fingertips instantly!",
  },
];
