import { SessionProps } from "models/chat/MessageProps";

const NOTIFICATIONS_LIST = [
  "Curating Relevant Research",
  "Extracting Insights",
  "Integrating Insights",
  "Fetching Papers & Molecules",
];

export function stepIndex(notification: string | undefined) {
  if (!notification) return 0;
  return NOTIFICATIONS_LIST.indexOf(notification);
}

export function categorizeSessions(sessions: SessionProps[]) {
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const previous7Days = new Date();
  previous7Days.setDate(previous7Days.getDate() - 7);

  const result: { [key: string]: SessionProps[] } = {
    Today: [],
    Yesterday: [],
    "Previous 7 Days": [],
    Month: [],
  };

  sessions.forEach((session: SessionProps) => {
    const sessionDate = new Date(session.created_at);

    if (
      sessionDate.getDate() === today.getDate() &&
      sessionDate.getMonth() === today.getMonth()
    ) {
      result["Today"].push(session);
    } else if (
      sessionDate.getDate() === yesterday.getDate() &&
      sessionDate.getMonth() === yesterday.getMonth()
    ) {
      result["Yesterday"].push(session);
    } else if (sessionDate >= previous7Days) {
      result["Previous 7 Days"].push(session);
    } else {
      const monthName = new Intl.DateTimeFormat("en-US", {
        month: "long",
      }).format(sessionDate);
      if (!result[monthName]) {
        result[monthName] = [];
      }
      result[monthName].push(session);
    }
  });

  return result;
}
