import { Box, useColorModeValue } from "@chakra-ui/react";
import { GetGradients } from "utils/gradients";

function BackgroundShadowEffect() {
  // Theme
  const { bgShadowEffect, bgShadowEffectDark } = GetGradients();
  const bgGradient = useColorModeValue(bgShadowEffect, bgShadowEffectDark);

  return (
    <Box
      position="absolute"
      bottom={0}
      left={0}
      right={0}
      height="24px"
      bgGradient={bgGradient}
    />
  );
}

export default BackgroundShadowEffect;
