import { Flex, Text } from "@chakra-ui/react";
import { SocialMediaIcon } from "../FooterLinks";
import { FaFacebook, FaLinkedin } from "react-icons/fa";

function CopyrightAndSocialMedia() {
  return (
    <Flex align={"center"} justify={"space-between"} w={"full"}>
      <Text>
        © {new Date().getFullYear()} Vitafluence.ai. All rights reserved.
      </Text>
      <Flex align={"center"} gap={4}>
        <SocialMediaIcon
          icon={FaLinkedin}
          link="https://www.linkedin.com/company/vitafluence/"
        />
        {/* <SocialMediaIcon
          icon={FaFacebook}
          link="https://www.facebook.com/Vitafluenceai-GmbH-108724718260934"
        /> */}
      </Flex>
    </Flex>
  );
}

export default CopyrightAndSocialMedia;
