import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// icons
import { BsBookmarkHeartFill, BsHighlights, BsSignal } from "react-icons/bs";
import { PiGraphFill, PiNewspaperFill, PiTestTubeFill } from "react-icons/pi";
import { FaVideo } from "react-icons/fa";
import { FaCode } from "react-icons/fa6";

// Initial navigation history state
const initNavHistory = sessionStorage.getItem("navigationHistory");
const initNavHistoryState: string[] = initNavHistory
  ? JSON.parse(initNavHistory)
  : [];
const pathsToBeTracked = ["chat", "compounds", "assays", "papers", "graph"];

const pushPathToNavigationHistory = (
  path: string,
  prevNavHistoryState: string[]
) => {
  if (pathsToBeTracked.every((r: string) => !path.startsWith(`/${r}`)))
    return prevNavHistoryState;
  if (prevNavHistoryState.slice(-1)[0] !== path) {
    let newNavHistoryEntries: string[] = [];
    // Clear previous related history
    const root = pathsToBeTracked.find((rt: string) =>
      path.startsWith(`/${rt}`)
    );
    if (root)
      newNavHistoryEntries = prevNavHistoryState.filter(
        (str: string) => !str.includes(`/${root}`)
      );
    newNavHistoryEntries =
      newNavHistoryEntries.length >= 40
        ? [...newNavHistoryEntries.slice(1), path]
        : [...newNavHistoryEntries, path];
    sessionStorage.setItem(
      "navigationHistory",
      JSON.stringify(newNavHistoryEntries)
    );
    return newNavHistoryEntries;
  } else {
    return prevNavHistoryState;
  }
};

function useNavigationTabs() {
  const location = useLocation();
  const navigate = useNavigate();

  const [historyEntries, setHistoryEntries] =
    useState<string[]>(initNavHistoryState);

  interface TabsNavigationHandlersProps {
    chatTabLink?: string;
    compoundsTabLink?: string;
    assaysTabLink?: string;
    papersTabLink?: string;
    graphTabLink?: string;
  }

  const tabsNavigationHandlers: TabsNavigationHandlersProps = useMemo(() => {
    const currentPath = `${location.pathname}${location.search}`;
    setHistoryEntries((prevState: string[]) =>
      pushPathToNavigationHistory(currentPath, prevState)
    );

    const links = pathsToBeTracked.reduce((acc, tab) => {
      const latestRelatedHistory = [...historyEntries]
        .reverse()
        .find((entry) => entry.startsWith(`/${tab}`));

      const linkValue = latestRelatedHistory
        ? latestRelatedHistory === currentPath
          ? `/${tab}`
          : latestRelatedHistory
        : `/${tab}`; // Default to tab root if no related history

      return {
        ...acc,
        [`${tab}TabLink`]: linkValue,
      };
    }, {});
    return links;
  }, [location.pathname, location.search, historyEntries]);

  const navItems = useMemo(() => {
    const {
      chatTabLink,
      compoundsTabLink,
      assaysTabLink,
      papersTabLink,
      graphTabLink,
    }: TabsNavigationHandlersProps = tabsNavigationHandlers;
    return [
      {
        name: "chat",
        // icon: BsRobot,
        icon: BsSignal,
        link: chatTabLink,
        tooltip: "chatbot",
      },
      {
        name: "compounds",
        // icon: PiGraph,
        icon: PiGraphFill,
        link: compoundsTabLink,
        tooltip: "compounds",
      },
      {
        name: "assays",
        // icon: PiTestTube,
        icon: PiTestTubeFill,
        link: assaysTabLink,
        tooltip: "assays",
      },
      {
        name: "papers",
        // icon: BsPaperclip,
        icon: PiNewspaperFill,
        link: papersTabLink,
        tooltip: "literature network",
      },
      {
        name: "graph",
        icon: BsHighlights,
        // icon: TbArrowsSplit,
        link: graphTabLink,
        tooltip: "interactive charts",
      },
    ];
  }, [tabsNavigationHandlers]);

  const operators = useMemo(
    () => [
      {
        name: "saved",
        icon: BsBookmarkHeartFill,
        handler: () => navigate("/saved"),
        tooltip: "saved elements",
      },
      {
        name: "tutorials",
        icon: FaVideo,
        handler: () => navigate("/tutorials"),
        tooltip: "tutorials",
      },
      {
        name: "docs",
        icon: FaCode,
        handler: () => window.open(`${window.location.origin}/docs`),
        tooltip: "API Documentation",
      },
    ],
    [navigate]
  );

  return { navItems, operators };
}

export default useNavigationTabs;
