import { useState, useEffect, useMemo, ChangeEvent, useContext } from "react";
import {
  Flex,
  List,
  ListItem,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";

import Suggestion from "./Suggestion";
import ResultsLength from "components/ui/ResultsLength";

import { getPaperColor } from "views/compounds/helpers";
import { CustomScrollBar } from "components/ui/CustomScrollBar";
import { classifiedTypes } from "../paperDetails/SelectedPaperDetails";
import { CheckboxStyled } from "components/checkbox/Checkbox";
import SelectAllToEmbedButton from "./SelectAllToEmbedButton";
import NoResults from "./NoResults";
import { PapersContext } from "views/papers";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { useSelector } from "react-redux";
import { maxHeight } from "utils/responsive";
import { SuggestionProps } from "views/papers/SuggestionsView";


export default function SuggestionsList() {
  // Auth
  const { user } = useSelector(selectCurrentAuthData);

  const {
    search,
    suggestions,
    filters,
    papersToEmbed,
    setPapersToEmbed,
    isEmbeddingInProgress,
    showEmbeddingControls,
  } = useContext(PapersContext);

  // States
  const [filteredSuggestions, setFilteredSuggestions] = useState<
    SuggestionProps[] | []
  >(suggestions);

  // Theme
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBg = useColorModeValue("gray.100", "dark.700");

  function handleSelectAll() {
    if (papersToEmbed.length !== 0) {
      setPapersToEmbed([]);
    } else {
      setPapersToEmbed(
        suggestionsToShow
          ?.filter((s: SuggestionProps) => !s.embedded)
          .map((s: SuggestionProps) => s?.id)
      );
    }
  }

  function handleSelect(e: ChangeEvent<HTMLInputElement>) {
    let isSelected = e.target.checked;
    let value = e.target.value;

    if (isSelected) {
      setPapersToEmbed([...papersToEmbed, value]);
    } else {
      let filtered = papersToEmbed.filter((id: string) => id !== value);
      setPapersToEmbed(filtered);
    }
  }

  const suggestionsToShow = useMemo(() => {
    if (showEmbeddingControls) {
      const classifiedSuggestions = filteredSuggestions?.filter(
        (s: SuggestionProps) =>
          classifiedTypes.includes(s?.paper_type?.toLowerCase())
      );

      const sortedClassifiedSuggestions = classifiedSuggestions
        ?.slice()
        ?.sort((a: SuggestionProps, b: SuggestionProps) => {
          const aEmbedded = a.embedded ? 1 : 0;
          const bEmbedded = b.embedded ? 1 : 0;
          return aEmbedded - bEmbedded;
        });
      return sortedClassifiedSuggestions;
    } else {
      return filteredSuggestions;
    }
    // return showEmbeddingControls
    //   ? filteredSuggestions?.filter((s: SuggestionProps) =>
    //     classifiedTypes.includes(s?.paper_type?.toLowerCase())
    //   )?.sort((a: any, b: any) => a?.embedded - b?.embedded)
    //   : filteredSuggestions
  }, [filteredSuggestions, showEmbeddingControls]);
  useEffect(() => {
    let filteredData: SuggestionProps[] | [] = [...suggestions];

    const { embedded, types } = filters;

    // Filter by embedded
    if (embedded === "embedded") {
      filteredData = filteredData.filter((item) => item.embedded);
    } else if (embedded === "not embedded") {
      filteredData = filteredData.filter((item) => !item.embedded);
    }

    // Filter by types
    if (types && types.length > 0) {
      filteredData = filteredData.filter((item: SuggestionProps) =>
        types.includes(item.paper_type?.toLowerCase())
      );
    } else {
      filteredData = [];
    }

    setFilteredSuggestions(filteredData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  // clear selected papers for embedding
  useEffect(() => {
    setPapersToEmbed([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredSuggestions, search.query]);

  // Responsiveness: ~992px, ~1280px, ~1536px
  const SuggestionsListHeight = useBreakpointValue({
    lg: "calc(100vh - 100px)", // 100 = 8 x 2 + 8 x 2 + 40 + 12 + 16: py + py + header h + gap + added pb
    xl: "calc(100vh - 108px)", // 108 = 12 x 2 + 8 x 2 + 40 + 12 + 16
    "2xl": "calc(100vh - 116px)", // 116 = 16 x 2 + 8 x 2 + 40 + 12 + 16
  });

  const SuggestionsListStyle = {
    height: SuggestionsListHeight,
    width: "100%",
  };

  const fullHeight = useBreakpointValue(maxHeight);

  // no results
  // if (!filteredSuggestions || filteredSuggestions.length === 0) {
  if (!suggestionsToShow || suggestionsToShow.length === 0) {
    return (
      <Flex align={"center"} h={fullHeight} w={"100%"}>
        <NoResults />
      </Flex>
    );
  }

  return (
    <>
      {suggestions?.length > 0 ? (
        <Flex direction={"column"} justify={"center"} w={"100%"} gap={3}>
          {/* Results Stats */}
          <Flex
            align={"center"}
            justify={"space-between"}
            w={"100%"}
            h={"40px"}
            direction={"row-reverse"}
          >
            {suggestionsToShow?.length ? (
              <ResultsLength
                length={suggestionsToShow?.length}
                item="paper"
              />
            ) : (
              <></>
            )}

            {/* Embed controls */}
            {filteredSuggestions &&
              filteredSuggestions?.length > 0 &&
              user?.user_type === "premium" &&
              showEmbeddingControls &&
              !isEmbeddingInProgress && (
                <SelectAllToEmbedButton
                  onSelectAll={handleSelectAll}
                  allSelected={papersToEmbed?.length !== 0}
                />
              )}
          </Flex>

          {/* filtered list */}
          <List w={"100%"} overflow={"hidden"}>
            <CustomScrollBar style={SuggestionsListStyle}>
              {suggestionsToShow?.map((suggestion: SuggestionProps) => (
                <ListItem
                  key={"opt-" + suggestion?.id}
                  cursor={showEmbeddingControls ? "default" : "pointer"}
                  _hover={{ bg: hoverBg }}
                  borderColor={borderColor}
                  borderBottomWidth={"1px"}
                  display={"flex"}
                  alignItems={"flex-start"}
                  gap={0}
                  pl={showEmbeddingControls ? 2 : 0}
                >
                  {user?.user_type === "premium" &&
                    showEmbeddingControls &&
                    !isEmbeddingInProgress &&
                    !suggestion?.embedded && (
                      <Flex mt={"16px"}>
                        <CheckboxStyled
                          large
                          roundedFull
                          forEmbeddings={true}
                          isChecked={papersToEmbed.includes(suggestion?.id)}
                          onChange={handleSelect}
                          value={suggestion?.id}
                        />
                      </Flex>
                    )}
                  <Suggestion
                    title={suggestion.title}
                    type={suggestion.paper_type}
                    year={suggestion.year_published}
                    color={getPaperColor(suggestion.paper_type?.toLowerCase())}
                    id={suggestion?.id}
                  />
                </ListItem>
              ))}
            </CustomScrollBar>
          </List>
        </Flex>
      ) : (
        <></>
      )}
    </>
  );
}
