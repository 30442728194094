import { Image, Flex, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router";

function Logo() {
  const navigate = useNavigate();

  return (
    <Flex
      align={"center"}
      gap={3}
      cursor={"pointer"}
      onClick={() => navigate("/")}
    >
      <Image
        borderRadius="full"
        boxSize={[null, null, null, "30px", "34px", "36px"]}
        src="https://reacto.s3.eu-central-1.amazonaws.com/logo/white.svg"
        opacity={0.25}
      />
      <Text
        fontSize="xl"
        lineHeight={"1.3"}
        color={"neutral.500"}
        fontWeight={"500"}
      >
        MoleculeLake
      </Text>
    </Flex>
  );
}

export default Logo;
