import { CloseIcon } from "@chakra-ui/icons";
import { Box, Icon, Progress, Text, useColorModeValue } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentEmbeddingData,
  setProgress,
} from "redux/features/embedding/embeddingSlice";

const EmbeddingLoading = () => {
  const dispatch = useDispatch();
  const { progress, error } = useSelector(selectCurrentEmbeddingData);

  const handleCloseProgress = () => {
    dispatch(setProgress(null));
  };
  // Theme
  const bg = !error && progress !== null ? "highlight.primary" : "red.500";
  const color = useColorModeValue("neutral.100", "dark.800");

  return (
    <Box
      zIndex={999}
      position={"absolute"}
      left={["60px", "60px", "60px", "100px", "115px", "115px"]}
      bottom={error || progress !== null ? "10px" : "-80px"}
      h={"40px"}
      w={[
        "calc(100% - 75px)",
        "calc(100% - 75px)",
        "calc(100% - 75px)",
        "calc(100% - 115px)",
        "calc(100% - 130px)",
        "calc(100% - 130px)",
      ]}
      transition={"all .4s"}
      mx={2}
    >
      {(error || progress !== null) && (
        <Box
          bg={bg}
          textAlign="center"
          borderRadius="md"
          color={color}
          _hover={{ opacity: 0.9 }}
          px={2}
          py={1}
        >
          <Text
            alignSelf={"center"}
            fontWeight={"500"}
            fontSize={"14px"}
            letterSpacing={".05rem"}
          >
            {!error && progress !== null
              ? `Embedding ${progress}%`
              : "Error embedding"}
          </Text>
          {progress !== null && (
            <Progress
              value={progress || 0}
              size="xs"
              colorScheme={progress && progress < 100 ? "pink" : "dark.800"}
              borderRadius={5}
              mt={1}
            />
          )}
          {/* Close icon */}
          {error && (
            <Icon
              as={CloseIcon}
              cursor={"pointer"}
              boxSize={"10px"}
              position="absolute"
              top={2}
              right={2}
              onClick={handleCloseProgress}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default EmbeddingLoading;
