import { Button, Icon } from "@chakra-ui/react";
import { HiCodeBracket } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

function DocsAPI() {
  // Navigation
  const navigate = useNavigate();

  return (
    <Button
      py={2}
      px={3}
      w={"fit-content"}
      h={"fit-content"}
      letterSpacing={".03rem"}
      bg={"whiteAlpha.300"}
      color={"neutral.100"}
      _hover={{ backgroundColor: "whiteAlpha.500" }}
      onClick={() => navigate("/docs")}
    >
      <Icon as={HiCodeBracket} mr="2" fontSize="md" />
      Docs
    </Button>
  );
}

export default DocsAPI;
