import {
  Flex,
  Image,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import AddNewFolderButton from "./AddNewFolderButton";
import { maxHeight } from "utils/responsive";
import noFolders from "assets/no-folders.svg";

interface NoFoldersProps {
  onAddNewFolder: () => void;
}

function NoFolders({ onAddNewFolder }: NoFoldersProps) {
  // Theme
  const color = useColorModeValue("blue.700", "gray.400");

  // Responsiveness: ~992px, ~1280px, ~1536px
  const FolderContainerHeight = useBreakpointValue(maxHeight);

  return (
    <Flex
      h={FolderContainerHeight}
      direction={"column"}
      justify={"center"}
      align={"center"}
      gap={10}
    >
      <Flex direction={"column"} align={"center"} justify={"center"} gap={2}>
        <Image src={noFolders} w={"80px"} alt="no folders" loading="lazy" />
        <Text color={color}>There are no folders created yet.</Text>
      </Flex>
      <AddNewFolderButton onAddNewFolder={onAddNewFolder} />
    </Flex>
  );
}

export default NoFolders;
