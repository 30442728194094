import { Text, useColorModeValue } from "@chakra-ui/react";
import { adjustCompoundName } from "views/compounds/helpers";
import { ReducedCompoundProps } from "views/graph";

function CompoundName({ cmpd }: { cmpd: ReducedCompoundProps }) {
  // Theme
  const color = useColorModeValue("gray.600", "gray.300");

  return (
    <Text
      color={color}
      fontSize={[null, null, null, "12px", "13px", "14px"]}
      whiteSpace={"nowrap"}
      overflow={"hidden"}
      lineHeight={"1.3"}
      textOverflow={"ellipsis"}
    >
      {adjustCompoundName(cmpd)}
    </Text>
  );
}

export default CompoundName;
