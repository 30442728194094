import { AssayChartItemProps, AssayProps } from "models/assays/AssayProps";
import { AssayTypeProps } from ".";

export const findAssaysByQuery = (query: string, assayData: AssayProps[]) => {
  // make a call to the API to retrieve all assays data?
  const results = assayData?.filter((assay: AssayProps) => {
    const l_query = query.toLowerCase();
    // name, indication, assay types
    const includesIndication = assay?.overview?.indication
      .toLowerCase()
      .includes(l_query);
    const includesAssayType = assay?.overview.assay_type
      ?.toLowerCase()
      .includes(l_query);
    const nameMatch = assay?.name?.toLowerCase().includes(l_query);

    return includesIndication || includesAssayType || nameMatch;
  });

  return results;
};

export const findAssay = (assays: AssayProps[], id: string) => {
  return assays?.find((assay: AssayProps) => {
    return assay.assay_id === id;
  });
};

export const filterAssays = (
  assayData: AssayProps[],
  query: string | null = "",
  indication: string | null,
  assayTypes: string | null
) => {
  if (!!query) {
    return findAssaysByQuery(query, assayData);
  }
  const selected_assay_types =
    assayTypes?.split(",")?.map((item) => item.trim()) || [];

  const filteredAssays = assayData?.filter((assay: AssayProps) => {
    const db_assay_types =
      assay?.overview.assay_type?.split(",")?.map((item) => item.trim()) || [];

    const matchesAssayType =
      !assayTypes ||
      assayTypes === "" ||
      db_assay_types.some((type) => selected_assay_types.includes(type));
    const matchesIndication =
      !indication ||
      indication === "" ||
      assay?.overview?.indication === indication;

    return matchesAssayType && matchesIndication;
  });

  return filteredAssays;
};

const countFrequencyInResults = (
  items: string[],
  searchResults: AssayProps[]
): Record<string, number> => {
  // Create a dictionary to store the counts
  const dictionary: Record<string, number> = {};

  // Filter the strings list to only unique elements
  const uniqueStrings = Array.from(new Set(items));

  // Initialize counts to 0 for each unique string
  uniqueStrings?.forEach((uniqueString) => {
    dictionary[uniqueString] = 0;
  });

  // Iterate through the objects and update the counts in the dictionary
  searchResults?.forEach((assay) => {
    uniqueStrings?.forEach((uniqueString) => {
      if (assay.overview.assay_type?.includes(uniqueString)) {
        dictionary[uniqueString]++;
      }
    });
  });

  return dictionary;
};

export const getAllAssayTypes = (data: AssayProps[]) => {
  const assaysTypes = data
    ?.map((item: AssayProps) => item?.overview.assay_type?.split(","))
    .flat();

  const trimmedAssaysTypes = assaysTypes?.map((item: string | undefined) =>
    item?.trim()
  );

  return trimmedAssaysTypes;
};

// sort assays alphabetically
const sortAssays = (result: Omit<AssayTypeProps, "isChecked">[]) => {
  return result.slice().sort((a, b) => a.name.localeCompare(b.name));
};

export const transformToCheckboxData = (
  data: AssayProps[],
  searchResults: AssayProps[]
) => {
  const trimmedAssaysTypes = getAllAssayTypes(data);

  const occurencesDict = countFrequencyInResults(
    trimmedAssaysTypes as string[],
    searchResults
  );

  const result = Object.entries(occurencesDict)?.map(([name, value]) => ({
    name,
    value,
  }));

  return sortAssays(result);
};

export const getUniqueObjects = (data: AssayChartItemProps[]) => {
  const duplicates = new Set();

  const unique = data.filter((item: AssayChartItemProps) => {
    const stringified = JSON.stringify(item);
    if (!duplicates.has(stringified)) {
      duplicates.add(stringified);
      return true;
    }
    return false;
  });

  return unique;
};
