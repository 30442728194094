import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import {
  Flex,
  useTheme,
  useMediaQuery,
  useColorModeValue,
  useBreakpointValue,
  Box,
} from "@chakra-ui/react";

// API
import Nav from "layouts/nav";
import Home from "views/home";
import Docs from "views/docs";

import LoginView from "views/auth/login";
import ProfileView from "views/profile";

import ChatbotView from "views/chatbot";

import CompoundsView from "views/compounds";
import CompoundsList from "views/compounds/CompoundsList";
import CompoundItem from "views/compounds/CompoundItem";

import AssaysView from "views/assays";
import AssayList from "views/assays/AssaysList";
import AssayItem from "views/assays/AssayItem";

import PapersView from "views/papers";
import SuggestionsView from "views/papers/SuggestionsView";
import PaperNetworkView from "views/papers/NetworkView";

import GraphView from "views/graph";
import Chart from "views/graph/Chart";

import PageNotFound from "views/PageNotFound";
import NewChatPanel from "components/chat/NewChatPanel";
import ChatMessages from "views/chatbot/ChatMessages";
import Feedback from "components/feedback/Feedback";
import SavedElements from "views/saved";
import Tutorials from "views/tutorials";

import PersistLogin from "components/authforms/PersistLogin";
import RequireAuth from "components/authforms/RequireAuth";

import Unauthorized from "views/Unauthorized";
import { ROLES } from "utils/roles";

import UpdateChecker from "components/ui/UpdateChecker";
import EmbeddingLoading from "components/papers/suggestions/EmbeddingLoading";
import useEmbeddingProcess from "hooks/literatureEmbedding/useEmbeddingProcess";
import useFetchSavedElements from "hooks/saved/useFetchSavedElements";
import MobileView from "views/MobileView";
import { GetGradients } from "utils/gradients";
import AuthLayout from "views/auth/AuthLayout";
import ResetPasswordView from "views/auth/resetPassword";

function Layout() {
  const { checkForEmbeddingsInProgress } = useEmbeddingProcess();

  // Hooks

  // Theme
  const { colors } = useTheme();
  const { lGradient2 } = GetGradients();

  const bgGradient = useColorModeValue(
    lGradient2,
    `linear(to-b, ${colors.dark[900]}, ${colors.dark[900]})`
  );

  useFetchSavedElements();

  // Connect to a socket
  useEffect(() => {
    checkForEmbeddingsInProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex
      position={"relative"}
      overflow={"hidden"}
      bgGradient={bgGradient}
      p={[null, null, null, 2, 3, 4]}
    >
      {/* navbar */}
      <Nav />

      {/* sidebar + main panel */}
      <Outlet />

      <Feedback />

      <UpdateChecker />

      {/* Embedding progress bar */}
      <EmbeddingLoading />
    </Flex>
  );
}

function AppWrapper() {
  const displayOnLargeScreen = useBreakpointValue({ base: false, lg: true });

  return (
    <Box visibility={!!displayOnLargeScreen ? "visible" : "hidden"}>
      <Outlet />
    </Box>
  );
}

export default function AppRoutes() {
  // State
  const [isMobileView] = useMediaQuery("(max-width: 991px)");

  return (
    <>
      {isMobileView && <MobileView isMobileView={isMobileView} />}
      <Router>
        <Routes>
          <Route element={<AppWrapper />}>
            {/* Public routes */}
            <Route element={<PersistLogin />}>
              <Route index element={<Home />} />
              <Route path="home" element={<Home />} />
              <Route path="unauthorized" element={<Unauthorized />} />
              <Route path="docs" element={<Docs />} />

              {/* Auth */}
              <Route element={<AuthLayout />}>
                <Route path="login" element={<LoginView />} />
                <Route path="reset-password" element={<ResetPasswordView />} />
              </Route>

              {/* Protected routes */}
              <Route
                element={
                  <RequireAuth allowedRoles={[...Object.values(ROLES)]} />
                }
              >
                <Route element={<Layout />}>
                  {/* chat routes */}
                  <Route path="chat/" element={<ChatbotView />}>
                    <Route index element={<NewChatPanel />} />
                    <Route path=":id" element={<ChatMessages />} />
                  </Route>

                  {/* compounds routes */}
                  <Route path="compounds/" element={<CompoundsView />}>
                    <Route index element={<CompoundsList />} />
                    <Route path=":id" element={<CompoundItem />} />
                  </Route>

                  {/* assays routes */}
                  <Route path="assays/" element={<AssaysView />}>
                    <Route index element={<AssayList />} />
                    <Route path=":id" element={<AssayItem />} />
                  </Route>

                  {/* papers routes */}
                  <Route path="papers/" element={<PapersView />}>
                    <Route index element={<SuggestionsView />} />
                    <Route path=":id" element={<PaperNetworkView />} />
                    {/* <Route path="tabs" element={<PaperNetworkView />} /> */}
                  </Route>

                  {/* graph routes */}
                  <Route path="graph/" element={<GraphView />}>
                    <Route index element={<Chart />} />
                  </Route>

                  {/* other routes */}
                  <Route path="profile" element={<ProfileView />} />
                  <Route path="saved" element={<SavedElements />} />
                  <Route path="tutorials" element={<Tutorials />} />
                </Route>
              </Route>
            </Route>
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}
