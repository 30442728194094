import {
  Flex,
  TabList,
  Text,
  useToast,
  Box,
  useBreakpointValue,
} from "@chakra-ui/react";
import DeleteModal from "components/chat/DeleteModal";
import AddNewFolderButton from "components/saved/AddNewFolderButton";
import FolderTab from "components/saved/FolderTab";
import { CustomScrollBar } from "components/ui/CustomScrollBar";
import Loading from "components/ui/Loading";
import { environment } from "environments";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { SavedFolderProps, setSuccess } from "redux/features/saved/savedSlice";

interface PropsType {
  folders: SavedFolderProps[];
  onAddNewFolder: () => void;
}

function FoldersList({ folders, onAddNewFolder }: PropsType) {
  const toast = useToast();
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();

  // States
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [folderToDelete, setFolderToDelete] = useState<string>("");

  // Handlers
  const handleRemoveFolder = async (folderId: string) => {
    setFolderToDelete(folderId);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    setLoading(false);

    try {
      setLoading(true);

      folderToDelete &&
        (await axiosPrivate.delete(
          `${environment.BACKEND_API}/api/remove_elements?folderId=${folderToDelete}`
        ));
      let newFolders: SavedFolderProps[] = folders.filter(
        (folder: SavedFolderProps) => folder.id !== folderToDelete
      );
      dispatch(setSuccess(newFolders));

      toast({
        position: "bottom-right",
        duration: 1500,
        render: () => (
          <Box
            color="white"
            p={3}
            bg={"highlight.primary"}
            borderRadius={"6px"}
          >
            Folder deleted successfully
          </Box>
        ),
      });
    } catch (error) {
      toast({
        title: "Error Deleting Folder",
        status: "error",
        duration: 1500,
      });
    } finally {
      setShowModal(false);
      setLoading(false);
    }
  };

  const handleCancelDelete = () => {
    setShowModal(false);
    setFolderToDelete("");
  };

  // Responsiveness: ~992px, ~1280px, ~1536px
  const FoldersListHeight = useBreakpointValue({
    lg: "calc(100vh - 190px)", // 146 = 8 x 2 + 16 x 2 + 16 + 20 + 16 x 2 +  14 + 16 + 8 + 8 x 2 + 20
    xl: "calc(100vh - 208px)", // 164 = 12 x 2 + 20 x 2 + 16 + 22 + 16 x 2 + 14 + 16 + 8 + 8 x 2 + 20
    "2xl": "calc(100vh - 228px)", // 184 = 16 x 2 + 24 x 2 + 16 + 24 + 16 x 2 + 16 + 16 + 8 + 8 x 2 + 208 + 8 x 2 + 20:  2 x py + 2 x py + pt + header + divider + header + mb + gap + paddingY + btnH
  });

  const FoldersListStyle = {
    height: FoldersListHeight,
    paddingRight: "6px",
    marginBottom: "8px",
  };

  return (
    <>
      <CustomScrollBar style={FoldersListStyle} overflowX={"hidden"}>
        <Flex
          direction={"column"}
          justify={"space-between"}
          overflow={"hidden"}
        >
          <Flex direction={"column"} gap={5}>
            {/* folders list */}
            <TabList position={"relative"}>
              {folders.map((folder: SavedFolderProps) => (
                <FolderTab
                  key={folder.id}
                  name={folder.name}
                  id={folder.id}
                  onRemoveFolder={handleRemoveFolder}
                />
              ))}
            </TabList>
          </Flex>
        </Flex>
      </CustomScrollBar>

      {folders && folders.length > 0 && (
        <AddNewFolderButton onAddNewFolder={onAddNewFolder} />
      )}

      <DeleteModal
        isOpen={showModal}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        header={"Delete research folder"}
      >
        {loading ? (
          <Loading message={"Deleting research folder.."} />
        ) : (
          <Text>{"Are you sure you want to delete this folder?"}</Text>
        )}
      </DeleteModal>
    </>
  );
}

export default FoldersList;
