import { Text, Flex, useColorModeValue } from "@chakra-ui/react";
import { AuthorProps } from "models/papers/PaperProps";
import { CustomScrollBar } from "components/ui/CustomScrollBar";

interface AuthorsProps {
  data: AuthorProps[];
}

// Styles
const listStyle = {
  minHeight: "fit-content",
  maxHeight: "140px",
};

function Authors({ data }: AuthorsProps) {
  // Theme
  const color = useColorModeValue("gray.500", "gray.400");

  return (
    <>
      {data && data?.length > 0 ? (
        <Flex direction={"column"} gap={1}>
          <Text
            fontSize={[null, null, null, "12px", "12px", "14px"]}
            fontWeight={"500"}
            color={color}
          >
            Authors:
          </Text>

          <CustomScrollBar style={listStyle}>
            <Flex direction={"column"} gap={0.5}>
              {data.map((author: AuthorProps, index: number) => (
                <Text
                  key={index}
                  fontSize={[null, null, null, "12px", "12px", "14px"]}
                  color={color}
                  lineHeight={1.3}
                >
                  ⬦ {author?.first_name} {author?.last_name}
                </Text>
              ))}
            </Flex>
          </CustomScrollBar>
        </Flex>
      ) : (
        <></>
      )}
    </>
  );
}

export default Authors;
