import React, { useState, ChangeEvent } from "react";
import {
    FormControl,
    FormLabel,
    Input,
    Button,
    InputRightElement,
    InputGroup,
    useToast,
    Icon,
    Flex,
    useColorModeValue,
    useColorMode,
    Link,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

import { axiosClient } from "api/axios";
import { useDispatch } from "react-redux";
import { setCredentials } from "redux/features/auth/authSlice";

export default function Login({
    setResetPasswordSession,
    setExpired,
    setFormData,
    setChangePassword,
    formData
}: {
    setResetPasswordSession: (val: string | null) => void,
    setExpired: (val: boolean) => void,
    setChangePassword: (val: boolean) => void
    setFormData: (data: {
        username: string,
        password: string,
    }) => void
    formData: {
        username: string,
        password: string,
    }
}) {
    // Hooks
    const toast = useToast();
    const dispatch = useDispatch();

    const [submitting, setSubmitting] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    // Theme
    const focusBg = useColorModeValue("gray.100", "gray.700");
    const { setColorMode } = useColorMode();

    setColorMode("light");

    // Handlers
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            handleSubmit();
        }
    };

    // form submit
    const handleSubmit = async () => {
        try {
            const { username, password } = formData;

            if (!username || !password) return;

            setSubmitting(true);
            const response = await axiosClient.post(
                "/api/auth",
                JSON.stringify({ username, password }),
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            );
            const { user, roles } = response?.data;

            dispatch(setCredentials({ user, roles }));

        } catch (error: any) {
            // No response from server
            if (!error.response) {
                toast({
                    description: "No server response!",
                    status: "error",
                });
                return;
            }
            if (error.response.data.message === "NEW_PASSWORD_REQUIRED") {
                toast({
                    description: "Please reset your password",
                    status: "info",
                    position: "top-right",
                });

                setResetPasswordSession(error.response.data.session);
                setChangePassword(true);
            } else if (error.response.data.message === 'PASSWORD_EXPIRED') {
                setExpired(true)
                toast({
                    description: "Your temporary password has expired, get new one",
                    status: "info",
                    position: "top-right",
                });
            } else {
                toast({
                    description: error.response.data.message,
                    status: "error",
                });
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <>
            <FormControl id="username">
                <FormLabel fontSize={"14px"} color={"gray.600"} lineHeight={"1.3"}>
                    Username
                </FormLabel>
                <Input
                    type="text"
                    name="username"
                    placeholder="Enter your username"
                    _placeholder={{ fontSize: "14px" }}
                    value={formData.username}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    width="100%"
                    _focus={{
                        outline: "none",
                        boxShadow: "none",
                        bg: focusBg,
                    }}
                />
            </FormControl>
            <FormControl id="password">
                <FormLabel fontSize={"14px"} color={"gray.600"} lineHeight={"1.3"}>
                    Password
                </FormLabel>
                <InputGroup>
                    <Input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Enter your password"
                        _placeholder={{ fontSize: "14px" }}
                        value={formData.password}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        width="100%"
                        _focus={{
                            outline: "none",
                            boxShadow: "none",
                            bg: focusBg,
                        }}
                    />
                    <InputRightElement>
                        <Icon
                            as={showPassword ? ViewOffIcon : ViewIcon}
                            color={"primary.400"}
                            cursor={"pointer"}
                            onClick={() => setShowPassword(!showPassword)}
                        />
                    </InputRightElement>
                </InputGroup>
            </FormControl>
            <FormControl>
                <Flex justify={"end"} align={"center"} maxW={"full"}>
                    <Link
                        color={"primary.400"}
                        fontSize={"12px"}
                        lineHeight={"1"}
                        href="/reset-password"
                    >
                        Forgot password?
                    </Link>
                </Flex>
            </FormControl>
            <Button
                bg={"primary.400"}
                color={"primary.100"}
                size="md"
                w="100%"
                type="submit"
                isLoading={submitting}
                loadingText="Processing"
                onClick={handleSubmit}
                mt={6}
                _hover={{ bg: "primary.500" }}
            >
                Login
            </Button>
        </>
    );
}
