import { useCallback, useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Table,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Text,
  Tr,
  VStack,
  chakra,
  Flex,
  Heading,
} from "@chakra-ui/react";

import { CompoundProps } from "models/compounds/CompoundProps";
import { categoryToColor } from "theme/helper";
import { capitalise } from "views/compounds/helpers";
import Subtitle from "components/ui/Subtitle";

import PkTable from "components/compounds/PkTable";
import LlmRefreshEnrichment, {
  LlmEnrichmentButton,
} from "./LlmRefreshEnrichment";
import { Enrichment, generateBotResponse } from "services/chatbot.service";
import { useSubmit } from "hooks";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { useSelector } from "react-redux";

const StyledAccordionButton = chakra(AccordionButton, {
  baseStyle: {
    h: 12,
    _expanded: {
      bg: "primary.600",
      color: "white",
    },
  },
});

interface PharmaAccordionProps {
  compound: CompoundProps;
}

interface RequestBodyProps {
  inputs: string | undefined | null;
  enrichment?: Enrichment;
  user_id: string | undefined | null;
  session_id: string | undefined | null;
  message_id: string | undefined | null;
}

interface EnrichmentAnswerProps {
  safety: string;
  indication: string;
  pharmacokinetics: string;
}

interface BotReplyProps {
  message?: [];
  generated_text?: string;
  data?: { generated_text: string };
  compounds?: string[] | [];
  sources?: { id: string; title: string }[] | [];
  followup_questions?: string[] | [];
}

function getQuestion(feature: string, molecule: string) {
  return `Make me a summary on the information you have in the papers database about ${molecule} regarding ${feature}. If you dont have information regarding this compound about ${feature}, just reply with 'No data available' and nothing else.`;
}

const safetyFeature = "safety, toxic effects and adverse events";
const indicationFeature = "indications";
const pkFeature = "pk, half life";

// property to enrich
let propertyToEnrich: string;

export default function PharmaAccordion({ compound }: PharmaAccordionProps) {
  // States
  const [answer, setAnswer] = useState<EnrichmentAnswerProps>({
    safety: compound?.enrichment?.safety ?? "",
    indication: compound?.enrichment?.indication ?? "",
    pharmacokinetics: compound?.enrichment?.pharmacokinetics ?? "",
  });

  const { user } = useSelector(selectCurrentAuthData);

  const molecule =
    compound.generic_name || compound.name || compound.compound_id;

  const gvk_data_categories = Array.isArray(compound?.gvk_data?.category)
    ? compound?.gvk_data?.category
    : [];
  const wiki_data_roles = compound?.wiki_data?.role ?? [];
  const integrity_data_roles = compound?.integrity_data?.category ?? [];

  const indications = [
    ...gvk_data_categories,
    ...wiki_data_roles,
    ...integrity_data_roles,
  ];

  const hasPkData =
    !!compound?.citeline_data?.pk && !!compound?.citeline_data?.pk.length;

  function getCatColor(cat: string) {
    return gvk_data_categories && gvk_data_categories?.includes(cat)
      ? "neutral.100"
      : "gray.700";
  }

  function getCatBg(cat: string) {
    return gvk_data_categories && gvk_data_categories?.includes(cat)
      ? categoryToColor(cat)
      : "gray.200";
  }

  // API
  const { onSubmit, loading } = useSubmit(
    useCallback(
      async (ques: string) => {
        // request body containing user question and other user-related details
        const requestBody: RequestBodyProps = {
          inputs: ques,
          enrichment: {
            compound_id: compound?.compound_id,
            label: propertyToEnrich ?? "", // indication, safety, pharmacokinetics
          },
          user_id: user?.id,
          session_id: null,
          message_id: null,
        };

        // to store llm bot reply
        let response: BotReplyProps = {};

        // LLM API call
        await generateBotResponse(requestBody)
          .then((res) => {
            response = res;
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              response = error.response;
            }
          });

        // generated bot reply
        const reply =
          response?.generated_text ??
          response?.data?.generated_text ??
          "no reply";

        setAnswer((ans: EnrichmentAnswerProps) => ({
          ...ans,
          indication:
            propertyToEnrich === "indication" ? reply : ans.indication,
          pharmacokinetics:
            propertyToEnrich === "pharmacokinetics"
              ? reply
              : ans.pharmacokinetics,
          safety:
            propertyToEnrich !== "indication" &&
            propertyToEnrich !== "pharmacokinetics"
              ? reply
              : ans.safety,
        }));
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [user]
    )
  );

  const handleSendQuestion = (feature: string) => {
    // if valid question and no bot generation in progress, send question
    const question = getQuestion(feature, molecule);

    onSubmit(question);
  };

  return (
    <Accordion allowMultiple>
      {/* Roles & Indications */}
      <AccordionItem>
        <Heading>
          <StyledAccordionButton>
            <Box as="span" flex="1" textAlign="left">
              Indications
            </Box>
            <AccordionIcon />
          </StyledAccordionButton>
        </Heading>
        <AccordionPanel pb={4}>
          {/* Tags */}
          <VStack spacing={4} align={"stretch"}>
            <Flex mt={2} gap={3} wrap={"wrap"}>
              {indications.map((cat: string, idx: number) => (
                <Tag
                  key={"cat" + idx}
                  color={getCatColor(cat)}
                  bg={getCatBg(cat)}
                >
                  <TagLabel>{capitalise(cat)}</TagLabel>
                </Tag>
              ))}
            </Flex>

            <HStack>
              <Box display="inline-block">
                <Flex gap={4} direction={"column"}>
                  <Table
                    variant="unstyled"
                    size={"sm"}
                    style={{ width: "auto" }}
                  >
                    <Tbody>
                      <Tr>
                        <Td
                          wordBreak={"break-word"}
                          py={3}
                          px={1}
                          minW={"120px"}
                        >
                          <Subtitle w={"100%"} as="b">
                            Medical conditions treated
                          </Subtitle>
                        </Td>
                        {compound?.wiki_data?.medical_condition_treated ? (
                          <Td display="inline-block">
                            {compound?.wiki_data?.medical_condition_treated
                              ?.map((cond) => capitalise(cond))
                              .join(" · ") ?? "No Data"}
                          </Td>
                        ) : (
                          <Td>
                            <Text>No data available</Text>
                          </Td>
                        )}
                      </Tr>
                      {/* {indicationAnswer ? ( */}
                      {answer?.indication ? (
                        <Tr>
                          <Td>
                            <Subtitle as="b">Indication Enrichment</Subtitle>
                          </Td>
                          <Td>
                            <LlmRefreshEnrichment answer={answer?.indication} />
                          </Td>
                        </Tr>
                      ) : null}
                    </Tbody>
                  </Table>

                  <LlmEnrichmentButton
                    text={
                      answer?.indication
                        ? "Update enrichment"
                        : "Generate enrichment"
                    }
                    loading={
                      (loading && propertyToEnrich === "indication") ?? false
                    }
                    onSendQuestion={() => {
                      propertyToEnrich = "indication";
                      handleSendQuestion(indicationFeature);
                    }}
                  />
                </Flex>
              </Box>
            </HStack>
          </VStack>
        </AccordionPanel>
      </AccordionItem>

      {/* Pharmaceutical Formulation  */}
      <AccordionItem>
        <Heading>
          <StyledAccordionButton>
            <Box as="span" flex="1" textAlign="left">
              Pharmaceutical Formulation
            </Box>
            <AccordionIcon />
          </StyledAccordionButton>
        </Heading>
        <AccordionPanel pb={4}>
          <VStack spacing={4} align="stretch">
            {/* Props */}
            <Box display="inline-block">
              <Table variant="unstyled" size={"sm"} style={{ width: "auto" }}>
                <Tbody>
                  <Tr>
                    <Td>
                      <Subtitle as="b">Route of Administration</Subtitle>
                    </Td>
                    <Td>
                      {compound?.gvk_data?.administration_route?.join(" · ") ??
                        "No data"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Subtitle as="b">Mode of Appliaction</Subtitle>
                    </Td>
                    <Td>No data</Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Subtitle as="b">Dosage</Subtitle>
                    </Td>
                    <Td>No data</Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Subtitle as="b">Pregnancy Category</Subtitle>
                    </Td>
                    <Td>
                      <VStack spacing={2} align="stretch">
                        <Text fontSize={"sm"}>
                          {Array.isArray(
                            compound?.wiki_data?.pregnancy_category
                          )
                            ? compound?.wiki_data?.pregnancy_category?.join(
                                " · "
                              )
                            : "No data provided"}
                        </Text>
                      </VStack>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </VStack>
        </AccordionPanel>
      </AccordionItem>

      {/* Pharmacodynamics */}
      <AccordionItem>
        <Heading>
          <StyledAccordionButton>
            <Box as="span" flex="1" textAlign="left">
              Pharmacodynamics
            </Box>
            <AccordionIcon />
          </StyledAccordionButton>
        </Heading>
        <AccordionPanel pb={4}>
          <VStack spacing={2} align="stretch">
            {/* Table */}
            <Flex gap={4} direction={"column"}>
              <Table variant="unstyled" size={"sm"} style={{ width: "auto" }}>
                <Tbody>
                  <Tr>
                    <Td>
                      <Subtitle as="b">Mechanism of Action</Subtitle>
                    </Td>
                    <Td>
                      {/* Tags */}
                      <HStack spacing={2} my={2}>
                        {Array.isArray(
                          compound?.integrity_data?.action_mechanism
                        ) &&
                          compound?.integrity_data?.action_mechanism?.map(
                            (moa, index) => (
                              <Tag key={"moa-tag-" + index}>{moa}</Tag>
                            )
                          )}
                      </HStack>
                      {/* Description */}
                      {/* CHANGE: when compounds data gets updated, then it will always be an array? */}
                      {Array.isArray(compound?.gvk_data?.action_mechanism) ? (
                        compound?.gvk_data?.action_mechanism.map(
                          (moa, index) => (
                            <Text key={"moa-" + index}>{moa}</Text>
                          )
                        )
                      ) : (
                        <Text>{compound?.gvk_data?.action_mechanism}</Text>
                      )}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Subtitle as="b">Targets</Subtitle>
                    </Td>
                    <Td>
                      <HStack spacing={2} mt={2}>
                        {compound?.citeline_data?.target_name?.map(
                          (target, index) => (
                            <Tag key={"target-tag-" + index}>{target}</Tag>
                          )
                        )}
                      </HStack>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Subtitle as="b">Pathways</Subtitle>
                    </Td>
                    <Td>No data</Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Subtitle as="b">Safety</Subtitle>
                    </Td>
                    <Td>No data</Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Subtitle as="b">SAR</Subtitle>
                    </Td>
                    <Td>Coming soon</Td>
                  </Tr>
                  {answer?.safety ? (
                    <Tr>
                      <Td>
                        <Subtitle as="b">Safety Enrichment</Subtitle>
                      </Td>
                      <Td>
                        <LlmRefreshEnrichment answer={answer?.safety} />
                      </Td>
                    </Tr>
                  ) : null}
                </Tbody>
              </Table>

              <LlmEnrichmentButton
                text={
                  answer?.safety ? "Update enrichment" : "Generate enrichment"
                }
                loading={(loading && propertyToEnrich === "safety") ?? false}
                onSendQuestion={() => {
                  propertyToEnrich = "safety";
                  handleSendQuestion(safetyFeature);
                }}
              />
            </Flex>
          </VStack>
        </AccordionPanel>
      </AccordionItem>

      {/* Pharmacokinetics */}
      <AccordionItem>
        <Heading>
          <StyledAccordionButton>
            <Box as="span" flex="1" textAlign="left">
              Pharmacokinetics
            </Box>
            <AccordionIcon />
          </StyledAccordionButton>
        </Heading>
        <AccordionPanel pb={4}>
          <VStack spacing={5} align="stretch">
            {/* Concept */}

            {hasPkData && (
              <VStack spacing={4} align="stretch">
                {/* Props */}
                <Flex gap={4} direction={"column"}>
                  <Table
                    variant="unstyled"
                    size={"sm"}
                    style={{ width: "auto" }}
                  >
                    <Tbody>
                      <Tr>
                        <Td>
                          <Subtitle as="b">PK Data</Subtitle>
                        </Td>
                        <Td>
                          <PkTable rows={compound?.citeline_data?.pk} />
                        </Td>
                      </Tr>
                      {answer.pharmacokinetics ? (
                        <Tr>
                          <Td>
                            <Subtitle as="b">PK Enrichment</Subtitle>
                          </Td>
                          <Td>
                            <LlmRefreshEnrichment
                              answer={answer.pharmacokinetics}
                            />
                          </Td>
                        </Tr>
                      ) : null}
                    </Tbody>
                  </Table>

                  <LlmEnrichmentButton
                    text={
                      answer.pharmacokinetics
                        ? "Update enrichment"
                        : "Generate enrichment"
                    }
                    loading={
                      (loading && propertyToEnrich === "pharmacokinetics") ??
                      false
                    }
                    onSendQuestion={() => {
                      propertyToEnrich = "pharmacokinetics";
                      handleSendQuestion(pkFeature);
                    }}
                  />
                </Flex>
              </VStack>
            )}
          </VStack>
        </AccordionPanel>
      </AccordionItem>

      {/* Pharmacogenetics */}
      <AccordionItem>
        <Heading>
          <StyledAccordionButton>
            <Box as="span" flex="1" textAlign="left">
              Pharmacogenetics
            </Box>
            <AccordionIcon />
          </StyledAccordionButton>
        </Heading>
        <AccordionPanel pb={4}>
          <VStack spacing={2} align="stretch">
            <Text fontSize={"xs"}>Coming soon... </Text>
          </VStack>
        </AccordionPanel>
      </AccordionItem>

      {/* Contraindications & Side Effects */}
      <AccordionItem>
        <Heading>
          <StyledAccordionButton>
            <Box as="span" flex="1" textAlign="left">
              Contraindications & Side Effects
            </Box>
            <AccordionIcon />
          </StyledAccordionButton>
        </Heading>
        <AccordionPanel pb={4}>
          <VStack spacing={2} align="stretch">
            <Text fontSize={"xs"}>Coming soon... </Text>
          </VStack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
