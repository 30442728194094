import ChemicalProps from "models/compounds/ChemicalProps";
import {
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";

interface ChemPropsTableProps {
  chemProperties: ChemicalProps;
  origin?: string;
}

export default function ChemPropsTable({
  chemProperties,
  origin,
}: ChemPropsTableProps) {
  const {
    formula,
    inchi,
    inchikey,
    isomeric_smiles,
    canonical_smiles,
    chirality,
  } = chemProperties;

  return (
    <Table variant="simple" size="sm">
      <Tbody>
        <TableRow text="Formula" item={formula} />
        <TableRow text="Inchi" item={inchi} fontSize={inchi ? "xs" : "sm"} />
        <TableRow text="Inchi Key" item={inchikey} />
        <TableRow
          text="Isomeric Smiles"
          item={isomeric_smiles}
          fontSize={isomeric_smiles ? "xs" : "sm"}
        />
        <TableRow
          text="Canonical Smiles"
          item={canonical_smiles}
          fontSize={canonical_smiles ? "xs" : "sm"}
        />
        <TableRow text="Chirality" item={chirality} />
        <TableRow text="Origin" item={origin} isLast />
      </Tbody>
    </Table>
  );
}

interface TableRowProps {
  text: string;
  item?: string;
  fontSize?: string;
  isLast?: boolean;
}

function TableRow({ text, item, fontSize, isLast }: TableRowProps) {
  // Theme
  const borderBottomColor = useColorModeValue("gray.100", "gray.700");

  return (
    <Tr wordBreak={"break-word"}>
      <Td
        py={3}
        px={1}
        borderBottomColor={isLast ? "transparent" : borderBottomColor}
        wordBreak={"break-word"}
        minW={"95px"}
      >
        <Text
          fontSize={[null, null, null, "xs", "sm", "sm"]}
          as="b"
          wordBreak={"break-word"}
          w={"100%"}
          display={"inline-block"}
        >
          {text}
        </Text>
      </Td>
      <Td
        maxW="100%"
        borderBottomColor={isLast ? "transparent" : borderBottomColor}
        fontSize={fontSize || "sm"}
      >
        {item || "no data"}
      </Td>
    </Tr>
  );
}
