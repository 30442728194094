import {
  AccordionButton,
  AccordionIcon,
  Box,
  useColorModeValue,
  useTheme,
} from "@chakra-ui/react";
import { GetGradients } from "utils/gradients";

interface AccordionHeaderProps {
  text: string;
  isBold?: boolean;
}

function AccordionHeader({ text, isBold }: AccordionHeaderProps) {
  // Theme
  const { colors } = useTheme();
  const { lGradient2 } = GetGradients();
  const color = useColorModeValue("secondary.700", "secondary.500");
  const border = useColorModeValue("gray.100", "gray.700");
  const bgGradient = useColorModeValue(
    lGradient2,
    `linear(to-b, ${colors.dark[700]}, ${colors.dark[700]})`
  );

  return (
    <AccordionButton
      _expanded={{ bgGradient: bgGradient }}
      _hover={{ bgGradient: bgGradient }}
      fontWeight={isBold ? "500" : "400"}
      color={color}
      borderColor={border}
      borderWidth={1}
      borderRadius={"6px"}
      overflow={"hidden"}
    >
      <Box as="span" flex="1" textAlign="left">
        {text}
      </Box>
      <AccordionIcon />
    </AccordionButton>
  );
}

export default AccordionHeader;
