import { createContext, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import { Box, Flex } from "@chakra-ui/react";

import { useFetch, useSubmit } from "hooks";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import SidebarLayout from "layouts/Sidebar";
import ChatsHistory from "./ChatsHistory";
import TextField from "components/chat/TextField";
import { ConversationProps, SessionProps } from "models/chat/MessageProps";

// utils
import { environment } from "environments";

// services
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { generateBotResponse } from "services/chatbot.service";
import { maxWidth, minWidth } from "utils/responsive";

interface BotReplyProps {
  message?: [];
  generated_text?: string;
  data?: { generated_text: string };
  compounds?: string[] | [];
  sources?: { id: string; title: string }[] | [];
  followup_questions?: string[] | [];
}

const apologiesMessage =
  "Apologies, but I'm currently experiencing technical difficulties and I'm unable to assist you at the moment.\nPlease try again later.";

export const ChatbotContext = createContext({
  messages: [] as ConversationProps[],
  sessions: [] as SessionProps[],
  loadingSessions: undefined as boolean | undefined,
  waitingOnBot: undefined as boolean | undefined,
  loadingChat: false,
});

export default function ChatbotView() {
  const location = useLocation();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  // Auth
  const { user } = useSelector(selectCurrentAuthData);

  // extract session id from url
  const { id } = useParams();

  // States
  const [questionOnWait, setQuestionOnWait] = useState<string>("");
  const [chatState, setChatState] = useState<ConversationProps[]>([]);

  // API
  const { loading: loadingChat } = useFetch(
    useCallback(async () => {
      if (id) {
        const response = await axiosPrivate.get(
          `${environment.BACKEND_API}/llm/get_or_delete_chat/${id}`
        );

        setChatState(response.data);
        return response.data as ConversationProps[];
      }
      return [];
    }, [id, axiosPrivate])
  );

  // API
  const {
    data: _sessions,
    loading: _loadingSessions,
    doRefetch: refetchSessions,
  } = useFetch(
    useCallback(async () => {
      if (user?.id) {
        const response = await axiosPrivate.get(
          `${environment.BACKEND_API}/api/get_sessions/${user?.id}`
        );

        if (response.data && response.data?.length > 0) {
          // sort sessions by creation date, newest to oldest
          const sortedByCreationDate = response.data.sort(
            (a: SessionProps, b: SessionProps) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          );

          return sortedByCreationDate as SessionProps[];
        }

        return response.data as SessionProps[];
      }
      return [];
    }, [user?.id, axiosPrivate])
  );

  // API
  const { onSubmit, loading: waitingOnBot } = useSubmit(
    useCallback(
      async (question: string) => {
        if (!user) return;

        // display question in chat
        setQuestionOnWait(question);

        // request body containing user question and other user-related details
        const requestBody = {
          inputs: question,
          user_id: user?.id,
          session_id: id ?? null,
          message_id: null,
        };

        // to store llm bot reply
        let response: BotReplyProps = {};

        // LLM API call
        await generateBotResponse(requestBody)
          .then((res) => {
            response = res;
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              response = error.response;
            }
          });

        // bot partial reply (paragraph only, without: sources, compounds, and followup questions)
        const reply =
          response?.generated_text ??
          response?.data?.generated_text ??
          apologiesMessage;

        // latest exchange containing question and complete bot reply
        const latestExchange: Partial<ConversationProps> = {
          messages: [{ human: question, ai: reply }],
          sources: response.sources ?? [],
          compounds: response.compounds ?? [],
          followup_questions: response.followup_questions ?? [],
        };

        // Update chat with response received from the server
        setChatState(
          (prev: ConversationProps[]) =>
            [...prev, latestExchange] as ConversationProps[]
        );

        // clear question state after receiveing bot reply
        setQuestionOnWait("");
      },
      [id, user]
    )
  );

  // handler - update sessions list when deleting one/all chat(s)
  function updateSessions() {
    refetchSessions();
  }

  // handler
  function handleSendQuestion(qst: string) {
    // if valid question and no bot generation in progress, send question
    if (!waitingOnBot && qst.trim()) {
      onSubmit(qst);
    } else return;
  }

  useEffect(() => {
    if (_sessions) {
      if (_sessions?.length > 0) {
        location.pathname === "/chat" &&
          navigate(`/chat/${_sessions?.at(0)?.id}`);
      } else {
        navigate("/chat");
        setChatState([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_sessions]);

  // refetch sessions after new session created
  useEffect(() => {
    // no session id in url && bot generates reply
    if (!id && chatState.length) {
      refetchSessions();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatState]);

  return (
    <Flex>
      <ChatbotContext.Provider
        value={{
          messages: chatState ?? [],
          sessions: _sessions ?? [],
          loadingChat: loadingChat ?? false,
          waitingOnBot: waitingOnBot,
          loadingSessions: _loadingSessions,
        }}
      >
        {/* Sidebar tools */}
        <SidebarLayout>
          <ChatsHistory onUpdateSessions={updateSessions} />
        </SidebarLayout>

        {/* Main Panel */}
        <Box w={minWidth} minW={minWidth} maxW={maxWidth} mx={"auto"}>
          {/* route to new chat -> "chat/"
              route to existing chat -> "chat/<id>" */}
          <Outlet context={{ questionOnWait, handleSendQuestion }} />
          {/* send message */}
          <TextField onSendQuestion={handleSendQuestion} />
        </Box>
      </ChatbotContext.Provider>
    </Flex>
  );
}
