import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import {
  Link,
  Box,
  Flex,
  useColorModeValue,
  useTheme,
  useBreakpointValue,
} from "@chakra-ui/react";

import { VirtualItem, useVirtualizer } from "@tanstack/react-virtual";
import Loading from "components/ui/Loading";

import { useFetch } from "hooks";
import { CompoundsContext } from ".";
import CompoundsTable from "components/compounds/CompoundsTable";
import ResultsLength from "components/ui/ResultsLength";
import Pagination from "components/ui/Pagination";

import { addDataIntoCache, getCachedData } from "./helpers";
import NoDataFound from "components/ui/NoDataFound";
import CompoundIcon from "components/compounds/CompoundIcon";
import CompoundName from "components/compounds/CompoundName";

import { ReducedCompoundProps } from "views/graph";
import { CompoundProps } from "models/compounds/CompoundProps";
import { environment } from "environments";
import { hexToRgba } from "utils/helpers";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import { maxHeight } from "utils/responsive";

export default function CompoundsList() {
  // Ref
  const parentRef = React.useRef<HTMLDivElement | null>(null);

  // Context
  const { setSearchCount } = useContext(CompoundsContext);

  const axiosPrivate = useAxiosPrivate();

  const [searchParams] = useSearchParams();
  const query = searchParams.get("query");

  // Theme
  const { colors } = useTheme();
  const hoverBg = useColorModeValue(
    hexToRgba(colors.blue[600], 0.1),
    "dark.700"
  );

  // API
  const { data: searchResults, loading: searchLoading } = useFetch(
    useCallback(async () => {
      if (query) {
        const url = `${environment.BACKEND_API}/api/find_compounds_by_query?query=${query}`;
        const cachedResponse = await getCachedData(
          "CompoundsSearchResult",
          url
        );

        // * We can return the cached data then proceed with fetching new data then cache them
        // Check for cached search first
        if (cachedResponse) {
          return cachedResponse;
        }
        let response = await axiosPrivate.get(url);
        addDataIntoCache("CompoundsSearchResult", url, response.data);
        return response.data;
      }
      return [];
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams, axiosPrivate])
  );

  useEffect(() => {
    if (searchResults?.length) {
      setSearchCount(searchResults.length);
    } else {
      setSearchCount(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults]);

  // API
  const { data, loading } = useFetch(
    useCallback(async () => {
      if (!query) {
        const url = `${environment.BACKEND_API}/api/get_all_compounds_names`;
        const cachedResponse = await getCachedData("CompoundsResult", url);

        // Check for cached search first
        if (cachedResponse) return cachedResponse;

        let response = await axiosPrivate.get(url);
        addDataIntoCache("CompoundsResult", url, response.data);

        return response.data as ReducedCompoundProps[];
      }

      return [];
    }, [query, axiosPrivate])
  );

  // Pagination
  const itemsPerPage = 20; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1); // Current page number

  // Calculate the range of items to display based on pagination
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = searchResults?.slice(startIndex, endIndex);
  const totalPages = Math.ceil(searchResults?.length / itemsPerPage);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  // Virtualisation
  const rowVirtualizer = useVirtualizer({
    count: data && data.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 60,
    overscan: 5,
    lanes: 4,
    paddingStart: 20,
    paddingEnd: 10,
  });

  const sortedCompounds: CompoundProps[] = useMemo(
    () =>
      data &&
      data.slice().sort((a: CompoundProps, b: CompoundProps) => {
        const a_name = a.name;
        const b_name = b.name;
        return a_name.localeCompare(b_name);
      }),

    [data]
  );

  // Responsiveness: ~992px, ~1280px, ~1536px
  const CompoundsListHeight = useBreakpointValue(maxHeight);

  return (
    <Flex w={"100%"} h={CompoundsListHeight}>
      {/* show all compounds list */}
      {!query && (
        <Box ref={parentRef} w={"100%"} h={"100%"} overflow={"auto"}>
          {loading ? (
            <Loading message="Loading compounds..." />
          ) : (
            <Flex direction="column">
              {/* nbr of compounds  */}
              <ResultsLength
                ml={"auto"}
                length={data?.length}
                item="compound"
              />

              {/* compounds list */}
              <Box
                height={`${rowVirtualizer.getTotalSize()}px`}
                width={"100%"}
                position={"relative"}
              >
                {rowVirtualizer
                  .getVirtualItems()
                  .map((virtualRow: VirtualItem) => {
                    return (
                      <Link
                        position="absolute"
                        top={0}
                        left={`${virtualRow.lane * 25}%`}
                        key={virtualRow.index}
                        as={RouterLink}
                        to={`/compounds/${data[virtualRow.index]?.compound_id}`}
                        width={"25%"}
                        borderRadius={6}
                        px={1}
                        transform={`translateY(${virtualRow.start}px)`}
                        whiteSpace={"nowrap"}
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                        _hover={{ bg: hoverBg }}
                        transition={"0.25s all ease"}
                      >
                        <Flex gap={2} align={"center"} h={"60px"} w={"100%"}>
                          <CompoundIcon />
                          <CompoundName
                            cmpd={sortedCompounds[virtualRow.index]}
                          />
                        </Flex>
                      </Link>
                    );
                  })}
              </Box>
            </Flex>
          )}
        </Box>
      )}

      {/* waiting for search results */}
      {query && searchLoading && (
        <Flex
          direction={"column"}
          justify={"center"}
          align={"center"}
          my={"auto"}
          w={"100%"}
          h={"100%"}
        >
          <Loading message="Searching..." />
        </Flex>
      )}

      {/* Search Results */}
      {query && !searchLoading && searchResults && searchResults.length > 0 && (
        <Flex direction="column" w={"100%"} h={"100%"}>
          {/* table */}
          <CompoundsTable items={currentItems} />

          {/* Bottom Pagination */}
          <Flex justify="flex-end" mt={5}>
            {totalPages > 1 && (
              <Pagination
                page={currentPage}
                totalPages={totalPages}
                onNextPage={() => handlePageChange(currentPage + 1)}
                onPreviousPage={() => handlePageChange(currentPage - 1)}
                onPageChange={handlePageChange}
              />
            )}
          </Flex>
        </Flex>
      )}

      {/* No compounds found */}
      {query &&
        !searchLoading &&
        (!searchResults || searchResults.length === 0) && (
          <Flex
            direction={"column"}
            justify={"center"}
            align={"center"}
            my={"auto"}
            w={"100%"}
            h={"100%"}
          >
            <NoDataFound message={"No compounds found!"} />
          </Flex>
        )}
    </Flex>
  );
}
