import { Card, Flex } from "@chakra-ui/react";
import AdditionalPapersHeader from "./AdditionalPapersHeader";
import AdditionalPapersList from "./AdditionalPapersList";
import { ExtraPapersProps } from "./PapersReferencesChart";
import { PaperProps } from "models/papers/PaperProps";

function AdditionalPapersPanel({
  showPanel,
  showChart,
  titles,
  data,
}: {
  showPanel: boolean;
  showChart: boolean;
  titles: (ExtraPapersProps | undefined)[];
  data: PaperProps[];
}) {
  if (!showPanel) return <></>;

  return (
    <Flex
      as={Card}
      direction={"column"}
      bg={"gray.100"}
      width={`${!showChart ? "100%" : "25%"}`}
      maxWidth={`${!showChart ? "100%" : "30%"}`}
      gap={4}
      p={2.5}
      borderTop="2px solid"
      borderTopColor="secondary.700"
    >
      <AdditionalPapersHeader titles={titles} data={data} />
      <AdditionalPapersList titles={titles} />
    </Flex>
  );
}

export default AdditionalPapersPanel;
