import { Grid } from "@chakra-ui/react";
import Content from "components/home/About/Content";
import Logo from "components/home/About/Logo";

interface AboutProps {
  id: string;
}

export default function About({ id }: AboutProps) {
  return (
    <Grid
      mx={"auto"}
      maxW={"1200px"}
      w={[null, null, null, "90%", "80%", "70%"]}
      py={[null, null, null, 32, 32, 32]}
      id={id}
      templateColumns="repeat(3, 1fr)"
      gap={[null, null, null, "40px", "50px", "60px"]}
    >
      <Logo />
      <Content />
    </Grid>
  );
}
