import { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Flex } from "@chakra-ui/react";

import { PapersContext } from ".";
import Loading from "components/ui/Loading";

import { PaperProps } from "models/papers/PaperProps";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import { environment } from "environments";
import PapersTabsHeader from "components/papers/paperDetails/PapersTabsHeader";
import NetworkGraph from "components/papers/NetworkGraph";
import SelectedPaperDetails from "components/papers/paperDetails/SelectedPaperDetails";
import { maxWidth } from "utils/responsive";

export interface PaperNetworkViewProps {
  reference_paper: PaperProps;
  similar_papers: PaperProps[];
}

function PaperNetworkView() {
  // Hooks
  const { id } = useParams();
  const axiosPrivate = useAxiosPrivate();

  // States
  const [loadingPapers, setLoadingPapers] = useState(false);
  const {
    setOpenedPapers,
    openedPapers,
    setSelectedTab,
    selectedTab,
    setSearchError,
  } = useContext(PapersContext);

  const originalPaper: PaperProps = selectedTab?.reference_paper;

  const [nodeDetails, setNodeDetails] = useState<PaperProps>(originalPaper);

  const chartData = useMemo(
    () => selectedTab?.similar_papers?.concat([originalPaper]),
    [selectedTab, originalPaper]
  );

  // Handlers
  const handleNodeDetails = (node: echarts.ECElementEvent) => {
    const nodeDetails: PaperProps | undefined = chartData.find(
      (p: PaperProps) => p.title === node.name
    );

    if (!!nodeDetails) setNodeDetails(nodeDetails);
  };

  // Hooks
  useEffect(() => {
    setNodeDetails(originalPaper);
  }, [originalPaper]);

  useEffect(() => {
    setSearchError("");
    if (id) {
      (async () => {
        try {
          // Check if already opened
          let tabAlreadyOpened: PaperNetworkViewProps | undefined =
            openedPapers.find(
              (tab: PaperNetworkViewProps) => tab.reference_paper.id === id
            );

          if (tabAlreadyOpened) {
            setSelectedTab(tabAlreadyOpened);
            return;
          }
          setLoadingPapers(true);

          const response = await axiosPrivate.get(
            `${environment.BACKEND_API}/api/get_similar_papers/${id}`
          );
          const data: PaperNetworkViewProps = response.data;

          let newOpenedTabs = [...openedPapers];
          newOpenedTabs.push(data);
          setOpenedPapers(newOpenedTabs);
          setSelectedTab(data);
          sessionStorage.setItem(
            "openedPapersTabs",
            JSON.stringify(newOpenedTabs)
          );
        } catch (error: any) {
          if (!error.response) {
            setSearchError("No server response!");
          } else {
            setSearchError(error.response.data.message);
          }
          setSelectedTab({
            reference_paper: {},
            similar_papers: [],
          });
        } finally {
          setLoadingPapers(false);
        }
      })();
    }

    return () => {
      setSelectedTab({
        reference_paper: {},
        similar_papers: [],
      });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // loading
  if (loadingPapers) return <Loading message={"Preparing network..."} />;

  return (
    <Flex h={"100%"} w={"100%"}>
      {selectedTab.hasOwnProperty("reference_paper") &&
        selectedTab.hasOwnProperty("similar_papers") && (
          // Main network display
          <Flex
            direction={"column"}
            w={"100%"}
            maxW={maxWidth}
            p={[
              null,
              null,
              null,
              "60px 12px 12px",
              "60px 14px 14px",
              "60px 26px 16px",
            ]}
            mx={"auto"}
            overflow={"hidden"}
          >
            <PapersTabsHeader />
            <Flex
              w={"100%"}
              h={"100%"}
              p={[null, null, null, "12px", "14px", "16px"]}
              mx={"auto"}
            >
              <NetworkGraph
                onClickNode={handleNodeDetails}
                paperData={selectedTab}
              />
              {id && (
                <SelectedPaperDetails
                  data={nodeDetails ?? originalPaper}
                  isOrigin={nodeDetails?.id === originalPaper?.id}
                />
              )}
            </Flex>
          </Flex>
        )}
    </Flex>
  );
}

export default PaperNetworkView;
