import { Flex, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import { CustomScrollBar } from "components/ui/CustomScrollBar";

import HistoryItem from "./HistoryItem";

import { RiHistoryLine } from "react-icons/ri";
import { ReducedCompoundProps } from "views/graph";
import SidebarTitle from "components/ui/SidebarTitle";
import Disclaimer from "components/ui/Disclaimer";

export interface RecentlyViewedProps {
  compounds: ReducedCompoundProps[];
}

export default function RecentlyViewed({ compounds }: RecentlyViewedProps) {
  // Theme
  const headerTextColor = useColorModeValue("secondary.700", "secondary.400");

  // Responsiveness: ~992px, ~1280px, ~1536px
  // NOTE: 250 + 16, 270 + 16, and 290 + 16 are not exact values, picked based on
  // current display resolution. (16: indicates added padding on top to align dividers)
  // Feel free to adjust them, if you find better solution
  const historyListHeight = useBreakpointValue({
    lg: "calc(100vh - 266px)",
    xl: "calc(100vh - 286px)",
    "2xl": "calc(100vh - 306px)",
  });

  const historyListStyle = {
    height: historyListHeight,
    padding: "0",
  };

  return (
    <Flex direction={"column"} gap={2}>
      <SidebarTitle
        text="Recently Viewed"
        icon={RiHistoryLine}
        color={headerTextColor}
      />
      {compounds && compounds?.length > 0 ? (
        <CustomScrollBar style={historyListStyle}>
          <Flex direction="column" gap={0.5}>
            {compounds?.map((comp: ReducedCompoundProps, index: number) => (
              <HistoryItem key={index} compound={comp} />
            ))}
          </Flex>
        </CustomScrollBar>
      ) : (
        <Disclaimer
          content={
            "When you explore compounds, we'll display links to your most recently visited."
          }
          type="info"
          mt={1}
        />
      )}
    </Flex>
  );
}
