import {
  Flex,
  Icon,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { videoTutorialWidth } from "utils/responsive";
import { getIconByName } from "views/tutorials/helpers";

interface VideoThumbnailProps {
  type: string;
  url: string;
  title: string;
  isActive: boolean;
}

function VideoThumbnail({ type, url, title, isActive }: VideoThumbnailProps) {
  // Theme
  const bg = useColorModeValue("gray.300", "dark.600");
  const color = useColorModeValue("gray.500", "gray.300");

  const w = useBreakpointValue(videoTutorialWidth);

  return (
    <Flex
      direction={"column"}
      w={w}
      gap={2}
      filter={isActive ? "none" : "grayscale(1)"}
      opacity={isActive ? "1" : ".4"}
    >
      <Flex
        as="video"
        muted
        src={url}
        w={"100%"}
        objectFit="cover"
        borderRadius={[null, null, null, "4px", "4px", "6px"]}
        minH={"92px"}
        bg={bg}
        _hover={{ cursor: "pointer" }}
      />

      {/* video description */}
      <Flex h={"100%"} w={"100%"} direction={"column"} gap={1}>
        <Flex gap={2} align={"center"}>
          <Icon
            as={getIconByName(type)}
            boxSize={[null, null, null, "13px", "14px", "16px"]}
            color={"highlight.primary"}
          />
          <Text
            fontSize={[null, null, null, "13px", "14px", "16px"]}
            textTransform={"capitalize"}
            color={"highlight.primary"}
            fontWeight={"500"}
          >
            {type}
          </Text>
        </Flex>

        <Text
          fontSize={"12px"}
          color={color}
          letterSpacing={"0.01rem"}
          whiteSpace="pre-line"
          fontFamily={"Poppins, sans-serif"}
        >
          {title}
        </Text>
      </Flex>
    </Flex>
  );
}

export default VideoThumbnail;
