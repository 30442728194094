import { Outlet, useParams } from "react-router-dom";
import { Box, Divider, Flex, useDisclosure, useToast } from "@chakra-ui/react";
import { createContext, useMemo, useState } from "react";
import { SuggestionProps } from "./SuggestionsView";
import { PaperNetworkViewProps } from "./NetworkView";
import { useSelector } from "react-redux";
import useEmbeddingProcess from "hooks/literatureEmbedding/useEmbeddingProcess";
import {
  EmbeddingState,
  selectCurrentEmbeddingData,
} from "redux/features/embedding/embeddingSlice";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import SidebarLayout from "layouts/Sidebar";
import PapersBreadcrumb from "./Breadcrumb";
import NetworkTools from "./NetworkTools";
import SearchTools from "./SearchTools";
import MainPanelError from "components/ui/MainPanelError";
import { maxWidth, minWidth } from "utils/responsive";
import { PaperProps } from "models/papers/PaperProps";

export interface RecentSearchProps {
  searchResults: SuggestionProps[];
  searchQuery: string;
}

interface SearchProps {
  searching: boolean;
  searched: boolean;
  query: string;
}

interface FiltersProps {
  embedded: string;
  types: string[];
}

export interface PapersContextProps {
  recentSearch: RecentSearchProps | null;
  setRecentSearch: (recentSearch: RecentSearchProps | null) => void;
  openedPapers: PaperNetworkViewProps[];
  setOpenedPapers: (ids: PaperNetworkViewProps[]) => void;
  selectedTab: string | undefined | null;
  setSelectedTab: (id: string | undefined | null) => void;
}

export interface PapersOutletContextProps {
  showEmbeddingControls: boolean;
}

export interface PapersViewContextProps {
  openedPapers: PaperNetworkViewProps[];
  setOpenedPapers: React.Dispatch<
    React.SetStateAction<PaperNetworkViewProps[]>
  >;

  selectedTab: PaperNetworkViewProps;
  setSelectedTab: React.Dispatch<React.SetStateAction<PaperNetworkViewProps>>;
  isEmbeddingInProgress: boolean;
  initEmbedding: (papers: string[] | string) => void;

  // Search
  suggestions: SuggestionProps[];
  setSuggestions: (s: SuggestionProps[]) => void;
  search: SearchProps;
  setSearch: (b: SearchProps) => void;

  // Error
  searchError: string;
  setSearchError: (err: string) => void;

  // Filters
  filters: FiltersProps;
  applyFilters: (s: string | string[], isTypes?: boolean) => void;
  initialFilterTypes: string[]
  // Embeddings Controls
  showEmbeddingControls: boolean;
  onToggleEmbeddingControls: () => void;
  papersToEmbed: string[];
  setPapersToEmbed: (papersToEmbed: string[]) => void;

  // Highlight
  highlightedPaper: PaperProps;
  handleHighlightedPaper: (highlightedPaper: PaperProps) => void;
}

export const PapersContext = createContext<PapersViewContextProps>({
  selectedTab: {
    reference_paper: {},
    similar_papers: [],
  },
  setSelectedTab: () => { },
  openedPapers: [] as PaperNetworkViewProps[],
  setOpenedPapers: () => { },
  isEmbeddingInProgress: false,
  papersToEmbed: [],
  setPapersToEmbed: (papersToEmbed: string[]) => { },
  initEmbedding: () => { },
  suggestions: [] as SuggestionProps[],
  setSuggestions: (s: SuggestionProps[]) => { },
  searchError: "",
  setSearchError: (err: string) => { },
  search: {
    searching: false,
    searched: false,
    query: "",
  },
  setSearch: (s: SearchProps) => { },
  filters: { embedded: "all", types: [] },
  applyFilters: (s: string | string[], isTypes?: boolean) => { },
  initialFilterTypes: [],
  showEmbeddingControls: false,
  onToggleEmbeddingControls: () => { },
  highlightedPaper: {},
  handleHighlightedPaper: (highlightedPaper: PaperProps) => { },
});

// let selectedP: PaperProps = {};

export default function PapersView() {
  // opened tabs
  const recentOpenedTabs = sessionStorage.getItem("openedPapersTabs");
  const tabs = recentOpenedTabs ? JSON.parse(recentOpenedTabs) : [];

  // recentSearchResults
  const recentSearch = sessionStorage.getItem("recentLiteraturesSearch");
  const recentSearchResults = recentSearch ? JSON.parse(recentSearch) : null;

  // Params
  const { id } = useParams();

  // Store
  const { user } = useSelector(selectCurrentAuthData);
  const { progress }: EmbeddingState = useSelector(selectCurrentEmbeddingData);

  // Hooks
  const toast = useToast();

  // search
  const [suggestions, setSuggestions] = useState<SuggestionProps[] | []>(
    recentSearchResults?.searchResults || []
  );
  const [search, setSearch] = useState({
    searching: false,
    searched: recentSearchResults?.searchQuery ? true : false,
    query: recentSearchResults?.searchQuery || "",
  });

  // tabs
  const [openedPapers, setOpenedPapers] =
    useState<PaperNetworkViewProps[]>(tabs);
  const [selectedTab, setSelectedTab] = useState<PaperNetworkViewProps>({
    reference_paper: {},
    similar_papers: [],
  });

  const initialFilterTypes = useMemo(() => {
    return [
      ...new Set(suggestions?.map((sugg) => sugg?.paper_type.toLowerCase())),
    ]
  }, [suggestions]);

  // filters
  const [filters, setFilters] = useState<FiltersProps>({
    embedded: "all",
    types: initialFilterTypes,
  });

  // highlight paper on paper item hovered
  const [highlightedPaper, setHighlightedPaper] = useState<PaperProps>({});

  function applyFilters(option: string[] | string, isTypes: boolean = false) {
    if (isTypes) {
      if (Array.isArray(option)) {
        setFilters({
          ...filters,
          types: option,
        });
      }
    } else {
      if (typeof option === "string") {
        setFilters({ ...filters, embedded: option });
      }
    }
  }

  // Error
  const [searchError, setSearchError] = useState<string>("");

  // embed
  const { initiateSocket } = useEmbeddingProcess();
  // const isEmbeddingInProgress = progress !== null && progress < 100;
  const isEmbeddingInProgress = progress !== null && progress < 100;

  const [papersToEmbed, setPapersToEmbed] = useState<string[]>([]);

  const { isOpen: showEmbeddingControls, onToggle: onToggleEmbeddingControls } =
    useDisclosure();

  const initEmbedding = async (papers: string[] | string) => {
    // Embedding Only for premium
    if (user?.user_type !== "premium") return;

    // Don't allow multiple embeddings
    if (isEmbeddingInProgress) {
      toast({
        description: "An embed operation in progress, please wait",
        status: "error",
        position: "top-right",
      });
      return;
    }

    // Connect to a socket
    initiateSocket(papers);
  };

  // let temp: PaperProps = {};

  function handleHighlightedPaper(p: PaperProps) {
    if (p && p.id) setHighlightedPaper(p);
  }

  return (
    <Flex>
      <PapersContext.Provider
        value={{
          searchError,
          setSearchError,
          openedPapers,
          setOpenedPapers,
          selectedTab,
          setSelectedTab,
          isEmbeddingInProgress,
          papersToEmbed,
          setPapersToEmbed,
          initEmbedding,
          suggestions,
          setSuggestions,
          search,
          setSearch,
          filters,
          applyFilters,
          initialFilterTypes,
          showEmbeddingControls,
          onToggleEmbeddingControls,
          highlightedPaper,
          handleHighlightedPaper,
        }}
      >
        {/* Sidebar */}
        <SidebarLayout>
          <PapersBreadcrumb />
          <Divider my={4} />
          {id ? <NetworkTools /> : <SearchTools />}
        </SidebarLayout>

        {/* Main Panel */}
        <Box w={minWidth} minW={minWidth} maxW={maxWidth} mx={"auto"} py={2}>
          {/* Search Results & Networks*/}
          {searchError ? (
            <MainPanelError errorMessage={searchError} />
          ) : (
            <Outlet />
          )}
        </Box>
      </PapersContext.Provider>
    </Flex>
  );
}
