import { Box, Card, useColorModeValue } from "@chakra-ui/react";
import { ReactNode } from "react";

interface PropsCardProps {
  children: ReactNode;
}

function PropsCard({ children }: PropsCardProps) {
  // Theme
  const bg = useColorModeValue("whiteAlpha.600", "dark.700");

  return (
    <Box
      as={Card}
      mb={2}
      p={4}
      // borderTop="2px solid"
      // borderTopColor="secondary.700"
      // variant="outline"
      bg={bg}
      overflow={"hidden"}
    >
      {children}
    </Box>
  );
}

export default PropsCard;
