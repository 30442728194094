import {
  Flex,
  Grid,
  GridItem,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import ElementTab, { ElementItemProps } from "./ElementTab";
import { PiGraph, PiTestTube } from "react-icons/pi";
import { BsPaperclip } from "react-icons/bs";
import { TbArrowsSplit } from "react-icons/tb";
import {
  SavedAssayProps,
  SavedChartProps,
  SavedCompoundProps,
  SavedLiteratureProps,
} from "redux/features/saved/savedSlice";

export interface DeleteElementHandlerProps {
  elementType: "COMPOUND" | "LITERATURE" | "ASSAY" | "TREEOFLIFE";
  content: {
    elementId: string | undefined;
    selected_feature?: string | undefined;
  };
}

function FolderContentSection({
  data,
  sectionType,
  deleteElementHandler,
}: {
  data:
    | SavedCompoundProps[]
    | SavedAssayProps[]
    | SavedLiteratureProps[]
    | SavedChartProps[];
  sectionType: "COMPOUND" | "LITERATURE" | "ASSAY" | "TREEOFLIFE";
  deleteElementHandler: ({
    elementType,
    content,
  }: DeleteElementHandlerProps) => void;
}) {
  const color = useColorModeValue("gray.600", "gray.300");
  const deleteElement = (content: {
    elementId: string | undefined;
    selected_feature?: string | undefined;
  }) => {
    deleteElementHandler({ elementType: sectionType, content });
  };

  const renderContent = () => {
    switch (sectionType) {
      case "COMPOUND":
        return {
          sectionTitle: "Compounds",
          icon: PiGraph,
        };
      case "LITERATURE":
        return {
          sectionTitle: "Literatures",
          icon: BsPaperclip,
        };
      case "ASSAY":
        return {
          sectionTitle: "Assays",
          icon: PiTestTube,
        };
      case "TREEOFLIFE":
        return {
          sectionTitle: "Tree of life",
          icon: TbArrowsSplit,
        };
    }
  };

  return (
    <Grid
      templateColumns={"auto 1fr"}
      templateRows={"100%"}
      gap={2}
      color={color}
    >
      <GridItem colSpan={1} rowSpan={2} p={0}>
        <Icon as={renderContent().icon} boxSize={4} />
      </GridItem>

      <GridItem colSpan={1} rowSpan={2} p={0}>
        <Text fontSize={"14px"} fontWeight={"500"}>
          {renderContent().sectionTitle}
        </Text>

        <Flex direction={"column"} gap={0.5} py={1}>
          {data.map((item: ElementItemProps, index: number) => (
            <ElementTab
              item={item}
              key={index}
              elementType={sectionType}
              deleteElement={deleteElement}
            />
          ))}
        </Flex>
      </GridItem>
    </Grid>
  );
}

export default FolderContentSection;
