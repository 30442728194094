import { Box, Grid } from "@chakra-ui/react";
import Header from "components/home/FAQ/Header";
import Content from "components/home/FAQ/Content";

interface FAQProps {
  id: string;
}

export default function FAQ({ id }: FAQProps) {
  return (
    <Box
      bgImage="https://reacto.s3.eu-central-1.amazonaws.com/images/bg-poly7.png"
      bgSize="cover"
      mt="-40px"
    >
      <Grid
        id={id}
        w={[null, null, null, "90%", "95%", "100%"]}
        maxW={[null, null, null, "1100px", "1360px", "1360px"]}
        mt={[null, null, null, "60px", "100px", "100px"]}
        mb={[null, null, null, "60px", "160px", "100px"]}
        mx={"auto"}
        templateColumns="repeat(3, 1fr)"
        gap={[null, null, null, "40px", "50px", "60px"]}
      >
        <Header />
        <Content />
      </Grid>
    </Box>
  );
}
