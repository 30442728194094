import HelperProps from "./HelperProps";

export const screeningdataprops_helper: HelperProps[] = [
  {
    title: "Assay",
    description:
      "Assay refers to a method or procedure used in the laboratory to analyze and measure the presence, concentration, or activity of a substance or biological component of interest.",
  },
  {
    title: "Assay Type",
    description:
      "Assays can vary widely based on the specific purpose and the nature of the substance being studied. They could involve techniques such as biochemical assays, cell-based assays, or molecular assays.",
  },
  {
    title: "Indication",
    description:
      "Refers to the specific purpose or reason for conducting the assay. It outlines the intended use or what the assay is designed to detect, measure, or analyze. ",
  },
  {
    title: "Screening Hit",
    description: "Specifies if the screening was a hit or not."
  },
  {
    title: "Assay Source",
    description: "Refers to the entity that carried out the assays.",
  },
];
