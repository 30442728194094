import { Text, TextProps } from "@chakra-ui/react";

export default function Subtitle(props: TextProps) {
  const { children, ...rest } = props;

  const transformedChildren =
    typeof children === "string" ? children.toUpperCase() : children;

  return (
    <Text fontSize="xs" mb={2} {...rest}>
      {transformedChildren}
    </Text>
  );
}

export function SubtitleSM(props: TextProps) {
  const { children, ...rest } = props;

  const transformedChildren =
    typeof children === "string" ? children.toUpperCase() : children;

  return (
    <Text fontSize="sm" mb={2} {...rest} as={"b"}>
      {transformedChildren}
    </Text>
  );
}
