import { useState, useMemo } from "react";
import {
  Flex,
  TabPanel,
  TabPanels,
  Tabs,
  useColorModeValue,
  Box,
  Text,
  Icon,
  useBreakpointValue,
} from "@chakra-ui/react";
import FoldersList from "./FoldersList";
import Loading from "components/ui/Loading";
import { useSelector } from "react-redux";
import {
  SavedFolderProps,
  selectCurrentSavedData,
} from "redux/features/saved/savedSlice";
import NoFolders from "components/saved/NoFolders";
import EmptyFolder from "components/saved/EmptyFolder";
import FolderContent from "components/saved/FolderContent";
import SidebarLayout from "layouts/Sidebar";
import { FaFolder } from "react-icons/fa6";
import { maxHeight, maxWidth, minWidth } from "utils/responsive";
import MainPanelError from "components/ui/MainPanelError";
import NewFolderModal from "components/saved/NewFolderModal";

export default function SavedElements() {
  // States
  const [showModal, setShowModal] = useState(false);
  const { loading, error, savedFolders } = useSelector(selectCurrentSavedData);

  function hasNoSavedElements(folder: SavedFolderProps) {
    return (
      !folder.compounds?.length &&
      !folder.assays?.length &&
      !folder.literatures?.length &&
      !folder.treeOfLifes?.length
    );
  }

  const hasNoFolders = useMemo(() => !savedFolders.length, [savedFolders]);
  const headerTextColor = useColorModeValue("secondary.700", "secondary.400");
  const headerIconColor = useColorModeValue("secondary.600", "secondary.400");

  // Responsiveness: ~992px, ~1280px, ~1536px
  const FolderContainerHeight = useBreakpointValue(maxHeight);

  return (
    <Flex>
      <Tabs isLazy orientation="vertical" variant="unstyled" flex={1}>
        {/* Sidebar */}
        <SidebarLayout>
          {/* Header */}
          <Flex gap={2} align={"center"} mb={4}>
            <Icon
              as={FaFolder}
              color={headerIconColor}
              boxSize={[null, null, null, "14px", "14px", "16px"]}
            />

            <Text
              color={headerTextColor}
              fontSize={[null, null, null, "12px", "12px", "14px"]}
              fontWeight={"500"}
              lineHeight={1}
            >
              My Folders
            </Text>
          </Flex>

          {/* Folders List */}
          {!loading && (
            <FoldersList
              folders={savedFolders}
              onAddNewFolder={() => setShowModal(true)}
            />
          )}
        </SidebarLayout>

        {/* Main Panel */}
        <Box w={minWidth} minW={minWidth} maxW={maxWidth} mx={"auto"} py={2}>
          {!!error ? (
            // if has error
            <Flex h={FolderContainerHeight} w={"100%"}>
              <MainPanelError />
            </Flex>
          ) : !!loading ? (
            // if loading
            <Loading message="Loading folders.." />
          ) : hasNoFolders ? (
            // if has no error, not loading, and has no folders
            <NoFolders onAddNewFolder={() => setShowModal(true)} />
          ) : (
            // if has no error, not loading, and has folders
            <TabPanels
              h={"100%"}
              w={"100%"}
              justifyContent="center"
              color={"gray.600"}
            >
              {savedFolders.map((folder: SavedFolderProps, index: number) => (
                <TabPanel key={index} h={"100%"}>
                  {hasNoSavedElements(folder) ? (
                    <EmptyFolder />
                  ) : (
                    <FolderContent folder={folder} />
                  )}
                </TabPanel>
              ))}
            </TabPanels>
          )}
        </Box>
      </Tabs>

      <NewFolderModal isOpen={showModal} onClose={() => setShowModal(false)} />
    </Flex>
  );
}
