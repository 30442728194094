import axios from "axios";
import { PaperProps } from "models/papers/PaperProps";

const client_id = "479d8619-a340-4970-98be-05630785c5bf";
const client_secret = "RNW8Q~q12ESP320EOsQsEjHBpHsWoAk~osw83amD";
const site_id =
  "vitafluenceai.sharepoint.com,eb9d447b-d0b2-4a5c-9d04-1c8f9a01ce41,6225eb28-122a-4e18-a0f6-ef5bcde9f853";
const drive_id = "";
const tenant_id = "a8cde73f-8496-42fb-9eb0-ffdb841e3010";

const token_url = `https://login.microsoftonline.com/${tenant_id}/oauth2/v2.0/token`;

export const findPaperByQuery = (paperData: PaperProps[], query: string) => {
  const results = paperData?.filter((paper: PaperProps) => {
    const includesTitle = paper?.title
      ?.toLowerCase()
      .includes(query.toLowerCase());

    return includesTitle;
  });

  return results;
};

export const truncateString = (str: string, maxLength: number) => {
  if (str?.length > maxLength) {
    return str.slice(0, maxLength) + "...";
  }
  return str;
};

const getAccessToken = async () => {
  const formData = new FormData();
  formData.append("grant_type", "client_credentials");
  formData.append("client_id", client_id);
  formData.append("client_secret", client_secret);
  formData.append("scope", "https://graph.microsoft.com/.default");

  const response = await axios.post(token_url, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });

  return response.data.access_token;
};

export const handleDownload = async (paper: PaperProps) => {
  const downloadUrl = `https://graph.microsoft.com/v1.0/sites/${site_id}/drives/${drive_id}/items/${paper.id}/content`;
  axios
    .get(downloadUrl, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        "Content-Type": "application/octet-stream",
      },
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = `paper-${paper.title}.pdf`;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      alert(error.message);
    });
};
