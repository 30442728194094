import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Icon,
  Text,
  SimpleGrid,
  useColorModeValue,
  Box,
  useToast,
  useTheme,
} from "@chakra-ui/react";

import Loading from "components/ui/Loading";
import NewFolderModal from "./NewFolderModal";
import { CustomThickScrollBar } from "components/ui/CustomScrollBar";

import { hexToRgba } from "utils/helpers";

import {
  SavedFolderProps,
  selectCurrentSavedData,
  setSuccess,
} from "redux/features/saved/savedSlice";

import { BsFillPlusCircleFill } from "react-icons/bs";
import { TbFolderCheck } from "react-icons/tb";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import { environment } from "environments";

interface FoldersModalProps {
  isOpen: boolean;
  onClose: () => void;
  payload: {
    saveElementPayload: {
      elementType: "COMPOUND" | "LITERATURE" | "ASSAY" | "TREEOFLIFE";
      content: {
        elementId: string;
        selected_feature?: string;
      };
    };
    successMessage: string;
  };
}

// Styles
const listStyle = {
  maxHeight: "146px",
  width: "100%",
  padding: "0 10px 0 0",
  margin: "0 0 12px 0",
};

export default function FoldersModal({
  isOpen,
  onClose,
  payload,
}: FoldersModalProps) {
  const toast = useToast();

  // Redux
  const { loading, savedFolders } = useSelector(selectCurrentSavedData);
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();

  // States
  const [selectedFolder, setSelectedFolder] = useState<string>("");
  const [showNewFolderModal, setNewFolderShowModal] = useState(false);
  const [saving, setSaving] = useState(false);

  // Theme
  const { colors } = useTheme();
  const headerColor = useColorModeValue("gray.600", "gray.200");
  const bg = useColorModeValue("neutral.100", "transparent");
  const color = useColorModeValue("gray.500", "gray.300");
  const color2 = useColorModeValue("neutral.100", "dark.800");
  const color3 = useColorModeValue("gray.500", "dark.800");

  // Handler
  const saveElementHandler = async () => {
    try {
      if (!selectedFolder) return;
      setSaving(true);

      const response = await axiosPrivate.put(
        `${environment.BACKEND_API}/api/saved_elements`,
        {
          ...payload.saveElementPayload,
          folderId: selectedFolder,
        }
      );
      const data = response.data;
      const newFolders = savedFolders.map((fold: SavedFolderProps) => {
        if (fold.id === data.id) return data;
        return fold;
      });

      dispatch(setSuccess(newFolders));

      toast({
        position: "bottom-right",
        duration: 1500,
        render: () => (
          <Box
            color="white"
            p={3}
            bg={"highlight.primary"}
            borderRadius={"6px"}
          >
            {payload.successMessage}
          </Box>
        ),
      });

      setSelectedFolder("");
      setSaving(false);
      onClose();
    } catch (error: any) {
      // NOTE: type not provided for error object
      toast({
        description: error.response.data.message,
        status: "error",
        position: "top-right",
      });
      setSaving(false);
      console.log(error);
    }
    // catch error element already saved
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay backdropFilter="blur(3px)" />
        <ModalContent
          alignSelf={"center"}
          p={1}
          w={"fit-content"}
          minW={"412px"}
          minH={"224px"}
        >
          <ModalHeader
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            gap={2}
          >
            <Box display={"flex"} alignItems={"center"} gap={2}>
              <Icon
                as={TbFolderCheck}
                bg={"highlight.primary"}
                color={color2}
                boxSize={"28px"}
                borderRadius={"6px"}
                p={"3px"}
              />
              <Text fontSize={"16px"} color={headerColor} fontWeight={"500"}>
                Save to research folder:
              </Text>
            </Box>

            <Box
              display={"flex"}
              alignItems={"center"}
              gap={1}
              cursor={"pointer"}
              onClick={() => setNewFolderShowModal(true)}
            >
              <Icon
                as={BsFillPlusCircleFill}
                color={"highlight.primary"}
                boxSize={"22px"}
                borderRadius={"6px"}
                p={"1px"}
              />
              <Text
                fontSize={"12px"}
                color={headerColor}
                _hover={{ color: "highlight.primary" }}
              >
                New folder
              </Text>
            </Box>
          </ModalHeader>
          <ModalBody>
            {loading ? (
              <Loading />
            ) : (
              <CustomThickScrollBar style={listStyle}>
                <SimpleGrid minChildWidth="120px" spacing="12px">
                  {savedFolders && savedFolders.length > 0 ? (
                    savedFolders.map((folder: SavedFolderProps, i) => (
                      <Button
                        key={i}
                        bg={
                          folder?.id === selectedFolder
                            ? hexToRgba(colors.highlight.primary, 0.06)
                            : bg
                        }
                        height="40px"
                        px={2}
                        borderRadius={6}
                        borderWidth={1}
                        borderColor={
                          folder.id === selectedFolder
                            ? "highlight.primary"
                            : "gray.200"
                        }
                        _hover={{
                          borderColor: "highlight.primary",
                          bg: hexToRgba(colors.highlight.primary, 0.06),
                        }}
                        onClick={() => setSelectedFolder(folder?.id ?? "")}
                      >
                        <Text
                          color={color}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          whiteSpace={"nowrap"}
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                        >
                          {folder.name}
                        </Text>
                      </Button>
                    ))
                  ) : (
                    <Text color={color} textAlign={"center"}>
                      No folders found.
                      <br />
                      Create new folder to save your items
                    </Text>
                  )}
                </SimpleGrid>
              </CustomThickScrollBar>
            )}
          </ModalBody>
          <ModalFooter gap={2}>
            <Button
              bg="gray.100"
              color={color3}
              _hover={{ bg: "gray.200" }}
              borderRadius={"100px"}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              bg={"highlight.primary"}
              color={color2}
              border={"none"}
              _hover={{ opacity: 0.9 }}
              disabled={!selectedFolder}
              borderRadius={"100px"}
              onClick={() => !saving && saveElementHandler()}
            >
              {saving ? <Loading message="Saving" /> : "Select"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <NewFolderModal
        isOpen={showNewFolderModal}
        onClose={() => setNewFolderShowModal(false)}
      />
    </>
  );
}
