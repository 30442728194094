import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { useEffect, useState } from "react";
import useRefreshToken from "hooks/auth/useRefreshToken";

const PersistLogin = () => {
    const { user } = useSelector(selectCurrentAuthData);
    const refresh = useRefreshToken();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loggedIn = localStorage.getItem('loggedIn')
        const LOGGEDIN = loggedIn ? JSON.parse(loggedIn) : false

        const verifyRefreshToken = async () => {
            try {
                LOGGEDIN && await refresh();
            } catch (err) {
                console.log(err)
            } finally {
                setLoading(false);
            }
        };
        // Always attempt token refresh when user is not available (User initially is Null in state)
        !user ? verifyRefreshToken() : setLoading(false);

    }, [user, refresh]);

    return <>{!loading && <Outlet />}</>
}

export default PersistLogin;