import { ChangeEvent, KeyboardEvent, useState } from "react";
import { Flex, Icon, Textarea, useColorModeValue } from "@chakra-ui/react";
import { BsMicFill, BsSendFill } from "react-icons/bs";

interface TextFieldProps {
  onSendQuestion: (s: string) => void;
}

export default function TextField({ onSendQuestion }: TextFieldProps) {
  // State
  const [question, setQuestion] = useState("");

  // Theme
  const bg = useColorModeValue("neutral.100", "dark.700");
  const placeholderColor = useColorModeValue("gray.500", "gray.400");
  const iconColor = useColorModeValue("primary.300", "highlight.primary");
  const iconHoverColor = useColorModeValue("primary.400", "primary.200");
  // Handler
  function handleInputChange(e: ChangeEvent<HTMLTextAreaElement>) {
    // remove line break characters when pasting question
    setQuestion(e.target.value.replace(/(\r\n|\n|\r)/gm, ""));
  }

  // Handler
  function handleSend(event: KeyboardEvent<HTMLTextAreaElement>) {
    const trimmed = question.trim();

    // Check if the trimmed string is not empty
    if (trimmed !== "") {
      if (event.key === "Enter") {
        // don't break line when 'Enter' key pressed
        event.preventDefault();

        onSendQuestion(trimmed);
        setQuestion("");
      }
    }
  }

  // Handler
  function handleSendIconClicked() {
    const trimmed = question.trim();

    if (trimmed !== "") {
      onSendQuestion(question);
      setQuestion("");
    }
  }

  return (
    <Flex gap={2} direction={"column"} w={"900px"} maxW={"90%"} mx={"auto"}>
      <Textarea
        value={question}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => handleInputChange(e)}
        onKeyDown={handleSend}
        name="message-box"
        placeholder="Ask MoleculeLake..."
        _placeholder={{ color: placeholderColor }}
        resize="none"
        minHeight="60px"
        maxHeight="60px"
        bg={bg}
        _focus={{
          outline: "none",
          borderWidth: 1,
          boxShadow: "md",
          overflow: "auto",
        }}
        _focusVisible={{ outline: "none" }}
      />
      <Flex justify="flex-end" gap={2} px={1}>
        {/* Do functionality later */}
        {/* <Icon
          as={BsMicFill}
          cursor={"pointer"}
          boxSize={4}
          color={iconColor}
          _hover={{ color: iconHoverColor }}
        /> */}
        <Icon
          as={BsSendFill}
          cursor={"pointer"}
          boxSize={4}
          color={iconColor}
          onClick={handleSendIconClicked}
          _hover={{ color: iconHoverColor }}
        />
      </Flex>
    </Flex>
  );
}
