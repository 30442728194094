import { Button, Icon, useColorModeValue, useTheme } from "@chakra-ui/react";
import { HiOutlineFolderPlus } from "react-icons/hi2";
import { hexToRgba } from "utils/helpers";

interface AddNewFolderButtonProps {
  onAddNewFolder: () => void;
}

function AddNewFolderButton({ onAddNewFolder }: AddNewFolderButtonProps) {
  // Theme
  const color = useColorModeValue("neutral.100", "blue.100");
  const { colors } = useTheme();

  return (
    <Button
      right={0}
      maxW={"full"}
      h={"fit-content"}
      bg={hexToRgba(colors.blue[700], 0.78)}
      color={color}
      py={2}
      px={10}
      letterSpacing={".03rem"}
      fontSize={"14px"}
      borderRadius={4}
      _hover={{ opacity: 0.9 }}
      _active={{ opacity: 0.9 }}
      onClick={onAddNewFolder}
    >
      <Icon as={HiOutlineFolderPlus} mr={2} boxSize={5} />
      New Folder
    </Button>
  );
}

export default AddNewFolderButton;
