import { useState } from "react";
import { Heading, Text, Flex, useTheme, useColorMode } from "@chakra-ui/react";
import { GetGradients } from "utils/gradients";
import { hexToRgba } from "utils/helpers";
import UpdateChecker from "components/ui/UpdateChecker";
import ResendNewPassword from "./loginInputs/ResendNewPassword";
import ChangePassword from "./loginInputs/ChangePassword";
import Login from "./loginInputs/Login";

export default function LoginForm() {
  // Hooks
  const { colors } = useTheme();

  const [changePassword, setChangePassword] = useState(false);
  const [expired, setExpired] = useState(false);
  const [resetPasswordSession, setResetPasswordSession] = useState<string | null>(null);

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  // Theme
  const { appTitleGradient } = GetGradients();
  const { setColorMode } = useColorMode();

  setColorMode("light");

  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      gap={4}
      p={10}
      borderRadius={"10px"}
      bg={hexToRgba(colors.neutral[100], 0.95)}
      boxShadow="0 8px 32px 0 rgba( 31, 38, 80, 0.37 )"
      backdropFilter="blur( 4.5px )"
      border="1px solid rgba( 255, 255, 255, 0.18 )"
    >
      <UpdateChecker />
      <Heading fontSize="26px" color={"gray.600"} mb={"30px"}>
        Welcome to{" "}
        <Text
          as="span"
          fontWeight={"bold"}
          bgGradient={appTitleGradient}
          bgClip="text"
        >
          MoleculeLake
        </Text>
      </Heading>

      {changePassword ?
        <ChangePassword
          username={formData.username}
          resetPasswordSession={resetPasswordSession}
          setResetPasswordSession={setResetPasswordSession}
        />
        : expired ? <ResendNewPassword username={formData.username} />
          : <Login
            setResetPasswordSession={setResetPasswordSession}
            setFormData={setFormData}
            formData={formData}
            setExpired={setExpired}
            setChangePassword={setChangePassword}
          />
      }
    </Flex>
  );
}
