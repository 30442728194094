import { Button, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import FormattedText from "components/ui/FormattedText";
import { BsDatabaseCheck } from "react-icons/bs";
import { GetGradients } from "utils/gradients";

interface LlmRefreshEnrichmentProps {
  answer: string;
}

export default function LlmRefreshEnrichment({
  answer,
}: LlmRefreshEnrichmentProps) {
  return (
    <>
      {answer
        ?.split("\n")
        .filter(Boolean)
        .map((messageItem, index) => (
          <FormattedText key={index} text={messageItem} />
        ))}
    </>
  );
}

interface LlmEnrichmentButtonProps {
  loading: boolean;
  text: string;
  onSendQuestion: () => void;
}

export function LlmEnrichmentButton({
  loading,
  text,
  onSendQuestion,
}: LlmEnrichmentButtonProps) {
  // Theme
  const hoverBg = useColorModeValue("primary.100", "dark.700");
  const color = useColorModeValue("secondary.700", "gray.400");
  const { emGradient, maskGradient } = GetGradients();

  return (
    <Button
      ml={3}
      borderRadius={"40px"}
      bg={"transparent"}
      _hover={{
        textDecoration: "none",
        bg: hoverBg,
      }}
      display={"flex"}
      gap={1}
      alignItems={"center"}
      w={"fit-content"}
      h={"fit-content"}
      px={4}
      py={2}
      position={"relative"}
      _before={{
        content: "''",
        position: "absolute",
        inset: "0",
        borderRadius: "24px",
        padding: ".1rem",
        background: emGradient,
        WebkitMask: maskGradient,
        WebkitMaskComposite: "xor",
        maskComposite: "exclude",
        pointerEvents: "none",
      }}
      onClick={onSendQuestion}
      isLoading={loading}
      loadingText="Enriching..."
      _loading={{
        color: color,
      }}
    >
      <Icon as={BsDatabaseCheck} boxSize={4} color={color} />
      <Text fontWeight={"500"} color={color} textTransform={"capitalize"}>
        {text}
      </Text>
    </Button>
  );
}
