import { Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import { TbMailX } from "react-icons/tb";

function ErrorFeedbackMessage() {
  // Theme
  const color = useColorModeValue("gray.700", "gray.200");
  const color2 = useColorModeValue("gray.500", "gray.300");

  return (
    <Flex
      direction={"column"}
      gap={6}
      justify={"center"}
      align={"center"}
      w={"full"}
      alignSelf={"center"}
      justifySelf={"center"}
      mt={"30px"}
    >
      <Flex direction={"column"} gap={2} justify={"center"} align={"center"}>
        <Icon as={TbMailX} boxSize={10} color={"red.600"} />
        <Text color={color} fontWeight={"500"}>
          Feedback not delivered!
        </Text>
      </Flex>

      <Text fontSize={"14px"} color={color2}>
        Something went wrong. Please try again later.
      </Text>
    </Flex>
  );
}

export default ErrorFeedbackMessage;
