import {
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  useColorModeValue,
  useTheme,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { FaSearch } from "react-icons/fa";
import { hexToRgba } from "utils/helpers";

type SearchInputProps = InputProps & { rightComponent?: ReactNode };

export default function SearchInput({
  rightComponent = <></>,
  ...props
}: SearchInputProps) {
  // Theme
  const { colors } = useTheme();
  const fontColor = useColorModeValue("dark.900", "neutral.300");
  const placeholderColor = useColorModeValue("neutral.800", "gray.400");
  const bg = useColorModeValue(hexToRgba(colors.neutral[600], 0.1), "dark.700");

  return (
    <InputGroup w={"100%"} borderRadius={20}>
      <InputLeftElement pointerEvents="none">
        <Icon as={FaSearch} w={4} h={4} color="neutral.700" />
      </InputLeftElement>
      <Input
        type="text"
        size={"md"}
        fontSize={[null, null, null, "14px", "14px", "14px"]}
        color={fontColor}
        bg={bg}
        borderRadius={20}
        border={"none"}
        _focusVisible={{
          shadow: "none",
          outline: "none",
          border: "none",
          color: fontColor,
        }}
        _placeholder={{ color: placeholderColor }}
        {...props}
      />
      {rightComponent}
    </InputGroup>
  );
}
