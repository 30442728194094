import { useCallback } from "react";
import { Box, Flex, useToast } from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import {
  selectCurrentAuthData,
  clearCredentials,
} from "redux/features/auth/authSlice";

import UserAccess from "../UserAccess";
import DocsAPI from "../DocsAPI";
import { GoSignIn, GoSignOut } from "react-icons/go";

function ActionButtons() {
  // Navigation
  const navigate = useNavigate();
  const toast = useToast();

  // Custom hooks
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const { user } = useSelector(selectCurrentAuthData);

  // Handlers
  const handleLogout = useCallback(async () => {
    try {
      await axiosPrivate.get("/api/logout");
      dispatch(clearCredentials());

      toast({
        position: "bottom-right",
        duration: 1500,
        render: () => (
          <Box
            color="white"
            p={3}
            bg={"highlight.primary"}
            borderRadius={"6px"}
          >
            Logged out successfully
          </Box>
        ),
      });
    } catch (error) {
      toast({
        description: "Logout failed. Try again..",
        status: "error",
        position: "top-right",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, toast]);

  return (
    <Flex align={"center"} gap={2}>
      {user ? (
        <>
          {/* API Documentation */}
          <DocsAPI />

          {/* Logout button */}
          <UserAccess
            icon={GoSignOut}
            state={"Logout"}
            onAccess={handleLogout}
          />
        </>
      ) : (
        // Login button
        <UserAccess
          icon={GoSignIn}
          state={"Login"}
          onAccess={() => navigate("/login")}
        />
      )}
    </Flex>
  );
}

export default ActionButtons;
