import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Button, Flex, HStack, IconButton, Text } from "@chakra-ui/react";

interface PaginationProps {
  page: number;
  totalPages: number;
  onNextPage: () => void;
  onPreviousPage: () => void;
  onPageChange: (n: number) => void;
}

export default function Pagination({
  page,
  totalPages,
  onNextPage,
  onPreviousPage,
  onPageChange,
}: PaginationProps) {
  const pages = Array.from({ length: totalPages }, (_, index) => index + 1);

  const pages14 = Array.from({ length: 4 }, (_, index) => index + 1);

  return (
    <HStack spacing={1}>
      {/* Previous Page */}
      <IconButton
        colorScheme="primary"
        variant="gost"
        aria-label="previous"
        onClick={onPreviousPage}
        icon={<ChevronLeftIcon />}
        isDisabled={page === 1}
      />

      {/* Numbers */}
      {totalPages < 6 ? (
        pages.map((p, index) => {
          return (
            <Flex key={"page-" + index}>
              <PageNumber
                num={p}
                selected={p === page}
                onPageChange={onPageChange}
              />
            </Flex>
          );
        })
      ) : (
        // Handle overflow
        <>
          {pages14.map((p, index) => {
            return (
              <Flex key={"page-" + index}>
                <PageNumber
                  num={p}
                  selected={p === page}
                  onPageChange={onPageChange}
                />
              </Flex>
            );
          })}
          <Text>...</Text> {/* if page is in between */}
          {page > 4 && page < totalPages && (
            <>
              {/* show middle page */}
              <PageNumber
                num={page}
                selected={true}
                onPageChange={onPageChange}
              />{" "}
              <Text>...</Text>
            </>
          )}
          <PageNumber
            num={totalPages}
            selected={totalPages === page}
            onPageChange={onPageChange}
          />
        </>
      )}

      {/* Next Page */}
      <IconButton
        colorScheme="primary"
        variant="gost"
        aria-label="next"
        onClick={onNextPage}
        icon={<ChevronRightIcon />}
        isDisabled={page === totalPages}
      />
    </HStack>
  );
}

interface PageNumberProps {
  num: number;
  selected: boolean;
  onPageChange: (n: number) => void;
}

function PageNumber({ num, selected, onPageChange }: PageNumberProps) {
  const variant = selected ? "solid" : "ghost";

  return (
    <Button
      colorScheme="primary"
      size="sm"
      onClick={() => onPageChange(num)}
      variant={variant}
    >
      {num}
    </Button>
  );
}
