import { Link as RouterLink } from "react-router-dom";
import {
  Flex,
  Link,
  Text,
  useColorModeValue,
  useTheme,
} from "@chakra-ui/react";
import { GetGradients } from "utils/gradients";
import { hexToRgba } from "utils/helpers";

interface MoleculesProps {
  compounds: string[] | undefined;
}

function Molecules({ compounds }: MoleculesProps) {
  // Theme
  const { colors } = useTheme();
  const color = useColorModeValue("gray.500", "gray.300");
  const hoverColor = useColorModeValue("gray.600", "gray.100");
  const borderColor = useColorModeValue("gray.200", "gray.500");
  const titleColor = useColorModeValue("primary.300", "highlight.primary");
  const borderHoverColor = useColorModeValue(
    hexToRgba(colors.blue[600], 0.2),
    hexToRgba(colors.blue[600], 0.6)
  );
  const { lGradient } = GetGradients();

  return (
    <>
      {compounds && compounds.length > 0 && (
        <Flex direction={"column"} gap={1.5} mt={7}>
          <Text fontSize={"14px"} fontWeight={"500"} color={titleColor}>
            Explore Molecules
          </Text>

          {/* molecules list */}
          <Flex columnGap={1} rowGap={1} w={"full"} flexWrap={"wrap"}>
            {compounds.map((comp: string, index: number) => (
              <Link
                key={"term" + index}
                as={RouterLink}
                to={"/compounds?query=" + comp}
                fontSize={"13px"}
                lineHeight={"normal"}
                color={color}
                bgGradient={lGradient}
                borderWidth={1}
                borderColor={borderColor}
                borderRadius={"30px"}
                px={1.5}
                pb={0.5}
                transition="all ease .2s"
                _hover={{
                  color: hoverColor,
                  borderColor: borderHoverColor,
                  textDecoration: "none",
                }}
              >
                {comp.length < 21 ? comp : comp.slice(0, 20) + ".."}
              </Link>
            ))}
          </Flex>
        </Flex>
      )}
    </>
  );
}

export default Molecules;
