import { Flex } from "@chakra-ui/react";
import Container from "components/home/Pipeline/Container";
import Content from "components/home/Pipeline/Content";

interface PipelineProps {
  id: string;
}

export default function Pipeline({ id }: PipelineProps) {
  return (
    <Flex direction={"column"} id={id} position="relative">
      <Container>
        <Content />
      </Container>
    </Flex>
  );
}
