import { useToast } from "@chakra-ui/react";
import { EmbeddingSocketContext } from "contexts/EmbeddingSocketContext";
import { environment } from "environments";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { resetProgress, setError, setProgress } from "redux/features/embedding/embeddingSlice";

const getWS_URL = (userId: string, socketId: string) => {
    const HOST_URL = window.location.hostname;
    const params = `${userId}/${socketId}`
    const WS_URL =
        !process.env.NODE_ENV || process.env.NODE_ENV === "development"
            ? `ws://127.0.0.1:8000/ws/embedding_progress/${params}`
            : `wss://${HOST_URL}/ws/embedding_progress/${params}`;
    return WS_URL
}

const useEmbeddingProcess = () => {
    // Hooks
    const toast = useToast()
    const dispatch = useDispatch();
    const axiosPrivate = useAxiosPrivate()
    const { user } = useSelector(selectCurrentAuthData)
    const userId = user?.id

    const { socket, setSocket } = useContext(EmbeddingSocketContext);

    //* Check if user has any embeddings in progress  
    const checkForEmbeddingsInProgress = async () => {
        try {
            if (!userId) return
            if (user.user_type !== 'premium') return
            const response = await axiosPrivate.get(`${environment.BACKEND_API}/literature_map/check_for_embeddings_in_progress/${userId}`)
            if (!response.data.length) return

            const { socketId, progress, status } = response.data[0]
            if (status === 'SUCCESS') {
                setTimeout(() => {
                    toast({
                        description: "Previous Papers Embedding is done",
                        status: "success",
                        position: "top-right",
                    });
                }, 1500)
                return
            }

            if (status === 'IN_PROGRESS') {
                const newSocket = new WebSocket(getWS_URL(userId, socketId));
                dispatch(setProgress(progress));
                setSocket(newSocket)
                return
            }

            if (status === 'FAIL') {
                setTimeout(() => {
                    toast({
                        description: "Previous embedding was done but some papers couldn't be embedded!",
                        status: "error",
                        position: "top-right",
                    });
                }, 1500)
                return
            }

        } catch (error: any) {
            if (!error.response) {
                toast({
                    description: "No server response!",
                    status: "error",
                });
                return
            }
            console.log(error)
        }
    }

    //* Initiate Socket Connection 
    const initiateSocket = (papers: string[] | string) => {
        if (!userId) return

        const socketId = Math.random().toString(36).substring(7);
        const newSocket = new WebSocket(getWS_URL(userId, socketId));


        const sendPaperIdRequest = async () => {
            try {
                await axiosPrivate.post(
                    `${environment.BACKEND_API}/literature_map/send_paper_id/${userId}/${socketId}`,
                    { papers }
                );

                dispatch(setProgress(0));
                setSocket(newSocket);
            } catch (error: any) {
                if (!error.response) {
                    toast({
                        description: "No server response!",
                        status: "error",
                    });
                    return;
                }
                toast({
                    description: error.response.data.message,
                    status: "error",
                    position: "top-right",
                });

                dispatch(resetProgress());
            }
        };

        newSocket.onopen = () => sendPaperIdRequest();
    }

    useEffect(() => {
        if (!socket) return;
        const webSocket = socket as WebSocket;
        webSocket.onmessage = (event) => {
            const data = JSON.parse(event.data)
            const { status, progress } = data;

            if (status === 'IN_PROGRESS') {
                dispatch(setProgress(progress));
            }

            if (status === 'SUCCESS') {
                setTimeout(() => {
                    toast({
                        description: "Papers Embedding is done",
                        status: "success",
                        position: "top-right",
                    });
                }, 1500)
            }

            if (status === 'FAIL') {
                dispatch(setError("Embedding is done but some papers couldn't be embedded!"));
                // console.log(error.failedPapers)
                toast({
                    description: "Embedding is done but some papers couldn't be embedded!",
                    status: "error",
                    position: "top-right",
                });

            }
        };

        webSocket.onerror = (event) => {
            console.log(event)
            console.log('Error!!')
        }

        webSocket.onclose = (event) => {
            setTimeout(() => {
                dispatch(resetProgress())
                clearCache()
            }, 1000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket, user])


    //* Clear cache 
    const clearCache = () => {
        // Clear cached searched papers
        if ("caches" in window) {
            caches.delete('PapersSearchResult');
        }

        // Clear session
        sessionStorage.removeItem('recentLiteraturesSearch');
    }


    return { socket, initiateSocket, checkForEmbeddingsInProgress };
};

export default useEmbeddingProcess;