import { Text } from "@chakra-ui/react";
import { getPaperColor } from "views/compounds/helpers";

interface PaperTypeProps {
  type: string;
}

export default function PaperType({ type }: PaperTypeProps) {
  return (
    <Text
      fontSize={[null, null, null, "11px", "11px", "12px"]}
      fontWeight={"500"}
      textTransform={"uppercase"}
      lineHeight="1.3"
      w="fit-content"
      color={getPaperColor(type)}
      borderRadius="3px"
      padding="2px 4px"
    >
      {type ?? "Unclassified"}
    </Text>
  );
}
