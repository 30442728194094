import { Text } from "@chakra-ui/react";

function Bullet() {
  return (
    <Text as="span" color={"secondary.500"} fontWeight={"500"}>
      ⬦{" "}
    </Text>
  );
}

export default Bullet;
