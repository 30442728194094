import { useEffect } from 'react';
import { environment } from 'environments';
import useAxiosPrivate from 'hooks/auth/useAxiosPrivate';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentAuthData } from 'redux/features/auth/authSlice';
import { setSuccess, setFail, setLoading, selectCurrentSavedData } from 'redux/features/saved/savedSlice';
import { useToast } from '@chakra-ui/react';

const useFetchSavedElements = () => {

    const dispatch = useDispatch()
    const toast = useToast();
    const axiosPrivate = useAxiosPrivate()
    const { user } = useSelector(selectCurrentAuthData);
    const { loading, error, savedFolders } = useSelector(selectCurrentSavedData);

    useEffect(() => {
        const fetchSavedElements = async () => {
            if (!user) return
            dispatch(setLoading())

            try {
                const response = await axiosPrivate.get(`${environment.BACKEND_API}/api/saved_elements?user=${user?.id}`);
                dispatch(setSuccess(response.data))
            } catch (error) {
                dispatch(setFail('An error occurred'))
                toast({
                    title: 'An error occurred',
                    status: "error",
                    duration: 1500,
                });
            }
        };

        fetchSavedElements();
    }, [user, axiosPrivate, dispatch, toast]);

    return { loading, error, savedFolders };
};

export default useFetchSavedElements;