import { useContext } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  SimpleGrid,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";

import { AssaysControlsContext } from ".";
import { AssayProps } from "models/assays/AssayProps";
import AssaySummaryCard from "components/assays/AssaySummary";
import MainPanelError from "components/ui/MainPanelError";
import Loading from "components/ui/Loading";
import { maxHeight } from "utils/responsive";

interface NoAssaysResultProps {
  height: string | undefined;
}

export default function AssayList() {
  // Navigation
  const navigate = useNavigate();

  // Context
  const {
    searchResults,
    selectedAssayTypes,
    selectedIndication,
    query,
    error,
    loadingAssays,
  } = useContext(AssaysControlsContext);

  // Handlers
  const handleAssayTypeClick = (types: string) => {
    navigate({
      pathname: "/assays",
      search: `?${createSearchParams({
        types,
        indication: selectedIndication,
      })}`,
    });
  };

  const handleIndicationClick = (indication: string) => {
    navigate({
      pathname: "/assays",
      search: `?${createSearchParams({
        type: selectedAssayTypes,
        indication,
      })}`,
    });
  };

  // Responsiveness: ~992px, ~1280px, ~1536px
  const templateColumns = useBreakpointValue({
    lg: "repeat(auto-fill, minmax(220px, 1fr))",
    xl: "repeat(auto-fill, minmax(250px, 1fr))",
    "2xl": "repeat(auto-fill, minmax(280px, 1fr))",
  });

  // Responsiveness: ~992px, ~1280px, ~1536px
  const AssaysListHeight = useBreakpointValue(maxHeight);

  // if error
  if (!!error)
    return (
      <Flex h={AssaysListHeight} w={"100%"}>
        <MainPanelError />
      </Flex>
    );

  // if Loading
  if (loadingAssays)
    return (
      <Flex
        direction={"column"}
        h={AssaysListHeight}
        w={"100%"}
        align={"center"}
        justify={"center"}
      >
        <Loading message="Loading assays.." />
      </Flex>
    );

  return (
    <Box w={"100%"} h={"100%"}>
      {searchResults?.length > 0 ? (
        <SimpleGrid
          spacing={[null, null, null, 2, 3, 4]}
          templateColumns={templateColumns}
        >
          {searchResults.map((assay: AssayProps, index: number) => {
            return (
              <AssaySummaryCard
                key={index}
                index={index}
                assay={assay}
                onNameClick={handleIndicationClick}
                onAssayTypeClick={handleAssayTypeClick}
              />
            );
          })}
        </SimpleGrid>
      ) : (
        query && <NoAssaysResult height={AssaysListHeight} />
      )}
    </Box>
  );
}

function NoAssaysResult({ height }: NoAssaysResultProps) {
  return (
    <Flex h={height} w={"100%"} align={"center"} justify={"center"}>
      <Text>No results found</Text>
    </Flex>
  );
}
