import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
  Tooltip,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useContext, useMemo } from "react";
import { PapersContext } from ".";
import BreadcrumbHome from "components/ui/BreadcrumbHome";

export default function PapersBreadcrumb() {
  // Navigation
  const navigate = useNavigate();

  // Theme
  const idColor = useColorModeValue("gray.600", "gray.400");
  const color = useColorModeValue("gray.600", "gray.200");

  // Context
  const { openedPapers, selectedTab } = useContext(PapersContext);

  const handleGoToIndex = () => {
    navigate(`/papers`);
  };
  const handleGoToTabs = () => {
    navigate(`/papers/${openedPapers[0].reference_paper.id}`);
  };

  // Responsiveness: ~992px, ~1280px, ~1536px
  const truncLength = useBreakpointValue({
    lg: 19,
    xl: 24,
    "2xl": 28,
  });

  const slicedTitle = useMemo(() => {
    if (!truncLength) return "";
    else {
      if (selectedTab.reference_paper.title)
        return (
          selectedTab.reference_paper.title?.slice(0, truncLength) +
          (selectedTab.reference_paper.title?.length > truncLength ? ".." : "")
        );
    }
  }, [selectedTab, truncLength]);

  return (
    <Breadcrumb
      spacing={1}
      separator={<ChevronRightIcon color="gray.500" />}
      fontSize={"14px"}
      color={color}
    >
      {/* Index */}
      <BreadcrumbItem>
        <BreadcrumbLink onClick={handleGoToIndex} lineHeight={1} p={1.5}>
          <BreadcrumbHome />
        </BreadcrumbLink>
      </BreadcrumbItem>

      {!selectedTab.reference_paper.id && openedPapers.length && (
        <BreadcrumbItem>
          <BreadcrumbLink onClick={handleGoToTabs} lineHeight={1} p={1.5}>
            <Text fontSize={"small"}>Tabs</Text>
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}

      {/* Id or tabs */}
      {!!Object.keys(selectedTab.reference_paper).length && (
        <BreadcrumbItem isCurrentPage color={idColor} fontWeight={"500"}>
          <BreadcrumbLink>
            <Tooltip label={selectedTab.reference_paper.title}>
              {slicedTitle}
            </Tooltip>
          </BreadcrumbLink>
          {/* <BreadcrumbLink>
            <Text
              fontSize={"small"}
              whiteSpace={"nowrap"}
              overflow={"hidden"}
              textOverflow={"ellipsis"}
              maxW={"180px"}
            >
              {slicedTitle}
            </Text>
          </BreadcrumbLink> */}
        </BreadcrumbItem>
      )}
    </Breadcrumb>
  );
}
