import { Dispatch, SetStateAction, createContext, useState } from "react";
import { Outlet } from "react-router-dom";

import { Box, Divider, Flex, useBreakpointValue } from "@chakra-ui/react";

import CompoundsBreadcrumb from "./Breadcrumb";
import CompoundsTools from "./Tools";
import SidebarLayout from "layouts/Sidebar";
import { CustomScrollBar } from "components/ui/CustomScrollBar";

// utils
import { ReducedCompoundProps } from "views/graph";
import { maxHeight, maxWidth, minWidth } from "utils/responsive";

interface CompoundsContextProps {
  searchCount: null | number;
  setSearchCount: Dispatch<SetStateAction<null | number>>;
  visitedCompounds: ReducedCompoundProps[];
  setVisitedCompounds: Dispatch<SetStateAction<ReducedCompoundProps[]>>;
}

export const CompoundsContext = createContext<CompoundsContextProps>({
  searchCount: null,
  setSearchCount: () => {},
  visitedCompounds: [],
  setVisitedCompounds: () => {},
});

export default function CompoundsView() {
  const cachedData = localStorage.getItem("recentlyViewedCompounds");
  const initialData: ReducedCompoundProps[] = cachedData
    ? JSON.parse(cachedData)
    : [];

  // states
  const [visitedCompounds, setVisitedCompounds] =
    useState<ReducedCompoundProps[]>(initialData);
  const [searchCount, setSearchCount] = useState<null | number>(null);

  // Responsiveness: ~992px, ~1280px, ~1536px
  const compoundsContentHeight = useBreakpointValue(maxHeight);

  const listStyle = {
    height: compoundsContentHeight,
    width: "100%",
    padding: "0 10px",
  };

  return (
    <Flex>
      {/* Sidebar */}
      <CompoundsContext.Provider
        value={{
          searchCount,
          setSearchCount,
          visitedCompounds,
          setVisitedCompounds,
        }}
      >
        <SidebarLayout>
          <CompoundsBreadcrumb />
          <Divider my={4} />
          <CompoundsTools />
        </SidebarLayout>

        {/* Main Panel */}
        <Box w={minWidth} minW={minWidth} maxW={maxWidth} mx={"auto"} py={2}>
          {/* Specific View */}
          <CustomScrollBar style={listStyle} overflowX={"hidden"}>
            <Outlet context={{ visitedCompounds, setVisitedCompounds }} />
          </CustomScrollBar>
        </Box>
      </CompoundsContext.Provider>
    </Flex>
  );
}
