import { Flex } from "@chakra-ui/react";

import Logo from "components/home/Header/Logo";
import Menu from "components/home/Header/Menu";
import Container from "components/home/Header/Container";
import ActionButtons from "components/home/Header/ActionButtons";
import Content from "components/home/Header/Content";

export default function Header() {
  return (
    <Container>
      <Flex justifyContent="space-between" align="center" w="100%">
        <Logo />

        <Flex align="center" gap={6} h={"100px"}>
          <Menu />
          <ActionButtons />
        </Flex>
      </Flex>

      <Content />
    </Container>
  );
}
