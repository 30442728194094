import { Button } from "@chakra-ui/react";

interface SubmitEmailProps {
  sending: boolean;
}

function SubmitEmail({ sending }: SubmitEmailProps) {
  return (
    <Button
      type="submit"
      fontSize={[null, null, null, "14px", "15px", "16px"]}
      w={[null, null, null, "120px", "160px", "160px"]}
      bg={"blue.900"}
      borderRadius={[null, null, null, "12px", "24px", "24px"]}
      transition={"all .3s ease"}
      _hover={{ bg: "dark.800" }}
      _active={{ bg: "dark.800" }}
      isLoading={sending}
      loadingText="sending..."
      _loading={{ color: "neutral.100" }}
    >
      Request
    </Button>
  );
}

export default SubmitEmail;
