import { Flex, Icon, Text } from "@chakra-ui/react";

import { TbSquareRoundedArrowUp } from "react-icons/tb";

interface MessageProps {
  message?: string;
}
export default function ChartHint({ message }: MessageProps) {
  if (!message) return <></>;

  return (
    <Flex
      fontSize={"13px"}
      fontWeight={500}
      color={"gray.400"}
      py={2}
      px={1}
      gap={1.5}
      h={"fit-content"}
      w={"full"}
      align={"center"}
    >
      <Icon w={5} h={5} as={TbSquareRoundedArrowUp} />
      <Text fontSize={"12px"} fontWeight={"500"}>
        {message}
      </Text>
    </Flex>
  );
}
