import { useContext, useMemo } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  Flex,
  Box,
  Text,
  useTheme,
  Skeleton,
  useColorModeValue,
  useBreakpointValue,
} from "@chakra-ui/react";

import {
  getAllAssayTypes,
  transformToCheckboxData,
} from "views/assays/helpers";
import { AssaysControlsContext } from "views/assays";
import { CheckboxStyled } from "components/checkbox/Checkbox";
import { hexToRgba } from "utils/helpers";
import { CustomScrollBar } from "components/ui/CustomScrollBar";

export interface AssayTypeProps {
  name: string;
  value: number;
  isChecked: boolean;
}

export default function AssayTypes() {
  // Navigation
  const navigate = useNavigate();

  // Theme
  const { colors } = useTheme();
  const headerTextColor = useColorModeValue(
    "highlight.primary",
    "secondary.400"
  );

  // Context
  const {
    assays,
    selectedAssayTypes,
    selectedIndication,
    searchResults,
    loadingAssays,
  } = useContext(AssaysControlsContext);

  // State
  const selectedTypes = useMemo(() => {
    if (selectedAssayTypes === "") {
      return [];
    }
    return selectedAssayTypes.split(",").map((item) => item.trim());
  }, [selectedAssayTypes]);

  const checkboxData = useMemo(() => {
    return transformToCheckboxData(assays, searchResults);
  }, [assays, searchResults]);

  // Handlers
  const handleAssayClick = (item: string) => {
    const addType = selectedTypes.length !== 0 && !selectedTypes.includes(item);

    // if no are selected then we remove from all assays, if some selected we add or remove from it
    // define subset
    const all_assays =
      selectedTypes.length > 0 ? selectedTypes : getAllAssayTypes(assays);

    var types = addType
      ? all_assays.concat(item) // add to list
      : all_assays.filter((type) => type !== item); // remove list

    // if all assays are selected then no need to pass types
    if (all_assays.length === types.length) {
      types = [];
    }

    navigate({
      pathname: "/assays",
      search: `?${createSearchParams({
        indication: selectedIndication,
        types: types.join(","),
      })}`,
    });
  };

  const handleSelectAll = () => {
    // If was selected, deselect all and apply no type
    if (selectedTypes.length === 0) {
      navigate({
        pathname: "/assays",
        search: `?${createSearchParams({
          indication: selectedIndication,
          types: "none",
        })}`,
      });
      // if it was not clicked => select all => indication=&types=
    } else {
      // apply all
      navigate({
        pathname: "/assays",
        search: `?${createSearchParams({
          indication: selectedIndication,
        })}`,
      });
    }
  };

  // Responsiveness: ~992px, ~1280px, ~1536px
  // NOTE: 532 + 16, 486 + 16, and 442 + 16 are not exact values, picked based on
  // current display resolution. (16: indicates added padding on top to align dividers)
  // Feel free to adjust them, if you find better solution
  const assayTypesHeight = useBreakpointValue({
    lg: "calc(100vh - 458px)",
    xl: "calc(100vh - 502px)",
    "2xl": "calc(100vh - 548px)",
  });

  const assayTypesStyle = {
    height: assayTypesHeight,
    borderRadius: "6px",
    padding: "0",
  };

  const loadingStyle = {
    height: assayTypesHeight,
    background: "none",
    borderRadius: "6px",
    padding: "0",
    border: `1px solid  ${hexToRgba(colors.primary[400], 0.3)}`,
  };

  return (
    <Box>
      {/* Header */}
      <Flex align="center" textAlign="center" justify="space-between" mb={2}>
        <Text color={headerTextColor} fontSize={"12px"} fontWeight={"500"}>
          Assay types
        </Text>
        <CheckboxStyled
          large
          roundedFull
          isChecked={selectedTypes.length === 0}
          onChange={handleSelectAll}
          isDisabled={loadingAssays}
        />
      </Flex>

      {/* Assays Type List */}
      {loadingAssays ? (
        <Skeleton borderRadius={5} style={loadingStyle} />
      ) : (
        <Box position="relative" width={"100%"}>
          <CustomScrollBar style={assayTypesStyle}>
            {checkboxData && checkboxData.length > 0 ? (
              <Flex
                direction="column"
                gap={[null, null, null, 1, null, 1.5]}
                pr={"10px"}
              >
                {/* types */}
                {checkboxData.map((assay) => (
                  <CheckboxStyled
                    key={assay.name}
                    isChecked={
                      !selectedAssayTypes || selectedTypes.includes(assay.name)
                    }
                    onChange={() => handleAssayClick(assay.name)}
                    roundedFull
                  >
                    <Flex gap={1} align={"center"}>
                      <Text>{assay.name}</Text>
                      <Text fontWeight="bold" fontSize="75%">
                        ({assay.value})
                      </Text>
                    </Flex>
                  </CheckboxStyled>
                ))}
              </Flex>
            ) : (
              <Flex
                justify={"center"}
                align={"center"}
                textAlign={"center"}
                h={"calc(100vh - 520px)"}
              >
                <Text
                  textAlign={"center"}
                  color={colors.gray[500]}
                  opacity={0.75}
                  fontSize={"16px"}
                  fontWeight={"500"}
                >
                  No Assay Types.
                </Text>
              </Flex>
            )}
          </CustomScrollBar>
        </Box>
      )}
    </Box>
  );
}
