import { SimpleGrid } from "@chakra-ui/react";
import PipeStep from "./PipeStep";
import { IconType } from "react-icons";
import { processSteps } from "views/home/helpers";

export interface ProcessStep {
  icon: IconType;
  title: string;
  text: string;
}

function Steps() {
  return (
    <SimpleGrid
      columns={3}
      spacing={[null, null, null, "12px", "26px", "32px"]}
      w={"90%"}
      maxW={"1000px"}
      mx={"auto"}
    >
      {processSteps.map((step: ProcessStep, index: number) => {
        return (
          <PipeStep
            key={"step-" + index}
            step={index + 1}
            title={step.title}
            text={step.text}
            icon={step.icon}
          />
        );
      })}
    </SimpleGrid>
  );
}

export default Steps;
