import { Flex, Image, Text, useColorModeValue } from "@chakra-ui/react";

import notFoundImg from "assets/notFound.svg";
import LinkToView from "components/buttons/LinkToView";
import { PiGraph, PiTestTube } from "react-icons/pi";
import { BsDiagram2, BsPaperclip } from "react-icons/bs";

import UpdateChecker from "components/ui/UpdateChecker";

export default function PageNotFound() {
  // Theme
  const color = useColorModeValue("gray.600", "gray.300");

  return (
    <Flex
      h={"100vh"}
      w={"full"}
      direction={"column"}
      align={"center"}
      justify={"center"}
      gap={"40px"}
    >
      <UpdateChecker />
      <Image src={notFoundImg} alt={"404"} maxW={"20rem"} objectFit={"cover"} />
      <Text fontSize={"14px"} color={"gray.500"}>
        It seems we could not find the page you were looking for
      </Text>

      <Flex direction={"column"} align={"center"} justify={"center"} gap={3}>
        <Text color={color} fontSize={"14px"} fontWeight={"500"}>
          Suggested pages:{" "}
        </Text>
        <Flex align={"center"} justify={"center"} gap={2}>
          <LinkToView name="Compounds" icon={PiGraph} link="/compounds" />
          <LinkToView name="Assays" icon={PiTestTube} link="/assays" />
          <LinkToView name="Literature" icon={BsPaperclip} link="/papers" />
          <LinkToView name="Tree of life" icon={BsDiagram2} link="/graph" />
        </Flex>
      </Flex>
    </Flex>
  );
}
