import { useState } from "react";
import {
  Flex,
  Icon,
  Tab,
  Text,
  Tooltip,
  useColorModeValue,
  useTheme,
} from "@chakra-ui/react";
import { hexToRgba } from "utils/helpers";
import { TiDelete } from "react-icons/ti";

interface FolderTabProps {
  id: string;
  name: string;
  onRemoveFolder: (id: string) => void;
}

function FolderTab({ name, id, onRemoveFolder }: FolderTabProps) {
  // State
  const [show, setShow] = useState<boolean>(false);

  // Theme
  const { colors } = useTheme();
  const color = useColorModeValue("gray.600", "gray.300");
  const selectedColor = useColorModeValue("blue.500", "blue.400");
  const hoverBg = useColorModeValue(
    hexToRgba(colors.blue[300], 0.15),
    "dark.800"
  );

  return (
    <Tab
      display={"flex"}
      justifyContent={"space-between"}
      color={color}
      _selected={{
        fontWeight: "500",
        color: selectedColor,
        bg: hoverBg,
        letterSpacing: "-0.016rem",
      }}
      px={3}
      py={2}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      position={"relative"}
      borderRadius={"8px"}
    >
      {/* folder name */}
      <Text
        whiteSpace={"nowrap"}
        overflow={"hidden"}
        textOverflow={"ellipsis"}
        fontSize={"14px"}
      >
        {name}
      </Text>

      {/* delete folder item */}
      {show && (
        <Tooltip
          label={"Delete Folder"}
          placement="right"
          hasArrow
          bg="gray.200"
          color="gray.700"
        >
          <Flex position="absolute" right={1} align={"center"}>
            <Icon
              as={TiDelete}
              boxSize={5}
              color={"gray.500"}
              _hover={{
                color: "red.500",
              }}
              onClick={(e) => {
                e.stopPropagation(); // Prevent the Tab from being triggered
                onRemoveFolder(id);
              }}
            />
          </Flex>
        </Tooltip>
      )}
    </Tab>
  );
}

export default FolderTab;
