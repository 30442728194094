import { useState } from "react";

import FeedbackButton from "./FeedbackButton";
import FeedbackModal from "./FeedbackModal";

function Feedback() {
  const [showModal, setShowModal] = useState(false);
  const [sent, setSent] = useState(false);
  const [hasError, setHasError] = useState(false);

  return (
    <>
      <FeedbackButton
        handleClick={() => {
          setShowModal(true);
          setSent(false);
        }}
      />

      <FeedbackModal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
          setHasError(false);
        }}
        sent={sent}
        setSent={setSent}
        hasError={hasError}
        setHasError={setHasError}
      />
    </>
  );
}

export default Feedback;
