import { Button, useColorModeValue } from "@chakra-ui/react";
import { BsRobot } from "react-icons/bs";
import { hexToRgba } from "utils/helpers";

interface NewChatButtonProps {
  onNewChatHandler: () => void;
  isDisabled: boolean;
}

function NewChatButton({ onNewChatHandler, isDisabled }: NewChatButtonProps) {
  // Theme
  const color = useColorModeValue("neutral.100", "dark.800");
  const hoverColor = useColorModeValue("primary.400", "highlight.primary");

  return (
    <Button
      justifyContent="left"
      fontSize={[null, null, null, "14px", "14px", "16px"]}
      border="none"
      color={color}
      bg={"highlight.primary"}
      w="100%"
      leftIcon={<BsRobot />}
      rounded={"md"}
      _hover={{ boxShadow: `${hexToRgba(hoverColor, 0.4)} 0px 3px 8px` }}
      transition="all 0.2s ease"
      pointerEvents={isDisabled ? "none" : "auto"}
      onClick={onNewChatHandler}
    >
      New Chat
    </Button>
  );
}

export default NewChatButton;
