import {
  FormControl,
  FormErrorMessage,
  Input,
  useTheme,
} from "@chakra-ui/react";
import { ChangeEvent } from "react";
import { hexToRgba } from "utils/helpers";

interface NameFormControlProps {
  name: string;
  isInvalid: boolean;
  onNameChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

function NameFormControl({
  name,
  isInvalid,
  onNameChange,
}: NameFormControlProps) {
  // Theme
  const { colors } = useTheme();

  return (
    <FormControl isRequired isInvalid={isInvalid}>
      <Input
        name="name"
        value={name}
        onChange={onNameChange}
        placeholder="Name"
        _placeholder={{ color: "gray.600" }}
        borderRadius={"8px"}
        color={"dark.700"}
        bg={hexToRgba(colors.gray[50], 0.5)}
        border={"none"}
        _hover={{ bg: hexToRgba(colors.gray[50], 0.7) }}
        _focusVisible={{ bg: hexToRgba(colors.gray[50], 0.7) }}
      />
      <FormErrorMessage color={"red.50"}>
        Name is required. Should be longer than 3 characters
      </FormErrorMessage>
    </FormControl>
  );
}

export default NameFormControl;
