import { Flex, Icon, Text } from "@chakra-ui/react";
import { IoSparklesSharp } from "react-icons/io5";

function NoChats() {
  return (
    <Flex
      direction={"column"}
      gap={6}
      h={"calc(100vh - 200px)"}
      w={"full"}
      justify={"center"}
      align={"center"}
      borderRadius={3}
      color={"highlight.primary"}
      fontFamily={"Poppins"}
      fontWeight={"500"}
      fontSize={"13px"}
      textAlign={"center"}
    >
      <Text display={"flex"} gap={1.5} justifyContent={"center"}>
        <Icon as={IoSparklesSharp} boxSize={4} />
        Initiate new chat
      </Text>
    </Flex>
  );
}

export default NoChats;
