import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Button, useTheme } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export default function GetStartedButton() {
  // Theme
  const { colors } = useTheme();

  // Navigation
  const navigate = useNavigate();

  return (
    <Button
      m={0}
      p={6}
      w={"fit-content"}
      bgGradient={`linear(to-r, ${colors.highlight["primary"]}, ${colors.secondary["700"]} )`}
      borderRadius={"30px"}
      letterSpacing={".05rem"}
      transition={"all .3s ease"}
      _hover={{ px: 10 }}
      _active={{
        bgGradient: `linear(to-r, ${colors.highlight["primary"]}, ${colors.secondary["700"]} )`,
      }}
      onClick={() => navigate("/chat")}
      rightIcon={<ArrowForwardIcon />}
    >
      Get Started
    </Button>
  );
}
