import { Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import { BsFileEarmarkX } from "react-icons/bs";

export default function NoResults() {
  // Theme
  const NoResultsColor = useColorModeValue("gray.500", "gray.300");

  return (
    <Flex
      w={"100%"}
      align={"center"}
      justify={"center"}
      color={NoResultsColor}
      direction={"column"}
      gap={6}
    >
      <Icon as={BsFileEarmarkX} boxSize={"40px"} color={"orange.300"} />
      <Text fontSize={"14px"} lineHeight={"1.28"} fontWeight={"500"}>
        No Results
      </Text>
    </Flex>
  );
}
