import {
  Flex,
  Image,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import noDataImg from "assets/no-data.png";
import { maxHeight } from "utils/responsive";

interface NoDataFoundProps {
  message: string;
}

function NoDataFound({ message }: NoDataFoundProps) {
  // Theme
  const color = useColorModeValue("gray.500", "gray.300");

  const fullHeight = useBreakpointValue(maxHeight);

  return (
    <Flex
      direction={"column"}
      justify={"center"}
      align={"center"}
      w={"100%"}
      h={fullHeight}
      gap={6}
    >
      <Image
        src={noDataImg}
        w={[null, null, null, "100px", "120px", "140px"]}
        alt="No similar compounds found!"
      />
      <Text
        fontSize={"14px"}
        lineHeight={"1.28"}
        fontWeight={"500"}
        color={color}
      >
        {message}
      </Text>
    </Flex>
  );
}

export default NoDataFound;
