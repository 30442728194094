export const minWidth = [
  null,
  null,
  null,
  "calc(100vw - 118px - max(20vw, 240px))",
  "calc(100vw - 146px - max(20vw, 240px))",
  "calc(100vw - 164px - max(20vw, 240px))",
];

export const maxWidth = [
  null,
  null,
  null,
  "calc(100vw - 118px)",
  "calc(100vw - 146px)",
  "calc(100vw - 164px)",
];

// Responsiveness: ~992px, ~1280px, ~1536px
export const maxHeight = {
  lg: "calc(100vh - 32px)",
  xl: "calc(100vh - 40px)",
  "2xl": "calc(100vh - 48px)",
};

// Tutorials responsive values
export const slides = {
  lg: 4,
  xl: 3,
  "2xl": 3,
};

export const mWTutorials = {
  lg: "700px",
  xl: "1000px",
  "2xl": "1200px",
};

export const videoTutorialWidth = {
  lg: "125px",
  xl: "140px",
  "2xl": "165px",
};
