import { Button, Flex, Heading } from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";
import GetStartedButton from "../GetStartedButton";

interface AboutUsProps {
  setShow: Dispatch<SetStateAction<boolean>>;
}

function AboutUs({ setShow }: AboutUsProps) {
  return (
    <Flex direction={"column"} gap={3} mt={6} justifyContent={"end"}>
        <Button
          fontSize={[null, null, null, "14px", "15px", "16px"]}
          bg="transparent"
          color="secondary.600"
          w={"fit-content"}
          p={0}
          height={"fit-content"}
          _hover={{ bg: "transparent" }}
          _focus={{ bg: "transparent" }}
          _active={{ bg: "transparent" }}
          onClick={() => setShow(true)}
        >
          Privacy Policy
        </Button>
        <GetStartedButton />
    </Flex>
  );
}

export default AboutUs;
