import { chakra } from "@chakra-ui/react";

import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

export const CustomScrollBarLighterBg = chakra(SimpleBar, {
  baseStyle: {
    "& .simplebar-scrollbar::before": {
      backgroundColor: "gray.300",
    },
  },
});

export const CustomScrollBar = chakra(SimpleBar, {
  baseStyle: {
    "& .simplebar-scrollbar::before": {
      backgroundColor: "gray.400",
    },
  },
});

export const CustomThickScrollBar = chakra(SimpleBar, {
  baseStyle: {
    "& .simplebar-track": {
      width: "8px",
    },
    "& .simplebar-scrollbar::before": {
      backgroundColor: "gray.400",
    },
  },
});
