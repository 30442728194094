import { useContext, useEffect, useMemo, useState, ChangeEvent } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  Flex,
  Spacer,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";

import MoreInfo from "./MoreInfo";
import { CustomScrollBar } from "components/ui/CustomScrollBar";
import SearchSimilarCompounds from "./SearchSimilarCompounds";
import { CheckboxStyled } from "components/checkbox/Checkbox";
import {
  CharDataExtendedProps,
  ChartControlsContext,
  LeafNode,
  Node,
} from "views/graph";
import SimilarCompoundsSkeleton from "components/graph/SimilarCompoundsSkeleton";

export interface SimilarCompoundsProps {
  loading: boolean | undefined;
  onAddCompound: (name: string, event: ChangeEvent<HTMLInputElement>) => void;
}

interface StatsProps {
  input: string;
  data: CharDataExtendedProps;
  leaves: LeafNode[];
}

export default function SimilarCompounds({
  loading,
  onAddCompound,
}: SimilarCompoundsProps) {
  // Navigation
  const navigate = useNavigate();

  // Theme
  const headerTextColor = useColorModeValue("primary.500", "primary.300");

  // States
  const [searchResult, setSearchResult] = useState<LeafNode[] | undefined>([]);
  const [enteredInput, setEnteredInput] = useState("");

  // Context
  const { selectedId, selectedFeature, allData, chartData } =
    useContext(ChartControlsContext);

  // Handler
  function handleSearch(event: ChangeEvent<HTMLInputElement>) {
    setEnteredInput(event?.target?.value);

    setSearchResult(
      leaves.filter((node: Node) =>
        node.name.toLowerCase().includes(enteredInput?.toLowerCase())
      )
    );
  }

  const leaves: LeafNode[] = useMemo(
    () => allData?.nodes.filter((node: Node | LeafNode) => node.level === 3),
    [allData]
  );

  const leafNodes = useMemo(
    () => (!!enteredInput ? searchResult : leaves),
    [enteredInput, leaves, searchResult]
  );

  useEffect(() => {
    setSearchResult([]);

    if (
      !!selectedId &&
      !!selectedFeature &&
      Object.keys(chartData)?.length > 0 &&
      chartData?.selectedCompounds.nodes.length > 0 &&
      chartData?.selectedCompounds.links.length > 0
    ) {
      navigate({
        pathname: "/graph",
        search: `?${createSearchParams({
          compound: selectedId,
          feature: selectedFeature,
        })}`,
      });
    }
  }, [chartData, selectedFeature, selectedId, navigate]);

  // Responsiveness: ~992px, ~1280px, ~1536px
  // NOTE: 480, 495, and 510 are not exact values,
  // picked based on current display resolution.
  // Feel free to adjust them, if you find better solution
  const SimilarCompoundsListHeight = useBreakpointValue({
    lg: "calc(100vh - 480px)",
    xl: "calc(100vh - 495px)",
    "2xl": "calc(100vh - 510px)",
  });

  // Styles
  const SimilarCompoundsListStyle = {
    height: SimilarCompoundsListHeight,
    padding: "0",
  };

  if (loading) return <SimilarCompoundsSkeleton />;

  return (
    <>
      {!!selectedId && !!selectedFeature && (
        <Flex direction="column" gap={3}>
          {leaves?.length > 0 &&
            chartData?.selectedCompounds.nodes.length > 0 &&
            chartData?.selectedCompounds.links.length > 0 && (
              <>
                {/* header */}
                <Flex align={"center"} w={"full"}>
                  <MoreInfo />
                  <Text
                    color={headerTextColor}
                    fontSize={"14px"}
                    fontWeight={"500"}
                    ml={2}
                  >
                    Manage Compounds
                  </Text>

                  <Spacer />

                  <Stats
                    input={enteredInput}
                    data={chartData}
                    leaves={leaves}
                  />
                </Flex>

                {/* search */}
                <SearchSimilarCompounds
                  onSearch={handleSearch}
                  value={enteredInput}
                />

                {/* list */}
                <CustomScrollBar style={SimilarCompoundsListStyle}>
                  <Flex
                    direction="column"
                    gap={[null, null, null, 1, null, 1.5]}
                  >
                    {leafNodes?.map((cmpd: LeafNode, idx: number) => (
                      <CheckboxStyled
                        key={idx}
                        roundedFull
                        isChecked={chartData.selectedNames.includes(cmpd.name)}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          onAddCompound(cmpd.name, event);
                          setEnteredInput("");
                        }}
                      >
                        <Flex align={"center"} maxW={"full"}>
                          <Text wordBreak="break-word">{cmpd.name}</Text>
                        </Flex>
                      </CheckboxStyled>
                    ))}
                  </Flex>
                </CustomScrollBar>
              </>
            )}
        </Flex>
      )}
    </>
  );
}

function Stats({ input, data, leaves }: StatsProps) {
  // Theme
  const headerColor = useColorModeValue("primary.500", "primary.300");

  return (
    <Text
      color={headerColor}
      fontSize={[null, null, null, "10px", "10px", "11px"]}
      px={1}
    >
      {!input && `${data.selectedNames.length}/${leaves.length}`}
    </Text>
  );
}
