import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from 'reselect';

export interface User {
    email: string,
    email_verified: boolean,
    family_name?: string;
    given_name?: string;
    sub: string;
    id: string;
    user_type: string;
}

export interface AuthState {
    user: User | null;
    roles: string[];
}

const authSlice = createSlice({
    name: "auth",
    initialState: { user: null, roles: [] },
    reducers: {
        setCredentials: (state, action) => {
            const { user, roles } = action.payload;
            state.user = user;
            state.roles = roles;
            localStorage.setItem('loggedIn', JSON.stringify(true))
        },
        clearCredentials: (state) => {
            state.user = null;
            state.roles = [];
            localStorage.removeItem('loggedIn')
        },
        updateUserAttributes: (state, action) => {
            const newUserAttributes = action.payload;
            const prevAttr = state.user || {}
            state.user = { ...prevAttr, ...newUserAttributes };
        },
    },
});

export const { setCredentials, clearCredentials, updateUserAttributes } = authSlice.actions;

export default authSlice.reducer;

const selectAuthState = (state: { auth: AuthState }) => state.auth;

export const selectCurrentAuthData = createSelector(
    [selectAuthState],
    (auth) => ({
        user: auth.user,
        roles: auth.roles,
    })
);