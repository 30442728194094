import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Videos } from "./helpers";
import VideoPanel from "components/tutorials/VideoPanel";
import VideoThumbnail from "components/tutorials/VideoThumbnail";
import StepsContainer from "components/tutorials/StepsContainer";
import { Box, Flex, useBreakpointValue } from "@chakra-ui/react";
import { slides } from "utils/responsive";

function VideoTutorialView() {
  // States
  const [nav1, setNav1] = useState<Slider | null>(null);
  const [nav2, setNav2] = useState<Slider | null>(null);
  const [slideIndex, setSlideIndex] = useState(0);

  // Refs
  const sliderRef1 = useRef<Slider>(null);
  const sliderRef2 = useRef<Slider>(null);

  const slidesToShow = useBreakpointValue(slides);

  useEffect(() => {
    if (sliderRef1.current && sliderRef2.current) {
      setNav1(sliderRef1.current);
      setNav2(sliderRef2.current);
    }
  }, []);

  return (
    <Flex
      w={"100%"}
      h={"100%"}
      direction={[null, null, null, "column", "row", "row"]}
      my={[null, null, null, "20px", 0, 0]}
      mt={[null, null, null, null, "60px", "70px"]}
    >
      {/* videos section */}
      <Box w={[null, null, null, "100%", "60%", "60%"]} px={10}>
        <Box mb={10}>
          {/* main video Slider */}
          <Slider
            swipeToSlide={false}
            swipe={false}
            lazyLoad={"progressive"}
            asNavFor={nav2 ?? undefined}
            ref={sliderRef1}
            centerMode={true}
            centerPadding={"0px"}
            slidesToShow={1}
            nextArrow={<></>}
            prevArrow={<></>}
            fade={true}
            speed={800}
            waitForAnimate={false}
          >
            {Videos.map((v: any, idx: number) => (
              <VideoPanel url={v.url} key={idx} />
            ))}
          </Slider>
        </Box>

        {/* videos thumbnails Slider */}
        <Slider
          asNavFor={nav1 ?? undefined}
          ref={sliderRef2}
          slidesToShow={slidesToShow}
          swipeToSlide={true}
          focusOnSelect={true}
          lazyLoad={"progressive"}
          className="center"
          centerMode={true}
          centerPadding={"20px"}
          nextArrow={<></>}
          prevArrow={<></>}
          afterChange={setSlideIndex}
        >
          {Videos.map((v: any, idx: number) => (
            <VideoThumbnail
              url={v.url}
              type={v.type}
              title={v.title}
              key={idx}
              isActive={slideIndex === idx}
            />
          ))}
        </Slider>
      </Box>

      {/* Steps details */}
      <Box
        w={[null, null, null, "100%", "40%", "40%"]}
        px={[null, null, null, 16, 4, 4]}
        flex={1}
      >
        <StepsContainer currentVideo={slideIndex} />
      </Box>
    </Flex>
  );
}

export default VideoTutorialView;
