import { Flex, GridItem, Heading, Text } from "@chakra-ui/react";
import { HashLink as Link } from "react-router-hash-link";

function Header() {
  return (
    <GridItem colSpan={1} display={"flex"} flexDirection={"column"} gap={4}>
      <Flex direction={"column"} gap={1}>
        <Text
          size={[null, null, null, "sm", "sm", "md"]}
          fontWeight={"bold"}
          letterSpacing={2}
          color={"secondary.500"}
        >
          - Service
        </Text>
        <Heading
          size={[null, null, null, "lg", "lg", "xl"]}
          letterSpacing={1}
          color={"gray.700"}
        >
          FAQs
        </Heading>
      </Flex>

      <Text color={"gray.600"} w={"90%"}>
        Can't find what you are looking for?
        <br />
        Just <Link to="#contact" style={{textDecoration: "underline"}} smooth>contact</Link> our friendly team, and we will be more than happy to assist
        you.
      </Text>
    </GridItem>
  );
}

export default Header;
