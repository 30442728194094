import {
  Divider,
  Flex,
  Tag,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { hexToRgba } from "utils/helpers";
import { getLabelColor } from "views/tutorials/helpers";

interface VideoStepsProps {
  steps: string[];
  labels: string[];
}

export default function VideoSteps({ steps, labels }: VideoStepsProps) {
  // Theme
  const { colorMode } = useColorMode();
  const isLight = colorMode === "light";
  const bg = useColorModeValue("gray.500", "dark.600");
  const color = useColorModeValue("gray.700", "gray.300");
  function getBgGradient(label: string) {
    const bgGradient = isLight
      ? `linear(to-l, ${hexToRgba(getLabelColor(label), 0.5)}, ${getLabelColor(
          label
        )})`
      : `linear(to-l, ${getLabelColor(label)}, ${getLabelColor(label)})`;
    return bgGradient;
  }

  return (
    <Flex direction={"column"} gap={[null, null, null, 4, 6, 6]} w={"100%"}>
      <Flex align={"center"} gap={1} flexWrap={"wrap"}>
        {labels.map((label, index) => (
          <Tag
            bgGradient={getBgGradient(label)}
            color={"neutral.300"}
            variant="solid"
            borderRadius="20px"
            px={3}
            h={"fit-content"}
            fontSize={[null, null, null, "9px", "10px", "10px"]}
            fontWeight={"bold"}
            textTransform={"uppercase"}
            letterSpacing={"1px"}
            key={index}
          >
            {label}
          </Tag>
        ))}
      </Flex>

      <Flex direction={"column"} gap={3}>
        {steps.map((step, index) => (
          <Flex gap={3}>
            {/* Number */}
            <Flex
              direction={"column"}
              h={"100%"}
              align={"center"}
              justify={"center"}
            >
              <Tag
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                w={"100%"}
                borderRadius="full"
                fontSize={[null, null, null, "10px", "12px", "12px"]}
                p={0}
                bg={bg}
                color={"gray.50"}
                fontWeight={"500"}
                zIndex={10}
              >
                {index + 1}
              </Tag>
              <Divider
                orientation="vertical"
                borderColor={bg}
                position="relative"
                _after={{
                  content: '"●"',
                  fontSize: [null, null, null, "11px", "13px", "13px"],
                  color: bg,
                  position: "absolute",
                  width: "100%",
                  px: "auto",
                  bottom: [null, null, null, "-.28rem", "-0.33rem", "-0.33rem"],
                  left: [null, null, null, "-.22rem", "-4px", "-4px"],
                  zIndex: -1,
                }}
              />
            </Flex>

            {/* Text */}
            <Text
              color={color}
              fontSize={[null, null, null, "12px", "14px", "16px"]}
            >
              {step}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}
