import { Flex, Image, Text } from "@chakra-ui/react";

function VitafluenceContent() {
  return (
    <Flex direction={"column"} gap={8}>
      <Flex direction={"column"} gap={1}>
        <Image
          width={[null, null, null, "60%", "65%", "70%"]}
          objectFit={"cover"}
          src="https://vf-website-assets.s3.eu-central-1.amazonaws.com/logos/vitafluence.svg"
        />
        <Text
          color={"purple.800"}
          fontWeight={"500"}
          fontSize={[null, null, null, "14px", "15px", "16px"]}
          fontFamily={"Poppins, sans-serif"}
          w={"75%"}
        >
          Accelerating scientific discovery.
        </Text>
      </Flex>
    </Flex>
  );
}

export default VitafluenceContent;
