import { useState } from "react";
import { Box, Divider, Flex, Spacer } from "@chakra-ui/react";

import PrivacyPolicyModal from "components/home/PrivacyPolicyModal";
import VitafluenceContent from "components/home/Footer/VitafluenceContent";
import ContactUs from "components/home/Footer/ContactUs";
import AboutUs from "components/home/Footer/AboutUs";
import CopyrightAndSocialMedia from "components/home/Footer/CopyrightAndSocialMedia";

interface FooterProps {
  id: string;
}

export default function Footer({id} : FooterProps) {
  // State
  const [show, setShow] = useState(false);

  return (
    <Box id={id}>
      {/* privacy policy modal content */}
      <PrivacyPolicyModal isOpen={show} onClose={() => setShow(false)} />

      {/* Footer content */}
      <Flex
        direction={"column"}
        gap={8}
        w={[null, null, null, "90%", "95%", "100%"]}
        maxW={[null, null, null, "1100px", "1360px", "1360px"]}
        mx={"auto"}
        my={[null, null, null, "60px", "100px", "100px"]}
      >
        <Flex gap={[null, null, null, 4, 8, 10]}>
          <VitafluenceContent />
          <Spacer />
          <ContactUs />
          <AboutUs setShow={setShow} />
        </Flex>

        <Divider />
        <CopyrightAndSocialMedia />
      </Flex>
    </Box>
  );
}
