import HelperProps from "./HelperProps";

export const datadensity_helper: HelperProps[] = [
  {
    title: "What does the data density map tell us?",
    description: `The data density chart maps the data available on a compound. Each bar references a section or topic of interest and the values mean the next:\n
    1. No data available \n 
    2. Only data from ReframeDB \n 
    3. Only enriched data from different sources \n 
    4. Data from ReframeDB enriched with external sources \n\n
    We are considering the next elements for the sections:\n
    - Indication: conditions for what the compound is been used for.\n
    - Pharmacokinetics: pk data such as AUC, clearance, maximum concentration, half life, Tmax, and bioavailability \n
    - Pharmacodynamics: mechanisms of action and routes of administration.\n
    - Chemical Properties: smiles, formula, chirality, other chemical properties.\n
    - Safety indications: adverse reactions, drug interactions, pregnancy category, and toxicity.\n
    - Synthesis: compounds chemical origin, stereochemistry, and how to synthesize the compound.\n
    `,
    highlight: [
      "pharmacokinetics",
      "pharmacodynamics",
      "chemical",
      "safety",
      "synthesis",
      "similarity",
      "indication",
    ],
  },
];
