import { GridItem, Text, useColorModeValue } from "@chakra-ui/react";

interface SuggestionTitleProps {
  title: string | undefined;
}

function SuggestionTitle({ title }: SuggestionTitleProps) {
  // Theme
  const color = useColorModeValue("gray.700", "gray.300");

  return (
    <GridItem rowSpan={2} colSpan={1} h={"full"} alignContent={"center"}>
      <Text
        color={color}
        fontSize={"14px"}
        lineHeight={"1.28"}
        textTransform={"none"}
      >
        {title}
      </Text>
    </GridItem>
  );
}

export default SuggestionTitle;
