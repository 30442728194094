import { Icon, Link, Text, useColorModeValue } from "@chakra-ui/react";
import { CompoundProps } from "models/compounds/CompoundProps";
import { Link as RouterLink } from "react-router-dom";
import { PiShareNetwork } from "react-icons/pi";
import { GetGradients } from "utils/gradients";

interface TreeOfLifeLinkProps {
  compound: CompoundProps;
}

function TreeOfLifeLink({ compound }: TreeOfLifeLinkProps) {
  // Theme
  const hoverBg = useColorModeValue("primary.100", "dark.700");
  const color = useColorModeValue("secondary.700", "gray.400");
  const { emGradient, maskGradient } = GetGradients();

  return (
    <Link
      borderRadius={"40px"}
      h={"full"}
      w={"fit-content"}
      px={4}
      py={1}
      as={RouterLink}
      to={`/graph?compound=${compound?.compound_id}`}
      _hover={{
        textDecoration: "none",
        bg: hoverBg,
      }}
      display={"flex"}
      gap={1}
      alignItems={"center"}
      position={"relative"}
      _before={{
        content: "''",
        position: "absolute",
        inset: "0",
        borderRadius: "24px",
        padding: ".1rem",
        background: emGradient,
        WebkitMask: maskGradient,
        WebkitMaskComposite: "xor",
        maskComposite: "exclude",
        pointerEvents: "none",
      }}
    >
      <Icon as={PiShareNetwork} boxSize={4} color={color} />
      <Text fontWeight={"500"} color={color}>
        Tree of Life
      </Text>
    </Link>
  );
}

export default TreeOfLifeLink;
