import { Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import { BiSolidNetworkChart } from "react-icons/bi";

import CompoundInput from "components/graph/CompoundInput";
import FeatureInput from "components/graph/FeatureInput";
import { ChartControlsContext, OptionItem } from ".";
import ActionButton from "components/buttons/ActionButton";
import { FiDownload } from "react-icons/fi";
import { BsBookmarkCheck } from "react-icons/bs";
import { useContext } from "react";

interface GraphToolsProps {
  loading?: boolean;
  compounds: OptionItem[];
  onSaveClicked: () => void;
}

export default function GraphTools({
  loading,
  compounds,
  onSaveClicked,
}: GraphToolsProps) {
  // Theme
  const headerTextColor = useColorModeValue(
    "highlight.primary",
    "secondary.400"
  );

  // Context
  const { selectedId, selectedFeature } = useContext(ChartControlsContext);

  return (
    <Flex direction={"column"} w="100%">
      {/* Chart settings */}
      <Flex gap={2} align={"center"} mb={4}>
        <Icon
          as={BiSolidNetworkChart}
          color={headerTextColor}
          boxSize={"14px"}
        />
        <Text color={headerTextColor} fontSize={"14px"} fontWeight={"500"}>
          Chart Settings
        </Text>
      </Flex>

      {/* Inputs */}
      <CompoundInput loading={loading ?? false} compounds={compounds} />
      <FeatureInput />

      {/* Action buttons? */}
      {!!selectedId && !!selectedFeature && (
        <Flex alignItems="center" w={"full"} gap={2} mt={2}>
          <ActionButton label={"Download"} icon={<FiDownload />} />
          <ActionButton
            label={"Save"}
            icon={<BsBookmarkCheck />}
            onClick={onSaveClicked}
          />
        </Flex>
      )}
    </Flex>
  );
}
