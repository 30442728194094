import {
  Flex,
  useRadio,
  useTheme,
  useRadioGroup,
  useColorModeValue,
  Box,
  UseRadioProps,
} from "@chakra-ui/react";

import { hexToRgba } from "utils/helpers";
import SidebarTitle from "components/ui/SidebarTitle";
import { BsFilterLeft } from "react-icons/bs";
import { useContext, useState } from "react";
import { PapersContext } from "views/papers";

interface FilterByEmbedProps {
  onEmbedChanged: (e: string) => void;
}

export default function FilterByEmbed({ onEmbedChanged }: FilterByEmbedProps) {
  const embedOptions = ["All", "Embedded", "Not embedded"];
  const { filters } = useContext(PapersContext);

  // type button configuration
  const { getRadioProps } = useRadioGroup({
    name: "type",
    defaultValue: embedOptions[0],
  });

  return (
    <Flex direction={"column"} gap={3}>
      <SidebarTitle
        text="Embedding status"
        icon={BsFilterLeft}
        color="neutral.700"
      />

      <Flex flexWrap={"wrap"} gap={1}>
        {embedOptions.map((type: string) => {
          const radio = getRadioProps({ value: type });
          const { name, value } = radio;
          return (
            <EmbedItem
              key={type}
              {...radio}
              name={name}
              isChecked={value.toLocaleLowerCase() === filters.embedded && true}
              onChange={(e) => onEmbedChanged(value.toLocaleLowerCase())}
            >
              {type}
            </EmbedItem>
          );
        })}
      </Flex>
    </Flex>
  );
}

interface EmbedItemProps extends UseRadioProps {
  children: React.ReactNode;
}

function EmbedItem(props: EmbedItemProps | undefined) {
  // Theme
  const { colors } = useTheme();
  const color = useColorModeValue("gray.600", "gray.300");
  const border = useColorModeValue("gray.200", "gray.500");
  const bg = useColorModeValue("transparent", hexToRgba(colors.dark[800], 0.1));

  // Custom checkbox with radio functionalities
  const { getInputProps, getRadioProps } = useRadio(props);
  const input = getInputProps();
  const checkbox = getRadioProps();

  const [selected, setSelected] = useState<boolean>(false);

  return (
    <Box as="label">
      <input {...input} onClick={() => setSelected(!selected)} />
      <Box
        {...checkbox}
        cursor={"pointer"}
        color={color}
        bg={bg}
        borderWidth={1}
        borderColor={border}
        borderRadius={"16px"}
        fontSize={[null, null, null, "10px", "11px", "12px"]}
        textTransform={"capitalize"}
        h={"fit-content"}
        w={"fit-content"}
        _checked={{
          bg: "dark.500",
          color: "gray.100",
          borderColor: "dark.500",
          fontWeight: "500",
          letterSpacing: "-0.012rem",
        }}
        px={3}
        py={1}
      >
        {props?.children}
      </Box>
    </Box>
  );
}
