import {
  FormControl,
  FormErrorMessage,
  Input,
  useTheme,
} from "@chakra-ui/react";
import { ChangeEvent } from "react";
import { hexToRgba } from "utils/helpers";

interface EmailFormControlProps {
  email: string;
  isInvalid: boolean;
  onEmailChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

function EmailFormControl({
  email,
  isInvalid,
  onEmailChange,
}: EmailFormControlProps) {
  // Theme
  const { colors } = useTheme();

  return (
    <FormControl isRequired isInvalid={isInvalid}>
      <Input
        type="email"
        name="email"
        value={email}
        onChange={onEmailChange}
        placeholder="Email"
        _placeholder={{ color: "gray.600" }}
        borderRadius={"8px"}
        color={"dark.700"}
        bg={hexToRgba(colors.gray[50], 0.5)}
        border={"none"}
        _hover={{ bg: hexToRgba(colors.gray[50], 0.7) }}
        _focusVisible={{ bg: hexToRgba(colors.gray[50], 0.7) }}
      />
      <FormErrorMessage color={"red.50"}>
        Email is required. Sould be valid email address.
      </FormErrorMessage>
    </FormControl>
  );
}

export default EmailFormControl;
