import { useContext, useMemo } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";

import EChartsReact from "echarts-for-react";
import { hexToRgba, stylesToString } from "utils/helpers";
import { ChartControlsContext, Node, LeafNode, Link } from ".";
import {
  useTheme,
  useColorModeValue,
  useBreakpointValue,
  Flex,
} from "@chakra-ui/react";
import NoDataFound from "components/ui/NoDataFound";
import { maxHeight } from "utils/responsive";

interface tooltipProps {
  color: string;
  name: string;
  value: number;
  dataType: string;
  data: Node | LeafNode | Link;
}

interface ReducedNodeProps {
  data: {
    name: string;
    level: number;
    leaf_id: string;
  };
  dataType: string;
}

export default function Chart() {
  const similarCompoundsLoading = useOutletContext();
  const { selectedId, chartData } = useContext(ChartControlsContext);

  const { allData } = useContext(ChartControlsContext);
  const leaves: LeafNode[] = useMemo(
    () => allData?.nodes.filter((node: Node | LeafNode) => node.level === 3),
    [allData]
  );

  const navigate = useNavigate();

  // Theme
  const { colors } = useTheme();
  const loadingColor = useColorModeValue(
    colors.primary[800],
    colors.primary[300]
  );
  const cmpdNodeBorder = useColorModeValue(
    colors.neutral[100],
    colors.dark[800]
  );
  const compoundNodeColor = useColorModeValue(
    colors.primary[500],
    colors.highlight.primary
  );
  const featureNodeColor = useColorModeValue(
    colors.secondary[500],
    colors.secondary[300]
  );
  const labelColor = useColorModeValue(colors.primary[600], colors.gray[300]);
  const labelBg = useColorModeValue(
    hexToRgba(colors.neutral[200], 0.5),
    "transparent"
  );
  const labelBorder = useColorModeValue(
    hexToRgba(colors.neutral[200], 0.9),
    "transparent"
  );

  // Style
  const chartStyle = {
    width: "100%",
    height: "100%",
    maxHeight: "max(700px, 60vh)",
    maxWidth: "1200px",
    margin: "auto",
  };

  // Style
  const loadingChartStyle: React.CSSProperties = useMemo(
    () => ({
      fontSize: "14px",
      text: "Preparing chart...",
      color: colors.primary[300],
      textColor: loadingColor,
      maskColor: "transparent",
      borderRadius: "12px",
      zlevel: 0,
    }),
    [colors, loadingColor]
  );

  // Handler
  function handleNodeClick(node: ReducedNodeProps) {
    if (node?.dataType.toLowerCase() === "node") {
      if (node?.data?.level === 2) {
        navigate(`/compounds?query=${node?.data?.name}`);
      } else {
        const id = node?.data?.level === 1 ? selectedId : node?.data?.leaf_id;
        navigate(`/compounds/${id}`);
      }
    }
  }

  const hasCompounds: boolean = useMemo(
    () => chartData?.selectedNames.length > 0,
    [chartData]
  );

  const chartOptions = useMemo(() => {
    const sortedNodes: Node[] = chartData?.selectedCompounds?.nodes.sort(
      (a: Node, b: Node) => a.name.localeCompare(b.name)
    );

    const levels = [
      {
        depth: 0,
        itemStyle: {
          borderWidth: 1,
          borderRadius: 8,
          color: compoundNodeColor,
          borderColor: cmpdNodeBorder,
        },
        lineStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 1,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: compoundNodeColor,
              },
              {
                offset: 1,
                color: featureNodeColor,
              },
            ],
          },
        },
      },
      {
        depth: 1,
        itemStyle: {
          borderRadius: 8,
          color: featureNodeColor,
        },
        lineStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 1,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: featureNodeColor,
              },
              {
                offset: 1,
                color: compoundNodeColor,
              },
            ],
          },
        },
      },
      {
        depth: 2,
        itemStyle: {
          borderWidth: 1,
          borderRadius: 8,
          color: compoundNodeColor,
          borderColor: cmpdNodeBorder,
        },
      },
    ];

    const tooltip = {
      trigger: "item",
      triggerOn: "mousemove",
      formatter: function ({
        color,
        name,
        value,
        dataType,
        data,
      }: tooltipProps) {
        const isEdge = dataType === "edge";

        const tooltipStyle = {
          display: "flex",
          "flex-direction": "column",
          "justify-content": "center",
          gap: "10px",
          padding: "10px",
          margin: "0",
          "border-radius": "13px",
          "font-size": "14px",
          color: colors.gray[600],
        };

        const tooltipTitleStyle = {
          "font-weight": "bold",
          "max-width": "300px",
          "white-space": "normal",
          color: color,
        };

        const tooltipLinksStyle = {
          "font-weight": "bold",
          color: color,
        };

        const tooltipInfoStyle = {
          "font-family": "Poppins, sans-serif",
          "margin-top": "6px",
          "padding-top": "4px",
          "border-top": `1px solid ${colors.gray[300]}`,
          opacity: "0.85",
        };

        const inlineTooltipStyle = stylesToString(tooltipStyle);
        const inlineTooltipTitleStyle = stylesToString(tooltipTitleStyle);
        const inlineTooltipLinksStyle = stylesToString(tooltipLinksStyle);
        const inlineTooltipInfoStyle = stylesToString(tooltipInfoStyle);

        const level = (data as Node).level || (data as LeafNode).level;

        const infoContent =
          +level === 2
            ? "For details, click to search for this feature"
            : "For details, click to view compound page";

        const nodeContent = [
          `<span>Node: <span style="${inlineTooltipTitleStyle}">${name}</span></span>`,
          `<span>N° of Links: <span style="${inlineTooltipLinksStyle}">${value}</span></span>`,
          `<span style="${inlineTooltipInfoStyle}">${infoContent}</span>`,
        ].join("");

        const source = (data as Link).source;
        const target = (data as Link).target;

        const edgeContent = [
          `<span>Source: <span style="${inlineTooltipTitleStyle}">${source}</span></span>`,
          `<span>Target: <span style="${inlineTooltipTitleStyle}">${target}</span></span>`,
        ].join("");

        const tooltipContent = isEdge ? edgeContent : nodeContent;

        return `<Box style="${inlineTooltipStyle}">${tooltipContent}</Box>`;
      },
      padding: 6,
      confine: true, // replaces custom positioning. assigns dynamic position to prevent items overflow
    };

    const label = {
      formatter: function (value: echarts.ECElementEvent) {
        const maxLength = 20;
        return value?.name.length > maxLength
          ? value?.name.slice(0, maxLength) + "..."
          : value?.name;
      },

      color: labelColor,
      fontFamily: "Arial",
      fontSize: 12,
      backgroundColor: labelBg,
      borderColor: labelBorder,
      borderWidth: 0.5,
      padding: [2, 3],
      borderRadius: 2,
    };

    const series = [
      {
        type: "sankey",
        emphasis: {
          focus: "adjacency",
        },
        top: 20.0,
        right: 150.0,
        bottom: 20.0,
        left: 50.0,
        data: hasCompounds && !similarCompoundsLoading ? sortedNodes : [],
        links:
          hasCompounds && !similarCompoundsLoading
            ? chartData?.selectedCompounds?.links
            : [],
        lineStyle: {
          color: "source",
          curveness: 0.5,
          opacity: 0.4,
        },
        nodeAlign: "left",
        label,
        levels,
      },
    ];

    return {
      series,
      tooltip,
      animationDuration: 1000,
      animationEasingUpdate: "quinticInOut",
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    chartData,
    similarCompoundsLoading,
    hasCompounds,
    colors,
    featureNodeColor,
  ]);

  const onEvents = {
    click: (node: ReducedNodeProps) => {
      handleNodeClick(node);
    },
  };

  const fullHeight = useBreakpointValue(maxHeight);

  if (leaves?.length === 0)
    return <NoDataFound message={"No similar compounds found!"} />;

  return (
    <Flex h={fullHeight} w={"100%"}>
      <EChartsReact
        style={chartStyle}
        option={chartOptions}
        onEvents={onEvents}
        loadingOption={loadingChartStyle}
        showLoading={similarCompoundsLoading as boolean | undefined}
      />
    </Flex>
  );
}
