import { Button, Icon } from "@chakra-ui/react";
import { IconType } from "react-icons";

interface UserAccessProps {
  icon: IconType;
  state: string;
  onAccess: () => void;
}

function UserAccess({ icon, state, onAccess }: UserAccessProps) {
  return (
    <Button
      py={2}
      px={3}
      w={"fit-content"}
      h={"fit-content"}
      letterSpacing={".03rem"}
      bg={"whiteAlpha.300"}
      color={"neutral.100"}
      _hover={{ backgroundColor: "whiteAlpha.500" }}
      _active={{ backgroundColor: "whiteAlpha.500" }}
      onClick={onAccess}
    >
      <Icon as={icon} mr="2" fontSize="md" />
      {state}
    </Button>
  );
}

export default UserAccess;
