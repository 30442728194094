import { Flex } from "@chakra-ui/react";

import AssayTreeMap from "components/assays/AssayTreemap";
import AssayTypes from "components/assays/AssayTypes";
import SearchAssayInput from "components/assays/SearchInput";

export default function AssaysTools() {
  return (
    <Flex
      w={"100%"}
      h={"100%"}
      gap={[null, null, null, 2, 3, 5]}
      direction={"column"}
    >
      {/* Input */}
      <Flex direction="column" justify={"space-between"}>
        <SearchAssayInput />
      </Flex>

      {/* AdvancedFilters */}
      <Flex justifyContent="flex-start" direction="column" h={"100%"} gap={3}>
        <AssayTreeMap />
        <AssayTypes />
      </Flex>
    </Flex>
  );
}
