import { ReactNode } from "react";
import { Box } from "@chakra-ui/react";
import { GetGradients } from "utils/gradients";

interface ContainerProps {
  children: ReactNode;
}

function Container({ children }: ContainerProps) {
  // Theme
  const { homeGradient } = GetGradients();

  return (
    <Box
      backgroundImage={
        "url(https://reacto.s3.eu-central-1.amazonaws.com/H-background.png)"
      }
      bgRepeat="no-repeat"
      bgPosition="center"
      position="relative"
      bgSize="cover"
      h="100vh"
    >
      {/* Color overlay */}
      <Box
        bgGradient={homeGradient}
        p={[null, null, null, "40px", "40px", "60px"]}
        px={[null, null, null, "40px", "60px", "80px"]}
        pb={0}
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        color="neutral.200"
        textAlign="center"
      >
        {children}
      </Box>
    </Box>
  );
}

export default Container;
