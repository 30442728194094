import { environment } from "../environments";
import { axiosPrivate } from "api/axios";

export interface Enrichment {
  compound_id: string;
  label: string;
}

export const generateBotResponse = async (payload: {
  inputs: string | undefined | null;
  enrichment?: Enrichment;
  user_id: string | undefined | null;
  session_id: string | undefined | null;
  message_id: string | undefined | null;
}) => {
  const response = await axiosPrivate.post(
    `${environment.BACKEND_API}/llm/generate`,
    payload
  );
  return response.data;
};

export const deleteSession = async (session_id: string) => {
  const response = await axiosPrivate.delete(
    `${environment.BACKEND_API}/api/delete_session/${session_id}`
  );
  return response.data;
};

export const deleteAllSession = async (user_id: string | undefined) => {
  if (!user_id) return;

  const response = await axiosPrivate.delete(
    `${environment.BACKEND_API}/api/delete_all_sessions/${user_id}`
  );
  return response.data;
};
