import { useState, useEffect, useContext } from "react";
import { Button, Flex, useColorModeValue, useTheme } from "@chakra-ui/react";

import { hexToRgba } from "utils/helpers";
import { getPaperColor } from "views/compounds/helpers";
import SidebarTitle from "components/ui/SidebarTitle";
import { BsFilterLeft } from "react-icons/bs";
import { PapersContext } from "views/papers";

interface TypeProps {
  isSelected: boolean;
  type: string | undefined;
  color: string | undefined;
  onClick: (type: string) => void;
}

interface FilterByTypeProps {
  // types: string[] | [];
  onUpdateSelectedTypes: (selectedTypes: string[]) => void;
}

export default function FilterByType({
  // types,
  onUpdateSelectedTypes,
}: FilterByTypeProps) {
  // State
  const { initialFilterTypes } = useContext(PapersContext);
  const [selectedTypes, setSelectedTypes] = useState<string[]>(initialFilterTypes);

  // Handler
  const handleTypeSelected = (type: string) => {
    const isSelected = selectedTypes.includes(type);

    if (isSelected) {
      const filteredTypes = selectedTypes.filter((id) => id !== type);
      setSelectedTypes(filteredTypes);
    } else {
      const updatedTypes = [...selectedTypes, type];
      setSelectedTypes(updatedTypes);
    }
  };

  useEffect(() => {
    onUpdateSelectedTypes(selectedTypes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTypes]);

  return (
    <Flex align={"flex-start"} direction={"column"} gap={3}>
      <SidebarTitle text="Paper Type" icon={BsFilterLeft} color="neutral.700" />

      <Flex flexWrap={"wrap"} gap={1}>
        {initialFilterTypes?.map((type: string) => (
          <TypeItem
            key={type}
            type={type}
            color={getPaperColor(type?.toLowerCase())}
            onClick={handleTypeSelected}
            isSelected={selectedTypes.includes(type)}
          />
        ))}
      </Flex>
    </Flex>
  );
}

function TypeItem({ type, isSelected, onClick }: TypeProps) {
  // Theme
  const { colors } = useTheme();
  const border = useColorModeValue("gray.200", "gray.500");
  const color = useColorModeValue("gray.600", "gray.300");
  const bg = useColorModeValue("transparent", hexToRgba(colors.dark[800], 0.1));

  return (
    <Button
      h={"fit-content"}
      w={"fit-content"}
      color={isSelected ? getPaperColor(type) : color}
      bg={isSelected ? hexToRgba(getPaperColor(type), 0.1) : bg}
      fontSize={[null, null, null, "10px", "11px", "12px"]}
      textTransform={"capitalize"}
      fontWeight={isSelected ? "500" : "400"}
      letterSpacing={isSelected ? "-0.012rem" : "0"}
      borderWidth={1}
      borderColor={isSelected ? getPaperColor(type) : border}
      borderRadius="16px"
      px={3}
      py={1}
      transition={"all .2s ease"}
      onClick={() => onClick(type ?? "")}
      _hover={{
        bg: bg,
        borderColor: getPaperColor(type),
        transition: "all .2s ease",
      }}
    >
      {type}
    </Button>
  );
}
