import {
  Flex,
  Heading,
  Icon,
  Image,
  Tag,
  TagLabel,
  Text,
  useTheme,
} from "@chakra-ui/react";
import { IconType } from "react-icons";
import { hexToRgba } from "utils/helpers";

interface PipeStepProps {
  step: number;
  icon: IconType;
  title: string;
  text: string;
}

interface StepIconProps {
  step: number;
  icon: IconType;
}

export default function PipeStep({ step, title, text, icon }: PipeStepProps) {
  // Theme
  const { colors } = useTheme();

  return (
    <Flex direction={"column"} gap={4}>
      <Flex justify={"center"} position="relative">
        <Image
          src={`https://reacto.s3.eu-central-1.amazonaws.com/images/b${step}.png`}
          h={[null, null, null, "100px", "130px", "150px"]}
          objectFit={"cover"}
        />

        <StepIcon step={step} icon={icon} />
        <StepTag step={step} />
      </Flex>

      {/* Heading */}
      <Flex
        direction={"column"}
        justify={"center"}
        textAlign={"center"}
        px={3}
        minH={"60px"}
      >
        <Heading
          size={"sm"}
          fontFamily={"Poppins, sans-serif"}
          color={"blue.100"}
          textTransform={"uppercase"}
          w={"90%"}
          mx={"auto"}
          py={"auto"}
          letterSpacing={"0.02rem"}
        >
          {title}
        </Heading>
      </Flex>

      {/* Text */}
      <Flex
        direction={"column"}
        textAlign={"center"}
        h={[null, null, null, "340px", "360px", "370px"]}
        borderWidth={1}
        borderColor={hexToRgba(colors.highlight.neutral[200], 0.25)}
        borderRadius={"8px"}
        bg={hexToRgba(colors.secondary[600], 0.15)}
        px={2}
      >
        <Text
          color={"blue.50"}
          w={"93%"}
          m={"auto"}
          textAlign={"center"}
          fontSize={[null, null, null, "14px", "15px", "16px"]}
          lineHeight={"1.4"}
        >
          {text}
        </Text>
      </Flex>
    </Flex>
  );
}

function StepIcon({ step, icon }: StepIconProps) {
  return (
    <Icon
      position={"absolute"}
      top={[
        null,
        null,
        null,
        "calc(50% - 16px)",
        "calc(50% - 19px)",
        "calc(50% - 23px)",
      ]}
      left={[
        null,
        null,
        null,
        "calc(50% - 16px)",
        "calc(50% - 19px)",
        "calc(50% - 23px)",
      ]}
      as={icon}
      boxSize={[null, null, null, "32px", "38px", "46px"]}
      color={
        step === 1 ? "primary.200" : step === 2 ? "orange.100" : "secondary.100"
      }
    />
  );
}

function StepTag({ step }: Omit<StepIconProps, "icon">) {
  return (
    <Tag
      size={[null, null, null, "sm", "md", "md"]}
      borderRadius="full"
      variant="solid"
      bg={step === 1 ? "teal.100" : step === 2 ? "orange.100" : "blue.100"}
      position={"absolute"}
      top={4}
      left={[
        null,
        null,
        null,
        "calc(50% + 19px)",
        "calc(50% + 19px)",
        "calc(50% + 23px)",
      ]}
    >
      <TagLabel>
        <Text
          color={
            step === 1
              ? "teal.400"
              : step === 2
              ? "orange.400"
              : "secondary.400"
          }
        >
          STEP {step}
        </Text>
      </TagLabel>
    </Tag>
  );
}
