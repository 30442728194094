import { ChangeEvent, useState } from "react";
import SearchInput from "components/ui/SearchInput";

interface SearchPaperInputProps {
  onSearch: (query: string) => void;
}

function SearchPaperInput({ onSearch }: SearchPaperInputProps) {
  // State
  const [enteredInput, setEnteredInput] = useState("");

  // Handler
  const handleSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    setEnteredInput(event?.currentTarget?.value);

    onSearch(event?.currentTarget?.value);
  };

  return (
    <SearchInput
      value={enteredInput}
      onChange={handleSearch}
      placeholder="search paper in network"
    />
  );
}

export default SearchPaperInput;
