import { Text, Flex, useColorModeValue } from "@chakra-ui/react";

interface AbstractProps {
  data: string | null | undefined;
}

function Abstract({ data }: AbstractProps) {
  // Theme
  const color = useColorModeValue("gray.500", "gray.400");

  return (
    <>
      {data ? (
        <Flex gap={1} color={color} align={"center"}>
          <Text
            fontSize={[null, null, null, "12px", "12px", "14px"]}
            fontWeight={"500"}
            lineHeight={"1.3"}
          >
            Abstract:
          </Text>
          <Text
            fontWeight={"400"}
            fontSize={[null, null, null, "12px", "12px", "14px"]}
            lineHeight={"1.3"}
          >
            {data}
          </Text>
        </Flex>
      ) : null}
    </>
  );
}

export default Abstract;
