import { ChangeEvent } from "react";
import { Textarea } from "@chakra-ui/react";

interface FeedbackAreaProps {
  message: string;
  onMessageChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

export default function FeedbackArea({
  message,
  onMessageChange,
}: FeedbackAreaProps) {
  return (
    <Textarea
      name="message"
      value={message}
      onChange={onMessageChange}
      placeholder="Write your feedback.."
      _placeholder={{ fontSize: "14px", color: "gray.400" }}
      resize="none"
      minHeight="120px"
      maxHeight="180px"
      bg={"transparent"}
      _focus={{
        outline: "none",
        borderWidth: 1,
        boxShadow: "md",
        overflow: "auto",
      }}
      _focusVisible={{
        outline: "none",
      }}
    />
  );
}
