import { Card, Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import { ConversationProps } from "models/chat/MessageProps";
import { FaUserLarge } from "react-icons/fa6";

interface HumanMessageProps {
  botResponse?: ConversationProps | undefined;
  questionOnWait?: string | undefined;
}

export default function HumanMessage({
  botResponse,
  questionOnWait,
}: HumanMessageProps) {
  let question: string = "";

  if (botResponse) {
    const currentMessageIndex = botResponse.messages?.length - 1;
    question = botResponse?.messages[currentMessageIndex]?.human;
  }

  // Theme
  const textColor = useColorModeValue("gray.700", "gray.300");
  const iconColor = useColorModeValue("gray.500", "gray.300");

  return (
    <Flex width={"fit-content"} justify={"center"} align={"center"}>
      <Card
        pr={3}
        borderRadius={"6px"}
        position="relative"
        width={"fit-content"}
        boxShadow={"none"}
        bg={"transparent"}
      >
        <Text
          fontSize={["12px", "12px", "13px", "13px", "14px", "15px"]}
          fontWeight={"bold"}
        >
          YOU
        </Text>
        <Text
          color={textColor}
          lineHeight={["1.4", "1.4", "1.4", "1.4", "1.3", "1.2"]}
          fontSize={["14px", "14px", "14px", "14px", "15px", "15px"]}
          display={"flex"}
          gap={3}
        >
          {/* <Icon
            as={FaUserLarge}
            boxSize={"14px"}
            mt={"1px"}
            color={iconColor}
          /> */}

          {!!questionOnWait ? questionOnWait : question}
        </Text>
      </Card>
    </Flex>
  );
}
