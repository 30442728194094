import React, { useCallback, useMemo, useState } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import { Box, Divider, Flex, useBreakpointValue } from "@chakra-ui/react";

import AssaysBreadcrumb from "./components/Breadcrumb";

import SidebarLayout from "layouts/Sidebar";
import { CustomScrollBar } from "components/ui/CustomScrollBar";
import { filterAssays } from "./helpers";
import AssaysTools from "./Tools";
import { useFetch } from "hooks";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";

import { environment } from "../../environments";

import { AssayProps } from "models/assays/AssayProps";
import { maxHeight, maxWidth, minWidth } from "utils/responsive";

export const AssaysControlsContext = React.createContext({
  searchResults: [] as AssayProps[],
  assays: [] as AssayProps[],
  selectedAssayTypes: "",
  selectedIndication: "",
  query: "",
  loadingAssays: false,
  error: "",
});

export interface AssayTypeProps {
  name: string;
  value: number;
  isChecked: boolean;
}

export default function AssaysView() {
  // State
  const [error, setError] = useState("");

  // Hooks
  const [searchParams] = useSearchParams();
  const axiosPrivate = useAxiosPrivate();

  // API
  const { data, loading } = useFetch(
    useCallback(async () => {
      try {
        const response = await axiosPrivate.get(
          `${environment.BACKEND_API}/api/get_all_assays_names`
        );
        return response.data;
      } catch (error) {
        setError("Error fetching data");
        return [];
      }
    }, [axiosPrivate])
  );

  // Filters
  const { query, indication, assayTypes, searchResults } = useMemo(() => {
    const query = searchParams.get("query");
    const indication = searchParams.get("indication");
    const assayTypes = searchParams.get("types");

    return {
      query,
      indication,
      assayTypes,
      searchResults: filterAssays(data, query, indication, assayTypes),
    };
  }, [data, searchParams]);

  // Responsiveness: ~992px, ~1280px, ~1536px
  const AssaysListHeight = useBreakpointValue(maxHeight);

  // Styles
  const listStyle = {
    height: AssaysListHeight,
    padding: "0 10px",
  };

  return (
    <Flex>
      <AssaysControlsContext.Provider
        value={{
          assays: data,
          searchResults: searchResults ?? [],
          selectedAssayTypes: assayTypes ?? "",
          selectedIndication: indication ?? "",
          query: query ?? "",
          loadingAssays: loading ?? false,
          error: error ?? "",
        }}
      >
        {/* Sidebar */}
        <SidebarLayout>
          <AssaysBreadcrumb />
          <Divider my={4} />
          <AssaysTools />
        </SidebarLayout>

        {/* Main Panel */}
        <Box w={minWidth} minW={minWidth} maxW={maxWidth} mx={"auto"} py={2}>
          {/* Specific View */}
          <CustomScrollBar style={listStyle}>
            <Outlet />
          </CustomScrollBar>
        </Box>
      </AssaysControlsContext.Provider>
    </Flex>
  );
}
