import { useEffect } from "react";
import { useToast } from "@chakra-ui/react";

import { environment } from "../../environments";

function UpdateChecker() {
  const toast = useToast();

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      let timer: NodeJS.Timeout;

      const checkForUpdates = () => {
        // Make an AJAX request to the server to check for updates
        fetch(`${environment.BACKEND_API}/api/get_version`)
          .then((response) => response.json())
          .then((data) => {
            if (data.version !== "Unknown") {
              const apiVersionString = localStorage.getItem("apiVersion");

              const apiVersion =
                apiVersionString && apiVersionString !== "undefined"
                  ? JSON.parse(apiVersionString)
                  : null;

              if (apiVersion) {
                // Compare app versions and reload app if they are different
                if (apiVersion.version !== data.version) {
                  toast({
                    title: "New updates detected",
                    description: "App auto-restarts in 3 seconds",
                    status: "info",
                    duration: 5000,
                    position: "top",
                    isClosable: true,
                  });

                  // Set new api version
                  localStorage.removeItem("apiVersion");
                  localStorage.setItem("apiVersion", JSON.stringify(data));
                  // Reload the page and ignore the cache
                  timer = setTimeout(() => {
                    window.location.reload();
                  }, 5000);
                }
              } else {
                // If no api version available in localStorage, set it and do not reload app
                localStorage.removeItem("apiVersion");
                localStorage.setItem("apiVersion", JSON.stringify(data));
              }
            }
          })
          .catch((error) => {
            console.error("Error checking for api version:", error);
          });
      };

      // Check for updates every 1 minutes (60,000 milliseconds)
      const intervalId = setInterval(checkForUpdates, 60000);

      // Cleanup the interval when the component unmounts
      return () => {
        clearInterval(intervalId);
        clearTimeout(timer);
      };
    }
  }, [toast]);

  return null;
}

export default UpdateChecker;
