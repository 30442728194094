import { useNavigate } from "react-router-dom";
import { Grid } from "@chakra-ui/react";
import SuggestionTitle from "./SuggestionTitle";
import SuggestionType from "./SuggestionType";
import SuggestionYear from "./SuggestionYear";
import { useContext } from "react";
import { PapersContext } from "views/papers";

interface SuggestionProps {
  title: string | undefined;
  type: string | undefined;
  year: number | undefined;
  color: string | undefined;
  id: string | undefined;
}

function Suggestion({ title, type, year, color, id }: SuggestionProps) {
  const navigate = useNavigate();
  const { showEmbeddingControls } = useContext(PapersContext);

  return (
    <Grid
      templateColumns="1fr auto"
      templateRows="repeat(2, 1fr)"
      rowGap={1}
      columnGap={2}
      gap={1}
      py={3.5}
      px={3.5}
      w={"full"}
      onClick={() => !showEmbeddingControls && navigate(`/papers/${id}`)}
    >
      <SuggestionTitle title={title} />
      <SuggestionType type={type} color={color} />
      <SuggestionYear year={year} />
    </Grid>
  );
}

export default Suggestion;
