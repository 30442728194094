import { Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import { IconType } from "react-icons";

interface SidebarTitleProps {
  text: string;
  icon?: IconType;
  color?: string;
}
export default function SidebarTitle({ text, icon, color }: SidebarTitleProps) {
  const headerTextColor = useColorModeValue(
    "highlight.primary",
    "secondary.400"
  );
  const useColor = color ?? headerTextColor;

  return (
    <Flex gap={2} align={"center"}>
      {icon && (
        <Icon
          as={icon}
          color={useColor}
          boxSize={[null, null, null, "12px", "12px", "14px"]}
        />
      )}
      <Text
        color={color}
        fontSize={[null, null, null, "12px", "12px", "14px"]}
        fontWeight={"500"}
      >
        {text}
      </Text>
    </Flex>
  );
}
