import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Flex,
  Icon,
  Link,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { TiDelete } from "react-icons/ti";

export type ElementItemProps = {
  assay_id?: string;
  name?: string;
  id?: string;
  title?: string;
  compound_id?: string;
  compound_name?: string;
  selected_feature?: string;
};
type ElementType = "COMPOUND" | "LITERATURE" | "ASSAY" | "TREEOFLIFE";

function ElementTab({
  item,
  elementType,
  deleteElement,
}: {
  item: ElementItemProps;
  elementType: ElementType;
  deleteElement: (content: {
    elementId: string | undefined;
    selected_feature?: string | undefined;
  }) => void;
}) {
  const hoverColor = useColorModeValue("red.300", "red.300");
  const [show, setShow] = useState<boolean>(false);
  const renderContent = () => {
    switch (elementType) {
      case "COMPOUND":
        return {
          deleteIconText: "Delete Compound",
          text: item.name,
          link: `/compounds/${item.compound_id}`,
          onRemoveElement() {
            deleteElement({ elementId: item.compound_id });
          },
        };
      case "LITERATURE":
        return {
          deleteIconText: "Delete Literature",
          text: item.title,
          link: `/papers/${item.id}`,
          onRemoveElement() {
            deleteElement({ elementId: item.id });
          },
        };
      case "ASSAY":
        return {
          deleteIconText: "Delete Assay",
          text: item.name,
          link: `/assays/${item.assay_id}`,
          onRemoveElement() {
            deleteElement({ elementId: item.assay_id });
          },
        };
      case "TREEOFLIFE":
        const aliasedFeature =
          item.selected_feature === "indication"
            ? "assay_hits"
            : item.selected_feature;

        return {
          deleteIconText: "Delete Tree of life",
          text: `compound: ${item.name}, feature: ${aliasedFeature}`,
          link: `/graph?compound=${item.compound_id}&feature=${aliasedFeature}`,
          onRemoveElement() {
            deleteElement({
              elementId: item.compound_id,
              selected_feature: item.selected_feature,
            });
          },
        };
    }
  };

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      position={"relative"}
    >
      <Link
        h={"full"}
        as={RouterLink}
        to={renderContent().link}
        _hover={{
          textDecoration: "none",
          color: hoverColor,
        }}
      >
        <Text
          fontSize={"13px"}
          fontWeight={"500"}
          color={"blue.500"}
          lineHeight={1.3}
        >
          - {renderContent().text}
        </Text>
      </Link>
      {show && (
        <Tooltip
          label={renderContent().deleteIconText}
          placement="right"
          hasArrow
          bg="gray.200"
          color="gray.700"
        >
          <Flex position="absolute" right={1} align={"center"}>
            <Icon
              as={TiDelete}
              cursor={"pointer"}
              boxSize={5}
              color={"gray.500"}
              _hover={{
                color: "red.500",
              }}
              p={0}
              m={0}
              onClick={(e) => {
                e.stopPropagation();
                renderContent().onRemoveElement();
              }}
            />
          </Flex>
        </Tooltip>
      )}
    </Box>
  );
}

export default ElementTab;
