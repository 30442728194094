import { Flex } from "@chakra-ui/react";
import Header from "./Header";
import Steps from "./Steps";

export default function Content() {
  return (
    <Flex direction={"column"} px={"auto"} py={"220px"} bg={"secondary.700"}>
      <Header />
      <Steps />
    </Flex>
  );
}
