import {
  Flex,
  Grid,
  GridItem,
  HStack,
  Link,
  Tag,
  TagLabel,
  Text,
} from "@chakra-ui/react";
import ExternalResourceProps from "models/compounds/ExternalResource";
import { Link as RouterLink } from "react-router-dom";

interface ExternalSourcesListProps {
  resources: ExternalResourceProps[];
}

export default function ExternalSourcesList({
  resources,
}: ExternalSourcesListProps) {
  if (!!resources) {
    return (
      <Grid templateColumns={`repeat(3, 1fr)`} gap={2}>
        {resources.map((resource, index) => {
          return (
            <GridItem key={"resource" + index}>
              <HStack spacing={2}>
                <Text fontSize={"xs"}>{resource.name}:</Text>
                {resource?.links?.length === 0 && <Tag></Tag>}
                <Flex align={"center"} gap={2} wrap={"wrap"}>
                  {resource?.links?.map((link, index2) => {
                    return (
                      <Tag key={"link-" + index2}>
                        <TagLabel>
                          <Link as={RouterLink} to={link.url}>
                            {link.key}
                          </Link>
                        </TagLabel>
                      </Tag>
                    );
                  })}
                </Flex>
              </HStack>
            </GridItem>
          );
        })}
      </Grid>
    );
  }

  return <Text>No external resources provided</Text>;
}
