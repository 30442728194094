import { axiosPrivate } from "../../api/axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { clearCredentials } from 'redux/features/auth/authSlice';

const useAxiosPrivate = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                if (error?.response?.status === 401) {
                    dispatch(clearCredentials())
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosPrivate.interceptors.response.eject(responseIntercept);
        }
    }, [dispatch])

    return axiosPrivate;
}

export default useAxiosPrivate;