import { useContext, useMemo, useState } from "react";
import { Flex, useBreakpointValue } from "@chakra-ui/react";

import PapersList from "components/papers/PapersList";

import SearchPaperInput from "components/papers/SearchPaperInput";
import { findPaperByQuery } from "components/papers/helpers";

import { CustomScrollBar } from "components/ui/CustomScrollBar";
import { PaperProps } from "models/papers/PaperProps";
import ResultsLength from "components/ui/ResultsLength";
import { PapersContext } from ".";

export interface PaperNetworkViewProps {
  reference_paper: PaperProps;
  similar_papers: PaperProps[];
}

export default function NetworkTools() {
  // States
  const [searchResults, setSearchResult] = useState<PaperProps[] | []>([]);
  const [query, setQuery] = useState("");

  const { selectedTab } = useContext(PapersContext);

  const originalPaper: PaperProps = selectedTab?.reference_paper;
  const chartData = useMemo(
    () => selectedTab?.similar_papers?.concat([originalPaper]).reverse(),
    [selectedTab, originalPaper]
  );

  // Handlers
  const handleSearch = (query: string) => {
    setQuery(query);
    const result = findPaperByQuery(chartData, query);
    setSearchResult(result);
  };

  // Responsiveness: ~992px, ~1280px, ~1536px
  // + 40 (search) + 8 (gap) + 8 (gap) + 32 (length)
  // TODO: provide details
  const NetworkToolsHeight = useBreakpointValue({
    lg: "calc(100vh - 268px)",
    xl: "calc(100vh - 286px)",
    "2xl": "calc(100vh - 304px)",
  });

  const NetworkToolsStyle = {
    height: NetworkToolsHeight,
    padding: "0",
  };

  return (
    <Flex w={"100%"} overflow={"hidden"} direction="column" gap={2}>
      <SearchPaperInput onSearch={handleSearch} />

      {selectedTab.reference_paper.id && (
        <Flex direction="column" gap={2}>
          {/* references list */}
          <CustomScrollBar style={NetworkToolsStyle}>
            <PapersList
              data={query ? searchResults : chartData}
              originalPaper={originalPaper}
            />
          </CustomScrollBar>

          {/* nbr of references */}
          {!!chartData?.length && (
            <ResultsLength
              length={query ? searchResults?.length : chartData?.length}
              item="paper"
            />
          )}
        </Flex>
      )}
    </Flex>
  );
}
