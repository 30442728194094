import { Flex, Text, Icon, Button } from "@chakra-ui/react";
import { IoTrashBin } from "react-icons/io5";

interface DeleteChatsProps {
  onDelete: () => void;
  isDisabled: boolean;
}

function DeleteChats({ onDelete, isDisabled }: DeleteChatsProps) {
  return (
    <Button
      size="sm"
      leftIcon={<Icon as={IoTrashBin} boxSize={"13px"} />}
      colorScheme="secondary"
      variant="outline"
      _hover={{
        borderColor: "red.600",
        color: "red.600",
      }}
      transition="all 0.5s ease"
      onClick={onDelete}
    >
      Delete all chats
    </Button>
  );
}

export default DeleteChats;
