import { Text, useColorModeValue } from "@chakra-ui/react";
import { GetGradients } from "utils/gradients";

interface ResultsLengthProps {
  length: number;
  item: string;
  [key: string]: string | number;
}

export default function ResultsLength({
  length,
  item,
  ...props
}: ResultsLengthProps) {
  // Theme
  const color = useColorModeValue("gray.600", "gray.400");
  const { emGradient, maskGradient } = GetGradients();

  return (
    <Text
      w={"fit-content"}
      fontSize={[null, null, null, "10px", "11px", "12px"]}
      fontWeight={"500"}
      lineHeight={"1.3"}
      color={color}
      borderRadius={"30px"}
      transition="all ease .2s"
      py={2}
      px={4}
      position={"relative"}
      _before={{
        content: "''",
        position: "absolute",
        inset: "0",
        borderRadius: "30px",
        padding: "2px",
        background: emGradient,
        WebkitMask: maskGradient,
        WebkitMaskComposite: "xor",
        maskComposite: "exclude",
        pointerEvents: "none",
      }}
      {...props}
    >
      {length} {item}
      {length > 1 ? "s" : ""} found
    </Text>
  );
}
