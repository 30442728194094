import { Button, useColorModeValue } from "@chakra-ui/react";

interface SubmitButtonProps {
  submitting: boolean;
}

export default function SubmitButton({ submitting }: SubmitButtonProps) {
  // theme
  const submitColorButton = useColorModeValue("neutral.100", "dark.800");

  return (
    <Button
      bg={"highlight.primary"}
      color={submitColorButton}
      border={"none"}
      _hover={{ opacity: 0.9 }}
      borderRadius={"6px"}
      fontSize={"14px"}
      type="submit"
      mt={"30px"}
      w={"fit-content"}
      ml={"auto"}
      isLoading={submitting}
      loadingText="submitting..."
      _loading={{ color: "neutral.100" }}
    >
      Submit
    </Button>
  );
}
