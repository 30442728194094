export function indicationToColor(str: string) {
  const colors = [
    "#B1E89E",
    "#C3E6CB",
    "#CBF2FF",
    "#ADD8E6",
    "#A1D3FF",
    "#A1E8E5",
    "#52CEBE",
    "#9DB3FF",
    "#A1A1E8",
    "#B7A8F1",
    "#C5A1FF",
    "#FFA1D3",
    "#FFC0CB",
    "#df8e98",
    "#FFA1A1",
    "#FFC1A1",
    "#FFD59D",
  ];
  return hashColor(str, colors);
}

export function categoryToColor(str: string) {
  const colors = [
    "#f58d42",
    "#7c5b20",
    "#6e706b",
    "#5a8e78",
    "#6c4d7b",
    "#47697d",
    "#f4aa4b",
    "#4c8257",
    "#886b53",
    "#c0738f",
    "#60538f",
    "#8f6c5f",
  ];
  return hashColor(str, colors);
}

function hashColor(str: string, colors: string[]) {
  const hash = [...str].reduce((h, c) => c.charCodeAt(0) + ((h << 5) - h), 0);
  const colorIndex = Math.abs(hash) % colors.length;
  return colors[colorIndex];
}
