import { useOutletContext } from "react-router-dom";
import { Box, Card, Flex, useTheme, useColorModeValue } from "@chakra-ui/react";

import Sources from "./Sources";
import Molecules from "./Molecules";
import FollowUpQuestions from "./FollowUpQuestions";
import FormattedText from "components/ui/FormattedText";
import { ConversationProps } from "models/chat/MessageProps";

interface BotMessageProps {
  botResponse: ConversationProps;
}

export default function BotMessage({ botResponse }: BotMessageProps) {
  // Context
  const { handleSendQuestion }: { handleSendQuestion: (q: string) => void } =
    useOutletContext();

  const currentMessageIndex = botResponse.messages.length - 1;
  const hasError = botResponse.messages[currentMessageIndex]?.ai?.includes(
    "I'm currently experiencing technical difficulties"
  );

  const followupQuestions: string[] | undefined =
    botResponse?.followup_questions;
  const compounds: string[] | undefined = botResponse?.compounds;
  const sources: { title: string; id: string }[] | undefined =
    botResponse?.sources;

  // Theme
  const { colors } = useTheme();
  const bg = useColorModeValue("whiteAlpha.600", "dark.700");

  return (
    <Box width={"fit-content"}>
      <Card
        border={hasError ? `1px solid ${colors.orange[400]}` : ""}
        boxShadow={hasError ? "none" : "base"}
        bg={bg}
        p={3}
        borderRadius={"6px"}
        position="relative"
        color={hasError ? "orange.400" : ""}
      >
        <Flex h={"full"} w={"full"} direction={"column"} gap={1.5}>
          {botResponse?.messages[currentMessageIndex]?.ai
            ?.split("\n")
            .filter(Boolean)
            .map((messageItem, index) => (
              <FormattedText key={index} text={messageItem} />
            ))}
        </Flex>

        {botResponse && (
          <>
            <Molecules compounds={compounds} />
            <Sources sources={sources} />
            <FollowUpQuestions
              questions={followupQuestions}
              onSendFollowUpQuestion={handleSendQuestion}
            />
          </>
        )}
      </Card>
    </Box>
  );
}
