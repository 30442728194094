interface FreqObj {
  [key: string]: number;
}

interface StylesObject {
  [key: string]: string;
}

// count item occurence
function FrequencyObject(arr: string[]) {
  return arr.reduce((acc: FreqObj, item: string) => {
    acc[item] = (acc[item] || 0) + 1;
    return acc;
  }, {});
}

// echarts-format data
export function transformDataForChart(arr: string[] | []) {
  return Object.keys(FrequencyObject(arr)).map((key) => ({
    name: key,
    value: +FrequencyObject(arr)[key],
  }));
}

export function stylesToString(stylesObj: StylesObject) {
  let styleString = "";

  for (const property in stylesObj) {
    if (stylesObj.hasOwnProperty(property)) {
      styleString += `${property}: ${stylesObj[property]}; `;
    }
  }

  return styleString.trim();
}

export function savedAt() {
  const now = new Date();
  return now.toISOString().split("T")[0];
}

export function hexToRgba(hexCode: string, opacity = 1): string {
  // fallback color
  if (!hexCode) return "black";

  // Remove the # symbol from the hex code
  hexCode = hexCode.replace("#", "");

  // If the hex code is short, expand it to the full 6-digit format
  if (hexCode.length === 3) {
    hexCode = `${hexCode[0]}${hexCode[0]}${hexCode[1]}${hexCode[1]}${hexCode[2]}${hexCode[2]}`;
  }

  // Parse the hex code into RGB values
  const r = parseInt(hexCode.substring(0, 2), 16);
  const g = parseInt(hexCode.substring(2, 4), 16);
  const b = parseInt(hexCode.substring(4, 6), 16);

  // Calculate the opacity value
  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100;
  }

  // Return the RGBA color string
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
