import { Flex, Heading, Text } from "@chakra-ui/react";

function Headers() {
  return (
    <Flex direction={"column"} gap={2}>
      <Heading
        size={[null, null, null, "md", "lg", "lg"]}
        color={"secondary.100"}
      >
        Be AI empowered!
      </Heading>
      <Text size={[null, null, null, "sm", "md", "md"]} color={"secondary.200"}>
        Scientific discovery awaits you. Request access today!
      </Text>
    </Flex>
  );
}

export default Headers;
