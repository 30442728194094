import { Flex, Heading, Text } from "@chakra-ui/react";
import GetStartedButton from "components/home/GetStartedButton";

export default function Content() {
  return (
    <Flex
      direction={"column"}
      justify={"center"}
      textAlign={"left"}
      h={[
        null,
        null,
        null,
        "calc(100vh - 140px)",
        "calc(100vh - 140px)",
        "calc(100vh - 160px)",
      ]}
      gap={"60px"}
    >
      {/* Heading content */}
      <Flex direction={"column"} w={"full"} gap={10}>
        {/* welcome heading */}
        <Flex direction={"column"} gap={2}>
          <Text
            fontSize={[null, null, null, "20px", "22px", "24px"]}
            color={"secondary.200"}
            letterSpacing={3}
            lineHeight={1}
          >
            - Welcome to
          </Text>
          <Heading
            fontSize={[null, null, null, "78px", "86px", "94px"]}
            lineHeight={1}
            letterSpacing={-1}
          >
            MoleculeLake
          </Heading>
        </Flex>

        <Flex direction={"column"} gap={3}>
          {/* secondary heading */}
          <Heading color={"gray.200"} size="lg" letterSpacing={4}>
            Accelerating scientific discovery
          </Heading>

          {/* paragraph */}
          <Text
            w={"70%"}
            maxW="600px"
            color={"gray.300"}
            letterSpacing={0.5}
            lineHeight={"1.5"}
          >
            Taking advantage of the latest advancements in generative AI,
            MoleculeLake enables you to intelligently explore privileged life
            science data and the entire body of scientific literature.
          </Text>
        </Flex>
      </Flex>

      <GetStartedButton />
    </Flex>
  );
}
