import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Tag,
  Image,
  Flex,
  Tooltip,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { CompoundProps } from "models/compounds/CompoundProps";
import {
  adjustCompoundName,
  getAliases,
  getIndications,
  getInfoTypesHeatmap,
  getSmilesImageSrc,
  stringToColor,
} from "views/compounds/helpers";
import DataDensityBar from "./assets/DataDensityChart";
import HelperModal from "./HelperModal";
import { datadensity_helper } from "data/helpers/datadensity";
import { CustomScrollBar } from "components/ui/CustomScrollBar";

interface CompoundsTableProps {
  items: CompoundProps[];
  similarityScores?: string[];
}

// Styles
const tableStyle = {
  height: "100%",
  width: "100%",
  padding: "0",
};

export default function CompoundsTable({
  items,
  similarityScores,
}: CompoundsTableProps) {
  // Theme
  const compoundBg = useColorModeValue("gray.100", "dark.600");

  const navigate = useNavigate();
  const cols = useMemo(
    () => ["Molecule", "ID", "Name", "Aliases", "Assay Hits", "Data Density"],
    []
  );

  const cleanitems = useMemo(
    () => items.filter((item) => item !== undefined),
    [items]
  );

  return (
    <TableContainer>
      <CustomScrollBar style={tableStyle}>
        <Table variant="simple" size="sm">
          {/* Head */}
          <Thead>
            <Tr>
              {cols.map((col, index) => {
                return (
                  <Th
                    key={"cpmds-table-header-" + index}
                    isTruncated={col === "Aliases" || col === "Assay Hits"}
                  >
                    {col}
                    <>
                      {col === "Data Density" && (
                        <HelperModal
                          title={"Chemical Properties"}
                          data={datadensity_helper}
                        />
                      )}
                    </>
                  </Th>
                );
              })}
              {similarityScores && <Th isTruncated>Similarity Score</Th>}
            </Tr>
          </Thead>

          {/* Body */}
          <Tbody>
            {cleanitems.map((cmpd, index) => {
              return (
                <Tr
                  key={"cmpd-row" + index}
                  _hover={{ cursor: "pointer", bg: compoundBg }}
                  transition="background 0.3s ease"
                  onClick={() => navigate(`/compounds/${cmpd?.compound_id}`)}
                >
                  {/* Molecule */}
                  <Smiles cmpd={cmpd} />

                  {/* ID */}
                  <Td maxW={"15%"} fontSize={"12px"} p={1.5}>
                    {cmpd?.compound_id}
                  </Td>

                  {/* Name */}
                  <Td
                    fontSize={[null, null, null, "12px", "14px", "14px"]}
                    maxW={"15%"}
                    textTransform={"capitalize"}
                    p={1.5}
                    isTruncated
                  >
                    {adjustCompoundName(cmpd)}
                  </Td>

                  {/* Aliases */}
                  <Td maxW={"10%"} p={1.5} isTruncated>
                    <Flex w={"fit-content"} direction={"column"} gap={1}>
                      {getAliases(cmpd).map((alias, index) => {
                        return (
                          <Tag
                            key={"alias-" + index}
                            fontSize={[
                              null,
                              null,
                              null,
                              "12px",
                              "14px",
                              "14px",
                            ]}
                          >
                            {alias}
                          </Tag>
                        );
                      })}
                    </Flex>
                  </Td>

                  {/* Indication */}
                  <Td maxW={"20%"} isTruncated p={1.5}>
                    <Flex wrap={"wrap"} rowGap={0.5} columnGap={0.5}>
                      {getIndications(cmpd).map((indication, index) => {
                        return (
                          <Tooltip
                            key={"ind-" + index}
                            label={indication}
                            fontSize={[
                              null,
                              null,
                              null,
                              "10px",
                              "12px",
                              "12px",
                            ]}
                          >
                            <Tag
                              key={"indication-" + index}
                              fontSize={[
                                null,
                                null,
                                null,
                                "10px",
                                "12px",
                                "12px",
                              ]}
                              bg={stringToColor(indication)}
                            >
                              {indication.length < 21
                                ? indication
                                : indication.slice(0, 20) + ".."}
                            </Tag>
                          </Tooltip>
                        );
                      })}
                    </Flex>
                  </Td>

                  {/* Data density */}
                  <Td maxW={"15%"} p={1.5}>
                    <DataDensityBar data={getInfoTypesHeatmap(cmpd)} />
                  </Td>

                  {/* Similarity Score */}
                  {similarityScores && (
                    <Td isTruncated>
                      <Text as="b">{similarityScores[index]}</Text>
                    </Td>
                  )}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </CustomScrollBar>
    </TableContainer>
  );
}

interface SmilesProps {
  cmpd: CompoundProps;
}

function Smiles({ cmpd }: SmilesProps) {
  return (
    <Td w={"20%"}>
      <Image
        src={getSmilesImageSrc(cmpd)}
        boxSize="100%"
        maxHeight={"120px"}
        maxWidth={"120px"}
        objectFit={"cover"}
        fallbackSrc="https://via.placeholder.com/120x120.png/FFFFFF"
        alt={cmpd?.chemical_props?.formula}
        boxShadow={"md"}
        borderRadius={"6px"}
        mx={"auto"}
      />
    </Td>
  );
}
