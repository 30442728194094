import { useContext, useEffect, useState } from "react";
import { Card, Flex, useColorModeValue } from "@chakra-ui/react";

import BotAvatar from "./BotAvatar";
import { ChatbotContext } from "views/chatbot";
import LoadingDots from "components/ui/LoadingDots";
import { stepIndex } from "views/chatbot/helpers";
import FormattedText from "components/ui/FormattedText";

import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { useSelector } from "react-redux";

export default function LoadingBubble() {
  // Context
  const { waitingOnBot } = useContext(ChatbotContext);

  // States
  const [notifications, setNotifications] = useState<string[]>([]);
  const [partialReply, setPartialReply] = useState<string[]>([]);

  // received at least one notification
  const hasNotifs: boolean = !!notifications?.length;

  // Auth
  const { user } = useSelector(selectCurrentAuthData);

  // websocket connection handler
  function handleNotifications() {
    const HOST_URL = window.location.hostname;
    const WS_URL =
      !process.env.NODE_ENV || process.env.NODE_ENV === "development"
        ? `ws://127.0.0.1:8000/ws/chat/${user?.id}/`
        : `wss://${HOST_URL}/ws/chat/${user?.id}/`;

    const socket = new WebSocket(WS_URL);

    socket.onopen = () => {
      setNotifications([]);
      setPartialReply([]);
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      const action = data.action;
      const message = data.message;

      if (action === "notification") {
        setNotifications((list) => [...list, message]);
      } else if (action === "reply") {
        setPartialReply((list) => [...list, message]);
      }
    };

    socket.onclose = () => {};
  }

  // Theme
  const bg = useColorModeValue("whiteAlpha.600", "dark.700");

  // enable user engagement feature while waiting on bot
  // useEffect(() => {
  //   if (waitingOnBot) handleNotifications();

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [waitingOnBot]);

  return (
    <Flex w="100%" mt={4} mb={8} minH={"50px"}>
      <BotAvatar />

      <Card p={3} bg={bg} borderRadius={"6px"} position="relative">
        {/* bot reply without molecules or sources */}
        {/* <Flex w={"full"} mb={!!partialReply?.length ? 5 : 0}>
          {!!partialReply?.length && (
            <FormattedText text={partialReply?.at(-1) ?? ""} />
          )}
        </Flex> */}

        {/* notifications */}
        <Flex h={"full"} w={"full"} gap={3} align={"center"}>
          {/* dots indicators */}
          <LoadingDots step={hasNotifs ? stepIndex(notifications.at(-1)) : 0} />

          {/* notification message */}
          {/* <FormattedText text={notifications?.at(-1) || ""} /> */}
        </Flex>
      </Card>
    </Flex>
  );
}
