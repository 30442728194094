import { Flex, Text, useColorModeValue, useTheme } from "@chakra-ui/react";
import { hexToRgba } from "utils/helpers";

interface DisclaimerProps {
  content: string;
  type: string;
  [key: string]: string | number;
}

function Disclaimer({ content, type = "info", ...props }: DisclaimerProps) {
  // Theme
  const { colors } = useTheme();
  const disclaimerColor = useColorModeValue(
    type === "warning" ? "gray.600" : "gray.500",
    type === "warning" ? "orange.300" : "gray.400"
  );
  const disclaimerBorder = useColorModeValue(
    type === "warning" ? "orange.300" : "gray.400",
    type === "warning" ? "orange.300" : "gray.600"
  );
  const disclaimerBg = useColorModeValue(
    type === "warning"
      ? hexToRgba(colors.orange[300], 0.06)
      : hexToRgba(colors.highlight.neutral[200], 0.5),
    type === "warning" ? hexToRgba(colors.orange[300], 0.15) : "dark.700"
  );

  return (
    <Flex
      bg={disclaimerBg}
      borderColor={disclaimerBorder}
      borderWidth={1}
      borderStyle={"dashed"}
      borderRadius={"6px"}
      p={4}
      {...props}
    >
      <Text
        fontSize={"12px"}
        color={disclaimerColor}
        whiteSpace="pre-line"
        lineHeight={1.4}
      >
        {content}
      </Text>
    </Flex>
  );
}

export default Disclaimer;
