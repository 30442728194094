import { Flex, Spinner, Text, useColorModeValue } from "@chakra-ui/react";

interface MessageProps {
  message?: string;
  small?: boolean;
  pt?: number;
}

export default function Loading({ message, small, ...props }: MessageProps) {
  // Theme
  const messageColor = useColorModeValue("gray.600", "gray.400");
  const color = useColorModeValue("gray.300", "gray.100");

  return (
    <Flex
      w={"full"}
      h={"full"}
      justify={"center"}
      align={"center"}
      gap={3}
      {...props}
    >
      <Spinner
        thickness={"2px"}
        boxSize={small ? "12px" : "20px"}
        speed="1.5s"
        color={color}
        emptyColor="gray.600"
      />
      {message && (
        <Text fontWeight={"600"} color={messageColor} fontSize={"14px"}>
          {message}
        </Text>
      )}
    </Flex>
  );
}
