import { useContext } from "react";
import { Flex } from "@chakra-ui/react";

import Loading from "components/ui/Loading";
import HumanMessage from "./HumanMessage";
import LoadingBubble from "./LoadingBubble";
import NewChatHeading from "./NewChatHeading";
import NewChatFeature from "./NewChatFeature";
import { ChatbotContext } from "views/chatbot";
import { useOutletContext } from "react-router-dom";
import { CustomScrollBar } from "components/ui/CustomScrollBar";
import { ft1, ft2, ft3 } from "assets/features";

// scrollbar style
const scrollBarStyle = {
  height: "100%",
  width: "100%",
  padding: "0 6px 0 0",
};

interface FeatureProps {
  text: string;
  delay: string;
  icon: string;
}

const chatFeatures: FeatureProps[] = [
  { text: "Ask Complex Questions", delay: "0s", icon: ft1 },
  { text: "AI-enabled Analysis", delay: "0.2s", icon: ft2 },
  { text: "Get Great Answers", delay: "0.4s", icon: ft3 },
];

function NewChatPanel() {
  // Contexts
  const { waitingOnBot, loadingSessions } = useContext(ChatbotContext);
  const { questionOnWait }: { questionOnWait: string | undefined } =
    useOutletContext();

  return (
    <>
      {/* submit qst in new chat */}
      {!!waitingOnBot && (
        <Flex
          h={"calc(100% - 84px)"}
          w={"900px"}
          maxW={"90%"}
          mx={"auto"}
          py="24px"
          align={"center"}
          justify={"center"}
        >
          {/* user question and notifications/bot partial reply */}
          <Flex w={"full"} h={"95%"} justify={"flex-end"} align={"flex-end"}>
            <CustomScrollBar style={scrollBarStyle}>
              <Flex direction={"column"} px={2}>
                <Flex w="100%" mt={2} pr={2} pl={"48px"}>
                  <HumanMessage questionOnWait={questionOnWait} />
                </Flex>
                <LoadingBubble />
              </Flex>
            </CustomScrollBar>
          </Flex>
        </Flex>
      )}

      {/* welcoming content */}
      {!waitingOnBot && !loadingSessions && <Welcome />}

      {/* transition phase - loading */}
      {!waitingOnBot && loadingSessions && (
        <Flex
          w={"full"}
          h={"calc(100% - 84px)"}
          justify={"center"}
          align={"center"}
        >
          <Loading />
        </Flex>
      )}
    </>
  );
}

export default NewChatPanel;

function Welcome() {
  return (
    <Flex
      direction={"column"}
      align={"center"}
      justify={"center"}
      gap={5}
      w={"900px"}
      maxW={"75%"}
      mx={"auto"}
      py="24px"
      h={"calc(100% - 84px)"}
    >
      {/* Heading with icon */}
      <NewChatHeading />

      {/* chat features */}
      <Flex w={"full"} gap={2} mt={8} justify={"center"}>
        {chatFeatures.map((ft: FeatureProps, idx: number) => (
          <NewChatFeature
            key={idx}
            value={ft.text}
            delay={ft.delay}
            icon={ft.icon}
          />
        ))}
      </Flex>
    </Flex>
  );
}
