import HelperProps from "./HelperProps";

export const similarsummaryprops_helper: HelperProps[] = [
  {
    title: "Molecule",
    description:
      "2D visual representation of the canonical or isometric SMILES.",
  },
  {
    title: "ID = InChiKey",
    description:
      "The id is the same as the InChiKey of the compound. InChI is a structure-based identifier, strictly unique, and non-proprietary, open source and freely accessible; and InChIKey is a hashed version of InChI which allows for a compact representation and for searching in standard search engines.",
  },
  {
    title: "Name & Aliases",
    description:
      "We try to curate the database and show for name the generic name of a compound. Aliases of a chemical compound refer to the various names or identifiers used to represent that compound across different contexts, disciplines, or databases. Chemical compounds can have multiple names due to differences in nomenclature systems, language variations, historical origins, or specific applications. Aliases can be chemical names, trade names, abbreviations and acronyms.",
  },
  {
    title: "Assay Hits",
    description:
      "Refers to the outcomes or results obtained from a scientific assay or experimental test that indicate a potential positive response or activity",
  },
  {
    title: "Data density",
    description:
      "Represents the amound of data available for the given compound. the fields meassured are 'Pharmacokinetics', 'Pharmacodynamics', 'Molecule', 'Efficacy', 'Safety', 'Synthesis', 'Mechanism of Action', 'Interactions', 'Affinity', and 'Repurposing'. ",
    image: "",
  },
  {
    title: "Similarity Score",
    description:
      "The similarity between original compound and displayed is meassured by the tanimoto similarity score. The score ranges from 0 to 1, with 0 indicating no similarity between the sets and 1 indicating complete similarity. Higher Tanimoto coefficients suggest greater similarity between the sets being compared. \n \ntanimoto_score = |A ∩ B| / |A ∪ B|",
    highlight: ["tanimoto_score = |A ∩ B| / |A ∪ B|"],
  },
];
