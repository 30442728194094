import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";

// store
import { store } from "./redux/store";
import { Provider } from "react-redux";

// theme
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import AppRoutes from "./routes";
import { EmbeddingSocketContextProvider } from "contexts/EmbeddingSocketContext";

import * as serviceWorker from "./serviceWorker";

if (process.env.NODE_ENV === "production") disableReactDevTools();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ChakraProvider theme={theme}>
      <EmbeddingSocketContextProvider>
        <AppRoutes />
      </EmbeddingSocketContextProvider>
    </ChakraProvider>
  </Provider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
