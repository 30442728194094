import {
  Flex,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Spacer,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import MoreInfo from "./MoreInfo";
import { CustomScrollBar } from "components/ui/CustomScrollBar";

export default function SimilarCompoundsSkeleton() {
  const headerTextColor = useColorModeValue("primary.500", "primary.300");

  // Responsiveness: ~992px, ~1280px, ~1536px
  // NOTE: 480, 495, and 510 are not exact values,
  // picked based on current display resolution.
  // Feel free to adjust them, if you find better solution
  const SimilarCompoundsListHeight = useBreakpointValue({
    lg: "calc(100vh - 480px)",
    xl: "calc(100vh - 495px)",
    "2xl": "calc(100vh - 510px)",
  });

  // Styles
  const SimilarCompoundsListStyle = {
    height: SimilarCompoundsListHeight,
    padding: "0",
  };

  return (
    <Flex direction={"column"} height={"500px"} gap={4}>
      <Flex align={"center"} w={"full"}>
        <MoreInfo />
        <Text
          color={headerTextColor}
          fontSize={"14px"}
          fontWeight={"500"}
          ml={2}
        >
          Manage Compounds
        </Text>
        <Spacer />
        <Text color={"primary.600"} fontSize="12px" px={1}>
          -/-
        </Text>
      </Flex>

      <Skeleton borderRadius={5} height={"25px"} mb={1} />

      <CustomScrollBar style={SimilarCompoundsListStyle}>
        <Flex direction={"column"} gap={2}>
          {Array.from({ length: 10 }, (_, index) => (
            <Flex alignItems="center" height={"23px"} key={index} gap={2}>
              <SkeletonCircle
                width={"14px"}
                height={"14px"}
                borderRadius={"50%"}
              />
              <SkeletonText w={"full"} noOfLines={1} skeletonHeight={3} />
            </Flex>
          ))}
        </Flex>
      </CustomScrollBar>
    </Flex>
  );
}
