import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

export interface EmbeddingState {
  progress: number | null;
  error: string;
}

const initialState = {
  progress: null,
  error: "",
};

const embeddingSlice = createSlice({
  name: "embedding",
  initialState,
  reducers: {
    setProgress: (state, action) => {
      state.progress = action.payload && Math.round(action.payload);
      state.error = "";
    },
    setError: (state, action) => {
      state.progress = null;
      state.error = action.payload;
    },
    resetProgress: (state) => {
      state.progress = null;
      state.error = "";
    }
  },
});

export const { setProgress, setError, resetProgress } = embeddingSlice.actions;

export default embeddingSlice.reducer;

const selectEmbeddingState = (state: { embedding: EmbeddingState }) =>
  state.embedding;

export const selectCurrentEmbeddingData = createSelector(
  [selectEmbeddingState],
  (embedding) => ({
    progress: embedding.progress,
    error: embedding.error,
  })
);
