import { Button } from "@chakra-ui/react";

interface SendButtonProps {
  sending: boolean;
}

function SendButton({ sending }: SendButtonProps) {
  return (
    <Button
      px={10}
      py={5}
      letterSpacing={".03rem"}
      transition={"0.25s ease all"}
      bg={"whiteAlpha.300"}
      color={"neutral.100"}
      _hover={{ backgroundColor: "whiteAlpha.500" }}
      _active={{ backgroundColor: "whiteAlpha.600" }}
      w={"fit-content"}
      borderRadius={"12px"}
      type="submit"
      isLoading={sending}
      loadingText="sending..."
      _loading={{ color: "neutral.100" }}
    >
      Send
    </Button>
  );
}

export default SendButton;
