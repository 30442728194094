import { QuestionProps } from "./Content";

export const questions: QuestionProps[] = [
  {
    title: "What is your data protection policy? ",
    description:
      "We are committed to protecting your personal information and right to privacy governed by the principles of General Data Protection Regulation (GDPR). If you have any questions or concerns about our practices with regards to your personal information, please see our privacy policy or contact us on connect@vitafluence.ai. ",
  },
  {
    title: "How can I give feedback?",
    description:
      "Find the feedback button in the centre right of the application. Click this buttons and provide your feedback, either to suggest a new feature, give a compliment, or report an error.",
  },

  {
    title: "Can I add my own research papers?",
    description:
      "For the time being, only papers already collected by our team can be added in the scientific research space. Users will guide the continued selection of scientific papers embedded to use the large language model. Soon, institutions will be able to add their own proprietary data and list of research literature. Scientific papers exist as literature in Molecule-Lake. However, not all literature is used in the model. By selecting literature in your area of interest, you can customize the model with aditional knowledge from selected literature in your area of interest. This process is fast and efficient allowing you to enrich the model in just a few clicks.",
  },
  {
    title: "What is Machine Learning?",
    description:
      "Machine Learning is a branch of artificial intelligence (AI) that focuses on developing algorithms and techniques that enable computers to learn from and make predictions or decisions based on data, without being explicitly programmed to perform specific tasks. In the context of bioresearch, machine learning can be a powerful tool for analysing large and complex biological datasets, extracting meaningful patterns or relationships, and making predictions or classifications.",
  },
  {
    title: "What is an embedding?",
    description:
      "An embedding refers to a way of representing data, such as words or images, in a lower-dimensional space. For instance, in natural language processing (NLP), words from a large vocabulary can be represented as dense vectors in a lower-dimensional space, where similar words are closer together. This representation helps machine learning models understand the relationships between different pieces of data, enabling them to perform tasks like language translation or sentiment analysis more effectively.",
  },
  {
    title: "What is an llm and a transformer?",
    description:
      "A large lenguage model is a neural network architecture that has been trained on vast amounts of text data to understand and generate human-like language. These models consist of multiple layers of interconnected nodes (neurons) that process input data and generate output predictions. Large language models can perform a wide range of natural language understanding and generation tasks. A transformer is a specific type of neural network architecture designed to process sequential data, such as text, with a focus on capturing long-range dependencies effectively compared to traditional recurrent neural networks (RNNs) or convolutional neural networks (CNNs).",
  },
];
