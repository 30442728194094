import { KeyboardEvent, useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Icon,
  Text,
  Input,
  useColorModeValue,
} from "@chakra-ui/react";
import { TbFolderCheck } from "react-icons/tb";
import {
  selectCurrentSavedData,
  setSuccess,
} from "redux/features/saved/savedSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import { environment } from "environments";

interface NewFolderModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function NewFolderModal({
  isOpen,
  onClose,
}: NewFolderModalProps) {
  const { savedFolders } = useSelector(selectCurrentSavedData);
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();

  const inputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // Theme
  const headerColor = useColorModeValue("gray.600", "gray.200");
  const color = useColorModeValue("neutral.100", "dark.800");
  const color2 = useColorModeValue("gray.500", "dark.800");

  // User
  const { user } = useSelector(selectCurrentAuthData);

  const handleCreateFolder = async (newFolderName: string) => {
    setError(null);
    setLoading(false);

    try {
      if (!user) return;
      setLoading(true);

      const response = await axiosPrivate.post(
        `${environment.BACKEND_API}/api/saved_elements`,
        {
          user: user?.id,
          name: newFolderName,
        }
      );

      dispatch(setSuccess([...savedFolders, response.data]));
      onClose();
    } catch (error: any) {
      // NOTE: type not provided for error object
      setError(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent
        alignSelf={"center"}
        p={1}
        w={"fit-content"}
        minW={"412px"}
        minH={"164px"}
        onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
          if (e.key === "Enter") {
            inputRef.current?.value &&
              handleCreateFolder(inputRef.current?.value.trim());
          }
        }}
      >
        <ModalHeader display={"flex"} alignItems={"center"} gap={2}>
          <Icon
            as={TbFolderCheck}
            bg={"highlight.primary"}
            color={color}
            boxSize={"28px"}
            borderRadius={"6px"}
            p={"3px"}
          />
          <Text fontSize={"16px"} color={headerColor} fontWeight={"500"}>
            Create new research folder
          </Text>
        </ModalHeader>
        <ModalBody>
          <Input
            variant="outline"
            placeholder="Enter folder name.."
            _placeholder={{ fontSize: "14px", color: "gray.400" }}
            ref={inputRef}
            bg={"transparent"}
            _focus={{
              outline: "none",
              borderWidth: 1,
              boxShadow: "md",
              overflow: "auto",
            }}
            _focusVisible={{ outline: "none" }}
          />

          {/* Display the error message if exists */}
          {inputRef.current?.value && error && (
            <Text fontSize="12px" fontWeight="400" color="red.500" py={1}>
              {error}
            </Text>
          )}
        </ModalBody>
        <ModalFooter gap={2}>
          <Button
            bg="gray.100"
            color={color2}
            _hover={{ bg: "gray.200" }}
            borderRadius={"100px"}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            minW={"140px"}
            bg={"highlight.primary"}
            color={color}
            border={"none"}
            _hover={{ opacity: 0.9 }}
            isLoading={loading ?? false}
            pointerEvents={loading ? "none" : "auto"}
            loadingText="Creating..."
            borderRadius={"100px"}
            onClick={() => {
              inputRef.current?.value &&
                handleCreateFolder(inputRef.current?.value.trim());
            }}
          >
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
