import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Text, Icon } from "@chakra-ui/react";

import { hexToRgba } from "utils/helpers";
import { PapersContext } from "views/papers";
import { getPaperColor } from "views/compounds/helpers";
import { PaperNetworkViewProps } from "views/papers/NetworkView";

import { TiDelete } from "react-icons/ti";

interface PaperTabProps {
  id: string;
  name: string;
  color: string;
  onCloseTab: (id: string) => void;
  navigateToPaper: () => void;
}

export default function PapersTabsHeader() {
  const navigate = useNavigate();

  const { openedPapers, setOpenedPapers, selectedTab, setSelectedTab } =
    useContext(PapersContext);

  const handleCloseTab = async (paperId: string) => {
    // Closing current selected tab
    if (paperId === selectedTab.reference_paper.id) {
      let navigatingToAfterClosing = `/papers`;

      // Change selected Tab
      let prevSelectedTap: PaperNetworkViewProps | undefined =
        openedPapers.find(
          (pa: PaperNetworkViewProps) => pa.reference_paper.id === paperId
        );

      let indexOfPrevSelectedTab = !!prevSelectedTap
        ? openedPapers.indexOf(prevSelectedTap)
        : -1;

      // check if not the only opened tab left
      if (openedPapers.length >= 2) {
        let isLast = indexOfPrevSelectedTab === openedPapers.length - 1;
        navigatingToAfterClosing = `/papers/${
          openedPapers[indexOfPrevSelectedTab + (isLast ? -1 : +1)]
            .reference_paper.id
        }`;
      } else {
        // go back to papers parent view if the last opened Tab is closed
        setSelectedTab({
          reference_paper: {},
          similar_papers: [],
        });
        sessionStorage.removeItem("openedPapersTabs");
      }
      navigate(navigatingToAfterClosing);
    }

    // Closing other tabs
    let newOpenedTabs = openedPapers.filter(
      (pa: PaperNetworkViewProps) => pa.reference_paper.id !== paperId
    );
    sessionStorage.setItem("openedPapersTabs", JSON.stringify(newOpenedTabs));
    setOpenedPapers(newOpenedTabs);
  };

  return (
    <Flex
      position={"relative"}
      overflowX={"auto"}
      w={"100%"}
      height={[null, null, null, "60px", "65px", "60px"]}
      align={"flex-end"}
    >
      {openedPapers.map((paper: PaperNetworkViewProps, index: number) => (
        <PaperTab
          key={index}
          name={paper.reference_paper.title ?? ""}
          id={paper.reference_paper.id ?? ""}
          color={getPaperColor(paper.reference_paper.paper_type?.toLowerCase())}
          onCloseTab={handleCloseTab}
          navigateToPaper={() =>
            navigate(`/papers/${paper.reference_paper.id}`)
          }
        />
      ))}
    </Flex>
  );
}

function PaperTab({
  name,
  id,
  color,
  onCloseTab,
  navigateToPaper,
}: PaperTabProps) {
  // State
  const [show, setShow] = useState<boolean>(false);

  // Context
  const { selectedTab } = useContext(PapersContext);

  const selected = id === selectedTab.reference_paper.id;

  return (
    <Flex
      cursor={"pointer"}
      align={"center"}
      maxW={"180px"}
      h={selected ? "40px" : "36px"}
      color={color}
      bg={selected ? hexToRgba(color, 0.06) : "transparent"}
      borderColor={selected ? color : hexToRgba(color, 0.2)}
      borderBottomColor={hexToRgba(color, 0.2)}
      borderWidth={1}
      borderRadius={6}
      borderBottomEndRadius={0}
      borderBottomStartRadius={0}
      p={2}
      transition="all ease 0.25s"
      onMouseOver={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      onClick={navigateToPaper}
    >
      {/* Tab name */}
      <Text
        whiteSpace={"nowrap"}
        overflow={"hidden"}
        textOverflow={"ellipsis"}
        fontSize={"12px"}
        fontWeight={"500"}
      >
        {name}
      </Text>

      {/* close tab */}
      {show && (
        <Flex align={"center"}>
          <Icon
            as={TiDelete}
            boxSize={5}
            color={"gray.500"}
            _hover={{
              color: "red.500",
            }}
            p={0}
            m={0}
            onClick={(e) => {
              e.stopPropagation();
              onCloseTab(id);
            }}
          />
        </Flex>
      )}
    </Flex>
  );
}
