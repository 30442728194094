import { ChangeEvent } from "react";
import SearchInput from "components/ui/SearchInput";

interface SearchSimilarCompoundsProps {
  onSearch: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string;
}

function SearchSimilarCompounds({
  onSearch,
  value,
}: SearchSimilarCompoundsProps) {
  return <SearchInput value={value} onChange={onSearch} placeholder="Search" />;
}

export default SearchSimilarCompounds;
