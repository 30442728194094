import { useContext, useMemo } from "react";
import ReactECharts from "echarts-for-react";
import { useTheme, useColorModeValue } from "@chakra-ui/react";

import { stylesToString } from "utils/helpers";
import { getPaperColor } from "views/compounds/helpers";
import { truncateString } from "./helpers";

import { PaperProps } from "models/papers/PaperProps";
import { PaperNetworkViewProps } from "views/papers/NetworkView";
import { PapersContext } from "views/papers";

interface GraphProps {
  onClickNode: (item: echarts.ECElementEvent) => void;
  paperData: PaperNetworkViewProps;
}

export interface Link {
  source: string | undefined;
  target: string;
}

const PaperNetworkStyle = {
  height: "100%",
  width: "100%",
  maxHeight: "560px",
  margin: "auto",
};

function NetworkGraph({ paperData, onClickNode }: GraphProps) {
  // Theme
  const { colors } = useTheme();
  const color = useColorModeValue(colors.gray[600], colors.gray[400]);

  const { highlightedPaper } = useContext(PapersContext);

  const originalPaper: PaperProps = paperData?.reference_paper;

  const chartData = useMemo(
    () =>
      paperData?.similar_papers
        ?.filter((item: PaperProps) => item?.id !== originalPaper?.id)
        ?.concat([originalPaper]),
    [paperData, originalPaper]
  );

  const hasOnlyOriginNode = useMemo(
    () => !paperData?.similar_papers || paperData?.similar_papers.length === 0,
    [paperData]
  );

  function getXPos(idx: number) {
    return Math.floor(
      Math.cos((2 * Math.PI * idx) / (chartData?.length - 1)) * 270
    );
  }

  function getYPos(idx: number) {
    return Math.floor(
      Math.sin((2 * Math.PI * idx) / (chartData?.length - 1)) * 270
    );
  }

  // FIXME: remove ALL un-necessary renders
  const nodes = useMemo(
    () =>
      chartData?.map((item: PaperProps, idx: number) => {
        return {
          id: item.id,
          name: item?.title,
          x: item.id === originalPaper.id ? 0 : getXPos(idx),
          y: item.id === originalPaper.id ? 0 : getYPos(idx),
          value: item.year_published,
          year: item.year_published,
          type: item.paper_type,
          symbolSize: item.id === originalPaper.id ? 50 : 30,
          itemStyle: {
            color: getPaperColor(item.paper_type),
            shadowColor:
              item.id === originalPaper.id && !hasOnlyOriginNode
                ? getPaperColor(item.paper_type)
                : "transparent",
            shadowBlur: item.id === originalPaper.id ? 10 : 0,
            borderType: "dashed",
            borderWidth: item.id === highlightedPaper.id ? 2 : 0,
            borderColor:
              item.id === highlightedPaper.id ? colors.red[500] : "transparent",

            emphasis: {
              borderColor: colors.red[500],
              borderWidth: 2,
            },
            animation: true,
            animationDuration: 1000,
            animationEasing: "quinticInOut",
          },
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartData, originalPaper, hasOnlyOriginNode, highlightedPaper]
  );

  // const [updatedNodes, setUpdatedNodes] = useState(nodes);

  const targets: string[] = paperData?.similar_papers?.map(
    (paper: PaperProps) => paper.id ?? ""
  );

  const links: Link[] = useMemo(
    () =>
      targets.map((id: string) => {
        return {
          source: originalPaper.id,
          target: id,
        };
      }),
    [targets, originalPaper]
  );

  const option = {
    tooltip: {
      formatter: (params: { data: { name: string; year: number } }) => {
        if (!params.data.name || !params.data.year) return;
        const tooltipTitleStyle = {
          "min-width": "240px",
          "max-width": "300px",
          "font-size": "14px",
          "white-space": "normal",
          "line-height": "1.35",
        };

        const tooltipStyle = {
          display: "flex",
          "flex-direction": "column",
          "justify-content": "center",
          gap: "6px",
          padding: "12px",
        };

        const spacing = {
          margin: "4px 0",
          "font-size": "12px",
        };

        const published = {
          "font-weight": "bold",
        };

        const origin = {
          "font-weight": "bold",
          "font-family": "Arial",
          color: "red",
          "font-size": "12px",
        };
        const inlineTooltipStyle = stylesToString(tooltipStyle);
        const inlineSpacing = stylesToString(spacing);
        const publishedYear = stylesToString(published);
        const originStyle = stylesToString(origin);

        const inlineTooltipTitleStyle = stylesToString(tooltipTitleStyle);

        const tooltipContent = [
          `<span style="${inlineTooltipTitleStyle}">${params.data.name}</span>`,
          `<span style="${inlineSpacing}"><span style="${publishedYear}">Published:</span> ${params.data.year}</span>`,
          params.data.name === originalPaper.title && !hasOnlyOriginNode
            ? `<span style="${originStyle}">Origin paper</span>`
            : null,
        ].join("");

        return `<Flex style="${inlineTooltipStyle}">${tooltipContent}</Flex>`;
      },
      confine: true,
      padding: 0,
    },
    series: [
      {
        name: "Network Graph",
        type: "graph",
        layout: "none",
        roam: "move",
        draggable: true,
        label: {
          show: true,
          position: "top",
          fontSize: 10,
          distance: 2,
          formatter: (params: { data: { name: string; year: number } }) =>
            `${truncateString(params.data.name, 30)}`,

          fontFamily: "Arial",
          backgroundColor: "transparent",
          color: color,
          borderColor: "transparent",
          borderWidth: 0.5,
          padding: [2, 3],
          borderRadius: 2,
        },
        lineStyle: {
          color: "source",
          curveness: 0.2,
        },
        emphasis: {
          scale: false,
          focus: "self",
        },
        data: nodes,
        links: links,
      },
    ],
    animation: true,
    animationDuration: 1000,
    animationEasingUpdate: "quinticInOut",
  };

  const onEvents = {
    click: (params: echarts.ECElementEvent) => {
      if (params?.dataType === "node") onClickNode(params);
    },
  };

  return (
    <ReactECharts
      option={option}
      onEvents={onEvents}
      notMerge={true}
      lazyUpdate={true}
      style={PaperNetworkStyle}
    />
  );
}

export default NetworkGraph;
