import { Flex, Icon, useColorModeValue } from "@chakra-ui/react";
import { BiSolidNetworkChart } from "react-icons/bi";

function CompoundIcon() {
  // Theme
  const bg = useColorModeValue("highlight.neutral.200", "dark.600");
  const borderColor = useColorModeValue("gray.400", "gray.600");

  return (
    <Flex
      bg={bg}
      minW={[null, null, null, "30px", "34px", "36px"]}
      maxW={[null, null, null, "30px", "34px", "36px"]}
      h={[null, null, null, "30px", "34px", "36px"]}
      borderRadius={"50%"}
      align={"center"}
      justify={"center"}
      borderWidth={"1px"}
      borderColor={borderColor}
      boxShadow={"sm"}
    >
      <Icon
        as={BiSolidNetworkChart}
        boxSize={[null, null, null, "12px", "14px", "15px"]}
        color={"gray.500"}
      />
    </Flex>
  );
}

export default CompoundIcon;
