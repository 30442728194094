import HelperProps from "./HelperProps";

export const chemprops_helper: HelperProps[] = [
  {
    title: "Formula",
    description:
      "The formula of a compound represents the types and number of atoms present in a molecule. The formula provides the elemental symbols and the ratio of atoms in the compound. ",
  },
  {
    title: "InChi & InChiKey",
    description:
      "InChi stands for International Chemical Identifier and it is a structure-based chemical identifier. As a standard identifier for chemical databases, InChI is essential for enabling effective information management across chemistry. InChI identifiers describe chemical substances in terms of layers of information - the atoms and their bond connectivity, tautomeric information, isotope information, stereochemistry and electronic charge. The InChIKey is a fixed length (27 character) condensed digital representation of the InChI that is not designed to be human-understandable.\n In summary: InChI is a structure-based identifier, strictly unique, and non-proprietary, open source and freely accessible; and InChIKey is a hashed version of InChI which allows for a compact representation and for searching in standard search engines like Google.",
    image: "",
  },
  {
    title: "Smiles",
    description:
      'SMILES stands for Simplified Molecular Input Line Entry System, and is a string notation to encode the structure of a chemical compound in a simple and concise line of text. A canonicalization algorithm exists to generate one special generic SMILES among all valid possibilities; this special one is known as the "canonical SMILES". SMILES written with isotopic and chiral specifications are collectively known as "isometric SMILES", while "esoteric SMILES" refer to less common, unconventional, or non-standardized representations, potentially involving hidden or less obvious details within the SMILES notation.',
  },
  {
    title: "Chirality",
    description:
      "Chirality refers to the property of asymmetry in molecules, where a molecule and its mirror image are not superimposable. This phenomenon occurs due to the spatial arrangement of atoms within the molecule, resulting in left-handed and right-handed versions known as enantiomers. Possible values for chirality are: \n Achiral: A molecule is considered achiral if it is superimposable on its mirror image. In other words, it lacks handedness and does not have distinct left-handed and right-handed versions. Achiral molecules typically possess elements of symmetry that allow them to be superimposed on their mirror images. \nChiral: Chiral molecules are non-superimposable on their mirror images. They have distinct left-handed and right-handed versions known as enantiomers. Chirality arises when a molecule lacks a plane of symmetry or a center of symmetry, resulting in asymmetry in its three-dimensional structure. \nProchiral: Prochiral compounds are not chiral themselves but can be converted into chiral molecules. This happens under the influence of an external asymmetric influence, such as a chiral catalyst or reagent, which causes the creation of a chiral center or a chiral environment. \nRacemate: A racemate is formed when equal amounts of two enantiomers of a chiral molecule are combined. As a result, the overall mixture does not exhibit optical activity because the effects of the two enantiomers cancel each other out.",
    highlight: ["achiral:", "chiral:", "Prochiral:", "racemate:"]
  },
];

export const calculated_chemprops_helper = [
  {
    title: "Molecular Weight",
    description: "",
  },
  {
    title: "Hydrogen Bond Receptor",
    description: "",
  },
  {
    title: "Hydrogen Bond Donor",
    description: "",
  },
  {
    title: "Rotable bonds",
    description: "",
  },
  {
    title: "Ring Count",
    description: "",
  },
  {
    title: "Aromatic Ring Count",
    description: "",
  },
  {
    title: "cLogP",
    description: "",
  },
  {
    title: "TPSA",
    description: "",
  },
  {
    title: "Fraction CSP3",
    description: "",
  },
  {
    title: "Chiral Centers",
    description: "",
  },
  {
    title: "Largest Ring",
    description: "",
  },
];
