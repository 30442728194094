import { Flex } from "@chakra-ui/react";

import RecentlyViewed from "components/compounds/RecentlyViewed";
import SearchCompoundInput from "components/compounds/SearchInput";
import { useContext } from "react";
import { CompoundsContext } from ".";

export default function CompoundsTools() {
  // Hooks
  const { visitedCompounds } = useContext(CompoundsContext);

  return (
    <Flex w={"100%"} h={"100%"} gap={5} direction={"column"}>
      {/* Search Input: works as for an exact match */}
      <SearchCompoundInput />

      {/* recently viewed */}
      <RecentlyViewed compounds={visitedCompounds} />
    </Flex>
  );
}
