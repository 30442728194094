import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Flex,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";

import { ChatbotContext } from ".";
import ChatItem from "components/chat/ChatItem";
import DeleteChats from "components/chat/DeleteChats";
import NewChatButton from "components/chat/NewChatButton";
import SessionsSkeleton from "components/chat/SessionsSkeleton";

import Loading from "components/ui/Loading";
import { categorizeSessions } from "./helpers";
import DeleteModal from "components/chat/DeleteModal";
import { deleteAllSession, deleteSession } from "services/chatbot.service";

import NoChats from "components/chat/NoChats";
import BackgroundShadowEffect from "components/ui/BackgroundShadowEffect";
import { CustomThickScrollBar } from "components/ui/CustomScrollBar";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { useSelector } from "react-redux";

interface ChatsHistoryProps {
  onUpdateSessions: () => void;
}

export default function ChatsHistory({ onUpdateSessions }: ChatsHistoryProps) {
  // Navigation
  const navigate = useNavigate();

  const { id } = useParams();
  // Auth
  const { user } = useSelector(selectCurrentAuthData);

  // States
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteAllSessions, setDeleteAllSessions] = useState(false);
  const [deleting, setDeleting] = useState(false);

  // Context
  const { sessions, waitingOnBot, loadingSessions } =
    useContext(ChatbotContext);

  // Handlers
  async function handleDeleteSession(session_id: string) {
    setShowDeleteModal(true);
    setDeleteAllSessions(false);
  }

  async function handleDeleteAllSessions() {
    setShowDeleteModal(true);
    setDeleteAllSessions(true);
  }

  const handleConfirmDeleteSession = async (session_id: string) => {
    setDeleting(true);

    try {
      await deleteSession(session_id);
      navigate("/chat");
      onUpdateSessions();
      setDeleting(false);
    } catch (error) {
      console.error(error, "error while deleting a session");
    }
    setShowDeleteModal(false);
  };

  const handleConfirmDeleteAllSessions = async () => {
    setDeleting(true);

    try {
      await deleteAllSession(user?.id);
      navigate("/chat");
      onUpdateSessions();
      setDeleting(false);
    } catch (error) {
      console.error(error, "error while deleting all sessions");
    }

    setShowDeleteModal(false);
    setDeleteAllSessions(false);
  };

  // disable pointer events when loading sessions or generating reply
  const isDisabled = !!waitingOnBot || !!loadingSessions;

  // user with fresh start or deleted all previous chats
  const hasNoChats = !sessions || !sessions.length;

  // Responsiveness: ~992px, ~1280px, ~1536px
  // NOTE: to do not delete calculations below
  const chatHistoryHeight = useBreakpointValue({
    lg: "calc(100vh - 218px)", // 218 = 16 + (16x2) + 16 + 20 + (16x2) + 40 + 30 + (16x2): 2 x py + 2 x py + pt + text + divider + 'new' btn + 'del' btn + my
    xl: "calc(100vh - 244px)", // 244 = 24 + (20x2) + 16 + 22 + (16x2) + 40 + 30 + (20x2)
    "2xl": "calc(100vh - 270px)", // 270 = (16x2) + (24x2) + 16 + 24 + (16x2) + 40 + 30 + (24x2)
  });

  const listStyle = {
    height: chatHistoryHeight,
    width: "100%",
    padding: "0 6px 10px 0",
  };

  return (
    <>
      <Flex
        direction={"column"}
        w={"100%"}
        h={"100%"}
        gap={[null, null, null, 4, 5, 6]}
      >
        <NewChatButton
          isDisabled={isDisabled}
          onNewChatHandler={() => {
            navigate("/chat");
          }}
        />

        {/* sessions list */}
        <Flex position={"relative"} h={"100%"} w={"100%"}>
          <CustomThickScrollBar style={listStyle}>
            {loadingSessions && <SessionsSkeleton />}

            {typeof loadingSessions === "boolean" &&
              !loadingSessions &&
              hasNoChats && <NoChats />}

            {typeof loadingSessions === "boolean" &&
              !loadingSessions &&
              !hasNoChats &&
              Object.entries(categorizeSessions(sessions ?? []) ?? {})?.map(
                ([date, list], index) =>
                  list.length > 0 && (
                    <Flex direction={"column"} mb={4} key={index}>
                      {/* chat date */}
                      <ChatDate date={date} />

                      {/* available chats within correspondat date */}
                      {list.map((session) => (
                        <ChatItem
                          id={session?.id}
                          key={session?.id}
                          title={session.title}
                          isDisabled={isDisabled}
                          isSelected={session?.id === id}
                          deleteSession={handleDeleteSession}
                        />
                      ))}
                    </Flex>
                  )
              )}
          </CustomThickScrollBar>

          {/* background shadow effect at the bottom of sessions list */}
          {sessions && sessions.length > 1 && <BackgroundShadowEffect />}
        </Flex>

        {/* clear all chats */}
        {sessions && sessions.length > 1 && (
          <DeleteChats
            isDisabled={isDisabled}
            onDelete={handleDeleteAllSessions}
          />
        )}
      </Flex>

      {/* Modal */}
      <DeleteModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={() =>
          deleteAllSessions
            ? handleConfirmDeleteAllSessions()
            : handleConfirmDeleteSession(id ?? "")
        }
        header={deleteAllSessions ? "Delete all chats" : "Delete chat"}
      >
        {deleting ? (
          <Loading
            message={
              deleteAllSessions ? "Deleting all chats.." : "Deleting chat.."
            }
          />
        ) : (
          <Text>
            {!deleteAllSessions
              ? "This chat will no longer be available. Are you sure you want to delete?"
              : "All your chats will no longer be available. Are you sure you want to delete?"}
          </Text>
        )}
      </DeleteModal>
    </>
  );
}


interface ChatDateProps {
  date: string;
}

function ChatDate({ date }: ChatDateProps) {
  // Theme
  const color = useColorModeValue("primary.300", "highlight.primary");

  return (
    <Text
      fontSize={"12px"}
      fontWeight={"500"}
      lineHeight={"1"}
      fontFamily={"Poppins, sans-serif"}
      mb={1}
      pl={1.5}
      color={color}
      display={"flex"}
      alignItems={"end"}
      gap={1}
    >
      {date}
    </Text>
  );
}
