import { Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import { TbMessage2 } from "react-icons/tb";

interface FeedbackButtonProps {
  handleClick: () => void;
}

function FeedbackButton({ handleClick }: FeedbackButtonProps) {
  // Theme
  const color = useColorModeValue("neutral.100", "dark.800");

  return (
    <Flex
      zIndex={999}
      overflow={"hidden"}
      align={"center"}
      position={"absolute"}
      top={"50%"}
      right={"-41px"}
      color={color}
      bg={"highlight.primary"}
      borderRadius={"8px 8px 0 0"}
      transform={"translateY(-50%) rotate(-90deg)"}
      transition={"all .3s"}
      _hover={{ opacity: 0.9 }}
    >
      <Flex
        cursor={"pointer"}
        onClick={handleClick}
        m={0}
        px={3}
        py={2}
        gap={2}
        w={"fit-content"}
        h={"fit-content"}
        align={"center"}
      >
        <Icon as={TbMessage2} boxSize={"18px"} />

        <Text
          lineHeight={"1.3"}
          h={"fit-content"}
          alignSelf={"center"}
          fontWeight={"500"}
          fontSize={"14px"}
          letterSpacing={".05rem"}
        >
          Feedback
        </Text>
      </Flex>
    </Flex>
  );
}

export default FeedbackButton;
