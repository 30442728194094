import { ReactNode } from "react";
import { Flex, useTheme } from "@chakra-ui/react";
import { hexToRgba } from "utils/helpers";

interface OverlayProps {
  children: ReactNode;
}

function Overlay({ children }: OverlayProps) {
  // Theme
  const { colors } = useTheme();

  return (
    <Flex
      align={"center"}
      justify={"center"}
      bgGradient={`linear(to-r, ${hexToRgba(
        colors.highlight["primary"],
        0.8
      )}, ${hexToRgba(colors.secondary["700"], 0.8)})`}
      position="absolute"
      top="0"
      left="0"
      right="0"
      bottom="0"
      color="neutral.200"
      textAlign="center"
    >
      {children}
    </Flex>
  );
}

export default Overlay;
