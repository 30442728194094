import { ReactNode } from "react";
import { components, OptionProps } from "react-select";

interface CustomOptionProps extends OptionProps<any> {
  children: ReactNode;
}

const CustomOption = ({ children, ...props }: CustomOptionProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const newProps = { ...props, innerProps: rest };
  return (
    <components.Option {...newProps} className="custom-option">
      {children}
    </components.Option>
  );
};

export default CustomOption;
