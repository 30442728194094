import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Text,
  Flex,
  useColorModeValue,
  Card,
  Tooltip,
  Icon,
  useClipboard,
} from "@chakra-ui/react";

import FoldersModal from "components/saved/FoldersModal";
import { getPaperColor } from "views/compounds/helpers";

import { ExternalId, PaperProps } from "../../../models/papers/PaperProps";

import ActionButton from "components/buttons/ActionButton";
import Abstract from "./Abstract";
import Authors from "./Authors";
import LensId from "./LensId";
import TypeAndYear from "./TypeAndYear";
import Citation from "./Citation";

import { IoMdCode } from "react-icons/io";
import { PiGraphFill } from "react-icons/pi";
import { BsBookmarkCheck } from "react-icons/bs";
import { PapersContext } from "views/papers";
import { BiSolidCopy } from "react-icons/bi";
import { FaCheck } from "react-icons/fa";
import Doi from "./Doi";

interface SelectedPaperDetailsProps {
  data: PaperProps;
  isOrigin: boolean;
}

export const classifiedTypes = [
  "interventional study",
  "experimental study",
  "review",
];

export default function SelectedPaperDetails({
  data,
  isOrigin,
}: SelectedPaperDetailsProps) {
  // Hooks
  const navigate = useNavigate();
  const { onCopy } = useClipboard(data?.title ?? "");

  // Context
  const { initEmbedding, isEmbeddingInProgress } = useContext(PapersContext);

  // State
  const [hasCopied, setHasCopied] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);

  // Theme
  const borderColor = useColorModeValue("gray.200", "gray.600");

  // Theme
  const bg = useColorModeValue("whiteAlpha.600", "dark.700");
  const tooltipBg = useColorModeValue("dark.900", "dark.700");
  const containerBorderColor = useColorModeValue("gray.200", "transparent");
  const boxShadow = useColorModeValue("sm", "none");

  // Handlers
  const handleEmbedPaper = async () =>
    data.id && (await initEmbedding(data.id));

  function handleCopy() {
    onCopy();
    setHasCopied(true);
  }

  const doi = data?.external_ids?.find(
    (id: ExternalId) => id?.type?.toLowerCase() === "doi"
  )?.value;

  useEffect(() => {
    setHasCopied(false);
  }, [data]);

  return (
    <Flex
      as={Card}
      direction={"column"}
      bg={bg}
      h={"fit-content"}
      maxW={"300px"}
      w={"100%"}
      gap={4}
      p={2.5}
      my={"auto"}
      boxShadow={boxShadow}
      borderRadius="lg"
      borderColor={containerBorderColor}
      borderWidth={1}
    >
      <Flex direction={"column"} justify={"space-between"} h={"100%"}>
        <Flex direction={"column"} gap={3}>
          {/* paper type & publication year  */}
          <TypeAndYear data={data} />

          {/* title */}
          <Tooltip
            label={data?.title}
            placement="left"
            hasArrow
            bg={tooltipBg}
            color="gray.300"
            fontSize={"12px"}
            p={4}
            m={2}
            borderRadius={"6px"}
            display={data?.title && data?.title.length < 121 ? "none" : "block"}
          >
            <Text
              fontSize={[null, null, null, "12px", "14px", "16px"]}
              fontWeight={"500"}
              color={getPaperColor(data.paper_type)}
              lineHeight={"1.35"}
              display={"inline"}
              mb={1.5}
            >
              {data?.title && data?.title.length < 121
                ? data?.title
                : data?.title?.slice(0, 120) + ".."}

              <Icon
                ml={2}
                display={"inline"}
                color={hasCopied ? "highlight.primary" : "gray.500"}
                boxSize={"14px"}
                onClick={handleCopy}
                cursor={"pointer"}
                as={hasCopied ? FaCheck : BiSolidCopy}
              />
            </Text>
          </Tooltip>

          <Abstract data={data?.abstract ?? ""} />
          <Authors data={data?.authors ?? []} />
          <Citation data={data?.scholarly_citations_count} />
          <LensId data={data?.lens_id ?? ""} />
          <Doi data={doi ?? ""} />
        </Flex>

        {/* action buttons */}
        <Flex w={"100%"} borderTopColor={borderColor} borderTopWidth={1} mt={5}>
          <Flex alignItems="center" w={"100%"} gap={2} pt={1}>
            <ActionButton
              label={"Save"}
              icon={<BsBookmarkCheck />}
              onClick={() => setShowSaveModal(true)}
            />
            {!isEmbeddingInProgress && (
              <ActionButton
                label={"Embed"}
                icon={<IoMdCode />}
                onClick={handleEmbedPaper}
                isDisabled={data?.embedded}
              />
            )}
            {!isOrigin && (
              <Flex ml={"auto"}>
                <ActionButton
                  label={"Set as origin"}
                  icon={<PiGraphFill />}
                  onClick={() => navigate(`/papers/${data?.id}`)}
                />
              </Flex>
            )}
          </Flex>
        </Flex>

        {/* Modal */}
        {data?.id && (
          <FoldersModal
            isOpen={showSaveModal}
            onClose={() => setShowSaveModal(false)}
            payload={{
              saveElementPayload: {
                elementType: "LITERATURE",
                content: {
                  elementId: data?.id,
                },
              },
              successMessage: `Literature is successfully saved.`,
            }}
          />
        )}
      </Flex>
    </Flex>
  );
}
