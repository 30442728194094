import { Link as RouterLink } from "react-router-dom";
import {
  Flex,
  Text,
  Link,
  useColorModeValue,
  Icon,
  useTheme,
} from "@chakra-ui/react";

import { LuExternalLink } from "react-icons/lu";
import { ReducedCompoundProps } from "views/graph";
import { hexToRgba } from "utils/helpers";

interface HistoryItemProps {
  compound: ReducedCompoundProps;
}

export default function HistoryItem({ compound }: HistoryItemProps) {
  // Theme
  const { colors } = useTheme();

  const historyItemColor = useColorModeValue("gray.600", "gray.400");
  const historyItemIconColor = useColorModeValue("gray.500", "gray.400");
  const historyItemBg = useColorModeValue(
    hexToRgba(colors.blue[600], 0.05),
    hexToRgba(colors.blue[600], 0.05)
  );

  return (
    <Flex
      position={"relative"}
      bg={historyItemBg}
      transition="all ease .2s"
      borderRadius={"8px"}
      _hover={{
        bg: hexToRgba(colors.blue[600], 0.1),
      }}
    >
      <Link
        as={RouterLink}
        to={"/compounds/" + compound?.compound_id}
        fontSize={"12px"}
        color={historyItemColor}
        lineHeight={"normal"}
        w={"full"}
        p={2}
        transition="all ease .2s"
        _hover={{
          textDecoration: "none",
        }}
      >
        <Flex
          direction={"column"}
          gap={1}
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
          w={"92%"}
        >
          <Text
            fontWeight="500"
            whiteSpace={"nowrap"}
            overflow={"hidden"}
            textOverflow={"ellipsis"}
          >
            {compound?.name ?? compound?.generic_name}
          </Text>
          <Text fontSize="10px">{compound?.compound_id}</Text>
        </Flex>
      </Link>
      <Icon
        position={"absolute"}
        right={"6px"}
        top={"6px"}
        zIndex={-1}
        as={LuExternalLink}
        color={historyItemIconColor}
        boxSize={"12px"}
      />
    </Flex>
  );
}
