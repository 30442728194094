import { InputRightElement } from "@chakra-ui/react";
import { SmallCloseIcon } from "@chakra-ui/icons";
import { KeyboardEvent, useContext, useEffect } from "react";
import SearchInput from "components/ui/SearchInput";
import { PapersContext } from "views/papers";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import { addDataIntoCache, getCachedData } from "views/papers/helpers";
import { environment } from "environments";
import {
  EmbeddingState,
  selectCurrentEmbeddingData,
} from "redux/features/embedding/embeddingSlice";
import { useSelector } from "react-redux";

export interface SuggestionProps {
  id: string;
  year_published: number;
  title: string;
  paper_type: string;
}

function SearchBox() {
  const axiosPrivate = useAxiosPrivate();
  const { progress }: EmbeddingState = useSelector(selectCurrentEmbeddingData);

  // State
  const {
    suggestions,
    setSuggestions,
    search,
    setSearch,
    showEmbeddingControls,
    onToggleEmbeddingControls,
    setSearchError,
    searchError,
  } = useContext(PapersContext);
  const { searching, query: searchQuery } = search;

  const handleSearch = async (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      searchError && setSearchError("");
      // if user input is less than 4 characters, do nothing
      if (search.query.length < 4) return;
      try {
        let resultsToShow: SuggestionProps[] = [];

        // Read from cache first
        const cachedResponse = await getCachedData(
          "PapersSearchResult",
          search.query
        );

        if (cachedResponse) {
          // If cached result is available, set suggestions and initiate background request
          resultsToShow = cachedResponse;

          // Start a background request to fetch updated papers from the database
          axiosPrivate
            .get(
              `${environment.BACKEND_API}/api/search_papers_by_keyword/${search.query}`
            )
            .then(async (response) => {
              const data = response.data;
              if (data?.length > 0) {
                const cleanedResults: SuggestionProps[] = data.map(
                  (paper: SuggestionProps) => ({
                    ...paper,
                    paper_type:
                      paper?.paper_type &&
                      paper?.paper_type.toLowerCase() !== "neither"
                        ? paper?.paper_type
                        : "Unclassified",
                  })
                );

                // Cache the updated results
                addDataIntoCache(
                  "PapersSearchResult",
                  search.query,
                  cleanedResults
                );

                // Update suggestions if not already set (to avoid overwriting user-initiated suggestions)
                if (!resultsToShow) {
                  setSuggestions(cleanedResults);
                }
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          // If not cached, make a query and show loading
          setSuggestions([]);
          sessionStorage.removeItem("recentLiteraturesSearch");

          setSearch({ ...search, ...{ searching: true } });

          const response = await axiosPrivate.get(
            `${environment.BACKEND_API}/api/search_papers_by_keyword/${search.query}`
          );
          const data = response.data;
          if (data?.length > 0) {
            // Clean results
            const cleanedResults: SuggestionProps[] = data.map(
              (paper: SuggestionProps) => ({
                ...paper,
                paper_type:
                  paper?.paper_type &&
                  paper?.paper_type.toLowerCase() !== "neither"
                    ? paper?.paper_type
                    : "Unclassified",
              })
            );

            setSuggestions(resultsToShow);
            // Cache results
            addDataIntoCache(
              "PapersSearchResult",
              search.query,
              cleanedResults
            );
            resultsToShow = cleanedResults;
          }
          setSearch({ ...search, ...{ searching: false } });
        }

        setSearch({ ...search, ...{ searched: true } });

        if (resultsToShow) {
          // If a final result to show is available
          setSuggestions(resultsToShow);
          sessionStorage.setItem(
            "recentLiteraturesSearch",
            JSON.stringify({
              searchQuery: search.query,
              searchResults: resultsToShow,
            })
          );
        } else {
          // Reset
          setSuggestions([]);
          sessionStorage.removeItem("recentLiteraturesSearch");
        }
      } catch (error: any) {
        if (!error.response) {
          setSearchError("No server response!");
        } else {
          setSearchError(error.response.data.message);
        }
        setSearch({ ...search, ...{ searching: false } });
      }
    }
  };

  const handleChange = (value: string) => {
    setSearch({ ...search, query: value, searching: false, searched: false });
    searchError && setSearchError("");
    suggestions.length && setSuggestions([]);
    showEmbeddingControls && onToggleEmbeddingControls();
  };

  const clearSearch = () => {
    setSearch({ ...search, ...{ query: "" } });
    setSearchError("");
    setSuggestions([]);
    sessionStorage.removeItem("recentLiteraturesSearch");
  };

  useEffect(() => {
    progress === 100 && clearSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  return (
    <SearchInput
      rightComponent={
        !!searchQuery && (
          <InputRightElement>
            <SmallCloseIcon
              pointerEvents={!searching ? "auto" : "none"}
              cursor={!searching ? "pointer" : "not-allowed"}
              onClick={clearSearch}
              w={"18px"}
              h={"18px"}
              color="gray.500"
              bg={"gray.100"}
              borderRadius={"50%"}
            />
          </InputRightElement>
        )
      }
      value={searchQuery}
      onChange={(e) => handleChange(e.target.value)}
      onKeyDown={handleSearch}
      isDisabled={searching}
      placeholder="search paper title"
    />
  );
}

export default SearchBox;
