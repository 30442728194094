import { BsHighlights, BsSignal } from "react-icons/bs";
import { PiGraphFill, PiNewspaperFill, PiTestTubeFill } from "react-icons/pi";
import { HiMiniRectangleStack } from "react-icons/hi2";

import { IconType } from "react-icons";

export function getIconByName(name: string) {
  const tab = tabs.find((tab) => tab.name === name);
  if (tab) return tab.icon as IconType;
  else return HiMiniRectangleStack;
}

const labels = [
  { navbar: "#e81e63" },
  { favorites: "#9c27b0" },
  { docs: "#ffc107" },
  { feedback: "#673ab7" },
  { "report error": "#f44336" },
  { chatbot: "#3f51b5" },
  { edit: "#03a9f4" },
  { compounds: "#009688" },
  { search: "#2196f3" },
  { references: "#4caf50" },
  { assays: "#dd6b66" },
  { filters: "#8bc34a" },
  { literature: "#4A5568" },
  { embeddings: "#ff9800" },
  { networks: "#63B3ED" },
  { charts: "#ff5722" },
  { "tree of life": "#E062AE" },
];

export function getLabelColor(label: string): string {
  const matchingLabel = labels.find((obj) => obj.hasOwnProperty(label)) ?? {};

  if (matchingLabel && Object.values(matchingLabel)) {
    const value = Object.values(matchingLabel)[0];
    return value as "string";
  }
  return "black"; // Label not found
}

export const tabs = [
  { name: "general", icon: HiMiniRectangleStack },
  { name: "chatbot", icon: BsSignal },
  { name: "compounds", icon: PiGraphFill },
  { name: "assays", icon: PiTestTubeFill },
  { name: "literature", icon: PiNewspaperFill },
  { name: "charts", icon: BsHighlights },
];

export const Videos = [
  {
    type: "general",
    labels: ["navbar", "favorites", "docs"],
    url: "https://reacto.s3.eu-central-1.amazonaws.com/videos/ask_bot_1.mp4",
    title: "Navigate the dashboard",
    steps: [
      "The navbar is located on the left-hand side of the screen, the navigation menu provides easy access to all the key features. From top to bottom, there is the chatbot, molecules and assays database, literature citation network, and interactive charts.",
      "At the bottom of the navigation menu, you can find some helpers: 'Saved elements library' that compiles elements you have saved previously to keep your research organized, API documentation. You can also switch to dark mode and update your profile.",
    ],
  },
  {
    type: "general",
    labels: ["feedback", "report error"],
    url: "https://reacto.s3.eu-central-1.amazonaws.com/videos/ask_bot_1.mp4",
    title: "How do I give feedback?",
    steps: [
      "Click a button in the right center of the screen 'feedback'.",
      "A pop up form will appear. Select if you are sending a compliment, suggestion or problem.",
      "Write your feedback and submit. We will review as soon as possible.",
    ],
  },
  {
    type: "chatbot",
    labels: ["chatbot", "edit"],
    url: "https://reacto.s3.eu-central-1.amazonaws.com/videos/ask_bot_1.mp4",
    title: "How to start interacting with the chat?",
    steps: [
      "Either text right there in the input or open a new section by clicking the button in the top left.",
      "Ask any question and wait for your response. You can ask follow up questions or navigate through the papers references or compounds provided.",
      "If you want to edit the chat name you can do so by clicking the target chat in the sidebar and edit. Same way you can also delete the chat.",
    ],
  },
  {
    type: "compounds",
    labels: ["compounds", "search"],
    url: "https://reacto.s3.eu-central-1.amazonaws.com/videos/ask_bot_1.mp4",
    title: "How to search for compounds?",
    steps: [
      "Click on the search input and type a compound name, indication, or inchi-key.",
      "If you want to look for a previous search, use our commpounds history to find a previous compound, or go back to search results in the breadcrumb.",
      "Your search results will be listed in the right side. You can check the names and ids to discriminate you search.",
    ],
  },
  {
    type: "compounds",
    labels: ["compounds", "references", "favorites"],
    url: "https://reacto.s3.eu-central-1.amazonaws.com/videos/ask_bot_new_session_dark_theme.mp4",
    title: "Navigate compound details",
    steps: ["Different sections", "References", "Charts", "Save compound"],
  },
  {
    type: "assays",
    labels: ["assays", "search", "filters"],
    url: "https://reacto.s3.eu-central-1.amazonaws.com/videos/assays_filter_by_id_assay_type.mp4",
    title: `How to search for assays?`,
    steps: [
      "Click on the search input and type an assay name, indication, or id.",
      "Use the filters, to select assays by indication and type. You can also filter by clicking on the labels in the cards.",
      "Your search results will be listed in the right side. After selecting one, you can return to the search results using the breadcrumb at the top left.",
    ],
  },
  {
    type: "assays",
    labels: ["assays", "references", "filters"],
    url: "https://reacto.s3.eu-central-1.amazonaws.com/videos/assays_filter_by_id_assay_type.mp4",
    title: `Assay Details`,
    steps: ["Different sections", "References", "Charts"],
  },
  {
    type: "literature",
    labels: ["literature", "search", "filters"],
    url: "https://reacto.s3.eu-central-1.amazonaws.com/videos/literature_search_view_cell_lung_cancer.mp4",
    title: `How to search for a research paper?`,
    steps: [
      "Click on the search input and type a keyword that might be present in the paper's title.",
      "Your search results will be listed in the right side. You can filter further using the labels in the sidebar.",
      "When you select a paper, a new tab will appear with the network presenting its references. You can use the breadcrumb to go back to the search results.",
    ],
  },
  {
    type: "literature",
    labels: ["literature", "embeddings"],
    url: "https://reacto.s3.eu-central-1.amazonaws.com/videos/literature_network_from_cell_lung_cancer_search_results.mp4",
    title: "Embeddings",
    steps: [
      "As a premium user, you'll have access to embed new papers for deep reading of our LLM.",
      "You can enable embedding controls in the sidebar to select papers to embed, and the click 'embed' button.",
      "There might be a short waiting period, where this functionality will be disabled. You'll receive a notification when done.",
    ],
  },
  {
    type: "literature",
    labels: ["literature", "networks"],
    url: "https://reacto.s3.eu-central-1.amazonaws.com/videos/paper_network_search.mp4",
    title: "Networks",
    steps: [
      "You can use the sidebar to search for specific papers in the given network.",
      "You can set a new node as origin, to create a new network from the clicked node.",
      "When clicked a node in the network, you'll be able to see some details on the paper, and DOI.",
    ],
  },
  {
    type: "charts",
    labels: ["charts", "tree of life"],
    url: "https://reacto.s3.eu-central-1.amazonaws.com/videos/search_for_linezolid+compound.mp4",
    title: "What to navigate the tree of life?",
    steps: [
      "You can use the sidebar to search for specific papers in the given network.",
      "You can set a new node as origin, to create a new network from the clicked node.",
      "When clicked a node in the network, you'll be able to see some details on the paper, and DOI.",
    ],
  },
];
