import { useContext, useEffect, useRef } from "react";
import { useOutletContext } from "react-router-dom";
import { Box, Flex, useBreakpointValue } from "@chakra-ui/react";

import { ChatbotContext } from ".";
import Loading from "components/ui/Loading";
import HumanMessage from "components/chat/HumanMessage";
import LoadingBubble from "components/chat/LoadingBubble";
import MessageBubble from "components/chat/MessageBubble";

import { ConversationProps } from "models/chat/MessageProps";
import { CustomScrollBar } from "components/ui/CustomScrollBar";

export default function ChatMessages() {
  // Contexts
  const { messages, waitingOnBot, loadingChat } = useContext(ChatbotContext);
  const { questionOnWait }: { questionOnWait: string | undefined } =
    useOutletContext();

  // ref
  const messagesRef = useRef<HTMLDivElement>(null);

  // scroll to the bottom on page load
  useEffect(() => {
    const scrollToBottom = () => {
      if (messagesRef.current) {
        messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
      }
    };

    scrollToBottom();
  });

  // Responsiveness: ~992px, ~1280px, ~1536px
  // NOTE: to do not delete calculations below
  const conversationHeight = useBreakpointValue({
    lg: "calc(100vh - 148px)", // 148 = (8x2) + 16 + 8 + 60 + (24x2): py + boxSize + gap + textfield + py
    xl: "calc(100vh - 156px)", // 156 = (12x2) + 16 + 8 + 60 + (24x2)
    "2xl": "calc(100vh - 164px)", // 164 = (16x2) + 16 + 8 + 60 + (24x2)
  });

  const listStyle = {
    height: conversationHeight,
    width: "100%",
    padding: "0 6px 0 0",
  };

  return (
    <Flex
      justify={"flex-end"}
      align={"flex-end"}
      h={"calc(100% - 84px)"}
      w={"900px"}
      maxW={"90%"}
      mx={"auto"}
      py="24px"
    >
      {/* waiting for session content */}
      {loadingChat && (
        <Flex w={"100%"} h={"100%"} justify={"center"} align={"center"}>
          <Loading />
        </Flex>
      )}

      {/* messages content */}
      {!loadingChat && (messages.length > 0 || waitingOnBot) && (
        <CustomScrollBar
          scrollableNodeProps={{ ref: messagesRef }}
          style={listStyle}
        >
          {/* conversation thread: human(qst) + bot(reply) */}
          <Flex direction={"column"} pr={4}>
            {messages.map((conversation: ConversationProps, index: number) => (
              <MessageBubble
                key={"message" + index}
                content={conversation ?? undefined}
              />
            ))}
          </Flex>

          {/* temporary conversation thread: human(qst) + bot(notifications, partial reply) */}
          {waitingOnBot && (
            <Flex direction={"column"} px={2}>
              <Flex w="100%" mt={4} pr={2} pl={"48px"}>
                <Box
                  alignContent={"flex-start"}
                  width={"fit-content"}
                  display={"inline-block"}
                >
                  {!!questionOnWait && (
                    <HumanMessage questionOnWait={questionOnWait} />
                  )}
                </Box>
              </Flex>
              <LoadingBubble />
            </Flex>
          )}
        </CustomScrollBar>
      )}
    </Flex>
  );
}