import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Flex,
  Text,
  Icon,
  Tooltip,
  useTheme,
  useColorModeValue,
} from "@chakra-ui/react";

import { TiDelete } from "react-icons/ti";

interface ChatItemProps {
  id: string;
  title: string;
  isSelected: boolean;
  isDisabled: boolean;
  deleteSession: (id: string) => void;
}

export default function ChatItem({
  id,
  title,
  isSelected,
  isDisabled,
  deleteSession,
}: ChatItemProps) {
  const navigate = useNavigate();
  const { colors } = useTheme();

  // State
  const [show, setShow] = useState<boolean>(false);

  // Theme
  const color = useColorModeValue("gray.600", "gray.400");
  const bg = useColorModeValue("gray.50", "gray.700");

  return (
    <Flex
      position={"relative"}
      align="center"
      justify="space-between"
      rounded="xs"
      cursor="pointer"
      fontWeight={isSelected ? "500" : "400"}
      letterSpacing={isSelected ? "-0.012rem" : "0"}
      pointerEvents={isDisabled ? "none" : "auto"}
      onClick={() => navigate(id)}
      onMouseOver={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      transition="border .3s ease"
      _hover={{ bg: bg }}
      borderTopWidth={1}
      borderBottomWidth={1}
      borderTopColor={isSelected ? colors.gray[300] : "transparent"}
      borderBottomColor={isSelected ? colors.gray[300] : "transparent"}
    >
      {/* chat header */}
      <Text
        fontSize="13px"
        whiteSpace={"nowrap"}
        overflow={"hidden"}
        textOverflow={"ellipsis"}
        color={color}
        lineHeight={"1.3"}
        p={1.5}
      >
        {title}
      </Text>

      {/* delete chat item */}
      {show && (
        <Tooltip
          label={"Delete chat"}
          placement="right"
          hasArrow
          bg="gray.200"
          color="gray.700"
        >
          <Flex position="absolute" right={0} align={"center"}>
            <Icon
              as={TiDelete}
              boxSize={4}
              color={"gray.500"}
              _hover={{
                color: "red.500",
              }}
              p={0}
              m={0}
              pointerEvents={isDisabled ? "none" : "auto"}
              onClick={() => deleteSession(id)}
            />
          </Flex>
        </Tooltip>
      )}
    </Flex>
  );
}
