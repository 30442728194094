import { useCallback, useContext, useState } from "react";
import { useParams } from "react-router-dom";

import {
  Box,
  Card,
  Flex,
  Grid,
  HStack,
  Heading,
  Spacer,
  Text,
  VStack,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";

import { useFetch } from "hooks";
import { environment } from "environments";

import Loading from "components/ui/Loading";
import SmilesWithTag from "components/compounds/assets/SmilesWithTag";
import { CompoundProps } from "models/compounds/CompoundProps";

import ChemPropTag from "components/compounds/assets/ChemPropTag";
import { capitalise } from "./helpers";
import ChemPropsTable from "components/compounds/assets/ChemPropsTable";

import CompoundsTable from "components/compounds/CompoundsTable";
import PropsCard from "components/compounds/assets/PropsCard";
import PharmaAccordion from "components/compounds/PharmaAccordion";
import PhaseChart from "components/compounds/assets/PhaseChart";
import AssaysTable from "components/compounds/AssaysTable";
import ExternalSourcesList from "components/compounds/assets/ExternalSourcesList";

import { SubtitleSM } from "components/ui/Subtitle";
import { chemprops_helper } from "data/helpers/chemicalprops";

// Helpers
import HelperModal from "components/compounds/HelperModal";
import { pharmaprops_helper } from "data/helpers/pharmacologicalprops";

import { similarsummaryprops_helper } from "data/helpers/summaryprops";
import { screeningdataprops_helper } from "data/helpers/screeningdata";
import {
  externalsources_helper,
  papers_helper,
} from "data/helpers/externalsources";
import PapersReferencesChart from "components/compounds/PapersReferencesChart";
import { PaperProps } from "models/papers/PaperProps";
import TreeOfLifeLink from "components/compounds/TreeOfLifeLink";
import FoldersModal from "components/saved/FoldersModal";

import { BsBookmarkCheck } from "react-icons/bs";
import { ReducedCompoundProps } from "views/graph";
import MainPanelError from "components/ui/MainPanelError";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import ActionButton from "components/buttons/ActionButton";
import { CompoundsContext } from ".";
import { maxHeight } from "utils/responsive";
import { activitydataprops_helper } from "data/helpers/activitydata";

export default function CompoundItem() {
  // Hooks
  const { id } = useParams();
  const axiosPrivate = useAxiosPrivate();

  // Theme
  const headingColor = useColorModeValue("secondary.700", "secondary.400");
  const aliasesColor = useColorModeValue("gray.500", "gray.400");

  // States
  const [error, setError] = useState("");
  const [showSaveModal, setShowSaveModal] = useState(false);
  const { setVisitedCompounds } = useContext(CompoundsContext);

  // API
  // original compound
  const { data: compound, loading } = useFetch(
    useCallback(async () => {
      try {
        // get compound
        const response = await axiosPrivate.get(
          `${environment.BACKEND_API}/api/get_compound_by_id/${id}`
        );

        const data: CompoundProps = response.data;

        setVisitedCompounds((prev: ReducedCompoundProps[]) => {
          const newCachedData = prev.filter(
            (item: ReducedCompoundProps) => item?.compound_id !== id
          );
          newCachedData.unshift({
            compound_id: data?.compound_id,
            name: data?.name,
            generic_name: data?.generic_name,
          });

          const mostRecentItems = newCachedData?.slice(0, 10);

          localStorage.setItem(
            "recentlyViewedCompounds",
            JSON.stringify(mostRecentItems)
          );

          return mostRecentItems;
        });
        // get similar
        return data as CompoundProps;
        // eslint-disable-next-line react-hooks/exhaustive-deps
      } catch (error) {
        setError("Error fetching data");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])
  );

  // similar compounds
  const { data: similarCompounds, loading: loadingSimilar } = useFetch(
    useCallback(async () => {
      // get similars ids
      const similarIds =
        compound?.similar_compounds?.map((sim) => sim.compound_ikey) ?? [];

      // get similar compounds
      const similarCmpds = await Promise.all(
        similarIds?.map(async (id) => {
          try {
            const response = await axiosPrivate.get<CompoundProps>(
              `${environment.BACKEND_API}/api/get_compound_by_id/${id}`
            );
            return response.data;
          } catch (e) {
            console.log(e);
          }
        })
      );

      const filtered = similarCmpds.filter((element) => element !== undefined);
      return filtered as CompoundProps[];
    }, [compound, axiosPrivate])
  );

  // reference papers
  const { data: RefsPapersData, loading: RefsPapersLoading } = useFetch(
    useCallback(async () => {
      if (compound) {
        const response = await axiosPrivate.get(
          `${environment.BACKEND_API}/api/find_papers_by_compound/${compound?.name}`
        );
        return response.data as PaperProps[];
      }
    }, [compound, axiosPrivate])
  );

  // Responsiveness: ~992px, ~1280px, ~1536px
  const CompoundItemHeight = useBreakpointValue(maxHeight);

  // if error
  if (!!error)
    return (
      <Flex h={CompoundItemHeight} w={"100%"}>
        <MainPanelError />
      </Flex>
    );

  // if Loading
  if (loading)
    return (
      <Flex
        direction={"column"}
        h={CompoundItemHeight}
        w={"100%"}
        align={"center"}
        justify={"center"}
      >
        <Loading message="Loading compound data.." />
      </Flex>
    );

  return (
    <>
      {/* if data fetched successfully */}
      {compound ? (
        <VStack spacing={4} align="stretch">
          {/* Sumary */}
          <Flex align="flex-start">
            {/* Name & Aliases */}
            <Flex direction={"column"} gap={2}>
              {/* Name */}
              <Flex gap={5}>
                <Flex justify={"space-between"} align={"center"}>
                  {/* compound name */}
                  <Heading
                    size={[null, null, null, "md", "lg", "lg"]}
                    color={headingColor}
                    whiteSpace={"nowrap"}
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                    maxW={"600px"}
                    w={"fit-content"}
                  >
                    {capitalise(compound?.name || "")}
                  </Heading>
                </Flex>
                <ActionButton
                  label={"Save"}
                  icon={<BsBookmarkCheck />}
                  onClick={() => setShowSaveModal(true)}
                />
              </Flex>

              {/* Aliases */}
              {compound?.aliases && compound?.aliases?.length > 0 && (
                <HStack spacing={2} mb={4} align={"flex-start"}>
                  <Text color={aliasesColor} fontSize={"14px"}>
                    <Text as={"span"} fontWeight={"500"}>
                      {compound?.aliases.length < 2 ? "Alias:" : "Aliases:"}
                    </Text>{" "}
                    {compound?.aliases?.join(" · ")}
                  </Text>
                </HStack>
              )}
            </Flex>

            <Spacer />

            {/* General Props */}
            <HStack spacing={2}></HStack>
          </Flex>

          {/* Chemical Properties */}
          <PropsCard>
            {/* Title */}
            <Flex align={"flex-end"}>
              <SubtitleSM>Chemical Properties</SubtitleSM>
              <HelperModal
                title={"Chemical Properties"}
                data={chemprops_helper}
              />
            </Flex>

            <Flex direction="row" gap={5} mb={5} align={"center"}>
              {/* SMILES image */}
              <SmilesWithTag
                canonic_url={
                  compound?.chemical_props?.isomeric_smiles_structure
                }
                isometric_url={
                  compound?.chemical_props?.canonical_smiles_structure
                }
              />

              {/* General Info */}
              <ChemPropsTable
                chemProperties={compound?.chemical_props}
                origin={compound?.citeline_data?.origin_compound?.join(", ")}
              />
            </Flex>
            {/* RDKit calculated  */}
            {/* TODO: add when data is available or when possible to calculate with RDKit */}
            {false && (
              <Grid
                templateColumns="repeat(11, 1fr)"
                as={Card}
                height={20}
                variant="outline"
              >
                <ChemPropTag name="MW" value={5.4} tooltip="Molecular Weight" />
                <ChemPropTag
                  name="HBA"
                  value={2}
                  tooltip="Hydrogen Bond Acceptors"
                />
                <ChemPropTag
                  name="HBD"
                  value={3}
                  tooltip="Hydrogen Bond Donors"
                />
                <ChemPropTag name="RB" value={2} tooltip="Rotable Bonds" />
                <ChemPropTag name="RC" value={2} tooltip="Ring Cound" />
                <ChemPropTag name="LOGP" value={2} tooltip="cLogP" />
                <ChemPropTag
                  name="ARC"
                  value={2}
                  tooltip="Aromatic Ring Count"
                />
                <ChemPropTag name="TPSA" value={3} tooltip="TPSA" />
                <ChemPropTag name="CSP3" value={2} tooltip="Fraction CSP3" />
                <ChemPropTag name="CC" value={2} tooltip="Chiral centers" />
                <ChemPropTag name="LR" value={2} tooltip="Largest ring" />
              </Grid>
            )}
          </PropsCard>

          {/* Pharmacological Properties */}
          <PropsCard>
            <Flex align={"baseline"}>
              {/* Title */}
              <Flex align={"flex-end"} mb={4} gap={1}>
                <SubtitleSM>Pharmacological Properties</SubtitleSM>
                <HelperModal
                  title={"Phamacological Properties"}
                  data={pharmaprops_helper}
                />
              </Flex>
              <Spacer />

              {/* Phase */}
              <PhaseChart
                selected={
                  compound?.citeline_data?.phase ??
                  compound?.integrity_data?.phase ??
                  compound?.gvk_data?.phase ??
                  ""
                }
              />
            </Flex>

            {/* Pharmacological Properties */}
            <PharmaAccordion compound={compound} />
          </PropsCard>

          {/* Similar compounds */}
          {!loadingSimilar &&
            similarCompounds &&
            similarCompounds?.length > 0 && (
              <PropsCard>
                {/* Title */}
                <Flex align={"flex-end"} mb={4} gap={1}>
                  <SubtitleSM>Similar Compounds</SubtitleSM>
                  <HelperModal
                    title={"Compounds Summary Data"}
                    data={similarsummaryprops_helper}
                  />
                </Flex>

                {/* Similar Compounds */}
                <CompoundsTable
                  items={similarCompounds}
                  similarityScores={compound?.similar_compounds?.map(
                    (similar) => similar.similarity_score
                  )}
                />
              </PropsCard>
            )}

          {/* Assays */}
          {/* Assay Hits */}
          {compound?.assays_hits && compound?.assays_hits?.length > 0 && (
            <PropsCard>
              {/* Title */}
              <Flex align={"flex-end"} mb={4} gap={1}>
                <SubtitleSM>Activity Data</SubtitleSM>
                <HelperModal
                  title="Activity Data"
                  data={activitydataprops_helper}
                />
              </Flex>

              {/* Assay Hits */}
              <AssaysTable assays={compound?.assays_hits} isHit={true} />
            </PropsCard>
          )}

          {/* Screening collection */}
          <PropsCard>
            {/* Title */}
            <Flex align={"flex-end"} mb={4} gap={1}>
              <SubtitleSM>Primary Screening Data</SubtitleSM>
              <HelperModal
                title="Primary Screening Data"
                data={screeningdataprops_helper}
              />
            </Flex>

            {/* Assay Hits */}
            <AssaysTable assays={compound?.screening_collection} />
          </PropsCard>

          {/* External Resources */}
          <PropsCard>
            {/* Title */}
            <Flex align={"flex-end"} mb={4} gap={1}>
              <SubtitleSM>External Sources</SubtitleSM>
              <HelperModal
                title="External Sources"
                data={externalsources_helper}
              />
            </Flex>

            {/* External Resources List */}
            <ExternalSourcesList resources={compound?.external_resources} />
          </PropsCard>

          {/* References */}
          <PropsCard>
            {/* Title */}
            <Flex align={"flex-end"} mb={4} gap={1}>
              <SubtitleSM>References</SubtitleSM>
              <HelperModal title="References" data={papers_helper} />
            </Flex>

            {RefsPapersLoading ? (
              <Loading message="Loading papers data.." />
            ) : RefsPapersData && RefsPapersData.length > 0 ? (
              <PapersReferencesChart papersData={RefsPapersData} />
            ) : (
              <Text>No research papers related to this compound.</Text>
            )}
          </PropsCard>

          {/* Charts */}
          <PropsCard>
            {/* Title */}
            <Flex align={"flex-end"} mb={4} gap={1}>
              <SubtitleSM>Charts View</SubtitleSM>
            </Flex>
            {/* Papers References and Link to Papers View, Chart and link to charts View */}
            {/* tree of life link */}
            <Box>
              <TreeOfLifeLink compound={compound} />
            </Box>
          </PropsCard>
        </VStack>
      ) : (
        <Text textAlign={"center"}>No data for selected compound</Text>
      )}

      {/* Modal */}
      {id && (
        <FoldersModal
          isOpen={showSaveModal}
          onClose={() => setShowSaveModal(false)}
          payload={{
            saveElementPayload: {
              elementType: "COMPOUND",
              content: {
                elementId: id,
              },
            },
            successMessage: `Compound is successfully saved.`,
          }}
        />
      )}
    </>
  );
}
