import { Box, Flex } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { Options } from "react-select";
import VirtualList from "react-tiny-virtual-list";

const DefaultItemHeight = 40;

interface OptionItem {
  value: string;
  label: string;
}

interface MenuListProps {
  options: OptionItem[];
  children?: React.ReactNode;
  maxHeight: number;
  getValue: () => Options<OptionItem>;
}

export default function CustomMenuList({
  options,
  children,
  maxHeight,
  getValue,
}: MenuListProps): JSX.Element {
  const renderItem = (itemProps: {
    style: React.CSSProperties;
    index: number;
  }) => {
    if (Array.isArray(children)) {
      return (
        <Box
          key={`item-${itemProps.index}`}
          id={`item-${itemProps.index}`}
          style={{ ...itemProps.style }}
        >
          {children[itemProps.index]}
        </Box>
      );
    }
    return <Box key={itemProps.index}>{children}</Box>;
  };

  const [value] = getValue();

  const initialOffset = options?.indexOf(value) * DefaultItemHeight;
  const childrenOptions = useMemo(
    () => React.Children.toArray(children),
    [children]
  );
  const wrapperHeight: number =
    maxHeight < childrenOptions.length * DefaultItemHeight
      ? maxHeight
      : childrenOptions.length * DefaultItemHeight;

  return (
    <Flex className="react-virtualized-list-wrapper">
      <VirtualList
        width="100%"
        height={wrapperHeight + 6}
        scrollOffset={initialOffset}
        itemCount={childrenOptions.length}
        itemSize={DefaultItemHeight}
        renderItem={renderItem}
      />
    </Flex>
  );
}
