import { Link as RouterLink } from "react-router-dom";
import { Link, Icon } from "@chakra-ui/react";
import { IconType } from "react-icons";

interface SocialMediaIconProps {
  icon: IconType;
  link: string;
}

export function SocialMediaIcon({ icon, link }: SocialMediaIconProps) {
  return (
    <Link as={RouterLink} to={link} isExternal>
      <Icon
        boxSize={"26px"}
        as={icon}
        color="secondary.500"
        _hover={{
          color: "secondary.700",
          transition: "all .3s ease",
        }}
      />
    </Link>
  );
}
