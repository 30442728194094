import { useState } from "react";
import { Flex, Text, useToast } from "@chakra-ui/react";
import emailjs from "@emailjs/browser";

import Headers from "components/home/CTA/Headers";
import EmailInput from "components/home/CTA/EmailInput";
import SubmitEmail from "components/home/CTA/SubmitEmail";
import SuccessMessage from "components/home/CTA/SuccessMessage";

import { SubscriptionParams } from "environments";
import { HashLink as Link } from "react-router-hash-link";

export default function CTA() {
  return (
    <Flex
      w={[null, null, null, "90%", "95%", "100%"]}
      maxW={[null, null, null, "1100px", "1360px", "1360px"]}
      mx={"auto"}
      my={[null, null, null, "60px", "100px", "100px"]}
      borderRadius={"24px"}
      backgroundImage={require("assets/cta-bg.png")}
      backgroundPosition={"center center"}
      backgroundRepeat={"no-repeat"}
      backgroundSize={"cover"}
    >
      <Flex
        align={"center"}
        gap={"60px"}
        py={"80px"}
        w={"fit-content"}
        mx={"auto"}
      >
        <Headers />

        <Flex direction={"column"} gap={1}>
          <SubmitSubscription />
          <Text fontSize={"xs"} color={"primary.200"}>
            We care about your data. Check our <Link to="#footer" style={{textDecoration: "underline"}}>privacy policy</Link>.
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

function SubmitSubscription() {
  // Hooks
  const toast = useToast();

  // State
  const [email, setEmail] = useState("");
  const [sending, setSending] = useState(false);

  // Handler
  // NOTE: type not provided by emailJS
  async function sendEmail(e: any) {
    e.preventDefault();

    if (!email.trim()) return;

    const { SERVICE_ID, TEMPLATE_ID, PUBLIC_KEY } = SubscriptionParams;

    try {
      setSending(true);
      await emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY);

      toast({
        duration: 4000,
        render: () => <SuccessMessage type="subscription" />,
      });
    } catch (error) {
      toast({
        description: "Failed to send subscription request. Please try again.",
        status: "warning",
      });
    } finally {
      setSending(false);
    }

    // Clear textarea and reset text-area state
    e.target.reset();
    setEmail("");
  }

  return (
    <Flex gap={2} as="form" onSubmit={sendEmail}>
      <EmailInput email={email} setEmail={setEmail} />
      <SubmitEmail sending={sending} />
    </Flex>
  );
}
