import { GridItem, Image } from "@chakra-ui/react";

function Logo() {
  return (
    <GridItem
      colSpan={1}
      display={"flex"}
      justifySelf={"center"}
      alignItems={"center"}
      justifyContent={"center"}
      h={"full"}
      w={"full"}
    >
      <Image
        src="https://reacto.s3.eu-central-1.amazonaws.com/logo/green.svg"
        w={[null, null, null, "220px", "260px", "300px"]}
      />
    </GridItem>
  );
}

export default Logo;
