import { Dispatch } from "react";
import { Input, useTheme } from "@chakra-ui/react";
import { hexToRgba } from "utils/helpers";

interface EmailInputProps {
  email: string;
  setEmail: Dispatch<React.SetStateAction<string>>;
}

function EmailInput({ email, setEmail }: EmailInputProps) {
  // Theme
  const { colors } = useTheme();

  return (
    <Input
      name="email"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      type="email"
      minW={[null, null, null, "220px", "240px", "240px"]}
      placeholder="Enter your email"
      fontSize={"14px"}
      color={"neutral.100"}
      _placeholder={{ fontSize: "14px", color: "secondary.200" }}
      borderRadius={[null, null, null, "12px", "24px", "24px"]}
      bg={hexToRgba(colors.gray[50], 0.08)}
      borderWidth={1}
      borderColor={"blue.300"}
      _hover={{ borderColor: "blue.200" }}
      _focusVisible={{ borderColor: "neutral.100" }}
    />
  );
}

export default EmailInput;
