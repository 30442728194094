import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import authReducer, { AuthState } from './features/auth/authSlice'
import savedReducer, { SavedStateProps } from "./features/saved/savedSlice";
import embeddingReducer, { EmbeddingState } from "./features/embedding/embeddingSlice";

export interface StoreState {
  auth: AuthState;
  saved: SavedStateProps;
  embedding: EmbeddingState;
}

export const store = configureStore({
  reducer: {
    auth: authReducer,
    saved: savedReducer,
    embedding: embeddingReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
});

setupListeners(store.dispatch);
