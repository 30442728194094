import { useContext, useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Switch,
  Button,
  useToast,
  useBreakpointValue,
} from "@chakra-ui/react";
import SearchBox from "components/papers/suggestions/SearchBox";
import { PapersContext } from ".";
import FilterByEmbed from "components/papers/suggestions/filters/FilterByEmbed";
import FilterByType from "components/papers/suggestions/filters/FilterByType";
import { useSelector } from "react-redux";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import SidebarTitle from "components/ui/SidebarTitle";
import Disclaimer from "components/ui/Disclaimer";
import { CustomScrollBar } from "components/ui/CustomScrollBar";

export interface SeriesItem {
  id: string;
  value: number;
  depth: number;
  index: number;
}

export interface LiteratureProps {
  [key: string]: {
    $count: number;
  };
}

export default function SearchTools() {
  const toast = useToast();

  // Auth
  const { user } = useSelector(selectCurrentAuthData);

  // State
  const {
    suggestions,
    search,
    applyFilters,
    showEmbeddingControls,
    onToggleEmbeddingControls,
    papersToEmbed,
    initEmbedding,
    setPapersToEmbed,
    isEmbeddingInProgress,
  } = useContext(PapersContext);
  const [
    showEmbeddingNotAllowedDisclaimer,
    setShowEmbeddingNotAllowedDisclaimer,
  ] = useState(false);
  const [papersToEmbedExceededDisclaimer, setPapersToEmbedExceededDisclaimer] =
    useState(false);

  // const uniqueTypes = [
  //   ...new Set(suggestions?.map((sugg) => sugg?.paper_type)),
  // ];
  const handleEmbedChange = (option: string) => applyFilters(option);

  useEffect(() => {
    papersToEmbed.length > 10
      ? setPapersToEmbedExceededDisclaimer(true)
      : setPapersToEmbedExceededDisclaimer(false);
  }, [papersToEmbed]);

  const handleSelectedTypesUpdate = (e: string[]) =>
    applyFilters(
      e?.map((i: string) => i.toLowerCase()),
      true
    );

  const startEmbeddingBtnText = !!papersToEmbed.length
    ? `Start embeddings (${papersToEmbed?.length})`
    : "Start embeddings";

  const handleInitEmbedding = () => {
    // Check for papers validity
    if (!papersToEmbed?.length) return;
    if (papersToEmbed.length > 10) {
      toast({
        description: "Cannot embed more than 10 papers per embed process",
        status: "error",
        position: "top-right",
      });
    }
    initEmbedding(papersToEmbed);
    setPapersToEmbed([]);
  };

  const isDisabled =
    isEmbeddingInProgress ||
    papersToEmbed.length > 10 ||
    papersToEmbed.length === 0;

  const onSwitchEmbeddingController = () => {
    user?.user_type === "premium"
      ? onToggleEmbeddingControls()
      : setShowEmbeddingNotAllowedDisclaimer(true);
  };

  // Responsiveness: ~992px, ~1280px, ~1536px
  // + 40 (search) + 8 (gap)
  // TODO: provide details
  const SearchToolsHeight = useBreakpointValue({
    lg: "calc(100vh - 228px)",
    xl: "calc(100vh - 246px)",
    "2xl": "calc(100vh - 264px)",
  });

  const SearchToolsStyle = {
    height: SearchToolsHeight,
    padding: "0",
  };

  return (
    <Flex w={"100%"} overflow={"hidden"} direction={"column"} gap={2}>
      {/* Search Input */}
      <SearchBox />

      {/* Search hint */}
      {!search.searching && !!search.query && search.query?.length < 4 && (
        <Text color={"gray.400"} fontSize={"12px"} my={1} ml={10}>
          type at least 4 characters..{" "}
        </Text>
      )}

      <CustomScrollBar style={SearchToolsStyle}>
        <Flex w={"100%"} overflow={"hidden"} direction={"column"} gap={2}>
          {/* Filtering */}
          {suggestions && suggestions?.length > 0 && (
            <Box color={"gray.500"} fontSize={"12px"} py={8}>
              <Flex direction={"column"} gap={6}>
                <FilterByEmbed onEmbedChanged={handleEmbedChange} />
                <FilterByType
                  // types={initialFilterTypes}
                  onUpdateSelectedTypes={handleSelectedTypesUpdate}
                />
              </Flex>
            </Box>
          )}

          {/* Embedding Process */}
          {!!suggestions?.length ? (
            <Flex direction={"column"} gap={2}>
              <Flex gap={2} align={"center"}>
                {/* Switch show controls */}
                <Switch
                  size="sm"
                  isChecked={showEmbeddingControls}
                  onChange={onSwitchEmbeddingController}
                  sx={{
                    "span[class*='chakra-switch__track']": {
                      _checked: { bg: "secondary.400" },
                      _focusVisible: {
                        boxShadow: "none",
                      },
                      _focus: {
                        boxShadow: "none",
                      },
                    },
                  }}
                />
                {/* Title */}
                <SidebarTitle text="Embeddings Controls" color="neutral.700" />
              </Flex>

              {/* Proceed */}
              {showEmbeddingControls && user?.user_type === "premium" && (
                <Button
                  bg={"highlight.primary"}
                  color={"neutral.100"}
                  borderColor={"highlight.primary"}
                  borderWidth={1}
                  h={"fit-content"}
                  borderRadius={"20px"}
                  minW={"100px"}
                  px={0}
                  py={1.5}
                  fontSize={[null, null, null, "12px", "12px", "14px"]}
                  fontWeight={"400"}
                  _hover={{
                    opacity: !isDisabled && 0.8,
                  }}
                  // When embedding true
                  isLoading={false}
                  isDisabled={isDisabled}
                  _disabled={{
                    borderColor: "gray.300",
                    bg: "gray.300",
                    cursor: "not-allowed",
                  }}
                  onClick={handleInitEmbedding}
                >
                  {/* {buttonText} */}
                  {startEmbeddingBtnText}
                </Button>
              )}
            </Flex>
          ) : null}

          {/* Show a EmbeddingNotAllowed disclaimer */}
          {showEmbeddingNotAllowedDisclaimer && (
            <Disclaimer
              content={"Embedding feature is exclusive for premium users only."}
              mt={1}
              type="info"
            />
          )}

          {/* Show a PapersToEmbedExceeded disclaimer */}
          {showEmbeddingControls && papersToEmbedExceededDisclaimer && (
            <Disclaimer
              content={"You can only embed 10 papers per one process."}
              mt={1}
              type="warning"
            />
          )}
        </Flex>
      </CustomScrollBar>
    </Flex>
  );
}
