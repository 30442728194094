import { GridItem, Text } from "@chakra-ui/react";
import { hexToRgba } from "utils/helpers";
import { getPaperColor } from "views/compounds/helpers";

interface SuggestionTypeProps {
  type: string | undefined;
  color: string | undefined;
}

function SuggestionType({ type, color }: SuggestionTypeProps) {
  return (
    <GridItem justifyContent={"center"} textAlign={"right"}>
      <Text
        textTransform={"uppercase"}
        lineHeight={"1.3"}
        width={"fit-content"}
        color={color}
        borderColor={color}
        borderWidth={1}
        borderRadius={"12px"}
        padding={"2px 4px"}
        fontSize={"10px"}
        fontWeight={"500"}
        ml={"auto"}
        bg={hexToRgba(getPaperColor(type), 0.1)}
      >
        {type}
      </Text>
    </GridItem>
  );
}

export default SuggestionType;
