import {
  Box,
  Flex,
  Text,
  Tooltip,
  useColorModeValue,
  useTheme,
} from "@chakra-ui/react";
import { keyframes } from "@emotion/react";

import { truncateString } from "./helpers";
import { PaperProps } from "models/papers/PaperProps";
import { getPaperColor } from "views/compounds/helpers";
import { hexToRgba } from "utils/helpers";
import { useContext } from "react";
import { PapersContext } from "views/papers";

interface PaperItemProps {
  paper: PaperProps;
  origin?: boolean;
}

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
`;

function PaperItem({ paper, origin }: PaperItemProps) {
  // Theme
  const { colors } = useTheme();
  const color = useColorModeValue("neutral.100", "gray.700");
  const yearColor = useColorModeValue("gray.500", "gray.400");
  const titleColor = useColorModeValue("gray.600", "gray.400");
  const borderColor = useColorModeValue("gray.300", "gray.700");
  const bg = useColorModeValue(hexToRgba(colors.blue[600], 0.06), "dark.800");

  const { handleHighlightedPaper } = useContext(PapersContext);

  return (
    <Flex
      direction={"column"}
      gap={2}
      width="100%"
      py={3}
      px={1.5}
      _hover={{ bg: bg }}
      borderBottom={"1px"}
      borderBottomColor={borderColor}
      onMouseEnter={() => handleHighlightedPaper(paper)}
      onMouseLeave={() => handleHighlightedPaper({})}
    >
      {/* year and pulse */}
      <Flex justify={"space-between"} align={"center"}>
        <Flex align={"center"} gap={1}>
          <Text
            textAlign={"right"}
            fontSize={"10px"}
            textTransform={"uppercase"}
            lineHeight="1.3"
            width="fit-content"
            color={color}
            bg={getPaperColor(paper.paper_type)}
            borderRadius={"3px"}
            padding="2px 4px"
            h={"fit-content"}
          >
            {paper?.paper_type ?? "Unclassified"}
          </Text>

          {!!origin && (
            <Tooltip label={"Origin paper"} placement="top">
              <Box
                width="8px"
                height="8px"
                borderRadius="50%"
                background="red.500"
                animation={`${pulse} 1.4s infinite`}
              />
            </Tooltip>
          )}
        </Flex>

        <Text
          textAlign={"right"}
          fontSize={"sm"}
          color={yearColor}
          lineHeight={"1.3"}
        >
          {paper?.year_published}
        </Text>
      </Flex>

      {/* title */}
      <Text
        color={titleColor}
        fontSize={"14px"}
        fontWeight={"400"}
        lineHeight={"1.3"}
      >
        {truncateString(paper?.title ?? "", 62)}
      </Text>
    </Flex>
  );
}

export default PaperItem;
