import { Flex, Image, Text, useColorModeValue } from "@chakra-ui/react";
import LinkToView from "../buttons/LinkToView";
import { PiGraph, PiTestTube } from "react-icons/pi";
import { BsDiagram2, BsPaperclip } from "react-icons/bs";
import emptyFolderImg from "assets/empty-folder.svg";

function EmptyFolder() {
  const headerColor = useColorModeValue("gray.600", "gray.400");
  const color = useColorModeValue("gray.600", "gray.300");

  return (
    <Flex
      direction={"column"}
      align={"center"}
      justify={"center"}
      gap={10}
      w={"full"}
      h={"full"}
    >
      <Flex direction={"column"} align={"center"} justify={"center"} gap={2}>
        <Image
          src={emptyFolderImg}
          w={"120px"}
          alt="empty folder"
          loading="lazy"
        />
        <Text color={headerColor}>
          There are no saved items in this folder.
        </Text>
      </Flex>

      <Flex direction={"column"} align={"center"} justify={"center"} gap={3}>
        <Text color={color} fontSize={"14px"}>
          Select from the following items:{" "}
        </Text>
        <Flex align={"center"} justify={"center"} gap={2}>
          <LinkToView name="Compounds" icon={PiGraph} link="/compounds" />
          <LinkToView name="Assays" icon={PiTestTube} link="/assays" />
          <LinkToView name="Literature" icon={BsPaperclip} link="/papers" />
          <LinkToView name="Tree of life" icon={BsDiagram2} link="/graph" />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default EmptyFolder;
