import { useMemo } from "react";

import ReactECharts from "echarts-for-react";

import { AugmentationDensity } from "models/compounds/AugmentationDensity";

import { useTheme } from "@chakra-ui/react";
import { stylesToString } from "utils/helpers";

interface DataDensityBarProps {
  data: AugmentationDensity;
}

export default function DataDensityBar({ data }: DataDensityBarProps) {
  const { colors } = useTheme();

  const categories = useMemo(
    () => [
      "chemical",
      "indication",
      "pharmacokinetics",
      "pharmacodynamics",
      "safety",
      "similarity",
      "synthesis",
    ],
    []
  );

  function getLabel(value: number): string {
    switch (value) {
      case 1:
        return "Not Available";
      case 2:
        return "Original Available";
      case 3:
        return "Enrichement Available";
      case 4:
        return "Original & Enriched";
      default:
        return "";
    }
  }

  const tooltip = {
    formatter: function ({
      color,
      name,
      value,
    }: {
      color: string;
      name: string;
      value: number;
    }) {
      const tooltipStyle = {
        display: "flex",
        "flex-direction": "row",
        "justify-content": "center",
        "align-items": "center",
        gap: "10px",
        padding: "8px",
        margin: "0",
        "border-radius": "13px",
        "font-size": "11px",
      };

      const tooltipTitleStyle = {
        display: "block",
        "font-weight": "bold",
        "max-width": "200px",
        "white-space": "normal",
        "text-align": "center",
        color: color,
      };

      const inlineTooltipStyle = stylesToString(tooltipStyle);
      const inlineTooltipTitleStyle = stylesToString(tooltipTitleStyle);

      const tooltipContent = [
        `<span style="${inlineTooltipTitleStyle}">${name}</span>`,
        `<span> ${getLabel(value)}</span>`,
      ].join("");

      return `<Box style="${inlineTooltipStyle}">${tooltipContent}</Box>`;
    },
    padding: 0,
    position: "left",
  };

  const option = {
    xAxis: {
      type: "category",
      data: categories,
      show: false,
    },
    yAxis: {
      type: "value",
      show: false,
    },
    tooltip,
    series: [
      {
        data: [
          data.chemical,
          data.indication,
          data.pharmacokinetics,
          data.pharmacodynamics,
          data.safety,
          data.similarity,
          data.synthesis,
        ],
        type: "bar",
        itemStyle: {
          color: function (params: echarts.ECElementEvent) {
            switch (params.data) {
              case 1:
                return colors.none;

              case 2:
                return colors.low;

              case 3:
                return colors.middle;

              case 4:
                return colors.high;

              default:
                return colors.none;
            }
          },
        },
      },
    ],
  };

  return (
    <ReactECharts
      option={option}
      style={{ width: "80%", height: "30px", margin: "0 auto" }}
    />
  );
}
