import { useContext } from "react";
import { Flex, useBreakpointValue } from "@chakra-ui/react";
import { PapersContext } from ".";
import SuggestionsList from "components/papers/suggestions/SuggestionsList";
import Loading from "components/ui/Loading";
import { maxHeight } from "utils/responsive";
import NoResults from "components/papers/suggestions/NoResults";

export interface SuggestionProps {
  id: string;
  year_published: number;
  title: string;
  paper_type: string;
  embedded?: boolean;
}

export interface SeriesItem {
  id: string;
  value: number;
  depth: number;
  index: number;
}

export interface LiteratureProps {
  [key: string]: {
    $count: number;
  };
}

function SuggestionsView() {
  // Context
  const { search, suggestions } = useContext(PapersContext);

  // Responsiveness: ~992px, ~1280px, ~1536px
  const SuggestionsViewHeight = useBreakpointValue(maxHeight);

  // if loading search
  if (search.searching)
    return (
      <Flex
        direction={"column"}
        h={SuggestionsViewHeight}
        w={"100%"}
        align={"center"}
        justify={"center"}
      >
        <Loading message="Searching papers.." />
      </Flex>
    );

  // if no search result found
  if (
    suggestions?.length === 0 &&
    search.searched &&
    search.query?.length >= 4 &&
    !search.searching
  )
    return (
      <Flex
        direction={"column"}
        h={SuggestionsViewHeight}
        w={"100%"}
        align={"center"}
        justify={"center"}
      >
        <NoResults />
      </Flex>
    );
  return (
    <Flex
      h={"100%"}
      w={"100%"}
      direction={"column"}
      overflow={"hidden"}
      position={"relative"}
      px={[null, null, null, "3%", "6%", "8%"]}
    >
      {/* Search result list */}
      {!!suggestions.length && <SuggestionsList />}
    </Flex>
  );
}

export default SuggestionsView;
