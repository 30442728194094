import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Flex, Icon, Link, Text, useColorModeValue } from "@chakra-ui/react";
import { MdArrowCircleDown, MdArrowCircleUp } from "react-icons/md";

interface SourcesProps {
  sources: { title: string; id: string }[] | undefined;
}

function Sources({ sources }: SourcesProps) {
  const [showAll, setShowAll] = useState(false);

  // limit nbr of sources to show to 2 initially
  const sourcesToShow = showAll ? sources : sources?.slice(0, 2);

  // Theme
  const hoverColor = useColorModeValue("blue.700", "blue.200");
  const color = useColorModeValue("blue.500", "blue.400");
  const titleColor = useColorModeValue("primary.300", "highlight.primary");

  return (
    <>
      {sources && sources?.length > 0 && (
        <Flex direction={"column"} gap={1} mt={4} align={"flex-start"}>
          <Text fontSize={"14px"} fontWeight={"500"} color={titleColor}>
            Read Sources
          </Text>

          {/* sources list */}
          <Flex direction={"column"} gap={1}>
            {sourcesToShow?.map(
              (source: { title: string; id: string }, index: number) => (
                <Link
                  key={"term" + index}
                  as={RouterLink}
                  to={!!source.id ? `/papers/${source.id}` : undefined}
                  _hover={{
                    textDecoration: "none",
                  }}
                >
                  <Text
                    color={color}
                    fontSize={"13px"}
                    lineHeight={[null, null, null, "1.5", "1.4", "1.3"]}
                    transition="all ease .2s"
                    _hover={{ color: hoverColor }}
                  >
                    ✦ {source.title}
                  </Text>
                </Link>
              )
            )}
          </Flex>

          {/* read more/less */}
          {sources.length > 2 && (
            <Flex
              color={"gray.500"}
              gap={1}
              align={"center"}
              cursor={"pointer"}
              onClick={() => setShowAll(!showAll)}
              mt={1}
              _hover={{
                color: "gray.600",
              }}
            >
              <Icon
                as={showAll ? MdArrowCircleUp : MdArrowCircleDown}
                boxSize={4}
              />
              <Text fontSize={"12px"} fontWeight={"500"} lineHeight={"1.4"}>
                {showAll ? "read less" : "read more"}
              </Text>
            </Flex>
          )}
        </Flex>
      )}
    </>
  );
}

export default Sources;
