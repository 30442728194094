import { Box, Heading, Text } from "@chakra-ui/react";

function Header() {
  return (
    <Box textAlign={"center"} w={"fit-content"} maxW={"600px"} mx={"auto"}>
      <Text
        size={[null, null, null, "sm", "sm", "md"]}
        fontWeight={"bold"}
        letterSpacing={2}
        color={"neutral.100"}
        opacity={0.85}
      >
        - How can we help?
      </Text>
      <Heading
        size={[null, null, null, "lg", "lg", "xl"]}
        letterSpacing={1}
        color={"neutral.100"}
        opacity={0.65}
      >
        Contact Us
      </Heading>
      <Text
        pt={6}
        color={"primary.100"}
        fontFamily={"Poppins, sans-serif"}
        textAlign={"center"}
      >
        Our team will reach out to you as soon as possible.
      </Text>
    </Box>
  );
}

export default Header;
