import { Box, Flex } from "@chakra-ui/react";

import HumanMessage from "./HumanMessage";
import BotMessage from "./BotMessage";

import BotAvatar from "./BotAvatar";
import { ConversationProps } from "models/chat/MessageProps";

interface BubbleProps {
  content: ConversationProps;
  questionOnWait?: string | undefined;
}

export default function MessageBubble({
  content,
  questionOnWait,
}: BubbleProps) {
  return (
    <Flex direction={"column"}>
      {/* human question */}
      <Flex w="100%" my={4} pr={2} pl={"56px"}>
        <Box
          alignContent={"flex-start"}
          width={"fit-content"}
          display={"inline-block"}
        >
          <HumanMessage botResponse={content} questionOnWait={questionOnWait} />
        </Box>
      </Flex>

      {/* bot reply */}
      <Flex w="100%" mb={4} pr={2} pl={2}>
        <BotAvatar />
        <Box
          alignContent={"flex-start"}
          width={"fit-content"}
          display={"inline-block"}
        >
          <BotMessage botResponse={content} />
        </Box>
      </Flex>
    </Flex>
  );
}
