import { Flex, Heading, Text } from "@chakra-ui/react";

function ContactUs() {
  return (
    <Flex direction={"column"} gap={3} mt={6}>
      <Heading size={"sm"} color={"gray.700"}>
        Contact Us
      </Heading>

      <Flex direction={"column"} gap={1}>
        <Text color={"gray.600"} w={[null, null, null, "85%", "80%", "75%"]}>
          Westerbachstrasse 23, VitaFluence.ai GmbH,
          <br />
          Kronberg i. Taunus, Germany
        </Text>
      </Flex>
    </Flex>
  );
}

export default ContactUs;
