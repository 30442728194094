import { useContext, useMemo } from "react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { CompoundsContext } from ".";
import BreadcrumbHome from "components/ui/BreadcrumbHome";

export default function CompoundsBreadcrumb() {
  // Navigation
  const navigate = useNavigate();
  const { id } = useParams();

  // Theme
  const idColor = useColorModeValue("gray.600", "gray.400");
  const color = useColorModeValue("gray.600", "gray.200");

  // Context
  const { searchCount } = useContext(CompoundsContext);
  // Handlers
  const handleGoToSearchResults = () => {
    if (id) {
      navigate(-1);
    }
  };

  const handleGoToIndex = () => {
    navigate(`/compounds`);
  };

  // Responsiveness: ~992px, ~1280px, ~1536px
  const truncLength = useBreakpointValue({
    lg: 10,
    xl: 12,
    "2xl": 14,
  });

  const slicedId = useMemo(() => {
    console.log(truncLength);
    console.log(id);
    console.log(searchCount);

    if (!truncLength) return "";
    else {
      if (searchCount && id)
        return (
          id?.slice(0, truncLength) + (id?.length > truncLength ? ".." : "")
        );
      else return id;
    }
  }, [id, searchCount, truncLength]);

  return (
    <Breadcrumb
      spacing={1}
      separator={<ChevronRightIcon color="gray.500" />}
      fontSize={"14px"}
      color={color}
    >
      {/* Index */}
      <BreadcrumbItem>
        <BreadcrumbLink onClick={handleGoToIndex} lineHeight={1} p={1.5}>
          <BreadcrumbHome />
        </BreadcrumbLink>
      </BreadcrumbItem>

      {/* Search Results */}
      {searchCount && (
        <BreadcrumbItem isCurrentPage={!id}>
          <BreadcrumbLink onClick={handleGoToSearchResults}>
            Result{searchCount < 2 ? "" : "s"}
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}

      {/* Id */}
      {id && (
        <BreadcrumbItem isCurrentPage color={idColor} fontWeight={"500"}>
          <BreadcrumbLink>
            <Text fontSize={"small"}>{slicedId}</Text>
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
    </Breadcrumb>
  );
}
