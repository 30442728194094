import { useContext, useMemo, useCallback, useState } from "react";
import { useNavigate, useParams, createSearchParams } from "react-router-dom";
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  chakra,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { AssaysControlsContext } from ".";
import { AssayChartItemProps, AssayDataProps } from "models/assays/AssayProps";
import { DetailedProtocol } from "./components/DetailedProtocol";
import ActivityChart from "./components/ActivityChart";
import { useFetch } from "hooks";
import { CompoundProps } from "models/compounds/CompoundProps";
import { environment } from "environments";
import { BsBookmarkCheck } from "react-icons/bs";
import FoldersModal from "components/saved/FoldersModal";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import ActionButton from "components/buttons/ActionButton";
import Loading from "components/ui/Loading";
import MainPanelError from "components/ui/MainPanelError";
import { maxHeight } from "utils/responsive";

const properties = [
  ["Indication", "indication"],
  ["Assay type", "assay_type"],
  ["Components", "components"],
  ["Detection method", "detection_method"],
];

export default function AssayItem() {
  // Hooks
  const { id } = useParams();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  // States
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [error, setError] = useState("");

  // Theme
  const color = useColorModeValue("gray.700", "gray.200");
  const bg = useColorModeValue("whiteAlpha.600", "dark.700");
  const linkColor = useColorModeValue("secondary.600", "secondary.300");
  const linkHoverColor = useColorModeValue("secondary.300", "secondary.200");

  // Context
  const { selectedAssayTypes, selectedIndication } = useContext(
    AssaysControlsContext
  );

  // API
  const { data: assay, loading } = useFetch(
    useCallback(async () => {
      try {
        const response = await axiosPrivate.get(
          `${environment.BACKEND_API}/api/get_assay_by_id/${id}`
        );
        return response.data;
      } catch (error) {
        setError("Error fetching data");
        return;
      }
    }, [id, axiosPrivate])
  );

  const chartData = useMemo(() => {
    if (id) {
      const assayData: AssayChartItemProps[] = (assay?.assay_data ?? []).map(
        (obj: AssayDataProps) => ({
          id: obj?.calibr_id ?? "",
          name: obj?.name ?? "",
          value: !!obj?.ac50 ? [obj?.ac50] : [],
          assay_type: obj?.assay_type ?? "",
          ac_precision: obj?.ac_precision ?? "",
          efficacy: !!obj?.efficacy ? [obj?.efficacy] : [],
          r_sq: !!obj?.r_sq ? [obj?.r_sq] : [],
          smiles: obj?.smiles ?? "",
        })
      );

      const groupedData: { [key: string]: AssayChartItemProps } = {};

      assayData.forEach((item: AssayChartItemProps) => {
        const {
          id,
          name,
          value,
          assay_type,
          ac_precision,
          efficacy,
          r_sq,
          smiles,
        } = item;

        if (!groupedData[id]) {
          groupedData[id] = {
            id,
            name,
            value: [],
            efficacy: [],
            r_sq: [],
            assay_type,
            ac_precision,
            smiles,
          };
        }

        if (value && Array.isArray(groupedData[id]?.value)) {
          groupedData[id]?.value?.push(...value);
        }

        if (efficacy && Array.isArray(groupedData[id]?.efficacy)) {
          groupedData[id]?.efficacy?.push(...efficacy);
        }

        if (r_sq && Array.isArray(groupedData[id]?.r_sq)) {
          groupedData[id]?.r_sq?.push(...r_sq);
        }
      });

      const uniqueObjects = Object.values(groupedData);

      const rmDups = uniqueObjects.map((item: AssayChartItemProps) => {
        const uniquePotencyValues = new Set(item.value);
        const uniqueEfficacyValues = new Set(item.efficacy);
        const uniqueRsqValues = new Set(item.r_sq);

        return {
          ...item,
          value: Array.from(uniquePotencyValues),
          efficacy: Array.from(uniqueEfficacyValues),
          r_sq: Array.from(uniqueRsqValues),
        };
      });

      return rmDups;
    }
  }, [id, assay]);

  // compounds
  const { data: compounds } = useFetch(
    useCallback(async () => {
      if (chartData) {
        // get compounds to the associated assay
        const compoundsData = await Promise.all(
          chartData?.map(async (item) => {
            try {
              const response = await axiosPrivate.get<CompoundProps>(
                `${environment.BACKEND_API}/api/get_compound_by_id/${item.id}`
              );
              return response.data;
            } catch (e) {
              console.log(e);
            }
          })
        );
        return compoundsData as CompoundProps[];
      }
      return [];
    }, [chartData, axiosPrivate])
  );

  const handlePropertyClick = (key: string) => (item: React.MouseEvent) => {
    const { textContent: property } = item.target as HTMLElement;

    if (property)
      if (key === "indication") {
        navigate({
          pathname: "/assays",
          search: `?${createSearchParams({
            types: selectedAssayTypes,
            indication: property,
          })}`,
        });
      } else {
        const types = property.split(",").map((item: string) => item.trim());

        navigate({
          pathname: "/assays",
          search: `?${createSearchParams({
            indication: selectedIndication,
            types: types.join(","),
          })}`,
        });
      }
  };

  const StyledText = chakra(Text, {
    baseStyle: {
      textDecoration: "none",
      color: linkColor,
      transition: "color 0.3s",
      cursor: "pointer",
      fontSize: "15px",

      "&:hover": { color: linkHoverColor },
    },
  });

  // Responsiveness: ~992px, ~1280px, ~1536px
  const AssayItemHeight = useBreakpointValue(maxHeight);

  // if error
  if (!!error)
    return (
      <Flex h={AssayItemHeight} w={"100%"}>
        <MainPanelError />
      </Flex>
    );

  // if Loading
  if (loading)
    return (
      <Flex
        direction={"column"}
        h={AssayItemHeight}
        w={"100%"}
        align={"center"}
        justify={"center"}
      >
        <Loading message="Loading assay data.." />
      </Flex>
    );

  return (
    <Box>
      {assay && (
        <Card bg={bg} p={4}>
          <CardHeader>
            {/* header */}
            <Flex gap={5} align={"center"}>
              <Flex
                w={"full"}
                justify={"space-between"}
                align={"center"}
                gap={2}
              >
                <Heading
                  size={[null, null, null, "sm", "sm", "md"]}
                  color={"highlight.primary"}
                  fontWeight="bold"
                  w={"90%"}
                  maxW={"700px"}
                >
                  {assay.name}
                </Heading>

                <Text
                  fontSize={[null, null, null, "sm", "sm", "md"]}
                  color="gray.400"
                  w={"fit-content"}
                  textAlign={"right"}
                >
                  {assay.assay_id}
                </Text>
              </Flex>
              <ActionButton
                label={"Save"}
                icon={<BsBookmarkCheck />}
                onClick={() => setShowSaveModal(true)}
              />
            </Flex>

            {/* authors */}
            <Box my={2} w={"90%"}>
              <Text color="gray.400" fontSize={"14px"}>
                — {assay.authors}
              </Text>
            </Box>
          </CardHeader>

          <CardBody
            color={color}
            fontSize={[null, null, null, "sm", "sm", "md"]}
          >
            <Box w="90%" mb={4} color={color}>
              <Text>{assay.summary}</Text>
            </Box>

            {/* properties */}
            <Flex alignItems="center">
              {assay.overview && (
                <Box>
                  {properties.map(([label, key]) => (
                    <Flex alignItems="center" key={label}>
                      <Text
                        flexWrap="wrap"
                        color={color}
                        display={"flex"}
                        gap={1}
                      >
                        <Text fontWeight={"500"}>
                          <Text as={"span"} color={linkColor} mr={1}>
                            ⬦
                          </Text>
                          {label}:
                        </Text>

                        {["indication", "assay_type"].includes(key) ? (
                          <StyledText onClick={handlePropertyClick(key)}>
                            {assay.overview[
                              key as keyof typeof assay.overview
                            ] || ""}
                          </StyledText>
                        ) : (
                          assay.overview[key as keyof typeof assay.overview] ||
                          ""
                        )}
                      </Text>
                    </Flex>
                  ))}
                </Box>
              )}
            </Flex>

            <Tabs isLazy isManual variant="enclosed-colored" mt={8}>
              <TabList>
                <Tab>Assay Data</Tab>
                <Tab>Detailed Protocol</Tab>
              </TabList>
              <TabPanels>
                <TabPanel pl={0} mt={3}>
                  <ActivityChart chartData={chartData} compounds={compounds} />
                </TabPanel>
                <TabPanel pl={0}>
                  <DetailedProtocol assay={assay} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </CardBody>
        </Card>
      )}

      {/* Modal */}
      {id && (
        <FoldersModal
          isOpen={showSaveModal}
          onClose={() => setShowSaveModal(false)}
          payload={{
            saveElementPayload: {
              elementType: "ASSAY",
              content: {
                elementId: id,
              },
            },
            successMessage: `Assay is successfully saved.`,
          }}
        />
      )}
    </Box>
  );
}
