import { Flex } from "@chakra-ui/react";
import { CurveSectionDown2, CurveSectionUp2 } from "components/ui/Curves";
import { ReactNode } from "react";

interface ContainerProps {
  children: ReactNode;
}
function Container({ children }: ContainerProps) {
  return (
    <>
      <Flex
        direction={"column"}
        w="100vw"
        position="absolute"
        top={0}
        left={0}
        zIndex={100}
      >
        <CurveSectionUp2 />
      </Flex>

      {children}
      <Flex
        direction={"column"}
        w="100vw"
        position="absolute"
        bottom={0}
        left={0}
        zIndex={100}
        transform={"rotate(180deg)"}
      >
        <CurveSectionDown2 />
      </Flex>
    </>
  );
}

export default Container;
