import {
  Box,
  useRadio,
  useColorModeValue,
  useTheme,
  UseRadioProps,
} from "@chakra-ui/react";
import { hexToRgba } from "utils/helpers";

interface FeedbackTypeProps extends UseRadioProps {
  children: React.ReactNode;
}

export default function FeedbackType(props: FeedbackTypeProps | undefined) {
  // Theme
  const { colors } = useTheme();
  const color = useColorModeValue("gray.600", "gray.300");
  const border = useColorModeValue("gray.200", "gray.500");
  const bg = useColorModeValue("transparent", hexToRgba(colors.dark[800], 0.1));

  const { getInputProps, getRadioProps } = useRadio(props);
  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        color={color}
        bg={bg}
        borderWidth={1}
        borderColor={border}
        borderRadius="6px"
        fontSize="14px"
        height={"fit-content"}
        _checked={{
          bg: "highlight.primary",
          color: "neutral.100",
          borderColor: "highlight.primary",
          fontWeight: "500",
          letterSpacing: "-0.012rem",
          transition: "border 0.2s ease",
        }}
        transition="background 0.2s ease"
        px={5}
        py={2}
        display={"flex"}
        alignItems={"center"}
        gap={2}
      >
        {props?.children}
      </Box>
    </Box>
  );
}
