import { ReactElement } from "react";
import { IconButton, Tooltip, useColorModeValue } from "@chakra-ui/react";

interface ActionButtonProps {
  label: string;
  icon: ReactElement;
  isDisabled?: boolean;
  onClick?: () => void;
}

export default function ActionButton({
  label,
  icon,
  onClick,
  isDisabled,
}: ActionButtonProps) {
  const activeBg = useColorModeValue("neutral.200", "dark.600");

  return (
    <Tooltip
      label={label}
      placement="bottom"
      bg="gray.200"
      color="gray.700"
      fontSize={"12px"}
    >
      <IconButton
        icon={icon}
        aria-label={label}
        bg="transparent"
        fontSize={[null, null, null, "sm", "sm", "md"]}
        color={"gray.500"}
        borderRadius={"50%"}
        boxShadow={"md"}
        isDisabled={isDisabled}
        _hover={{ bg: activeBg, boxShadow: "lg" }}
        _active={{ bg: activeBg }}
        onClick={onClick}
      />
    </Tooltip>
  );
}
