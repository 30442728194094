import React from "react";
import { IconButton, Tooltip, useColorModeValue } from "@chakra-ui/react";

export interface OperatorProps {
  operator: {
    name: string;
    handler: () => void;
    tooltip: string;
    icon: React.ElementType;
  };
  active: string;
}

export default function Operator({ operator, active }: OperatorProps) {
  const { name, handler, tooltip, icon: IconComponent } = operator;

  const iconColor = useColorModeValue("neutral.500", "neutral.300");
  const activeColor = useColorModeValue("secondary.500", "secondary.500");

  return (
    <Tooltip label={tooltip} placement="right">
      <IconButton
        aria-label={name}
        icon={<IconComponent />}
        color={active === operator.name ? activeColor : iconColor}
        onClick={handler}
        variant="link"
        fontSize={[null, null, null, "16px", "18px", "20px"]}
      />
    </Tooltip>
  );
}
