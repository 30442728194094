import {
  SavedFolderProps,
  selectCurrentSavedData,
  setSuccess,
} from "redux/features/saved/savedSlice";
import FolderContentSection, {
  DeleteElementHandlerProps,
} from "./FolderContentSection";
import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import { environment } from "environments";
import { CustomScrollBar } from "components/ui/CustomScrollBar";
import { useBreakpointValue } from "@chakra-ui/react";
import { maxHeight } from "utils/responsive";

interface FolderContentProps {
  folder: SavedFolderProps;
}

function FolderContent({ folder }: FolderContentProps) {
  const dispatch = useDispatch();
  const { savedFolders } = useSelector(selectCurrentSavedData);
  const axiosPrivate = useAxiosPrivate();

  const removeElementHandler = async ({
    elementType,
    content,
  }: DeleteElementHandlerProps) => {
    try {
      const newFolders = savedFolders.map((fol: SavedFolderProps) => {
        if (fol.id === folder.id) {
          switch (elementType) {
            case "COMPOUND":
              return {
                ...fol,
                compounds: fol.compounds.filter(
                  (item: { compound_id: string; name: string }) =>
                    item.compound_id !== content.elementId
                ),
              };
            case "LITERATURE":
              return {
                ...fol,
                literatures: fol.literatures.filter(
                  (item: { id: string; title: string }) =>
                    item.id !== content.elementId
                ),
              };
            case "ASSAY":
              return {
                ...fol,
                assays: fol.assays.filter(
                  (item: { assay_id: string; name: string }) =>
                    item.assay_id !== content.elementId
                ),
              };
            case "TREEOFLIFE":
              return {
                ...fol,
                treeOfLifes: fol.treeOfLifes.filter(
                  (item: {
                    compound_id: string;
                    name: string;
                    selected_feature: string;
                  }) =>
                    !(
                      item.compound_id === content.elementId &&
                      item.selected_feature === content.selected_feature
                    )
                ),
              };
          }
        }
        return fol;
      });
      dispatch(setSuccess(newFolders));
      await axiosPrivate.put(`${environment.BACKEND_API}/api/remove_elements`, {
        folderId: folder.id,
        elementType,
        content,
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Responsiveness: ~992px, ~1280px, ~1536px
  const FolderContainerHeight = useBreakpointValue(maxHeight);

  const FolderContainerList = {
    height: `calc(${FolderContainerHeight} - 32px)`,
    width: "95%",
    maxWidth: "800px",
    padding: 0,
  };

  return (
    <CustomScrollBar style={FolderContainerList}>
      {folder?.compounds?.length ? (
        <FolderContentSection
          data={folder?.compounds}
          sectionType="COMPOUND"
          deleteElementHandler={removeElementHandler}
        />
      ) : null}
      {folder?.assays?.length ? (
        <FolderContentSection
          data={folder?.assays}
          sectionType="ASSAY"
          deleteElementHandler={removeElementHandler}
        />
      ) : null}
      {folder?.literatures?.length ? (
        <FolderContentSection
          data={folder?.literatures}
          sectionType="LITERATURE"
          deleteElementHandler={removeElementHandler}
        />
      ) : null}
      {folder?.treeOfLifes?.length ? (
        <FolderContentSection
          data={folder?.treeOfLifes}
          sectionType="TREEOFLIFE"
          deleteElementHandler={removeElementHandler}
        />
      ) : null}
    </CustomScrollBar>
  );
}

export default FolderContent;
