import {
  Box,
  SimpleGrid,
  Text,
  Link,
  Flex,
  Icon,
  useTheme,
  useColorModeValue,
} from "@chakra-ui/react";
import { PaperProps } from "models/papers/PaperProps";
import PaperItem from "./PaperItem";
import { Link as RouterLink } from "react-router-dom";
import { LuExternalLink } from "react-icons/lu";
import { hexToRgba } from "utils/helpers";

interface PapersListProps {
  data: PaperProps[];
  originalPaper: PaperProps;
}

function PapersList({ data, originalPaper }: PapersListProps) {
  // Theme
  const { colors } = useTheme();
  const disclaimerColor = useColorModeValue("gray.500", "gray.400");
  const disclaimerLink = useColorModeValue("gray.700", "gray.300");
  const disclaimerBorder = useColorModeValue("gray.400", "gray.600");
  const disclaimerBg = useColorModeValue(
    hexToRgba(colors.highlight.neutral[200], 0.5),
    "dark.700"
  );

  return (
    <Box width={"full"} borderRadius={"6px"}>
      {data && data.length > 0 ? (
        <SimpleGrid
          spacing={0}
          templateColumns="repeat(auto-fill, minmax(200px, 1fr))"
        >
          {data.map((paper: PaperProps, index: number) => (
            <PaperItem
              key={`${index}_${paper.id}`}
              paper={paper}
              origin={originalPaper.id === paper.id}
            />
          ))}
        </SimpleGrid>
      ) : (
        <Flex
          mt={5}
          bg={disclaimerBg}
          borderColor={disclaimerBorder}
          borderWidth={1}
          borderStyle={"dashed"}
          borderRadius={"6px"}
          px={2}
          py={3}
        >
          <Text
            fontSize={[null, null, null, "12px", "12px", "14px"]}
            color={disclaimerColor}
          >
            Not available on this network, please try{" "}
            <Link as={RouterLink} to={"/papers"} color={disclaimerLink}>
              the general search
              <Icon ml={1} as={LuExternalLink} boxSize={"12px"} />
            </Link>
          </Text>
        </Flex>
      )}
    </Box>
  );
}

export default PapersList;
