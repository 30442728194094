import { useContext, useMemo } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";

import { Flex, Text, useTheme, useColorModeValue } from "@chakra-ui/react";
import { createFilter, StylesConfig } from "react-select";
import AsyncSelect from "react-select/async";

import CustomMenuList from "./CustomMenuList";
import CustomOption from "./CustomOption";

import { filterOptions } from "views/graph/helpers";
import { ChartControlsContext, OptionItem } from "views/graph";
import { hexToRgba } from "utils/helpers";

interface ChartCompoundInputProps {
  loading: boolean;
  compounds: OptionItem[];
}

export default function CompoundInput({
  loading,
  compounds,
}: ChartCompoundInputProps) {
  // Navigation
  const navigate = useNavigate();

  // Theme
  const { colors } = useTheme();
  const fontColor = useColorModeValue(colors.dark[900], colors.neutral[100]);
  const titleColor = useColorModeValue("primary.400", "primary.200");
  const selectedBg = useColorModeValue(
    colors.primary[400],
    colors.highlight.primary
  );

  // Context
  const { selectedId } = useContext(ChartControlsContext);

  const selectInputStyle: StylesConfig<{}, false> = useMemo(
    () => ({
      control: (base) => ({
        ...base,
        fontSize: "12px",
        background: hexToRgba(colors.neutral[600], 0.1),
        border: "transparent",

        "&:hover": {
          cursor: "pointer",
        },
      }),

      singleValue: (base) => ({
        ...base,
        color: fontColor,
      }),

      placeholder: (base) => ({
        ...base,
        fontSize: "12px",
        color: colors.gray[400],
      }),

      option: (base, { isSelected }: { isSelected: boolean }) => ({
        ...base,
        paddingLeft: 10,
        fontSize: "12px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        backgroundColor: isSelected ? selectedBg : "transparent",
        color: isSelected ? colors.primary[100] : colors.primary[800],
        "&:hover": {
          backgroundColor: isSelected ? selectedBg : colors.neutral[200],
          color: isSelected ? colors.primary[100] : colors.primary[800],
        },
      }),
    }),
    [colors, fontColor, selectedBg]
  );

  let defaultValue: OptionItem | undefined = compounds?.find(
    (compound: OptionItem) => compound?.value === selectedId
  );

  const promiseOptions = (input: string) =>
    new Promise<OptionItem[]>((resolve) => {
      resolve(filterOptions(input, compounds));
    });

  const handleCompoundChange = (choice: OptionItem | null) => {
    const { value } = choice || {};

    navigate({
      pathname: "/graph",
      search: value ? `?${createSearchParams({ compound: value })}` : "",
    });
  };

  const placeholderText = `Explore +10.000 compound..`;

  return (
    <Flex direction={"column"} gap={1} justify={"center"} my={2}>
      <Text
        fontWeight={"500"}
        fontSize={[null, null, null, "12px", "12px", "12px"]}
        color={titleColor}
      >
        Compound
      </Text>
      <AsyncSelect
        placeholder={placeholderText}
        closeMenuOnSelect
        isSearchable
        cacheOptions
        isClearable={true}
        styles={selectInputStyle}
        isLoading={loading}
        defaultValue={defaultValue}
        value={selectedId ? defaultValue : null}
        defaultOptions={compounds}
        loadOptions={promiseOptions}
        filterOption={createFilter({ ignoreAccents: true })}
        onChange={handleCompoundChange}
        classNamePrefix="custom-select"
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
          Option: CustomOption,
          MenuList: (props) => {
            return (
              <CustomMenuList
                options={compounds}
                maxHeight={props.maxHeight}
                getValue={props.getValue}
                children={props.children}
              />
            );
          },
        }}
      />
    </Flex>
  );
}
