import HelperProps from "./HelperProps";

export const activitydataprops_helper: HelperProps[] = [
  {
    title: "Assay",
    description:
      "Assay refers to a method or procedure used in the laboratory to analyze and measure the presence, concentration, or activity of a substance or biological component of interest.",
  },
  {
    title: "Assay Type",
    description:
      "Assays can vary widely based on the specific purpose and the nature of the substance being studied. They could involve techniques such as biochemical assays, cell-based assays, or molecular assays.",
  },
  {
    title: "Indication",
    description:
      "Refers to the specific purpose or reason for conducting the assay. It outlines the intended use or what the assay is designed to detect, measure, or analyze. ",
  },
  {
    title: "Activity Type",
    description: "",
  },
  {
    title: "Measurement",
    description:
      "Refers to the outcomes or results obtained from a scientific assay or experimental test that indicate a potential positive response or activity. The measurement within an assay is the crucial outcome that provides data or results regarding the substance or process being studied.",
  },
  {
    title: "Assay Source",
    description: "Refers to the entity that carried out the assays.",
  },
];
