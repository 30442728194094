import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams, Outlet } from "react-router-dom";
import { Box, Divider, Flex } from "@chakra-ui/react";

import SidebarLayout from "layouts/Sidebar";
import GraphTools from "./Tools";
import Loading from "components/ui/Loading";
import SimilarCompoundsTools from "components/graph/SimilarCompoundsTools";
import FoldersModal from "components/saved/FoldersModal";
import { appendNodesAndLinks, createRootNode, getChartData } from "./helpers";

// utils
import { useFetch } from "hooks";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import { environment } from "environments";
import ChemicalProps from "models/compounds/ChemicalProps";
import { maxWidth, minWidth } from "utils/responsive";

// Props types
export interface Node {
  name: string;
  level: number;
}

export interface Link {
  source: string;
  target: string;
  value: number;
}

export interface ChartDataProps {
  nodes: Node[] | LeafNode[];
  links: Link[];
}

export interface ReducedCompoundProps {
  compound_id: string;
  generic_name?: string;
  name: string;
  chemical_props?: ChemicalProps;
}

export interface LeafNode extends Node {
  checked?: boolean;
  leaf_id?: string;
}

export interface SimilaritiesProps {
  [key: string]: ReducedCompoundProps[];
}

export interface OptionItem {
  readonly value: string;
  readonly label: string;
}

export interface CharDataExtendedProps {
  selectedCompounds: ChartDataProps;
  selectedNames: string[];
}

interface ChartControlsContextProps {
  selectedId: string;
  selectedFeature: string;
  similarCompounds: {
    compound: {};
    similarities: {};
  };
  allData: ChartDataProps;
  chartData: CharDataExtendedProps;
}

export const ChartControlsContext =
  React.createContext<ChartControlsContextProps>({
    selectedId: "",
    selectedFeature: "",
    similarCompounds: { compound: {}, similarities: {} },
    allData: { nodes: [], links: [] },
    chartData: {
      selectedCompounds: { nodes: [], links: [] },
      selectedNames: [],
    },
  });

export default function GraphView() {
  // Hooks
  const axiosPrivate = useAxiosPrivate();

  const [searchParams] = useSearchParams();

  const [chartData, setChartData] = useState<CharDataExtendedProps>({
    selectedCompounds: { nodes: [], links: [] },
    selectedNames: [],
  });

  const [showSaveModal, setShowSaveModal] = useState(false);

  // API: Get all compounds names
  const { data: allCompoundsList, loading: compoundsListLoading } = useFetch(
    useCallback(async () => {
      const response = await axiosPrivate.get(
        `${environment.BACKEND_API}/api/get_all_compounds_names`
      );

      return response?.data ?? [];
    }, [axiosPrivate])
  );

  // Filters
  const { compoundId, feature } = useMemo(() => {
    const compoundId = searchParams.get("compound");
    const feature = searchParams.get("feature");

    return {
      compoundId,
      feature,
    };
  }, [searchParams]);

  // API - Get similar compounds by feature and compound id
  const { data: similarCompoundsData, loading: similarCompoundsLoading } =
    useFetch(
      useCallback(async () => {
        if (compoundId && feature) {
          // rename back "assay_hits" to "indication" before calling the endpoint
          const aliasedFeature =
            feature === "assay_hits" ? "indication" : feature;

          const response = await axiosPrivate.get(
            `${environment.BACKEND_API}/api/find_similar_compounds_by_feature?compound_id=${compoundId}&feature=${aliasedFeature}`
          );

          return response.data;
        }
        return {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [feature])
    );

  // convert compounds names into data-format objects suitable for the chart
  const allCompoundsNames = useMemo(() => {
    const transformed: OptionItem[] = allCompoundsList?.map(
      (cmpd: ReducedCompoundProps) => {
        function getLabel() {
          if (cmpd.generic_name && cmpd.generic_name !== "(no name)") {
            return cmpd.generic_name;
          } else if (cmpd.name && cmpd.name !== "(no name)") {
            return cmpd.name;
          }
          return cmpd.name;
        }

        return {
          value: cmpd.compound_id,
          label: getLabel(),
        };
      }
    );

    // sort compounds alphabetically (asc.)
    const sorted: OptionItem[] = transformed
      ?.slice()
      .sort((a: OptionItem, b: OptionItem) => a.label.localeCompare(b.label));

    return sorted;
  }, [allCompoundsList]);

  const getAllData = useMemo((): ChartDataProps => {
    const _allData: ChartDataProps = { nodes: [], links: [] };

    if (
      compoundId &&
      feature &&
      similarCompoundsData &&
      _allData.nodes.length === 0 &&
      _allData.links.length === 0
    ) {
      const { compound, similarities } = similarCompoundsData;

      // if root compound exists and contains data
      const hasRoot: boolean = compound && Object.keys(compound)?.length > 0;

      if (hasRoot) {
        const compoundName: string =
          (compound as ReducedCompoundProps)?.generic_name ||
          (compound as ReducedCompoundProps)?.name;

        // add root node to data
        createRootNode(_allData, compoundName);

        // if similarities(features + similar compounds) exist and contains data
        const hasSimilarities: boolean =
          similarities && Object.keys(similarities)?.length > 0;

        if (hasSimilarities) {
          // add remaininng nodes to data, and their associated links
          appendNodesAndLinks(_allData, similarities, compoundName);
        }
      }
      return _allData;
    }

    return { nodes: [], links: [] };
  }, [compoundId, feature, similarCompoundsData]);

  function handleAddCompound(
    compoundName: string,
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    const addCompound: boolean = event?.target?.checked;

    if (compoundName) {
      // if item checked, remove it from list
      if (!addCompound) {
        setChartData((prevData: CharDataExtendedProps) => ({
          ...prevData,
          selectedNames: prevData.selectedNames.filter(
            (name: string) => name !== compoundName
          ),
          selectedCompounds: {
            ...prevData.selectedCompounds,
            nodes: prevData.selectedCompounds.nodes.filter(
              (node: Node | LeafNode) => node.name !== compoundName
            ),
            links: prevData.selectedCompounds.links.filter(
              (node: Link) => node.target !== compoundName
            ),
          },
        }));
      }
      // if item checked, concat it to list
      else {
        const addedNode: Node | LeafNode | undefined = getAllData.nodes.find(
          (node: Node | LeafNode) => node.name === compoundName
        );

        const addedLinks: Link[] | undefined = getAllData.links.filter(
          (link: Link) => link.target === compoundName
        );

        if (!!addedNode && !!addedLinks) {
          setChartData((prevData: CharDataExtendedProps) => ({
            selectedNames: prevData.selectedNames.concat(compoundName),
            selectedCompounds: {
              nodes: [...prevData.selectedCompounds.nodes, addedNode],
              links: [...prevData.selectedCompounds.links, ...addedLinks],
            },
          }));
        }
      }
    }
  }

  // Update chartData whenever similarCompoundsData or getAllData changes
  useEffect(() => {
    const updatedChartData = getChartData(similarCompoundsData, getAllData);
    setChartData(updatedChartData);
  }, [similarCompoundsData, getAllData]);

  return (
    <Flex>
      <ChartControlsContext.Provider
        value={{
          selectedId: compoundId ?? "",
          selectedFeature: feature ?? "",
          similarCompounds: similarCompoundsData ?? {
            compound: {},
            similarities: {},
          },
          allData: getAllData ?? { nodes: [], links: [] },
          chartData,
        }}
      >
        {/* Sidebar */}
        <SidebarLayout>
          <GraphTools
            loading={compoundsListLoading}
            compounds={allCompoundsNames}
            onSaveClicked={() => setShowSaveModal(true)}
          />
          <Divider my={4} />
          <SimilarCompoundsTools
            loading={similarCompoundsLoading}
            onAddCompound={handleAddCompound}
          />
        </SidebarLayout>

        {/* Main Panel */}
        <Box w={minWidth} minW={minWidth} maxW={maxWidth} mx={"auto"} py={2}>
          {!!compoundsListLoading ? (
            <Loading message="Loading compounds.." />
          ) : (
            <Outlet context={similarCompoundsLoading} />
          )}
        </Box>
      </ChartControlsContext.Provider>

      {/* Modal */}
      {compoundId && feature && (
        <FoldersModal
          isOpen={showSaveModal}
          onClose={() => setShowSaveModal(false)}
          payload={{
            saveElementPayload: {
              elementType: "TREEOFLIFE",
              content: {
                elementId: compoundId,
                selected_feature:
                  feature === "assay_hits" ? "indication" : feature,
              },
            },
            successMessage: `Tree of life is successfully saved.`,
          }}
        />
      )}
    </Flex>
  );
}
