import {
  FormControl,
  FormErrorMessage,
  Textarea,
  useTheme,
} from "@chakra-ui/react";
import { ChangeEvent } from "react";
import { hexToRgba } from "utils/helpers";

interface MessageFormControlProps {
  message: string;
  isInvalid: boolean;
  onMessageChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

function MessageFormControl({
  message,
  isInvalid,
  onMessageChange,
}: MessageFormControlProps) {
  // Theme
  const { colors } = useTheme();

  return (
    <FormControl isRequired isInvalid={isInvalid}>
      <Textarea
        isRequired
        name="message"
        value={message}
        onChange={onMessageChange}
        placeholder="Write message here..."
        _placeholder={{ color: "gray.600" }}
        resize={"none"}
        h={"140px"}
        borderRadius={"8px"}
        color={"dark.500"}
        bg={hexToRgba(colors.gray[50], 0.5)}
        border={"none"}
        _hover={{ bg: hexToRgba(colors.gray[50], 0.7) }}
        _focusVisible={{ bg: hexToRgba(colors.gray[50], 0.7) }}
      />
      <FormErrorMessage color={"red.50"}>
        Message is required. Should be longer than 3 characters.
      </FormErrorMessage>
    </FormControl>
  );
}

export default MessageFormControl;
