import { Box, useBreakpointValue } from "@chakra-ui/react";
import { CustomScrollBar } from "components/ui/CustomScrollBar";

import { mWTutorials, maxHeight } from "utils/responsive";
import VideoTutorialView from "./VideoTutorialView";

export default function Tutorials() {
  const TutorialsHeight = useBreakpointValue(maxHeight);
  const maxTutorialsWidth = useBreakpointValue(mWTutorials);

  const TutorialsStyle = {
    height: TutorialsHeight,
    width: "100%",
    maxWidth: maxTutorialsWidth,
    margin: "0 auto",
  };

  return (
    <Box w={mWTutorials} mx={"auto"} py={2}>
      <CustomScrollBar style={TutorialsStyle}>
        <VideoTutorialView />
      </CustomScrollBar>
    </Box>
  );
}
