import { GridItem, Text } from "@chakra-ui/react";

interface SuggestionYearProps {
  year: number | undefined;
}

function SuggestionYear({ year }: SuggestionYearProps) {
  return (
    <GridItem textAlign={"right"}>
      <Text fontSize={"12"} color={"gray.500"}>
        {year}
      </Text>
    </GridItem>
  );
}

export default SuggestionYear;
