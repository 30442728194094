import parse from "html-react-parser";
import { useBreakpointValue, useColorModeValue } from "@chakra-ui/react";

const FormattedText = ({ text }: { text: string }) => {
  // Theme
  const textColor = useColorModeValue("gray.600", "gray.300");

  const fontSize = useBreakpointValue({
    base: "14px",
    sm: "14px",
    md: "14px",
    lg: "14px",
    xl: "15px",
    "2xl": "15px",
  });

  if (!text.length) {
    return <div style={{ fontSize: fontSize, lineHeight: "1.7" }}>{text}</div>;
  }

  const boldedText = text.replace(/\*\*(.*?)\*\*/g, `<strong >$1</strong>`);
  const formattedString = boldedText.replace(/\*\*/g, "");
  const parsedText = parse(formattedString);

  return (
    <div style={{ fontSize: fontSize, lineHeight: "1.7", color: textColor }}>
      {parsedText}
    </div>
  );
};

export default FormattedText;
