import { Flex, Image, Text, keyframes, useTheme } from "@chakra-ui/react";
import { hexToRgba } from "utils/helpers";

const fadeFeature = keyframes`
  0% {
    transform: translateY(30px) scale(0.7);
    opacity: 0;
  }
  100% {
    transform: translateY(0px) scale(1);
    opacity: 1;
  }
`;

interface NewChatFeatureProps {
  value: string;
  delay: string;
  icon: string;
}

function NewChatFeature({ value, delay, icon }: NewChatFeatureProps) {
  // Theme
  const { colors } = useTheme();

  return (
    <Flex
      gap={2}
      align={"center"}
      border={`1px solid ${colors.highlight.primary}`}
      p={3}
      borderRadius={6}
      opacity={0}
      animation={`${fadeFeature} 1s ease-out ${delay} forwards`}
    >
      <Flex
        p={2}
        borderRadius={"50%"}
        bg={hexToRgba(colors.primary[200], 0.12)}
      >
        <Image src={icon} maxW={"28px"} objectFit="cover" alt="feature" />
      </Flex>
      <Text
        fontWeight={"500"}
        fontSize={"14px"}
        color={"gray.500"}
        lineHeight={"1.3"}
      >
        {value}
      </Text>
    </Flex>
  );
}

export default NewChatFeature;
