import React, { ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BiSolidRightArrow } from "react-icons/bi";
import {
  Box,
  Divider,
  Flex,
  Heading,
  IconButton,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  useTheme,
} from "@chakra-ui/react";

import { GetGradients } from "utils/gradients";
import useNavigationTabs from "hooks/useNavigationTabs";
import { ItemProps } from "layouts/nav";
import { hexToRgba } from "utils/helpers";
import MoreInfo from "components/papers/MoreInfo";

const viewMapper: Record<string, Record<string, JSX.Element | string>> = {
  chat: {
    name: "Smart Agent",
  },
  compounds: {
    name: "Compounds",
  },
  assays: {
    name: "Assays",
  },
  papers: {
    name: "Literature Map",
  },
  graph: {
    name: "Graphs",
  },
  tutorials: {
    name: "Tutorials",
  },
  saved: {
    name: "Folders",
  },
};

interface SidebarLayoutProps {
  children: ReactNode;
}

const SidebarLayout: React.FC<SidebarLayoutProps> = ({ children }) => {
  // Hooks
  const location = useLocation();

  const { pathname } = location;
  const { navItems } = useNavigationTabs();

  // Theme
  const { colors } = useTheme();
  const { colorMode } = useColorMode();
  const { navGradient, navGradientDark } = GetGradients();
  const bgGradient = useColorModeValue(navGradient, navGradientDark);
  const borderColor = useColorModeValue("transparent", "dark.700");
  const headingColor = useColorModeValue("secondary.800", "gray.300");
  const buttonBg = useColorModeValue("neutral.200", "dark.600");
  const buttonColor = useColorModeValue("neutral.600", "neutral.500");
  const boxShadow = useColorModeValue(
    "0 8px 32px 0 rgba(20, 26, 97, 0.37)",
    "none"
  );

  const pathSegments = pathname.split("/");
  const [active, setActive] = useState(pathSegments[1]);

  //   toggle
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });

  useEffect(() => {
    // extract first word after /
    const regex = /\/([^/]+)/;
    // search for a match in the URL using the regex pattern
    const match = pathname.match(regex);
    if (match) {
      const existingLink: ItemProps | undefined = navItems.find(
        (item: ItemProps) => item.name === match[1]
      );
      if (existingLink === undefined) {
        setActive(match[1]);
      } else {
        setActive(existingLink.name);
      }
    }
  }, [pathname, navItems, colorMode, active]);

  if (!isOpen) {
    return (
      <Box position={"relative"}>
        <IconButton
          icon={<BiSolidRightArrow />}
          onClick={onToggle}
          aria-label="Toggle Navigation"
          position={"absolute"}
          p={0}
          top={14}
          left={-8}
          zIndex={99}
          bg={hexToRgba(colors.neutral[100], 0.4)}
          color={buttonColor}
          _hover={{
            bg: buttonBg,
            color: buttonColor,
          }}
          isRound={true}
          // bg="rgba(255, 255, 255, 0.35)"
          boxShadow="0 8px 32px 0 rgba(20, 26, 97, 0.37)"
          backdropFilter="blur(4.5px)"
          borderRadius="10px"
          border="1px solid rgba(255, 255, 255, 0.18)"
          size={"sm"}
          mt={1}
        />
      </Box>
    );
  }

  return (
    <Flex
      direction={"column"}
      position={"relative"}
      borderRadius={"10px"}
      h={[
        null,
        null,
        null,
        "calc(100vh - 16px)",
        "calc(100vh - 24px)",
        "calc(100vh - 32px)",
      ]}
      w={"20vw"}
      maxW={"400px"}
      minW={"240px"}
      pb={[null, null, null, 4, 5, 6]}
      pt={[null, null, null, 8, 9, 10]}
      px={[null, null, null, "12px", "14px", "16px"]}
      mx={4}
      zIndex={99}
      borderColor={borderColor}
      bgGradient={bgGradient}
      boxShadow={boxShadow}
      borderWidth={1}
    >
      {/* Heading */}
      <Heading
        fontSize={[null, null, null, "20px", "22px", "24px"]}
        lineHeight={1}
        color={headingColor}
        fontWeight={"regular"}
        flexDirection={"row"}
      >
        {viewMapper[active].name}
        {viewMapper[active].name === "Literature Map" && (
          <span style={{marginLeft: 4}}>
            <MoreInfo />
          </span>
        )}
      </Heading>

      <Divider my={4} bg={"gray.200"} />

      {/* view-specific sidebar content */}
      <Flex flex={1} direction={"column"}>
        {children}
      </Flex>
    </Flex>
  );
};

export default SidebarLayout;
