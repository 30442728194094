import { axiosClient } from 'api/axios';
import { useDispatch } from 'react-redux';
import { setCredentials } from 'redux/features/auth/authSlice';

const useRefreshToken = () => {
    const dispatch = useDispatch()
    const refresh = async () => {
        try {
            const response = await axiosClient.get('/api/refresh', {
                withCredentials: true
            });

            const { user, roles } = response.data
            dispatch(setCredentials({ user, roles }))

        } catch (error) {
            // console.log(error)
        }
    }
    return refresh;
};

export default useRefreshToken;