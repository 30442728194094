import { IconButton, useColorModeValue } from "@chakra-ui/react";
import { BsRobot } from "react-icons/bs";

function BotAvatar() {
  // Theme
  const color = useColorModeValue("primary.800", "highlight.primary");
  const bg = useColorModeValue("whiteAlpha.600", "dark.700");

  return (
    <IconButton
      icon={<BsRobot />}
      cursor={"default"}
      _hover={{
        color: color,
        bg: bg,
      }}
      mr={2}
      isRound={true}
      boxShadow={"md"}
      aria-label=""
      disabled
      color={color}
      bg={bg}
    />
  );
}

export default BotAvatar;
