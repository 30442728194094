import HelperProps from "./HelperProps";

export const pharmaprops_helper: HelperProps[] = [
  {
    title: "Origin",
    description:
      "Compound origin refers to the source or the way a particular compound is obtained or derived. It typically indicates whether a compound is naturally occurring or chemically synthesized.",
  },
  {
    title: "Phases",
    description:
      "Phases are designed to assess the safety, efficacy, and overall viability of the drug. The phases can vary slightly depending on the region and regulatory agency, but the general phases are as follows: preclinical, first-in-human, clinical efficacy, large scale clinical trials, post marketing surveillance.",
    image: "",
  },
  {
    title: "Pharmacokinetics",
    description:
      "These attributes describe how the body processes the molecule, including absorption, distribution, metabolism, and excretion (ADME). Key parameters include: - Bioavailability: The fraction of the administered dose that reaches the systemic circulation. - Half-life: The time it takes for half of the drug to be eliminated from the body. - Clearance: The rate at which the drug is removed from the body.",
  },
  {
    title: "Pharmacodynamics",
    description:
      "These attributes describe how the molecule interacts with its target(s) and produces its effects. Key parameters include: - Potency: The concentration of the drug required to produce a specific effect. - Efficacy: The maximal effect that can be achieved with the drug. - Binding Affinity: The strength of the interaction between the drug and its target.",
  },
  {
    title: "Pharmacogenetics",
    description:
      "Information on how a person's genetic makeup can affect their response to the drug.",
  },
  {
    title: "Route of Administration",
    description:
      "Method by which a drug or medication is introduced into the body. Common routes of administration include oral (by mouth), intravenous (into a vein), intramuscular (into a muscle), subcutaneous (under the skin), transdermal (through the skin), and so on.",
  },
  {
    title: "Modes of Application",
    description:
      "Refers to the many ways in which a substance is applied or used, not necessarily related to drug administration. (e.g., as a cream, ointment, or gel) or how a medical device is used for drug delivery (e.g., an inhaler or a patch).",
  },
  {
    title: "Dosing Regimen",
    description:
      "Guidelines for how the drug should be administered, including dosing frequency, duration of treatment, and dosage adjustments for different patient populations.",
  },
  {
    title: "Indications & Medical conditions treated",
    description:
      "Indications are approved uses of a drug, it describes the medical condition or disease that the drug is intended to treat, prevent, or manage. While the term medical conditions treated can include a more comprehensive list of conditions, both approved and off-label, for which a drug might be prescribed or used in clinical practice.",
  },
  {
    title: "Target",
    description:
      "Refers to a specific molecule, usually a protein or nucleic acid (such as DNA or RNA), in the body that a drug or a compound interacts with. The interaction between the compound and its target can lead to various biological effects. These effects can range from the alteration of a biochemical process to the modulation of a specific biological function.",
  },
  {
    title: "Mechanism of Action",
    description:
      "Specific function or purpose of the drug within a treatment regimen or therapeutic context.",
  },
  {
    title: "Side Effects",
    description:
      "Side effects are unintended or secondary effects that occur in addition to the intended therapeutic effects of a medication, treatment, or substance. They can manifest as a result of the intended use of a drug, interaction with other substances, individual variability in response, or the body's reaction to the compound. ",
  },
  {
    title: "Toxicity",
    description:
      "Information about the molecule's potential for adverse effects or toxicity. ",
  },
  {
    title: "Pharmaceutical Formulation",
    description:
      "Details about how the drug is formulated for administration, including dosage form (e.g., tablet, capsule, injection), excipients, and stability.",
  },
  {
    title: "Pregnancy Category",
    description:
      "Also known as the pregnancy risk category, is a classification system used to inform healthcare providers about the potential risks associated with using a particular drug or compound during pregnancy.",
  },
];
