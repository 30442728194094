import { Dispatch, SetStateAction } from "react";
import { ErrorsProps, FormDataProps } from "./Form";

export function checkFormDataValidation(
  formData: FormDataProps,
  setErrors: Dispatch<SetStateAction<ErrorsProps>>
) {
  const { name, email, message } = formData;
  // validate name
  const isValidName = name?.trim().length >= 3;
  setErrors((prev: ErrorsProps) => ({ ...prev, name: !isValidName }));

  // validate email
  const isValidEmail = email?.trim().length >= 3 && /^\S+@\S+$/i.test(email);
  setErrors((prev: ErrorsProps) => ({ ...prev, email: !isValidEmail }));

  // validate message
  const isValidMessage = message?.trim().length >= 3;
  setErrors((prev: ErrorsProps) => ({ ...prev, message: !isValidMessage }));

  return isValidName && isValidEmail && isValidMessage;
}
