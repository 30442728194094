import { Flex } from "@chakra-ui/react";
import NavItem from "./NavItem";

function Menu() {
  return (
    <Flex gap={6} justify="center" align="center">
      <NavItem to="#about" text="About" />
      <NavItem to="#pipeline" text="Pipeline" />
      <NavItem to="#faq" text="FAQ" />
      <NavItem to="#contact" text="Contact Us" />
    </Flex>
  );
}

export default Menu;
