import { Box, Heading, Text } from "@chakra-ui/react";

function Header() {
  return (
    <Box
      my={10}
      textAlign={"center"}
      w={"fit-content"}
      maxW={"600px"}
      mx={"auto"}
    >
      <Text
        size={[null, null, null, "sm", "sm", "md"]}
        fontWeight={"bold"}
        letterSpacing={2}
        color={"neutral.100"}
        opacity={0.85}
      >
        - Pipeline
      </Text>
      <Heading
        size={[null, null, null, "lg", "lg", "xl"]}
        letterSpacing={1}
        color={"neutral.100"}
        opacity={0.65}
      >
        Our Process
      </Heading>
      <Text pt={6} color={"primary.100"} fontFamily={"Poppins, sans-serif"}>
        With our proven data enrichment pipeline, we make it easy to explore the
        full scientific corpus and accelerate drug discovery.
      </Text>
    </Box>
  );
}

export default Header;
