import { Flex } from "@chakra-ui/react";
import { Videos } from "views/tutorials/helpers";
import VideoSteps from "./VideoSteps";

interface StepsContainerProps {
  currentVideo: number;
}

function StepsContainer({ currentVideo }: StepsContainerProps) {
  const video = Videos?.at(currentVideo);

  return (
    <Flex
      h={"100%"}
      w={"100%"}
      justify={"center"}
      my={[null, null, null, 4, 0, 0]}
    >
      {video && <VideoSteps steps={video.steps} labels={video.labels} />}
    </Flex>
  );
}

export default StepsContainer;
