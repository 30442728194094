import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";

const RequireAuth = ({ allowedRoles }: { allowedRoles: string[] }) => {
    const { user } = useSelector(selectCurrentAuthData);
    const location = useLocation();
    return (
        // roles?.find(role => allowedRoles?.includes(role))
        user && allowedRoles?.includes(user.user_type)
            ? <Outlet />
            : user
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;